import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagesService } from 'src/app/services/images.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocationsService } from '../../_services/locations.service';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { FilesService } from 'src/app/_shared/components/files/files.service';
import { config } from 'src/app/_shared/configs/config';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-location-edit-details',
  templateUrl: './location-details.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditDetailsPage implements OnInit {
  
  isLoading = false;
  locationId;
  locationSubscription: Subscription;
  location: any;
  locationEditForm: FormGroup;

  // PROFILE IMAGE
  imgPreview = '/assets/images/locations/location_profile.jpg';
  loadingImage: boolean = false;

  // SUBMIT
  isSubmitting = false;

  //currentPlatform
  currentPlatform:string;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private cameraPlugin: Camera,
    private filesService: FilesService,
    private file: File,
    private alert: AlertService,
    private locationsService: LocationsService,
    private base64: Base64,
    public sanitizer: DomSanitizer,
    public storageServices:StorageService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();

    this.storageServices.currentPlatform.subscribe(platform =>{
      this.currentPlatform = platform;
    });
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.buildForm();

    this.locationSubscription = this.locationsService.getLocationInfo(this.locationId).subscribe((locationData: any) => {
      this.location = locationData;
      this.loadingImage = false;
      this.locationEditForm.patchValue(this.location);
      this.isLoading = false;
    });
  }

  buildForm() {
    this.locationEditForm = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      typeOther: [''],
      description: ['', [Validators.required]],
      contactNumber: ['', [Validators.required]],
      meetingRooms: ['', [Validators.required]],
      eventSpace: ['', Validators.required],
      noiseLevel: ['', Validators.required],
      parking: ['', Validators.required],
      availableSpaces: ['', [Validators.required, Validators.min(1)]]
    });
  }

  onTypeChange(event) {
    let val = event.target.value;
    if (val === 'other') {
      this.locationEditForm.get('typeOther').setValidators(Validators.required);
      this.locationEditForm.get('typeOther').updateValueAndValidity();
    } else {
      this.locationEditForm.get('typeOther').reset();
      this.locationEditForm.get('typeOther').clearValidators();
      this.locationEditForm.get('typeOther').updateValueAndValidity();
    }
  }

  onFileSelectedFromWeb(event) {
    console.log("Incoming file", event[0]);
    const pathInfo = {
      filePath: `entities/${config.entityId}/locations/${this.locationId}`,
      dbPath: `entities/${config.entityId}/locations/${this.locationId}`
    };
    console.log("file path",pathInfo.filePath);
    this.loadingImage = true;
    this.filesService.handleDrop(event, pathInfo);

  }

  // Take a picture using the phone camera
  takePicture(type) {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingImage = true;
          this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
            entry.file(file => {
              const imageFile = [file]
              const pathInfo = {
                filePath: `entities/${config.entityId}/locations/${this.locationId}`,
                dbPath: `entities/${config.entityId}/locations/${this.locationId}`
              };
              this.setImage(imageFile, pathInfo);
            });
          });
        },
        (error) => {
          console.log('ERROR TAKING PHOTO -> ' + JSON.stringify(error));
        }
      );
  }

  // Select a picture from the phone gallery
  selectPicture(type) {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingImage = true;
          this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
            entry.file(file => {
              const imageFile = [file]
              const pathInfo = {
                filePath: `entities/${config.entityId}/locations/${this.locationId}`,
                dbPath: `entities/${config.entityId}/locations/${this.locationId}`
              };
              this.setImage(imageFile, pathInfo);
            });
          });
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  setImage(imagesArray, pathInfo) {
    this.filesService.handleDrop(imagesArray, pathInfo);
    this.loadingImage = false;
  }

  onUpdate(locationEditForm) {

    const locationData = {
      name: locationEditForm.name,
      type: locationEditForm.type,
      typeOther: locationEditForm.typeOther,
      description: locationEditForm.description,
      contactNumber: locationEditForm.contactNumber,
      meetingRooms: locationEditForm.meetingRooms,
      eventSpace: locationEditForm.eventSpace,
      noiseLevel: locationEditForm.noiseLevel,
      parking: locationEditForm.parking,
      availableSpaces: locationEditForm.availableSpaces,
    }

    this.isSubmitting = true;
    this.locationsService.updateLocation(this.locationId, locationData).then(() => {
      this.isSubmitting = false;
      this.locationEditForm.markAsPristine();
      this.alert.displayToast('Location updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.locationSubscription.unsubscribe();
  }

}
