import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertService } from 'src/app/services/alert.service';
import { Platform } from '@ionic/angular';
import { TermsAndConditionsComponent } from 'src/app/_shared/components/modals/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from 'src/app/_shared/components/modals/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['../auth.scss'],
})
export class LandingPage implements OnInit {
  
  user: any;
  loading = false;
  isIOS = false;

  constructor(
    public afAuth: AngularFireAuth,
    private platform: Platform,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    if (this.platform.is('ios')) {
      this.isIOS = true;
    }
  }

  openTermsModal() {
    this.alertService.openModal(TermsAndConditionsComponent, {}, 'modal-terms');
  }

  openPrivacyModal() {
    this.alertService.openModal(PrivacyPolicyComponent, {}, 'modal-terms');
  }

}
