import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { MapsSearchComponent } from './maps-search/maps-search.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { MapsLocationsComponent } from './maps-locations/maps-locations.component';
import { NgPipesModule } from 'ngx-pipes';
import { IonicModule } from '@ionic/angular';
import { BoostedItemsPipe } from '../../pipes/boosted-locations.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtzpkn_YCwOiDNLhP54Ye8tz1p352lW4k',
      libraries: ['places']
    }),
    NgPipesModule
  ],
  declarations: [
    MapsSearchComponent,
    MapsLocationsComponent,
    BoostedItemsPipe
  ],
  exports: [
    MapsSearchComponent,
    MapsLocationsComponent,
    BoostedItemsPipe
  ]
})
export class MapsModule { }
