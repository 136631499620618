import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { Subscription } from 'rxjs';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { User } from 'src/app/_shared/models/users.model';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';

@Component({
  selector: 'app-location-checkin-component',
  templateUrl: './location-checkin.component.html'
})
export class LocationCheckinComponent implements OnInit {
  location: any;
  loading = false;
  user: User;
  bookingId;
  allowUserBooking = false;
  userTierSubscriptions = [];
  userTierSubscription: Subscription;
  submitting = false;
  processing = false;
  checkedIn = false;
  locationBookings = [];
  userBookedDays = [];

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private auth: AuthenticationService,
    private subscriptionsService: SubscriptionsService,
    private alertCtrl: AlertController,
    private bookingsService: BookingsService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;

        this.userTierSubscription = this.subscriptionsService.getUserSubscriptions(this.user.uid).subscribe((subs: any) => {
          this.userTierSubscriptions = subs.filter((e) => { return e.type === 'userSubscription' || e.type === 'teamSubscription' && e.teamId; });


          this.userTierSubscriptions.filter(subscription => {
            if (subscription.product === this.location.product.title) return this.allowUserBooking = true;
            else if (subscription.product === 'Classic' && this.location.product.title !== 'Premium') return this.allowUserBooking = true;
            else if (subscription.product === 'Premium') return this.allowUserBooking = true;
          });
        });

        this.bookingsService.getUserTypeBookings(this.location.uid, 'location').subscribe(locationBookings => {
          if (locationBookings.length > 0) {
            this.locationBookings = locationBookings;

            // GET USERS BOOKED DAYS FOR BOOKING POP UP
            this.userBookedDays = [];
            this.locationBookings.forEach(booking => {
              const bookingDates = {
                date: booking.startTime,
                disable: true
              }
              this.userBookedDays.push(bookingDates);
            });

            if (this.user) {
              const currentDate = new Date();
              const currentYear = currentDate.getFullYear();
              const currentMonth = currentDate.getMonth();
              const today = currentDate.getDate();
              const locationBookingCheck = this.locationBookings.some(locationInfo => {

                this.checkedIn = locationInfo.booking.checkedIn;
                if (locationInfo.booking.typeId === this.location.uid && locationInfo.startTime.getFullYear() === currentYear
                  && locationInfo.startTime.getMonth() === currentMonth && locationInfo.startTime.getDate() === today) {
                    this.location.bookingId = locationInfo.booking.uid;
                  return true;
                } else {
                  return false;
                }
              });
              console.log('location booking check ', locationBookingCheck);
              this.loading = false;
              if (locationBookingCheck && this.location.isOpen) {

                this.location.isBooked = true;
              } else {
                this.location.isBooked = false;
              }
            }
          }
        });
      }
    })
  }

  buildLoginForm() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  checkInToLocation() {
    console.log('checking in to location ', this.location);
    this.alertService.displayToast(`Checked in to ${this.location.name}`);
    this.modalCtrl.dismiss();
  }

  async openCheckIn() {
    if (this.userTierSubscriptions.length > 1) {
      let inputs = [];
      this.userTierSubscriptions.forEach((sub, i) => {
        console.log('sub info for each ', sub, i);
        if (sub.type === 'userSubscription' && !sub.suspended || sub.type === 'teamSubscription' && sub.teamId && !sub.suspended) {
          const subName = (sub.type === 'userSubscription') ? 'Personal' : sub.teamName;
          const subData = {
            name: `sub-${sub.type}`,
            type: 'radio',
            id: `radio-${i}`,
            value: sub,
            label: `${sub.product} (${subName})`
          };
          inputs.push(subData);
        }
      });
      const alertPopup = await this.alertCtrl.create({
        header: 'Select Subscription For Booking',
        buttons: [{
          text: 'Proceed',
          handler: data => {
            console.log('data from alert ', data);
            this.checkIn(data);
          }
        }],
        inputs: inputs
      });
      alertPopup.present();
    }
    else {
      this.checkIn(this.userTierSubscriptions[0]);
    }
  }

  checkIn(subscription) {
    this.processing = true;
    const data = {
      checkedIn: true
    };

    this.bookingsService.updateBooking(data, this.bookingId).then(() => {
      console.log('booking id ', this.bookingId);
      this.bookingsService.createCheckIn(this.location, this.bookingId, subscription).then(() => {
        this.alertService.displayToast('Check in successful');
        this.modalCtrl.dismiss();
        this.processing = false;
      }).catch(err => {
        console.log('THERE');
        this.modalCtrl.dismiss();
        this.processing = false;
        this.alertService.displayToast('There was an error checking in.');
      });
    }).catch(err => {
      console.log('HERE ', err);
      this.modalCtrl.dismiss();
      this.processing = false;
      this.alertService.displayToast('There was an error checking in.');
    })
  }

}
