import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { LocationsService } from '../_services/locations.service';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html'
})
export class LocationsListComponent implements OnInit {
  isLoading = false;
  user: User;

  locations: any[];
  userSubscription: Subscription;
  locationsSubscription: Subscription;

  constructor(
    private locationsService: LocationsService,
    private auth: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.userSubscription = this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.locationsSubscription = this.locationsService.getLocationsForUser(user.uid).subscribe(locationsData => {
          console.log('locations data ', locationsData);
          this.locations = locationsData;
          this.isLoading = false;
        });
      }
    });
  }

  acceptInvite(location, value) {
    let acceptedValues;
    if (value === 'yes') acceptedValues = true;
    else if (value === 'no') acceptedValues = false;
    this.locationsService.acceptLocationInvite(location, this.user, acceptedValues);
  }

  navigateToLocation(locationId, type) {
    if (type === 'edit') {
      this.router.navigate([`/location-edit/${locationId}`]);
    }
    if (type === 'view') {
      this.router.navigate([`/location/${locationId}`]);
    }
  }
}
