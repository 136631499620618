import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-checkins-list',
  templateUrl: './checkins-list.component.html'
})
export class CheckinsListComponent implements OnInit, OnDestroy {

  @Input() checkIns$: Observable<Array<{}>>;
  @Input() viewMember: boolean;
  @Input() type: string = '';
  @Output() sendCheckIn = new EventEmitter();

  isLoading: boolean = false;

  checkInsSubscription: Subscription;
  checkIns: any[];
  allCheckIns: any[];

  searchCriteria: any;
  showResetFilter: boolean = false;

  constructor(
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.checkInsSubscription = this.checkIns$.subscribe(checkInsData => {
      this.checkIns = checkInsData;
      this.allCheckIns = checkInsData;
      this.isLoading = false;
    });
  }

  findCheckIns() {
    if (this.searchCriteria) {
      const val = this.searchCriteria.toLowerCase();
      const temp = this.allCheckIns.filter((checkIn) => {
        const created = this.datePipe.transform(checkIn.created.toDate(), 'dd MMMM yyyy');
        const userRef = checkIn.createdByRef.toLowerCase().indexOf(val) !== -1 || !val;
        const locationName = checkIn.locationName.toLowerCase().indexOf(val) !== -1 || !val;
        const locationRef = checkIn.locationRef.indexOf(val) !== -1 || !val;
        const date = created.toLowerCase().indexOf(val) !== -1 || !val;
        return userRef || locationName || locationRef || date;
      });
      this.checkIns = temp;
      this.showResetFilter = true;
    } else {
      this.checkIns = this.allCheckIns;
      this.showResetFilter = false;
    }
  }

  resetFilter() {
    this.searchCriteria = '';
    this.checkIns = this.allCheckIns;
    this.showResetFilter = false;
  }

  viewUser(userRef, userId, index, checkin) {
    const dataToSend = {
      type: 'viewUser',
      data: {
        userId,
        userRef,
        type: this.type,
        userIndex: index,
        source: 'locationCheckins',
        checkIn: checkin
      }
    }
    this.sendCheckIn.emit(dataToSend);
  }

  deleteCheckIn(checkIn) {
    checkIn.deleting = true;
    const dataToSend = {
      type: 'deleteCheckIn',
      data: checkIn
    }
    this.sendCheckIn.emit(dataToSend);
  }

  ngOnDestroy(): void {
    this.checkInsSubscription.unsubscribe();
  }

}
