import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { UsersService } from 'src/app/services/users.service';
import { Platform } from '@ionic/angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-logins',
  templateUrl: './social-logins.html',
  styleUrls: ['../../auth.scss']
})
export class SocialLoginComponent implements OnInit {

  @Input() showDivide: boolean;
  isIOS = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    public menuCtrl: MenuController,
    private alertService: AlertService,
    private usersService: UsersService,
    private platform: Platform,
    private googlePlus: GooglePlus
  ) { }

  ngOnInit() {
    if (this.platform.is('ios')) {
      this.isIOS = true;
    }
  }

  async googleLogin() {
    if (this.platform.is('cordova')) {
      try {
        const gplusUser = await this.googlePlus.login({
          webClientId: environment.webClientId,
          scopes: 'profile email'
        });
        return await this.auth.googleLogin(gplusUser.idToken).then(() => {
          this.usersService.checkUserSuspended();
        });
      } catch (err) {
        console.log(err);
        this.alertService.displayToast(`Error logging in with google: ${err}`);
      }
    } else {
      try {
        return await this.auth.desktopGoogleLogin().then(() => {
          this.usersService.checkUserSuspended();
        });
      } catch (err) {
        console.log(err);
        this.alertService.displayToast(`Error logging in with google: ${err}`);
      }
    }
  }

  async facebookLogin() {
    if (this.platform.is('cordova')) {
      this.auth.facebookLogin().then(result => {
        this.usersService.checkUserSuspended();
        this.router.navigate(['/home']);
      }).catch(error => {
        this.alertService.displayToast(`Error logging in with facebook: ${error}`);
      });
    } else {
      this.auth.desktopFacebookLogin()
        .then(() => {
          this.usersService.checkUserSuspended();
          this.router.navigate(['/home']);
        }).catch((error) => {
          this.alertService.displayToast(`Error logging in with facebook: ${error}`);
        });
    }
  }

  async appleLogin() {
    this.auth.appleLogin().then((userData) => {
      console.log('user data from apple service ', userData);
      this.usersService.checkUserSuspended();
      this.router.navigate(['/home']);
    })
      .catch(error => {
        console.log('error logging in with apple ', error);
      })
  }
}
