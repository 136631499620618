import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html'
})
export class TutorialPageComponent implements OnInit {
  // loaders
  loading = false;

  constructor() { }

  ngOnInit() {

  }
}
