import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LocationsService } from '../../_services/locations.service';
import { weekdays } from '../../models/weekdays';
import * as moment from 'moment';

@Component({
  selector: 'app-location-edit-hours',
  templateUrl: './location-hours.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditHoursPage implements OnInit {
  
  isLoading = false;
  locationId;
  location: any;
  businessHours: any;
  locationEditForm: FormGroup;
  businessHoursSubscription: Subscription;

  // HOURS
  businessDays = weekdays;
  closedTime;

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.buildForm();
    this.businessHoursSubscription = this.locationsService.getLocationHours(this.locationId).subscribe((businessHours) => {
      this.businessHours = businessHours;
      this.locationEditForm.patchValue(this.businessHoursToISODate(this.businessHours));
      this.storage.get('location').then((location) => {
        this.location = location;
      });
      this.isLoading = false;
    });

    const midnight = moment().startOf('day');
    const isoMidnight = midnight.toISOString();
    this.closedTime = isoMidnight;
  }

  buildForm() {
    this.locationEditForm = this.fb.group({
      monday: this.fb.group(this.timeFields()),
      tuesday: this.fb.group(this.timeFields()),
      wednesday: this.fb.group(this.timeFields()),
      thursday: this.fb.group(this.timeFields()),
      friday: this.fb.group(this.timeFields()),
      saturday: this.fb.group(this.timeFields()),
      sunday: this.fb.group(this.timeFields())
    });
  }

  formatDatetoISODate(open, close) {
    return {
      open: moment(open).toISOString(),
      close: moment(close).toISOString()
    };
  }

  formatISODate(open, close) {
    return {
      open: {
        hour: moment(open).hour(),
        minute: moment(open).minute(),
        second: 0
      },
      close: {
        hour: moment(close).hour(),
        minute: moment(close).minute(),
        second: 0
      }
    };
  }

  businessHoursToISODate(hours) {
    return {
      monday: this.formatDatetoISODate(hours.monday.open, hours.monday.close),
      tuesday: this.formatDatetoISODate(hours.tuesday.open, hours.tuesday.close),
      wednesday: this.formatDatetoISODate(hours.wednesday.open, hours.wednesday.close),
      thursday: this.formatDatetoISODate(hours.thursday.open, hours.thursday.close),
      friday: this.formatDatetoISODate(hours.friday.open, hours.friday.close),
      saturday: this.formatDatetoISODate(hours.saturday.open, hours.saturday.close),
      sunday: this.formatDatetoISODate(hours.sunday.open, hours.sunday.close)
    };
  }

  formatBusinessHours(hours) {
    return {
      monday: this.formatISODate(hours.monday.open, hours.monday.close),
      tuesday: this.formatISODate(hours.tuesday.open, hours.tuesday.close),
      wednesday: this.formatISODate(hours.wednesday.open, hours.wednesday.close),
      thursday: this.formatISODate(hours.thursday.open, hours.thursday.close),
      friday: this.formatISODate(hours.friday.open, hours.friday.close),
      saturday: this.formatISODate(hours.saturday.open, hours.saturday.close),
      sunday: this.formatISODate(hours.sunday.open, hours.sunday.close)
    };
  }

  
  // TIME FIELDS
  private timeFields() {
    return {
      open: [''],
      close: ['']
    };
  }

  getChange(event, day, time) {
    const date = event.target.value;
    if (moment(date).hours() === moment(this.closedTime).hours() && moment(date).minutes() === moment(this.closedTime).minutes()) {
      this.locationEditForm.get(day).get(time).patchValue(this.closedTime);
    }
  }

  setDayClosed(event, day) {
    let hours = this.locationEditForm;
    hours.markAsDirty();
    if (event.target.checked) {
      hours.get(day).get('open').patchValue(this.closedTime);
      hours.get(day).get('close').patchValue(this.closedTime);
    } else {
      const openValue = this.locationEditForm.get(day).get('open').value;
      const closeValue = this.locationEditForm.get(day).get('open').value;
      if (closeValue === this.closedTime || openValue === this.closedTime) {
        hours.get(day).get('open').patchValue('');
        hours.get(day).get('close').patchValue('');
      }
    }
  }

  // BUSINESS HOUR FIELDS
  applyToAllDays(type) {
    let hours = this.locationEditForm;
    hours.markAsDirty();
    if (type === 'weekdays') {
      const open = hours.get('monday').get('open').value;
      const close = hours.get('monday').get('close').value;
      hours.get('tuesday').get('open').patchValue(open);
      hours.get('tuesday').get('close').patchValue(close);
      hours.get('wednesday').get('open').patchValue(open);
      hours.get('wednesday').get('close').patchValue(close);
      hours.get('thursday').get('open').patchValue(open);
      hours.get('thursday').get('close').patchValue(close);
      hours.get('friday').get('open').patchValue(open);
      hours.get('friday').get('close').patchValue(close);
    }
    if (type === 'weekend') {
      const open = hours.get('saturday').get('open').value;
      const close = hours.get('saturday').get('close').value;
      hours.get('sunday').get('open').patchValue(open);
      hours.get('sunday').get('close').patchValue(close);
    }
  }

  onUpdate(locationEditForm) {
    this.businessHours = this.formatBusinessHours(locationEditForm);

    this.isSubmitting = true;
    this.locationsService.updateLocationBusinessHours(this.locationId, this.businessHours).then(() => {
      this.isSubmitting = false;
      this.locationEditForm.markAsPristine();
      this.alert.displayToast('Location updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.businessHoursSubscription.unsubscribe();
  }

}
