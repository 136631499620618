import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/_shared/models/users.model';
import { ReportService } from 'src/app/services/report.service';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reporting-modal',
  templateUrl: './reporting-modal.component.html',
  styleUrls: ['./reporting-modal.component.scss'],
})
export class ReportingModalComponent implements OnInit {

  location: any;
  reportType: any;
  member: User;
  user: User;
  message: any;

  reportName: any;
  submitting: boolean = false;
  
  @ViewChild('reportForm', { static: false }) public reportForm: NgForm;

  constructor(
    private reportService: ReportService,
    private modalCtrl: ModalController,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.reportName = (this.reportType === 'location') ? this.location.name : `${this.member.firstname} ${this.member.surname}`;
    console.log(this.location)
  }

  onSubmit(form: NgForm) {

    this.submitting = true;

    const dataToSend = {
      message: form.value.message,
      reportType: this.reportType,
      created: moment(new Date()).format('llll'),
      subject: (this.reportType === 'location') ? `Member has reported a location` : `Member has reported another member`,
      report: {
        name: (this.reportType === 'location') ? `${this.location.name}` : `${this.member.firstname} ${this.member.surname}`,
        refNo: (this.reportType === 'location') ? this.location.refNo : this.member.refNo,
        email: (this.reportType === 'location') ? this.location.owner.email : this.member.email
      },
      reportee: {
        name: `${this.user.firstname} ${this.user.surname}`,
        refNo: this.user.refNo,
        email: this.user.email
      }
    }

    this.reportService.sendReport(dataToSend).then(() => {
      this.submitting = false;
      this.alertService.displayToast('Report Sent');
      form.reset();
      this.modalCtrl.dismiss();
    }).catch(err => {
      this.submitting = false;
      this.alertService.displayToast('Error: Report not sent');
    });

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
