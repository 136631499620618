import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';

@Component({
  selector: 'app-location-checkins-list',
  templateUrl: './location-checkins.page.html'
})
export class LocationCheckInsPage implements OnInit {

  locationId: string;

  isLoading: boolean = false;
  checkIns$: Observable<Array<{}>>;
  checkInsRef: string;

  // USER
  userId: string;
  userName: string;

  constructor(
    private bookingsService: BookingsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.checkInsRef = `entities/${config.entityId}/locations/${this.locationId}`;
    this.fetchCheckIns();
    this.isLoading = false;
  }

  fetchCheckIns() {
    this.checkIns$ = this.bookingsService.getCheckIns(this.checkInsRef);
  }

  getCheckIn(event) {
    if (event.type === 'viewUser') this.getUser(event.data);
    if (event.type === 'deleteCheckIn') this.deleteCheckIn(event.data);
  }

  getUser(user) {
    console.log('user for user view ', user)
    let checkin;
    checkin = (user.checkIn) ? user.checkIn : '';
    const modalData = {
      title: `${user.userRef}`,
      userId: user.userId,
      type: user.type,
      userIndex: user.userIndex,
      source: user.source,
      checkIn: user.checkIn,
      locationId: this.locationId
    };
    this.alert.openModal(UserViewComponent, modalData, 'modal-small');
  }

  deleteCheckIn(checkIn) {
    this.bookingsService.deleteCheckIn(checkIn).then(() => {
      this.alertService.displayToast('Check in cancelled succesfully')
    }).catch(err => {
      this.alertService.displayToast('Check in could not be cancelled')
    });
  }

}