import { Routes, RouterModule } from '@angular/router';
import { ProfilePage } from './profile/profile.page';
import { AuthGuard } from '../auth/auth.guard';
import { ProfileBillingPage } from './profile-billing/profile-billing.page';
import { ProfileEditPage } from './profile-edit/profile-edit.page';
import { UsersComponent } from './users.component';

const routes: Routes = [
    {
        path: '', component: UsersComponent, canActivate: [AuthGuard], children: [
            { path: '', redirectTo: 'profile', pathMatch: 'full' },
            {
                path: '', component: ProfilePage
            },
            {
                path: 'billing', component: ProfileBillingPage
            },
            {
                path: 'edit', component: ProfileEditPage
            },
        ]
    }
];

export const userRoutes = RouterModule.forChild(routes);
