import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from '../_services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { EventRSVPComponent } from 'src/app/_shared/components/modals/event-rsvp/event-rsvp.component';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './event-view.page.html'
})
export class EventViewPage implements OnInit {
  isLoading = false;
  todaysDate;
  event: any;
  eventId;
  user: User;
  eventBooked = false;

  eventSubscription: Subscription;
  currentPlatform;

  constructor(
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private launchNavigator: LaunchNavigator,
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private router: Router,
    private storage:StorageService

  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.eventId = this.route.snapshot.paramMap.get('eventId');

    this.eventSubscription = this.eventsService.getPublicEventDetails(this.eventId).subscribe(eventDetails => {
      if (eventDetails) {
        this.event = eventDetails;
        this.isLoading = false;

        this.todaysDate = new Date();
        const end = new Date(this.event.endDate.toDate());
        if (this.todaysDate > end) {
          this.event.isPast = true;
        }
      }

    });

    this.storage.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;

        this.eventsService.checkUserEvent(this.event.uid, this.user.uid).subscribe(event => {
          if (event) {
            this.eventBooked = true;
          }
        });
      }
    });

  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  launchNavigatorApp() {
    if(this.currentPlatform != "desktop"){
      this.launchNavigator.navigate(this.event.address.address);
    }else{
      console.log("address",this.event.address);
      window.open(`https://www.google.co.za/maps/place/${this.event.address.formatted_address}`,
        '_blank');
    }
    
  }

  async openRSVPModal(eventInfo) {
    const modal = await this.modalCtrl.create({
      component: EventRSVPComponent,
      componentProps: {
        event: eventInfo
      }
    });
    modal.present();
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  onDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

}
