import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_shared/models/users.model';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.page.html',
    styleUrls: ['../auth.scss']
  })
  export class VerfificationPage implements OnInit {

    firebaseId: string;
    userVerificationSuccessfull: boolean = false;
    userVerifying: boolean = true;
    userSubscription: Subscription;
    user: User;

    constructor(
      private auth: AuthenticationService,
      private router: Router,
      private alertService: AlertService,
      private route: ActivatedRoute
  
    ) { }

    ngOnInit() {
        this.userSubscription = this.auth.user.subscribe(userDetails => {
            if (userDetails) {
            this.user = userDetails;
            }
        });
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.firebaseId = params['uid'];
                    // console.log('firebaseId', this.firebaseId);

                    console.log(this.firebaseId);

                    // VERIFY USER EMAIL
                    this.auth.verifyUserEmail(this.firebaseId).then((data) => {
                      console.log(data);
                        // console.log('CC => UserVerificationComponent -> ngOnInit -> data', data);
                        this.userVerificationSuccessfull = true;
                        this.userVerifying = false;
                    }).catch((err) => {
                        console.log(err)
                        this.userVerificationSuccessfull = false;
                        this.userVerifying = false;
                    });
                }
            );
  
      // this.barcodeScannerOptions = {
      //   showTorchButton: true,
      //   showFlipCameraButton: true
      // };
    }

  }