import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { LoaderModule } from 'src/app/_shared/loader-component/loader.module';
import { EventViewPage } from './event-view/event-view.page';
import { EventsListPage } from './events-list/events-list.page';
import { ComponentsModule } from 'src/app/_shared/components/components.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        FormsModule,
        LoaderModule,
        ReactiveFormsModule,
        ComponentsModule
    ],
    declarations: [
        EventViewPage,
        EventsListPage
    ],
    exports: [
        EventViewPage,
        EventsListPage
    ]
})
export class EventsModule { }