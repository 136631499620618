import { NavController, AlertController } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../auth/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Subscription } from 'rxjs';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { User } from 'src/app/_shared/models/users.model';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { PackageInfoComponent } from 'src/app/_shared/components/modals/package-info/package-info.component';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-billing.page.html',
  styleUrls: ['../users.scss']
})
export class ProfileBillingPage implements OnInit, OnDestroy {
  profileEditForm: FormGroup;
  accountForm: FormGroup;
  userDetails: any;
  user: User;
  isLoading = false;
  saving = false;

  // SUBSCRIPTION
  subscriptionDetails = {
    submit: false,
    type: '',
    data: {
      user: {},
      subscription: ''
    }
  };
  userSubscriptionPlans: Subscription;
  subscriptionPlans: any[];
  hideProductSelection = false;
  userSubscriptionPlan: any;
  teamSubscriptionPlans: any;
  subscriptionPlan: Subscription;
  subscription: any;
  userSubscription: Subscription;
  cancelling: boolean = false;

  provinces = Provinces;

  billingMenu = '';

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public clipboard: Clipboard,
    private subscriptionsService: SubscriptionsService,
    private usersService: UsersService,
    private nav: NavController,
    public alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }
  
  ionViewDidEnter() {
    this.isLoading = true;
    this.billingMenu = 'info'
    this.buildForm();

    this.authenticationService.user.subscribe(user => {
      if (user) {
        this.userDetails = user;
        console.log(this.userDetails)

        this.userSubscription = this.usersService.fetchUserDetails(`/users/${this.userDetails.uid}`).subscribe((userData: User) => {
          this.user = userData;
          this.subscriptionDetails.submit = true;
          this.subscriptionDetails.type = 'User';
          this.subscriptionDetails.data.user = this.user;

          this.subscriptionDetails = {
            submit: true,
            type: 'User',
            data: {
              user: this.user,
              subscription: ''
            }
          };

          this.profileEditForm.patchValue(this.user);
          this.profileEditForm.markAsPristine();

          this.isLoading = false;
        });


        this.userSubscriptionPlans = this.subscriptionsService.getUserSubscriptions(this.userDetails.uid).subscribe(subs => {
          this.subscriptionPlans = subs;
          this.cancelling = false;
          if (this.subscriptionPlans.length > 0) {

            // SET USER PLAN
            const userPlan = this.subscriptionPlans.filter((e) => e.type === 'userSubscription');
            this.hideProductSelection = (!userPlan[0]) ? false : true;
            this.userSubscriptionPlan = userPlan[0];
            this.subscriptionDetails.data.subscription = this.userSubscriptionPlan;

            // SET TEAM PLANS
            this.teamSubscriptionPlans = this.subscriptionPlans.filter((e) => e.type === 'teamSubscription' && e.teamId);
          } else {
            this.hideProductSelection = false;
            this.userSubscriptionPlan = '';
            this.subscriptionDetails.data.subscription = '';
          }
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  goBack() {
    this.nav.back();
  }

  buildForm() {
    this.profileEditForm = this.formBuilder.group({
      billingInfo: this.formBuilder.group({
        billingName: [''],
        billingEmail: [''],
        billingAddress: this.formBuilder.group({
          streetAddress: [''],
          city: [''],
          postalCode: [''],
          country: ['South Africa'],
          region: [''],
        }),
        taxNumber: ['']
      })
    });
  }

  saveUserProfile(user) {
    this.saving = true;
    user.uid = this.userDetails.uid;
    user.firebaseId = this.userDetails.firebaseId;
    user.status = this.userDetails.status;
    user.firstname = this.userDetails.firstname;
    user.surname = this.userDetails.surname;
    user.gender = (this.userDetails.gender) ? this.userDetails.gender : '';
    user.ageGroup = (this.userDetails.ageGroup) ? this.userDetails.ageGroup : '';
    user.contactNumber = (this.userDetails.contactNumber) ? this.userDetails.contactNumber : '';
    user.email = this.userDetails.email;
    user.refNo = this.userDetails.refNo;
    user.photoURL = this.user.photoURL;
    user.uploadedPhoto = this.user.uploadedPhoto;
    user.uploadedPhotoThumbnail = this.user.uploadedPhotoThumbnail;

    this.authenticationService.updateUserDetails(user).then(() => {
      this.saving = false;
      this.profileEditForm.markAsPristine();
      this.alertService.displayToast('Billing information updated successfully');
    });
  }

  viewPlanDetails(plan) {
    plan.origin = 'profile';
    this.alertService.openModal(PackageInfoComponent, { package: plan }, '');
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `Pending`;
        break;
      case 2:
        return `Active`;
        break;
      default:
    }
  }

  changeTab(tab) {
    this.billingMenu = tab;
  }

  async presentConfirmCancel() {
    const alert = await this.alertCtrl.create({
      // header: 'Confirm!',
      message: 'Are you sure?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: 'Yes',
        handler: () => {
          this.cancelSubscription()
        }
      }]
    });

    await alert.present();
  }

  cancelSubscription() {
    console.log(this.user)
    const updateValues = {
      status: 3
    }
    this.cancelling = true;
    this.subscriptionsService.updateSubscription(this.user.uid, updateValues);
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.userSubscription.unsubscribe();
    this.userSubscriptionPlans.unsubscribe();
  }
}
