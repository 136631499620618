import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/pages/auth/auth.guard';
import { SignupPage } from './pages/auth/signup/signup.page';
import { LoginPage } from './pages/auth/login/login.page';
import { LandingPage } from './pages/auth/landing/landing.page';
import { PasswordResetPage } from './pages/auth/password-reset/password-reset.page';
import { MeetingRoomsPublicListComponent } from './pages/meeting-rooms/meeting-rooms-list/meeting-rooms-list.component';
import { MeetingRoomsPublicViewComponent } from './pages/meeting-rooms/meeting-rooms-view/meeting-rooms-view.component';
import { EventViewPage } from './pages/events/event-view/event-view.page';
import { TeamsListComponent } from './pages/teams/teams-list/teams-list.component';
import { TeamViewComponent } from './pages/teams/teams-view/teams-view.component';
import { TeamAddComponent } from './pages/teams/teams-add/teams-add.component';
import { UsersSearchComponent } from './pages/ws-users/users-search/users-search.component';
import { LocationViewPage } from './pages/location/location-view/location-view.page';
import { LocationEditPage } from './pages/location/location-edit/location-edit.page';
import { LocationAddPage } from './pages/location/location-add/location-add.page';
import { LocationsListComponent } from './pages/location/locations-list/locations-list.component';
import { UserBookingsComponent } from './pages/ws-users/user-bookings/user-bookings.component';
import { LocationBookingsComponent } from './pages/location/location-bookings/location-bookings.component';
import { EventsListPage } from './pages/events/events-list/events-list.page';
import { ContactUsPage } from './pages/communications/contact-us/contact-us.page';
import { PackagesListPage } from './pages/packages-list/packages-list.page';
import { ProfilePage } from './pages/ws-users/profile/profile.page';
import { ProfileEditPage } from './pages/ws-users/profile-edit/profile-edit.page';
import { TeamsTransactionsComponent } from './pages/teams/teams-transactions/teams-transactions.component';
import { TeamsCheckInsPage } from './pages/teams/teams-checkins/teams-checkins.page';
import { LocationCheckInsPage } from './pages/location/location-checkins/location-checkins.page';
import { UserNotificationsPage } from './pages/ws-users/user-notifications/user-notifications.page';
import { UserHistoryPage } from './pages/ws-users/user-history/user-history.page';
import { ProfileBillingPage } from './pages/ws-users/profile-billing/profile-billing.page';
import { FaqComponent } from './pages/support-pages/faq/faq.component';
import { TutorialPageComponent } from './pages/support-pages/tutorial/tutorial.component';
import { MessagesListComponent } from './pages/messages/messages-list/messages-list.page';
import { MessagesConversationsComponent } from './pages/messages/messages-conversations/messages-conversations.page';
import { MessagesConversationsCreateComponent } from './pages/messages/messages-conversations-create/messages-conversations-create.page';
import { TeamEditComponent } from './pages/teams/teams-edit/teams-edit.component';
import { LocationEditDetailsPage } from './pages/location/location-edit/location-details/location-details.page';
import { LocationEditAddressPage } from './pages/location/location-edit/location-address/location-address.page';
import { LocationEditHoursPage } from './pages/location/location-edit/location-hours/location-hours.page';
import { LocationEditAmenitiesPage } from './pages/location/location-edit/location-amenities/location-amenities.page';
import { LocationEditGalleryPage } from './pages/location/location-edit/location-gallery/location-gallery.page';
import { LocationEditMembersPage } from './pages/location/location-edit/location-members/location-members.page';
import { MeetingRoomsAddComponent } from './pages/meeting-rooms/meeting-rooms-add/meeting-rooms-add.component';
import { MeetingRoomsLocationsListComponent } from './pages/meeting-rooms/meeting-rooms-locations-list/meeting-rooms-locations-list.component';
import { MeetingRoomsEditComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-rooms-edit.component';
import { MeetingRoomEditDetailsComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-room-details/meeting-room-details.component';
import { MeetingRoomEditHoursComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-room-hours/meeting-room-hours.component';
import { MeetingRoomEditAmenitiesComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-room-amenities/meeting-room-amenities.component';
import { MeetingRoomEditTypesComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-room-types/meeting-room-types.component';
import { MeetingRoomEditGalleryComponent } from './pages/meeting-rooms/meeting-rooms-edit/meeting-room-gallery/meeting-room-gallery.component';
import { PaymentsConfirmationPage } from './pages/payments/payments-confirmation/payments-confirmation.page';
import { LocationEditReviewsPage } from './pages/location/location-edit/location-reviews/location-reviews.page';
import { MeetingRoomsBookingsComponent } from './pages/meeting-rooms/meeting-rooms-bookings/meeting-rooms-bookings.component';
import { LocationEditBoostPage } from './pages/location/location-edit/location-boost/location-boost.page';
import { VerfificationPage } from './pages/auth/verification/verification.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: LandingPage
  },
  {
    path: 'signup',
    component: SignupPage
  },
  {
    path: 'verification/:uid',
    component: VerfificationPage
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'password-reset',
    component: PasswordResetPage
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'notifications-list',
    canActivate: [AuthGuard],
    component: UserNotificationsPage
  },
  {
    path: 'users-search',
    canActivate: [AuthGuard],
    component: UsersSearchComponent
  },
  {
    path: 'events-list',
    component: EventsListPage
  },
  {
    path: 'contact-us',
    component: ContactUsPage
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'tutorial',
    component: TutorialPageComponent
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/ws-users/users.module').then(m => m.UsersModule)
  },
  // {
  //   path: 'profile-edit',
  //   canActivate: [AuthGuard],
  //   component: ProfileEditPage
  // },
  // {
  //   path: 'profile-billing',
  //   canActivate: [AuthGuard],
  //   component: ProfileBillingPage
  // },
  {
    path: 'my-bookings',
    canActivate: [AuthGuard],
    component: UserBookingsComponent
  },
  {
    path: 'user-history',
    canActivate: [AuthGuard],
    component: UserHistoryPage
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    component: MessagesListComponent
  },
  {
    path: 'messages/conversation/:conversationId',
    canActivate: [AuthGuard],
    component: MessagesConversationsComponent
  },
  {
    path: 'messages/conversation/create/:conversationId',
    canActivate: [AuthGuard],
    component: MessagesConversationsCreateComponent
  },
  {
    path: 'location-add',
    canActivate: [AuthGuard],
    component: LocationAddPage
  },
  {
    path: 'locations-list',
    canActivate: [AuthGuard],
    component: LocationsListComponent
  },
  {
    path: 'location-edit/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditPage
  },
  {
    path: 'location-details/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditDetailsPage
  },
  {
    path: 'location-address/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditAddressPage
  },
  {
    path: 'location-hours/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditHoursPage
  },
  {
    path: 'location-amenities/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditAmenitiesPage
  },
  {
    path: 'location-gallery/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditGalleryPage
  },
  {
    path: 'location-reviews/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditReviewsPage
  },
  {
    path: 'location-boost/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditBoostPage
  },
  {
    path: 'location-members/:locationId',
    canActivate: [AuthGuard],
    component: LocationEditMembersPage
  },
  {
    path: 'location-bookings/:locationId',
    canActivate: [AuthGuard],
    component: LocationBookingsComponent
  },
  {
    path: 'location/:locationId',
    component: LocationViewPage
  },
  {
    path: 'location-checkins/:locationId',
    canActivate: [AuthGuard],
    component: LocationCheckInsPage
  },
  {
    path: 'meeting-rooms/list',
    component: MeetingRoomsPublicListComponent
  },
  {
    path: 'meeting-room/:roomId',
    component: MeetingRoomsPublicViewComponent
  },
  {
    path: 'meeting-rooms/:locationId',
    canActivate: [AuthGuard],
    component: MeetingRoomsLocationsListComponent
  },
  {
    path: 'meeting-rooms/:locationId/add',
    canActivate: [AuthGuard],
    component: MeetingRoomsAddComponent
  },
  {
    path: 'meeting-rooms/:locationId/edit/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomsEditComponent
  },
  {
    path: 'meeting-room-details/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomEditDetailsComponent
  },
  {
    path: 'meeting-room-hours/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomEditHoursComponent
  },
  {
    path: 'meeting-room-amenities/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomEditAmenitiesComponent
  },
  {
    path: 'meeting-room-types/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomEditTypesComponent
  },
  {
    path: 'meeting-room-gallery/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomEditGalleryComponent
  },
  {
    path: 'meeting-room-bookings/:roomId',
    canActivate: [AuthGuard],
    component: MeetingRoomsBookingsComponent
  },
  {
    path: 'packages-list',
    component: PackagesListPage
  },
  {
    path: 'event/:eventId',
    component: EventViewPage
  },
  {
    path: 'teams',
    canActivate: [AuthGuard],
    component: TeamsListComponent
  },
  {
    path: 'team/edit/:teamId',
    canActivate: [AuthGuard],
    component: TeamEditComponent
  },
  {
    path: 'team/view/:teamId',
    canActivate: [AuthGuard],
    component: TeamViewComponent
  },
  {
    path: 'teamsAdd',
    canActivate: [AuthGuard],
    component: TeamAddComponent
  },
  {
    path: 'team-transactions/:teamId',
    canActivate: [AuthGuard],
    component: TeamsTransactionsComponent
  },
  {
    path: 'team-checkins/:teamId',
    canActivate: [AuthGuard],
    component: TeamsCheckInsPage
  },
  {
    path: 'membership-terms',
    loadChildren: () => import('./pages/membership-terms/membership-terms.module').then(m => m.MembershipTermsPageModule)
  },
  {
    path: 'payments-confirmation/:transactionId',
    canActivate: [AuthGuard],
    component: PaymentsConfirmationPage
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
