import { config } from '../configs/config';

export class Sender {
  id?: string;
  name?: string;
  image?: string;
  active?: boolean;

  constructor(
  ) { 
    this.id = 'wealthspaces';
    this.name = 'Wealth Spaces';
    this.image = config.wealthSpacesIcon;
    this.active = true;
  }
}