import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-tutorial-component',
  templateUrl: './tutorial.component.html'
})
export class TutorialComponent implements OnInit {

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  constructor(
    private navCtrl: NavController
  ) { }


  ngOnInit() {
  }

  goBack() {
    this.navCtrl.back();
  }

}
