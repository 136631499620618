import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { colors } from '../_utils/colors';
import { AuthenticationService } from '../../auth/auth.service';
import * as moment from 'moment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { config } from 'src/app/_shared/configs/config';

@Injectable()
export class BookingsService {
  
  entityId: string;
  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private auth: AuthenticationService,
    private notificationsService: NotificationsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getBookings() {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        if (booking.type === 'event') {
          color = colors.blue;
        }
        if (booking.type === 'meetingRoom') {
          color = colors.yellow;
        }
        if (booking.type === 'location') {
          color = colors.red;
        }
        return {
          title: booking.name,
          start: booking.startDate.toDate(),
          end: booking.endDate.toDate(),
          color: color,
          booking: booking
        };
      });
    }));
  }

  getMeetingRoomBookings(meetingRoomId) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/meetingRooms/${meetingRoomId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
          color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
          color = colors.yellow
        }
        if (booking.type === 'location') {
          color = colors.red
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        const userId = (booking.bookingFor) ? booking.bookingFor : booking.createdBy;
        this.afs.doc(`users/${userId}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
          title: booking.name,
          startTime: booking.startDate.toDate(),
          endTime: booking.endDate.toDate(),
          color: color,
          booking: booking,
          type: booking.type,
          displayType: 'meetingRoom',
          bookingTime: bookingTime
        };
      });
    }));
  }

  getUserBookings(userId) {
    const bookingsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        const {startTime, endTime} = booking;
        let color, start, end;
        start = booking.startDate.toDate();
        end = booking.endDate.toDate();

        let bookingTime = 'future';
        if (booking.type === 'event') {
          color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
          color = colors.yellow
        }
        if (booking.type === 'location') {
          color = colors.red;
          start = startTime ? new Date(startTime) : start;
          end = endTime ? new Date(endTime) : end;
        }
        if (booking.status === 3) {
          color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        return {
          title: booking.name,
          startTime: start,
          endTime: end,
          color: color,
          booking: booking,
          type: booking.type,
          displayType: 'user',
          bookingTime: bookingTime
        };
      });
    }));
  }

  getLocationBookings(locationId) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
          color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
          color = colors.yellow
        }
        if (booking.type === 'location') {
          color = colors.red
        }
        if (booking.status === 3) {
          color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        const userId = (booking.bookingFor) ? booking.bookingFor : booking.createdBy;
        this.afs.doc(`users/${userId}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
          title: booking.name,
          startTime: booking.startDate.toDate(),
          endTime: booking.endDate.toDate(),
          color: color,
          booking: booking,
          type: booking.type,
          displayType: 'location',
          bookingTime: bookingTime
        };
      });
    }));
  }

  getUserLocationBookings(userId) {
    const bookingsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/bookings`,
      ref => ref.where('active', '==', true).where('type', '==', 'location'));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const bookingData = a.payload.doc.data() as any;
        bookingData.color = colors.red;
        const {name, startDate, endDate, startTime, endTime, color} = bookingData;
        return {
          title: name,
          startTime: startTime ? new Date(startTime) : startDate.toDate(),
          endTime: endTime ? new Date(endTime) : endDate.toDate(),
          color: color,
          booking: bookingData
        };
      });
    }));
  }

  getBookingsType(typeId, type) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/${type}/${typeId}/bookings`, ref => ref
      .where('active', '==', true)
      .where('typeId', '==', typeId)
      .where('type', '==', type)
    );
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        return booking;
      });
    }));
  }

  getUserTypeBookings(typeId, type) {
    const bookingsCollection = this.afs.collection(`users/${this.loggedInUser.uid}/entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true).where('typeId', '==', typeId).where('type', '==', type));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const bookingData = a.payload.doc.data() as any;
        bookingData.color = colors.red;
        return {
          title: bookingData.name,
          startTime: bookingData.startDate.toDate(),
          endTime: bookingData.endDate.toDate(),
          color: bookingData.color,
          booking: bookingData
        };
      });
    }));
  }

  deleteMeetingRoomBooking(meetingRoomId, bookingId) {
    const deleteUserBooking = this.afs.doc(`users/${this.loggedInUser.uid}/entities/${this.entityId}/bookings/${bookingId}`).delete();
    const deleteEntityBooking = this.afs.doc(`entities/${this.entityId}/meetingRooms/${meetingRoomId}/bookings/${bookingId}`).delete();
    return Promise.all([deleteUserBooking, deleteEntityBooking]);
  }

  deleteLocationBooking(bookingId) {
    const deleteUserBooking = this.afs.doc(`users/${this.loggedInUser.uid}/entities/${this.entityId}/bookings/${bookingId}`).delete();
    const deleteEntityBooking = this.afs.doc(`entities/${this.entityId}/bookings/${bookingId}`).delete();
    return Promise.all([deleteUserBooking, deleteEntityBooking]);
  }

  addBooking(bookingData) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/bookings`);
    return bookingsCollection.add(bookingData);
  }

  updateBooking(data, uid, payment?, transactionId?) {
    const bookingsDoc = this.afs.doc(`entities/${this.entityId}/bookings/${uid}`);
    return bookingsDoc.set(data, { merge: true }).then((ref) => {
      if (payment) {
        return bookingsDoc.ref.get().then((booking: any) => {
          const bookingData = booking.data();
          bookingData.startTime = moment(bookingData.startDate.toDate()).format('HH:mm a');
          bookingData.endTime = moment(bookingData.endDate.toDate()).format('HH:mm a');
          const pendingNotifyData = {
            request: 'meetingRoomBookingNotify',
            source: config.source,
            entityId: config.entityId,
            bookingData: bookingData,
            user: this.loggedInUser
          }

          bookingData.transactionId = transactionId;
          const pendingPayment = {
            request: 'roomSplitPayment',
            entityId: config.entityId,
            source: config.source,
            bookingData: bookingData,
            user: this.loggedInUser
          }
          const pendingNotify = this.afs.collection(`pending`).add(pendingNotifyData);
          const splitCost = this.afs.collection(`pending`).add(pendingPayment);

          return Promise.all([pendingNotify, splitCost]).then(() => {
            return booking.data();
          })
        })
      }
    });
  }

  updateUserBooking(userId, booking) {
    const userBookingsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/bookings/${booking.uid}`);
    return userBookingsDoc.set(booking, { merge: true });
  }

  sendPendingNotify(data, message, type) {
    console.log(data, message, type);
    const pendingNotifyData = {
      request: 'bookingNotifyChange',
      source: config.source,
      entityId: config.entityId,
      bookingData: data,
      user: this.loggedInUser,
      type: type,
      message: message
    }
    return this.afs.collection(`pending`).add(pendingNotifyData);

  }

  createCheckIn(locationData, bookingId, subscription) {
    const checkInsCollection = this.afs.collection(`entities/${this.entityId}/checkIns`);
    const locationCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationData.uid}/checkIns`);
    const userCollection = this.afs.collection(`users/${this.auth.userId}/entities/${this.entityId}/checkIns`);
    const teamId = (subscription.type === 'teamSubscription') ? subscription.teamId : '';

    const checkInData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      createdByRef: this.loggedInUser.refNo,
      locationName: locationData.name,
      locationId: locationData.uid,
      locationRef: locationData.refNo,
      bookingId: bookingId,
      teamId: teamId,
      status: 1
    }

    // NOTIFICATIONS
    let teamNotification;
    let sendTeamNotification;

    const userNotification = {
      title: 'You Have Checked Into a Location',
      body: `You have checked into ${locationData.name} at ${moment(new Date()).format('hh:mm a')}`,
      type: 'locationCheckIn',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: this.loggedInUser.uid,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/my-check-ins`,
      },
      historyInfo: {
        created: new Date(),
        title: 'You Have Checked Into a Location',
        message: `You have checked into '<strong>${locationData.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
        linkText: `View Check Ins`,
        type: 'locationCheckIn',
        createdBy: this.loggedInUser.uid,
        userId: this.loggedInUser.uid,
        url: `/my-check-ins`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const sendLocationNotification = this.afs.doc(`entities/${this.entityId}/locations/${locationData.uid}`).ref.get().then((location: any) => {
      const locationInfo = location.data();
      const locationNotification = {
        title: 'Member Checked Into Your Location',
        body: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into ${locationInfo.name} at ${moment(new Date()).format('hh:mm a')}`,
        type: 'userCheckIn',
        createdBy: this.loggedInUser.uid,
        addToHistory: true,
        userId: locationInfo.owner.uid,
        customData: {
          createdBy: this.loggedInUser.uid,
          notificationUrl: `/locations/edit/${locationInfo.uid}`,
        },
        historyInfo: {
          created: new Date(),
          title: 'Member Checked Into Your Location',
          message: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into '<strong>${locationInfo.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
          linkText: `View Location`,
          type: 'userCheckIn',
          createdBy: this.loggedInUser.uid,
          userId: locationInfo.owner.uid,
          url: `/locations/edit/${locationInfo.uid}`,
          listRef: '/my-notifications',
          source: config.source,
          unread: true
        }
      }
      this.notificationsService.addNotification(locationNotification, locationInfo.owner);
    });

    const sendUserNotification = this.notificationsService.addNotification(userNotification);

    return checkInsCollection.add(checkInData).then(ref => {
      const saveToLocation = locationCollection.doc(ref.id).set(checkInData, { merge: true });
      const saveToUser = userCollection.doc(ref.id).set(checkInData, { merge: true });
      let teamCheckIn;
      if (subscription.type === 'teamSubscription') {
        teamCheckIn = this.afs.collection(`entities/${this.entityId}/teams/${subscription.teamId}/checkIns`).doc(ref.id).set(checkInData, { merge: true });
        sendTeamNotification = this.afs.doc(`entities/${this.entityId}/teams/${subscription.teamId}`).ref.get().then((team: any) => {
          const teamData = team.data();
          console.log(teamData)
          teamNotification = {
            title: 'Team Member Checked In',
            body: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into ${locationData.name} at ${moment(new Date()).format('hh:mm a')}`,
            type: 'teamLocationCheckIn',
            createdBy: this.loggedInUser.uid,
            addToHistory: true,
            userId: teamData.owner.uid,
            customData: {
              createdBy: this.loggedInUser.uid,
              notificationUrl: `/teams/edit/${subscription.teamId}`,
            },
            historyInfo: {
              created: new Date(),
              title: 'Team Member Checked In',
              message: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into '<strong>${locationData.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
              linkText: `View Team`,
              type: 'teamLocationCheckIn',
              createdBy: this.loggedInUser.uid,
              userId: teamData.owner.uid,
              url: `/teams/edit/${subscription.teamId}`,
              listRef: '/my-notifications',
              source: config.source,
              unread: true
            }
          }
          this.notificationsService.addNotification(teamNotification, teamData.owner);
        });
      }
      return Promise.all([saveToLocation, saveToUser, sendUserNotification, sendLocationNotification, teamCheckIn, sendTeamNotification]);
    })
  }

  getCheckIns(type) {
    const checkInsCollection = this.afs.collection(`${type}/checkIns`, ref => ref.where('active', '==', true));
    return checkInsCollection.valueChanges({ idField: 'id' });
  }

  getLatestCheckin() {
    const checkInsCollection = this.afs.collection(`users/${this.loggedInUser.uid}/entities/${config.entityId}/checkIns`, ref => ref.where('active', '==', true).orderBy('created', 'desc').limit(1));
    return checkInsCollection.valueChanges({ idField: 'id' });
  }

  // USER EVENTS
  addUserRsvpBooking(bookingData, userId) {
    const bookingsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/bookings/${bookingData.typeId}`);
    const bookingDate = moment(bookingData.startDate.toDate()).format('DD MMMM YYYY');
    const bookingStart = moment(bookingData.startDate.toDate()).format('HH:mm');
    const bookingEnd = moment(bookingData.endDate.toDate()).format('HH:mm');
    const notification = {
      title: 'Event Confirmation',
      body: `You have confirmed attendance for ${bookingData.name} for ${bookingDate} from ${bookingStart} to ${bookingEnd}`,
      type: 'eventBooking',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: this.loggedInUser.uid,
      historyInfo: {
        title: 'Event Confirmation',
        message: `You have confirmed attendance for ${bookingData.name} for ${bookingDate} from ${bookingStart} to ${bookingEnd}`,
        type: 'eventBooking',
        createdBy: this.loggedInUser.uid,
        userId: this.loggedInUser.uid,
        created: new Date()
      }
    };

    return bookingsDoc.set(bookingData, { merge: true }).then(() => {
      return this.notificationsService.addNotification(notification);
    });
  }

  getUserRsvpBookings(userId) {
    const bookingsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true).where('type', '==', 'event').where('attending', '==', true));
    return bookingsCollection.valueChanges();
  }

  // add booking to user who made the booking under /users/${userId}/entities/wealthspaces-sa/bookings
  // add the booking undet the wealthspaces entity bookings for pending approval under /entities/wealthspaces-sa/bookings
  // when adding the booking to the entity bookings, remember to include the location to determine who can approve it

  createUserMeetingBooking(bookingData, user?) {
    let userForBooking;
    if (user) {
      userForBooking = user;
      bookingData.bookingFor = user.uid;
    } else {
      userForBooking = this.loggedInUser;
      bookingData.bookingFor = this.loggedInUser.uid;
    }
    bookingData.status = 1;
    const addBookingToEntity = this.afs.collection(`entities/${this.entityId}/bookings`);
    return addBookingToEntity.add(bookingData).then(ref => {
      const addBookingToUser = this.afs.doc(`users/${this.auth.userId}/entities/${this.entityId}/bookings/${ref.id}`).set(bookingData, { merge: true });
      return Promise.all([addBookingToUser]).then(() => {
        return ref.id;
      });
    });
  }
  
  createUserLocationBooking(startDate, endDate, startTime, endTime, location, user?) {
    let userForBooking;
    let bookingFor;
    if (user) {
      userForBooking = user;
      bookingFor = user.uid;
    } else {
      userForBooking = this.loggedInUser;
      bookingFor = this.loggedInUser.uid;
    }
    const booking = {
      active: true,
      price: 0,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      bookingFor: bookingFor,
      name: location.name,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      locationId: location.uid,
      locationRef: `entities/${config.entityId}/locations/${location.uid}`,
      locationName: location.name,
      type: 'location',
      typeId: location.uid,
      typeRef: `entities/${config.entityId}/locations/${location.uid}`,
      referencePrefix: `LOCATION-B-`,
      entityId: config.entityId,
      source: config.source,
      address: location.address,
      status: 2,
      checkedIn: false,
    };

    const pendingNotifyData = {
      request: 'locationBookingNotify',
      source: config.source,
      entityId: config.entityId,
      bookingData: booking,
      user: userForBooking
    };

    const addBookingToEntity = this.afs.collection(`entities/${this.entityId}/bookings`);
    const pendingNotify = this.afs.collection(`pending`).add(pendingNotifyData);

    return addBookingToEntity.add(booking).then(ref => {
      const addBookingToUser = this.afs.doc(`users/${userForBooking.uid}/entities/${this.entityId}/bookings/${ref.id}`).set(booking, { merge: true });
      return Promise.all([addBookingToUser, pendingNotify]);
    });

  }

  deleteCheckIn(checkIn) {
    const pendingCollection = this.afs.collection(`pending`);
    const checkInData = {
      request: 'deleteCheckIn',
      source: config.source,
      entityId: config.entityId,
      userId: this.loggedInUser.uid,
      checkInData: checkIn,
      checkInId: checkIn.id
    }
    return pendingCollection.add(checkInData);
  }

  getCheckinData(locationId, checkInId) {
    return this.afs.doc(`entities/${config.entityId}/locations/${locationId}/checkIns/${checkInId}`).valueChanges();
  }

  markCheckinAsReviewed(locationId, checkInId) {
    const userRefSet = this.afs.doc(`users/${this.loggedInUser.uid}/entities/${config.entityId}/checkIns/${checkInId}`).update({ status: 2 });
    const locationRefSet = this.afs.doc(`entities/${config.entityId}/locations/${locationId}/checkIns/${checkInId}`).update({ status: 2 });

    return Promise.all([userRefSet, locationRefSet]);
  }

  inviteUser(user, booking) {
    const pendingCollection = this.afs.collection(`pending`);
    const emailData = {
      request: 'userRoomBookingInvite',
      source: config.source,
      entityId: config.entityId,
      userId: this.loggedInUser.uid,
      user: user,
      booking: {
        from: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} - ${this.loggedInUser.email}`,
        address: booking.address.formatted_address,
        startDate: booking.startDate,
        startTime: moment(booking.startDate.toDate()).format('HH:mm a'),
        endTime: moment(booking.endDate.toDate()).format('HH:mm a'),
        locationName: booking.locationName,
        roomName: booking.name
      }
    }
    return pendingCollection.add(emailData);

  }
}
