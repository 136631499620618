import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UsersService } from '../../../services/users.service';
import { FilesService } from 'src/app/_shared/components/files/files.service';
import { AuthenticationService } from '../../auth/auth.service';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { config } from 'src/app/_shared/configs/config';
import * as moment from 'moment';

@Injectable()
export class EventsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private bookingsService: BookingsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getEvents() {
    const eventsCollection = this.afs.collection(`entities/${this.entityId}/events`, ref => ref.orderBy('created', 'desc'));
    return eventsCollection.valueChanges({ idField: 'id' });
  }

  getEventDetails(eventId) {
    const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${eventId}`);
    return eventsDoc.valueChanges();
  }

  getPublicEvents() {
    const eventsCollection = this.afs.collection(`public/${this.entityId}/events`, ref => ref.where('active', '==', true).orderBy('created', 'desc'));
    return eventsCollection.valueChanges();
  }

  getPublicEventsBySlug(slug) {
    const locationsCollection = this.afs.collection(`public/${this.entityId}/events`, ref => ref.where('refNo', '==', slug));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicEventDetails(eventId) {
    const eventsDoc = this.afs.doc(`public/${this.entityId}/events/${eventId}`);
    return eventsDoc.valueChanges();
  }

  addEvent(eventInfo) {

    const eventsCollection = this.afs.collection(`entities/${this.entityId}/events`);

    // SET PREFIX FOR REF NO
    const referencePrefix = eventInfo.title.substring(0, 3).toUpperCase();

    // SET ROOM DOC DATA
    let eventData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      createdByName: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
      title: eventInfo.title,
      description: eventInfo.description,
      availableSpaces: eventInfo.availableSpaces,
      bookedSpaces: 0,
      address: eventInfo.locationDetails.address,
      referencePrefix: referencePrefix,
      source: config.source,
      locationId: eventInfo.locationDetails.uid,
      locationName: eventInfo.locationDetails.name,
      locationRef: `entities/${this.entityId}/locations/${eventInfo.locationDetails.uid}`,
      uploadedPhoto: '',
      uploadedPhotoThumbnail: '',
      startDate: eventInfo.startDate,
      endDate: eventInfo.endDate,
      date: eventInfo.date,
      times: eventInfo.times
    }
    return eventsCollection.add(eventData).then(ref => {

      const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${ref.id}`);

      return eventsDoc.set({ uid: ref.id }, { merge: true }).then(doc => {

        if (eventInfo.eventImage) {
          // SET REF PATH TO SAVE
          let saveRef = {
            dbPath: `entities/${config.entityId}/events/${ref.id}`,
            filePath: `entities/${config.entityId}/events/${ref.id}/${ref.id}_image`,
            uploadType: 'cropper',
            thumbSize: 150
          };
          const uploadFiles = this.filesService.handleDrop(eventInfo.eventImage, saveRef);
          return Promise.resolve(uploadFiles).then(() => {
            this.addEventToBookings(eventData, ref.id);
          });
        } else {
          this.addEventToBookings(eventData, ref.id);
        }

      });

    });

  }

  addEventToBookings(eventData, eventId) {

    const bookingData = {
      active: true,
      price: 0,
      created: eventData.created,
      createdBy: eventData.createdBy,
      createdByName: eventData.createdByName,
      name: eventData.title,
      startDate: eventData.startDate,
      endDate: eventData.endDate,
      locationId: eventData.locationId,
      locationRef: eventData.locationRef,
      type: 'event',
      referencePrefix: 'EVENT-B-',
      typeId: eventId,
      typeRef: `entities/${config.entityId}/events/${eventId}`,
      entityId: config.entityId,
      source: eventData.source
    }

    const addBooking = this.bookingsService.addBooking(bookingData);
    return Promise.resolve(addBooking);

  }

  updateEvent(data, uid) {
    const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${uid}`);
    return eventsDoc.set(data, { merge: true })
  }

  rsvpToEvent(event, user) {
    const eventUsersDoc = this.afs.doc(`entities/${this.entityId}/events/${event.uid}/users/${user.uid}`);
    const eventDoc = this.afs.doc(`entities/${this.entityId}/events/${event.uid}`);
    const pendingCollection = this.afs.collection(`pending`);
    return eventUsersDoc.set({
      firstname: user.firstname,
      surname: user.surname,
      email: user.email,
      active: true,
      ref: `/users/${user.uid}`,
      attending: true,
      uid: user.uid,
    }, { merge: true }).then(() => {

      const sendRsvpEmail = eventDoc.ref.get().then(eventDetails => {
        if (eventDetails.exists) {
          const eventInfo= eventDetails.data();
          const eventCreatorDoc = this.afs.doc(`users/${eventInfo.createdBy}`);
          return eventCreatorDoc.ref.get().then(creator => {
            if (creator.exists) {
              const creatorInfo = creator.data();
              event.startTime = moment(bookingData.startDate.toDate()).format('HH:mm a');
              event.endTime = moment(bookingData.endDate.toDate()).format('HH:mm a');
              const emailInfo = {
                request: 'emailEventRSVP',
                source: config.source,
                entityId: config.entityId,
                eventData: event,
                eventOwner: {
                  firstname: creatorInfo.firstname,
                  surname: creatorInfo.surname,
                  email: creatorInfo.email
                },
                attendee: {
                  firstname: user.firstname,
                  surname: user.surname,
                  email: user.email
                }
              }
              return pendingCollection.add(emailInfo).catch((err) => {
                console.log(err);
              });
            }
          });
        }
      });

      const bookingData = {
        active: true,
        attending: true,
        price: 0,
        created: new Date(),
        createdBy: user.uid,
        createdByName: `${user.firstname} ${user.surname}`,
        name: event.title,
        startDate: event.startDate,
        endDate: event.endDate,
        locationId: event.locationId,
        locationRef: `entities/${this.entityId}/locations/${event.locationId}`,
        type: 'event',
        refNo: event.refNo,
        typeId: event.uid,
        typeRef: `entities/${config.entityId}/events/${event.uid}`,
        entityId: config.entityId,
        uid: event.uid
      };

      const addBooking = this.bookingsService.addUserRsvpBooking(bookingData, user.uid);
      return Promise.all([addBooking, sendRsvpEmail]);
    })
  }

  deleteEventBooking(eventId) {
    const deleteUserEvent = this.afs.doc(`/users/${this.loggedInUser.uid}/entities/${this.entityId}/bookings/${eventId}`).delete();
    const deleteEventAttendee = this.afs.doc(`/entities/${this.entityId}/events/${eventId}/users/${this.loggedInUser.uid}`).delete();
    return Promise.all([deleteUserEvent, deleteEventAttendee]);
  }

  getEventUsers(eventId, userId) {
    const eventUsersDoc = this.afs.doc(`entities/${this.entityId}/events/${eventId}/users/${userId}`);
    return eventUsersDoc.valueChanges();
  }

  updateEventUser(eventId, userId, data) {
    const eventUsersDoc = this.afs.doc(`entities/${this.entityId}/events/${eventId}/users/${userId}`);
    return eventUsersDoc.set(data, { merge: true });
  }

  checkUserEvent(eventId, userId) {

    return this.afs.doc(`/users/${userId}/entities/${this.entityId}/bookings/${eventId}`).valueChanges();
  }

}
