import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { PackageInfoComponent } from '../modals/package-info/package-info.component';
import { AlertService } from 'src/app/services/alert.service';
import { PackageConfirmPurchaseComponent } from '../modals/package-confirm-purchase/package-confirm-purchase.component';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
 
@Component({
  selector: 'app-packages-list-component',
  templateUrl: './packages-list.component.html'
})
export class PackagesListComponent implements OnInit {
  productsSubscription: Subscription;
  packages: any;
  isLoading = false;
  user: User;
  activeProduct: any;
  productForm: FormGroup;

  @Input() listType: any;
  @Input() extraData: any;

  backgroundImageOne: string;
  backgroundImageTwo: string;
  backgroundImageThree: string;

  changeable: boolean = true;
  suspended: boolean = false;

  constructor(
    private productsService: ProductsService,
    private alertService: AlertService,
    private router: Router,
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.buildForm();

    this.auth.user.subscribe(user => {
      this.user = user;
    });

    this.productsSubscription = this.productsService.getPublicProducts().subscribe(productsData => {
      this.packages = productsData;
      if (this.extraData.data.subscription) {
        const active = this.packages.filter((e) => e.refNo === this.extraData.data.subscription.productRef && !this.extraData.data.subscription.suspended);
        this.activeProduct = active[0];
        this.changeable = this.extraData.data.subscription.changeable;
        this.suspended = this.extraData.data.subscription.suspended;
        if (this.route.snapshot.queryParamMap.get('product')) {
          const paramProduct = this.route.snapshot.queryParamMap.get('product');
          const chosenProduct = this.packages.filter((e) => e.refNo === paramProduct);
          this.openPurchaseModal(chosenProduct[0]);
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  buildForm() {
    this.productForm = this.fb.group({
      product: ['', [Validators.required]]
    });
  }

  async openPurchaseModal(packageInfo) {
    let oldSubscription = (this.extraData.data.subscription) ? this.extraData.data.subscription : undefined;
    this.extraData.data.oldSubscription = oldSubscription;
    this.alertService.openModal(PackageConfirmPurchaseComponent, { package: packageInfo, extraData: this.extraData }, '');
  }


  async openPackageInfoModal(packageInfo) {
    this.alertService.openModal(PackageInfoComponent, { package: packageInfo, extraData: this.extraData }, '');
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  getBackground(product) {
    switch (product) {
      case 'Essential':
        return `url("./assets/images/packages/essential.png") var(--ion-color-medium)`;
      case 'Classic':
        return `url("./assets/images/packages/classic.png") var(--ion-color-medium)`;
      case 'Premium':
        return `url("./assets/images/packages/premium.png") var(--ion-color-medium)`;
    }
  }
}
