import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {


  constructor() { }

  ngOnInit() {


  }

}
