import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { BehaviorSubject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { map, take } from "rxjs/operators";
import { User } from '../_shared/models/users.model';
import { config } from '../_shared/configs/config';
import { AuthenticationService } from '../pages/auth/auth.service';
import { StorageService } from './storage.service';
import { ModalController } from '@ionic/angular';
import { BlockedUserComponent } from '../_shared/components/modals/blocked-user/blocked-user.component';
import { environment } from 'src/environments/environment';

declare var toastr: any;

@Injectable()
export class UsersService {
  userSubscription: Subscription;
  storageSubscription: Subscription;
  userData: any;
  entityId: string;
  runningChecks = 0;

  userDoc: AngularFirestoreDocument<User>;
  usersCollection: AngularFirestoreCollection<User[]>;
  entityUsersCollection: AngularFirestoreCollection<any>;
  public userId = new BehaviorSubject<string>('');
  public userInfo = new BehaviorSubject<User>({});
  public blankInfo = new BehaviorSubject<any>({});

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private auth: AuthenticationService,
    private storage: StorageService,
    private modalCtrl: ModalController
  ) {
    this.entityId = config.entityId;
    this.runningChecks = 0;
  }

  fetchUserDetails(ref: string) {
    this.userDoc = this.afs.doc(ref);
    return this.userDoc.valueChanges();
  }

  getUser(id: string) {
    return this.afs.doc(`users/${id}`).get();
  }

  fetchEntityUsers() {
    const entityUsersCollection = this.afs.collection(`entities/${this.entityId}/users`);

    // FETCH ALL ENTITY USERS
    return entityUsersCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {

        const data = a.payload.doc.data() as any;
        data.full_name = `${data.firstname} ${data.surname}`;

        // FETCH EXTRA USER INFO
        this.afs.doc(`users/${a.payload.doc.id}`).ref.get().then((user: any) => {
          if (user.data()) {

            // FETCH USERS VERIFICATION

            if (user.data().firebaseId) {
              return this.afs.doc(`userRefs/${user.data().firebaseId}`).ref.get().then((userRef: any) => {
                if (userRef.data()) {
                  data.verified = userRef.data().verified;
                  data.socialLogin = userRef.data().socialLogin;
                  data.signInMethod = userRef.data().signInMethod;
                  data.photoURL = user.data().photoURL;
                  data.uploadedPhoto = user.data().uploadedPhoto;

                  // FETCH USERS PERMISSIONS
                  return this.afs.doc(`users/${a.payload.doc.id}/entities/${this.entityId}`).ref.get().then((permissions: any) => {
                    if (permissions.data()) {
                      data.permissions = permissions.data().permissions;
                    }
                  });
                }
              });
            } else {

              // FETCH USERS PERMISSIONS
              return this.afs.doc(`users/${a.payload.doc.id}/entities/${this.entityId}`).ref.get().then((permissions: any) => {
                if (permissions.data()) {
                  data.permissions = permissions.data().permissions;
                }
              });

            }
          }
        });

        return data;
      });
    }));
  }

  fetchUsers() {
    const entityUsersCollection = this.afs.collection(`users`);
    return entityUsersCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.full_name = `${data.firstname} ${data.surname}`;
        return data;
      });
    }));
  }

  findUsers() {
    const entityUsersCollection = this.afs.collection(`entities/${this.entityId}/users`, ref => ref.where('active', '==', true).where('status', '==', 1));
    return entityUsersCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as User;
        const refNo = (data.refNo) ? data.refNo : '';
        const photoURL = (data.photoURL) ? data.photoURL : '';
        const uploadedPhoto = (data.uploadedPhoto) ? data.uploadedPhoto : '';
        const uploadedPhotoThumbnail = (data.uploadedPhotoThumbnail) ? data.uploadedPhotoThumbnail : '';
        let user = {
          firstname: data.firstname,
          surname: data.surname,
          email: data.email,
          contactNumber: data.contactNumber,
          uid: data.uid,
          refNo,
          photoURL,
          uploadedPhoto,
          uploadedPhotoThumbnail
        };
        return user;
      });
    }));
  }

  fetchInvitedUsers() {
    const invitedUsersCollection = this.afs.collection(`pendingUserInvites`, ref => ref.where("entityId", "==", this.entityId));
    return invitedUsersCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.full_name = `${data.firstname} ${data.surname}`;
        return data;
      });
    }));
  }

  fetchUserRefDetails(firebaseId: string) {
    const userRefsRef = this.afs.doc(`userRefs/${firebaseId}`);
    return userRefsRef.valueChanges();
  }

  fetchUserDetailsForView(userId) {
    return this.afs.doc(`/users/${userId}`).valueChanges();
  }

  updateUser(user: User) {

    const updateUser = this.afs.doc(`/pending/${user.uid}`);
//create new document 
    return updateUser.set({
      //emailTo
      request: 'updateUserDetails',//emailShare
      user: user, // email body, dont need the user
      entityId: this.entityId,
      source: 'admin', //app
      firebaseId: user.firebaseId // dont need
    }).then(() => {

      // toastr.success("Your Profile has been updated!");
      let logData = {
        name: user.email,
        description: 'User was updated',
        type: 'update',
        category: 'user',
        created: Date.now()
      }
    });
  }

  updateUserPermission(userUID, permissions: any) {

    // SET PERMISSIONS
    let addPermissions = [];
    if (permissions) {
      addPermissions = ['user', permissions];
    } else {
      addPermissions = ['user'];
    }
    const data = {
      permissions: addPermissions
    };

    const permissionDoc = this.afs
      .collection("users")
      .doc(userUID)
      .collection("entities")
      .doc(this.entityId);
    permissionDoc.update(data);
  }

  updateInvitedUser(user: User) {

    if (user.permissions) {
      user.permissions = ['user', user.permissions];
    } else {
      user.permissions = ['user'];
    }

    const updateUser = this.afs.doc(`/pendingUserInvites/${user.uid}`);
    return updateUser.set(user, { merge: true }).then(() => {

      // toastr.success("Your Profile has been updated!");
      let logData = {
        name: user.email,
        description: 'User was updated',
        type: 'update',
        category: 'user',
        created: Date.now()
      }
    });
  }

  addHistoryLogToUser(userLog, userId) {
    const entityID = this.entityId;
    const propertyHistoryCollection = this.afs.collection(`entities/${entityID}/users/${userId}/history`);
    let logData = {
      userId: userId,
      created: Date.now(),
      changed: userLog
    }
    return propertyHistoryCollection.add(logData);;
  }

  addPendingUserUpdates(logData) {
    const entityID = this.entityId;
    const pendingUpdatesCollection = this.afs.collection(`pending`);
    logData.request = 'notifyReceiveUserUpdates';
    logData.entityId = entityID;
    pendingUpdatesCollection.add(logData).catch((err) => {
      console.log(err);
    });
  }

  inviteUser(userData) {

    // SET PERMISSIONS
    let permissions = [];
    if (userData.permissions) {
      permissions = ['user', userData.permissions];
    } else {
      permissions = ['user'];
    }

    // SET USER REF PREFIX
    const firstname = userData.firstname.toUpperCase();
    const shortLastname = userData.surname.substring(0, 1).toUpperCase();
    const referencePrefix = firstname + shortLastname;

    // SET TEMP USER
    let tmpUserData = {
      firstname: userData.firstname,
      surname: userData.surname,
      email: userData.email,
      contactNumber: userData.contactNumber,
      active: true,
      referencePrefix: referencePrefix
    }

    // CHECK IF USER EXISTS
    const usersCollection = this.afs.collection(`users`, ref => ref.where('email', '==', tmpUserData.email));
    return usersCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as User;
          return data;
        });
      }),
      take(1))
      .toPromise()
      .then(usersList => {
        if (usersList.length === 0) {
          return usersCollection.add(tmpUserData).then(ref => {
            return this.linkUserEntity(this.entityId, ref.id, tmpUserData, permissions).then(() => {
              return Promise.resolve(ref.id);
            });
          });
        } else {
          // ADD EXISTING USER

          // CHECK IF USER ALREADY ADDED TO ENTITY
          this.entityUsersCollection = this.afs
            .collection("entities")
            .doc(this.entityId)
            .collection("users", ref => ref.where("uid", "==", usersList[0].uid).where("active", "==", true));

          return this.entityUsersCollection
            .snapshotChanges().pipe(
              map(changes => {
                return changes.map(a => {
                  const data = a.payload.doc.data() as User;
                  data.uid = a.payload.doc.id;
                  return data.uid;
                });
              }),
              take(1))
            .toPromise()
            .then(entityUsersList => {
              if (entityUsersList.length === 0) {
                // USER NOT ADDED TO ENTITY SO CAN ADD USER
                return this.linkUserEntity(this.entityId, usersList[0].uid, usersList[0], permissions).then(() => {
                  return Promise.resolve(usersList[0].uid);
                });
              } else {
                // USER ALREADY ADDED TO ENTITY SO DISPLAY ERROR
                return Promise.reject([`The user already exists`]);
              }
            });
        }
      });
  }

  linkUserEntity(entityID, userID, userData, permissions) {
    const entityRef = this.afs.collection("entities").doc(entityID);
    const userRef = this.afs.collection("users").doc(userID);
    const pendingUserInvitesRef = this.afs.collection("pendingUserInvites").doc(userID);
    const pendingEmailEntityInviteRef = this.afs.collection("pending");
    const userRefsRef = this.afs.collection("userRefs", ref => ref.where("email", "==", userData.email));
    let usersCount = 0;

    // LINK ENTITY TO USER
    const linkEntityToUser = entityRef
      .snapshotChanges().pipe(
        take(1))
      .toPromise()
      .then(snap => {
        const entityDetails = snap.payload.data() as any;
        usersCount = entityDetails.usersCount;

        userRef
          .collection("entities")
          .doc(entityID)
          .set(
            {
              name: entityDetails.name,
              ref: entityRef.ref,
              uid: entityID,
              status: 0,
              permissions: permissions,
              active: true
            },
            { merge: true }
          );

        // INCREMENT USERS COUNT
        usersCount++;
        entityRef.set(
          {
            usersCount: usersCount
          },
          { merge: true }
        );
      });

    // LINK USER TO ENTITY
    if (!userData.contactNumber) {
      userData.contactNumber = "";
    }

    const linkUserToEntity = entityRef
      .collection("users")
      .doc(userID)
      .set(
        {
          firstname: userData.firstname,
          surname: userData.surname,
          email: userData.email.toLowerCase(),
          contactNumber: userData.contactNumber,
          ref: userRef.ref,
          uid: userID,
          status: 0,
          active: true
        },
        { merge: true }
      );



    // CREATE PENDING USER INVITE
    const createPendingUserInvite = userRefsRef
      .snapshotChanges().pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as User;
            data.uid = a.payload.doc.id;
            return data.uid;
          });
        }),
        take(1))
      .toPromise()
      .then(userRefsData => {
        if (userRefsData.length === 0) {
          // IF USER REF DOES NOT EXIST CREATE ONE
          return pendingUserInvitesRef.set(
            {
              firstname: userData.firstname,
              surname: userData.surname,
              email: userData.email.toLowerCase(),
              contactNumber: userData.contactNumber,
              ref: userRef.ref,
              uid: userID,
              created: Date.now(),
              entityId: entityID
            },
            { merge: true }
          );
        }
      });

    // CREATE PENDING EMAIL ENITY INVITE

    // BASE64 ENCODE USER DATA FOR EMAIL INVITE LINK
    const userDataToEncode = {
      firstname: userData.firstname,
      surname: userData.surname,
      email: userData.email.toLowerCase(),
      uid: userID,
      permissions: permissions
    }

    const encodedUserBase64Data = btoa(JSON.stringify(userDataToEncode));

    const createPendingEmailEntityInvite = pendingEmailEntityInviteRef.add({
      request: 'emailEntityInvites',
      entityId: entityID,
      userId: userID,
      userBase64Data: encodedUserBase64Data,
      domain: environment.websiteUrl
    });

    return Promise.all([linkEntityToUser, linkUserToEntity, createPendingUserInvite, createPendingEmailEntityInvite]).then(() => {
      return Promise.resolve(userID);
    });
  }

  emailCheck(email) {
    let collref = this.afs.collection(`entities/${this.entityId}/users`).ref;
    let queryref = collref.where('email', '==', email);
    return queryref.get().then((snapShot) => {
      return snapShot;
    });
  }

  addUserReferenceShare(sharingPlatform, userId) {
    const sharingInfo = {
      sharedVia: sharingPlatform,
      createdBy: userId,
      created: Date.now(),
      entityId: this.entityId,
      status: 0,
      active: true
    };
    const addEntityReferenceShare = this.afs.collection(`entities/${this.entityId}/referenceShares`).add(sharingInfo);
    const addUserReferenceShare = this.afs.collection(`users/${userId}/entities/${this.entityId}/referenceShares`).add(sharingInfo);

    return Promise.all([addEntityReferenceShare, addUserReferenceShare]);

  }

  async checkUserSuspended() {
    if (this.runningChecks === 0) {
      this.runningChecks++;
      this.userSubscription = this.auth.user.subscribe(user => {
        this.userData = user;
        if (this.userData) {
          this.storageSubscription = this.storage.blockedModalOpen.subscribe(async open => {
            if (!open && this.userData.status === 2) {
              const modal = await this.modalCtrl.create({
                component: BlockedUserComponent,
                componentProps: {},
                cssClass: 'modal-small'
              });
              modal.onDidDismiss()
                .then((data) => {
                  this.storage.blockedModalOpen.next(false);
                  this.userSubscription.unsubscribe();
                  this.storageSubscription.unsubscribe();
                  this.runningChecks = 0;
                });

              return await modal.present().then(() => {
                this.storage.blockedModalOpen.next(true);
              });
            }
          });
        }
      });
    }
  }

}
