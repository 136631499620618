import { BookingsService } from './pages/bookings/_services/bookings.service';
import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AuthGuard } from '../app/pages/auth/auth.guard';
import { AuthModule } from './pages/auth/auth.module';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { LoaderModule } from '../app/_shared/loader-component/loader.module';
import { Camera } from '@ionic-native/camera/ngx';
import { MapsModule } from './_shared/components/maps/maps.module';
import { UsersService } from './services/users.service';
import { LocationsService } from './pages/location/_services/locations.service';
import { FilesService } from './_shared/components/files/files.service';
import { GenerateThumbnailService } from './services/generate-thumbnail.service';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { MeetingRoomsModule } from './pages/meeting-rooms/meeting-rooms.module';
import { MeetingRoomsService } from './pages/meeting-rooms/_services/meeting-rooms.service';
import { ModalsModule } from './_shared/components/modals/modals.module';
import { EventsService } from './pages/events/_services/events.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { NgCalendarModule } from 'ionic2-calendar';
import { SubscriptionsService } from './services/subscriptions.service';
import { ProductsService } from './services/products.service';
import { TeamsService } from './pages/teams/_services/teams.service';
import { TeamsModule } from './pages/teams/teams.module';
import { FCM } from '@ionic-native/fcm/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FcmService } from './services/fcm.service';
import { TransactionsService } from './services/transactions.service';
import { ClientService } from './services/client.service';
import { NotificationsService } from './services/notifications.service';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { UsersModule } from './pages/ws-users/users.module';
import { LocationsModule } from './pages/location/locations.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { ComponentsModule } from './_shared/components/components.module';
import { EventsModule } from './pages/events/events.module';
import { PackagesListPageModule } from './pages/packages-list/packages-list.module';
import { ContactUsPageModule } from './pages/communications/contact-us/contact-us.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RatingService } from './services/ratings.service';
import { SupportModule } from './pages/support-pages/support.module';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { MessagesService } from './services/messages.service';
import { MessagesModule } from './pages/messages/messages.module';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { StarRatingModule } from 'ionic4-star-rating';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { PaymentsModule } from './pages/payments/payments.module';
import { ReportService } from './services/report.service';
import { CouponsService } from './services/coupons.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { NavHeaderComponent } from '../app/_shared/header/nav-header/nav-header.component';
import { NavHeaderDropdownComponent } from './_shared/header/nav-header-dropdown/nav-header-dropdown.component';
import { MatMenuModule, MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './_shared/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';

defineCustomElements(window);

@NgModule({
  declarations: [AppComponent, NavHeaderComponent,NavHeaderDropdownComponent,FooterComponent],
  entryComponents: [],
  imports: [
    NgCalendarModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SortablejsModule.forRoot({ animation: 150 }),
    IonicStorageModule.forRoot(),
    NgPipesModule,
    AuthModule,
    LoaderModule,
    MapsModule,
    MeetingRoomsModule,
    ModalsModule,
    TeamsModule,
    NgxQRCodeModule,
    UsersModule,
    LocationsModule,
    ComponentsModule,
    EventsModule,
    PackagesListPageModule,
    ContactUsPageModule,
    SupportModule,
    MessagesModule,
    StarRatingModule,
    PaymentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatMenuModule,
    BrowserAnimationsModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  providers: [
    GooglePlus,
    Facebook,
    LaunchNavigator,
    StatusBar,
    SplashScreen,
    Clipboard,
    SocialSharing,
    InAppBrowser,
    SignInWithApple,
    Geolocation,
    LocationAccuracy,
    AndroidPermissions,
     LocalNotifications,
    Camera,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} },
    AuthGuard,
    UsersService,
    LocationsService,
    FilesService,
    GenerateThumbnailService,
    MeetingRoomsService,
    BookingsService,
    EventsService,
    SubscriptionsService,
    ProductsService,
    TeamsService,
    FcmService,
    FCM,
    TransactionsService,
    ClientService,
    NotificationsService,
    RatingService,
    ReportService,
    CouponsService,
    MessagesService,
    FileTransfer,
    FileTransferObject,
    File,
    FileOpener,
    Keyboard,
    Base64,
    FileChooser,
    IOSFilePicker,
    Chooser
  ],
  exports: [
    SortablejsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
