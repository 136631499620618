import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PackagePaymentComponent } from '../package-payment/package-payment.component';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html'
})
export class PackageListComponent implements OnInit {

  team;
  user: User;
  subscription: any;

  productsSubscription: Subscription;
  products: any[];
  isLoading = false;
  activeProduct: any;
  productForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private alert: AlertService,
    private productsService: ProductsService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {

    this.productsSubscription = this.productsService.getPublicProducts().subscribe(productsData => {
      this.products = productsData;
      if (this.subscription) {
        const active = this.products.filter((e) => e.refNo === this.subscription.productRef);
        this.activeProduct = active[0];
      }
      this.isLoading = false;
    });
  }

  onSubmit(product) {
    const values = {
      product: product,
      user: this.user
    };
    console.log(values)
    this.modalCtrl.dismiss(values);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
