import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';

@Component({
  selector: 'app-teams-checkins-list',
  templateUrl: './teams-checkins.page.html'
})
export class TeamsCheckInsPage implements OnInit {

  teamId: string;

  isLoading: boolean = false;
  checkIns$: Observable<Array<{}>>;
  checkInsRef: string;

  // USER
  userId: string;
  userName: string;

  constructor(
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private nav: NavController,
    private route: ActivatedRoute,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.checkInsRef = `entities/${config.entityId}/teams/${this.teamId}`;
    this.fetchCheckIns();
    this.isLoading = false;
  }

  fetchCheckIns() {
    this.checkIns$ = this.bookingsService.getCheckIns(this.checkInsRef);
  }

  getCheckIn(event) {
    if (event.type === 'viewUser') return this.getUser(event.data);
  }

  getUser(user) {
    const modalData = {
      title: `${user.userRef}`,
      userId: user.userId,
      type: user.type,
      userIndex: user.userIndex
    }
    this.alert.openModal(UserViewComponent, modalData, 'modal-small');
  }

}