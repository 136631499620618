import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/_shared/models/users.model';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html'
})
export class QRCodeComponent implements OnInit {

  data: any;
  public qrdata: string = null;
  elementType = 'url';

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.qrdata = this.data.uid;
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
