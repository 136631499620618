import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { config } from '../../../_shared/configs/config';
import { combineLatest, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from '../../../services/users.service';
import { AuthenticationService } from '../../auth/auth.service';
import { FilesService } from 'src/app/_shared/components/files/files.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ImagesService } from 'src/app/services/images.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
// tslint:disable: max-line-length
@Injectable()
export class LocationsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  checkIns$: Observable<Array<{}>>;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private notificationsService: NotificationsService,
    private imagesService: ImagesService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getLocations() {
    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`, ref => ref.orderBy('name', 'asc'));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocations() {
    const locationsCollection = this.afs.collection(`/public/${this.entityId}/locations`,
      ref => ref.orderBy('name', 'asc').where('status', '==', 1).where('verified', '==', true).where('active', '==', true));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getLocationTypes(field, value) {
    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`, ref => ref.where(field, '==', value));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocationDetails(locationId, userId?) {
    const locationBusinessHours = this.afs.doc(`/public/${this.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const locationAmenities = this.afs.doc(`/public/${this.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const locationCheckIns = (userId) ? this.afs.collection(`entities/${this.entityId}/locations/${locationId}/checkIns`).valueChanges({ idField: 'id' }) : [''];
    return combineLatest([locationBusinessHours, locationAmenities, locationCheckIns]).pipe(map((dataArrays) => {
      const locationDetails = {
        businessHours: dataArrays[0],
        amenities: dataArrays[1],
        checkIns: dataArrays[2]
      };
      return locationDetails;
    }));
  }

  getLocationsForUser(userId) {
    return this.afs.collection(`users/${userId}/entities/${config.entityId}/locations/`, ref => ref.where('active', '==', true)).valueChanges();
  }

  getBoostedLocations(region) {
    const locationsCollection  = this.afs.collection(`entities/${this.entityId}/locations`, ref => ref.where('boosted', '==', true).where('address.region', '==', region));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getLocationDetails(locationId) {
    const getLocationDetails = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}`).valueChanges();
    const getLocationAmenities = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationRooms = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/meetingRooms`, ref => ref.where('active', '==', true)).valueChanges();
    return combineLatest([getLocationDetails, getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationRooms]).pipe(
      map((dataArrays) => {
        const locationData = {
          location: dataArrays[0],
          amenities: dataArrays[1],
          businessHours: dataArrays[2],
          gallery: dataArrays[3],
          meetingRooms: dataArrays[4]
        };
        return locationData;
      })
    );
  }

  getLocationInfo(locationId) {
    const getLocationDetails = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}`).valueChanges();
    return getLocationDetails;
  }

  getLocationGallery(locationId) {
    const getLocationGallery = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    return getLocationGallery;
  }

  getLocationHours(locationId) {
    const getLocationBusinessHours = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    return getLocationBusinessHours;
  }

  getLocationAmenities(locationId) {
    const getLocationAmenities = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    return getLocationAmenities;
  }

  getPublicLocationsBySlug(slug) {
    const locationsCollection = this.afs.collection(`public/${this.entityId}/locations`, ref => ref.where('refNo', '==', slug));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getLocationReviews(locationId) {
    const locationReviewsCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/reviews`);
    return locationReviewsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocationsDetails(locationId) {
    const getLocationDetails = this.afs.doc(`/public/${config.entityId}/locations/${locationId}`).valueChanges();
    const getLocationAmenities = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/public/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationMeetingRooms = this.afs.collection(`public/${config.entityId}/locations/${locationId}/meetingRooms`).valueChanges();
    return combineLatest([getLocationDetails, getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationMeetingRooms]).pipe(
      map((dataArrays) => {
        let locationData = {
          location: dataArrays[0],
          amenities: dataArrays[1],
          businessHours: dataArrays[2],
          gallery: dataArrays[3],
          meetingRooms: dataArrays[4]
        };
        return locationData;
      })
    );
  }

  getLocationUsers(locationId) {
    const locationUsersCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/users`, ref => ref.where('active', '==', true));
    return locationUsersCollection.valueChanges({ idField: 'id' });
  }

  addLocation(locationInfo) {
    console.log('location info ', locationInfo);
    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`);
    let members = locationInfo.users;

    // SET VERIFIED LOCATION
    let verified;
    if (config.source === 'admin') {
      verified = true;
    } else {
      verified = false;
    }
    let customType = '';
    if (locationInfo.basicInfo.typeOther) {
      customType = locationInfo.basicInfo.typeOther
    }

    // SET PREFIX FOR REF NO
    const referencePrefix = locationInfo.basicInfo.name.substring(0, 3).toUpperCase();

    // SET PREFIX FOR SLUG
    const slugPrefix = locationInfo.basicInfo.name.replace(/\s/g, '-').toLowerCase();

    // SET LOCATION DOC DATA
    let locationData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      name: locationInfo.basicInfo.name,
      type: locationInfo.basicInfo.type,
      customType: customType,
      description: locationInfo.basicInfo.description,
      contactNumber: locationInfo.basicInfo.contactNumber,
      availableSpaces: locationInfo.basicInfo.availableSpaces,
      meetingRooms: locationInfo.basicInfo.meetingRooms,
      noiseLevel: locationInfo.basicInfo.noiseLevel,
      eventSpace: locationInfo.basicInfo.eventSpace,
      parking: locationInfo.basicInfo.parking,
      address: locationInfo.locationAddress,
      status: 0,
      referencePrefix: referencePrefix,
      owner: locationInfo.owner,
      source: config.source,
      verified: verified,
      featureImage: '',
      featureImageThumbnail: '',
      featureImageId: '',
      uploadedPhoto: '',
      uploadedPhotoThumbnail: '',
      product: '',
      slugPrefix: slugPrefix
    };

    locationInfo.name = locationInfo.basicInfo.name;
    locationInfo.description = locationInfo.basicInfo.description;

    return locationsCollection.add(locationData).then(ref => {
      console.log('added location to collection ', ref);
      const locationsDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}`);
      const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}/amenities/list`);
      const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}/businessHours/list`);
      const requestDoc = this.afs.doc(`pending/${ref.id}`);

      return locationsDoc.set({ uid: ref.id }, { merge: true }).then(() => {

        console.log('location uid added ', ref.id);

        // SET AMENITIES
        const setAmenities = amenitiesDoc.set({ list: locationInfo.amenities }, { merge: true });

        // SET BUSINESS HOURS
        const setBusinessHours = businessHoursDoc.set(locationInfo.businessHours, { merge: true });

        console.log('business hours and amenities set ');

        // SET LOCATION REQUEST
        const encodedBase64Data = btoa(`/locations/edit/${ref.id}`);
        const locationUrl = `${environment.adminUrl}/redirect/${encodedBase64Data}`;
        const adminRequest = requestDoc.set({
          request: 'locationAddRequest',
          locationData: {
            locationId: ref.id,
            locationAddress: locationData.address.formatted_address,
            locationName: locationData.name,
          },
          owner: locationData.owner,
          redirectUrl: locationUrl,
          entityId: this.entityId,
          source: config.source,
        }, { merge: true });

        return Promise.all([setAmenities, setBusinessHours, adminRequest]).then(() => {
          console.log('added location amenities, business hours and admin request ');

          // SAVE LOGO
          if (locationInfo.locationLogo) {
            // SET REF PATH TO SAVE
            let saveRef = {
              dbPath: `entities/${config.entityId}/locations/${ref.id}`,
              filePath: `entities/${config.entityId}/locations/${ref.id}`
            };
            const uploadFiles = this.filesService.handleDrop(locationInfo.locationLogo, saveRef);
            return Promise.resolve(uploadFiles).then(() => {
              if (locationInfo.gallery) {
                const uploadGallery = this.handleGalleryImages(locationInfo.gallery, locationInfo.featuredImg, ref.id);
                return Promise.resolve(uploadGallery).then(() => {
                  if (members) {
                    members.forEach(member => {
                      this.linkUsersAndLocations(member, locationData, ref.id);
                    });
                  }
                });
              } else {
                if (members) {
                  members.forEach(member => {
                    this.linkUsersAndLocations(member, locationData, ref.id);
                  });
                }
              }
            });
          } else {
            if (locationInfo.gallery) {
              const uploadGallery = this.handleGalleryImages(locationInfo.gallery, locationInfo.featuredImg, ref.id);
              return Promise.resolve(uploadGallery).then(() => {
                if (members) {
                  members.forEach(member => {
                    this.linkUsersAndLocations(member, locationData, ref.id);
                  });
                }
              });
            } else {
              if (members) {
                members.forEach(member => {
                  this.linkUsersAndLocations(member, locationData, ref.id);
                });
              }
            }
          }

        });
      })
    });

  }


  deleteLocationUser(userId, locationId, locationName) {
    const locationsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/users/${userId}`);
    const usersLocationsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/locations/${locationId}`);

    const deletedUser = {
      uid: userId
    }
    const notification = {
      title: 'Removed from Location',
      body: `You have been removed from ${locationName} location`,
      type: 'locationUserRemoved',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: userId,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/locations/list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Removed from Location',
        message: `You have been removed from <strong>${locationName}</strong> location`,
        linkText: `View Locations`,
        type: 'locationUserRemoved',
        createdBy: this.loggedInUser.uid,
        userId: userId,
        url: `/locations/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const sendUserNotification = this.notificationsService.addNotification(notification, deletedUser);
    const deleteLocationUser = locationsUsersDoc.delete();
    const deleteUserLocation = usersLocationsDoc.delete();

    return Promise.all([deleteLocationUser, deleteUserLocation, sendUserNotification]);
  }

  linkUsersAndLocations(member, locationData, refId) {

    // LINK USER TO TEAM
    if (member.invited) {
      return this.usersService.inviteUser(member).then(userRef => {
        if (locationData.owner.uid === member.uid) {
          member.isOwner = true;
        }

        // LINK USER TO TEAM
        let linkData = member;
        linkData.uid = userRef;
        linkData.locationId = refId;
        linkData.name = locationData.name;
        linkData.address = locationData.address.formatted_address;
        linkData.verified = locationData.verified;
        linkData.status = locationData.status;
        linkData.created = locationData.created;
        return this.addUserToLocation(linkData);
      });
    } else {
      if (locationData.owner.uid === member.uid) {
        member.isOwner = true;
      }

      // LINK USER TO TEAM
      let linkData = member;
      linkData.locationId = refId;
      linkData.name = locationData.name;
      linkData.address = locationData.address.formatted_address;
      linkData.verified = locationData.verified;
      linkData.status = locationData.status;
      linkData.created = locationData.created;
      return this.addUserToLocation(linkData);
    }
  }

  addUserToLocation(linkData) {

    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${linkData.locationId}/users/${linkData.uid}`);
    const usersTeamsDoc = this.afs.doc(`users/${linkData.uid}/entities/${this.entityId}/locations/${linkData.locationId}`);

    let accepted = (linkData.isOwner) ? true : false;
    let sendUserNotification;

    // SET USER DATA FOR TEAM
    let locationUser = {
      active: true,
      firstname: linkData.firstname,
      surname: linkData.surname,
      email: linkData.email,
      ref: `/users/${linkData.uid}`,
      uid: linkData.uid,
      isAdmin: linkData.isAdmin,
      isOwner: linkData.isOwner,
      accepted: accepted
    };
    // SET TEAM DATA FOR USER
    let userTeam = {
      active: true,
      name: linkData.name,
      ref: `/entities/${this.entityId}/locations/${linkData.locationId}`,
      uid: linkData.locationId,
      isAdmin: linkData.isAdmin,
      isOwner: linkData.isOwner,
      address: linkData.address,
      verified: linkData.verified,
      status: linkData.status,
      created: linkData.created,
      accepted: accepted
    }

    const notification = {
      title: 'Added to Location',
      body: `You have been added as admin to ${linkData.name} location`,
      type: 'locationUserAdd',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: linkData.uid,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/locations-list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Added to Location',
        message: `You have been added as admin to <strong>${linkData.name}</strong> location`,
        linkText: `View Locations`,
        type: 'locationUserAdd',
        createdBy: this.loggedInUser.uid,
        userId: linkData.uid,
        url: `/locations/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const setLocationUser = teamsUsersDoc.set(locationUser, { merge: true });
    const setUserLocation = usersTeamsDoc.set(userTeam, { merge: true });

    if (!accepted) {
      sendUserNotification = this.notificationsService.addNotification(notification, linkData);
    }

    return Promise.all([setLocationUser, setUserLocation, sendUserNotification]);
  }

  updateLocation(locationId, location, businessHours?, amenitiesList?) {
    const locationsDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}`);
    return locationsDoc
      .set(location, { merge: true })
      .then(() => {
        if (businessHours) {
          this.updateLocationBusinessHours(locationId, businessHours);
        }
        if (amenitiesList) {
          this.updateAmenitiesHours(locationId, amenitiesList);
        }
      })
      .catch(error => {

      });
  }

  updateLocationBusinessHours(locationId, businessHours) {
    const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/businessHours/list`);
    return businessHoursDoc.set({
      monday: businessHours.monday,
      tuesday: businessHours.tuesday,
      wednesday: businessHours.wednesday,
      thursday: businessHours.thursday,
      friday: businessHours.friday,
      saturday: businessHours.saturday,
      sunday: businessHours.sunday
    }, { merge: true });
  }

  updateAmenitiesHours(locationId, amenitiesList) {
    const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/amenities/list`);
    return amenitiesDoc.set({ list: amenitiesList }, { merge: true });
  }

  updateLocationUser(userId, locationId, updateValues) {
    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/users/${userId}`);
    const usersTeamsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/locations/${locationId}`);

    const setTeamUser = teamsUsersDoc.set(updateValues, { merge: true });
    const setUserTeam = usersTeamsDoc.set(updateValues, { merge: true });

    return Promise.all([setTeamUser, setUserTeam]);
  }

  handleGalleryImages(imagesArray, featuredImg, locationId) {
    const locationPhotosArray: any = [];
    let pathInfo = [];
    if (imagesArray.length > 0) {
      return imagesArray.forEach((image, index, array) => {
        let imgInfo = {
          filePath: `entities/${config.entityId}/locations/${locationId}`,
          dbPath: `entities/${config.entityId}/locations/${locationId}/gallery`,
          order: image.order,
          tempId: image.tempId,
          feautureImgDoc: `entities/${config.entityId}/locations/${locationId}`
        };
        locationPhotosArray.push(image.file);
        console.log(JSON.stringify(locationPhotosArray))
        pathInfo.push(imgInfo);
        if (locationPhotosArray.length === array.length && pathInfo.length == array.length) {
          return this.filesService.handleGalleryDrop(locationPhotosArray, pathInfo, featuredImg);
        } else {
          return Promise.resolve('Failed to add images ');
        }
      })
    }
  }

  uploadGallery(imagesArray, featuredImg, locationId) {
    return imagesArray.forEach(image => {
      const imgDate = new Date();
      const saveRef = `entities/${config.entityId}/locations/${locationId}`;
      const dbRef = `entities/${config.entityId}/locations/${locationId}/gallery`;
      const imageName = `${imgDate}_image ${image.order}`;
      const data = { data: image.url };
      return this.imagesService.saveGalleryImage(data, saveRef, dbRef, locationId, imageName, image.order);
    });
  }

  updateGalleryImageOrder(locationId, image) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/gallery/${image.uid}`);
    return imageDoc.set({ order: image.order }, { merge: true });
  }

  deleteGalleryImage(locationId, imageId) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/gallery/${imageId}`);
    return imageDoc.delete();
  }

  acceptLocationInvite(locationData, user, accepted) {
    const locationDoc = this.afs.doc(locationData.ref);
    const locationUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationData.uid}/users/${user.uid}`);
    const usersLocationsDoc = this.afs.doc(`users/${user.uid}/entities/${this.entityId}/locations/${locationData.uid}`);
    let locationOwner;
    return locationDoc.ref.get().then((location: any) => {

      locationOwner = location.data().owner;

      let notification = {
        title: 'Accepted Location Invite',
        body: `${user.firstname} ${user.surname} accepted your invite to ${locationData.name}`,
        type: 'locationUserAdded',
        createdBy: user.uid,
        addToHistory: true,
        userId: locationOwner.uid,
        customData: {
          createdBy: user.uid,
          notificationUrl: `/location-edit/${locationData.uid}`
        },
        historyInfo: {
          created: new Date(),
          title: 'Accepted Location Invite',
          message: `${user.firstname} ${user.surname} accepted your invite to <strong>${locationData.name}</strong>`,
          linkText: `View Location`,
          type: 'locationUserAdded',
          createdBy: user.uid,
          userId: locationOwner.uid,
          url: `/locations/edit/${locationData.uid}`,
          listRef: '/my-notifications',
          source: config.source,
          unread: true
        }
      }

      if (!accepted) {
        notification.title = 'Declined Location Invite';
        notification.body = `${user.firstname} ${user.surname} declined your invite to ${locationData.name}`;
        notification.type = `locationUserDeleted`;
        notification.historyInfo.title = 'Declined Location Invite';
        notification.historyInfo.message = `${user.firstname} ${user.surname} declined your invite to <strong>${locationData.name}</strong>`;
        notification.historyInfo.type = `locationUserDeleted`;
      }

      const setLocationUser = (accepted) ? locationUsersDoc.set({ accepted: accepted }, { merge: true }) : locationUsersDoc.delete();
      const setUserLocation = (accepted) ? usersLocationsDoc.set({ accepted: accepted }, { merge: true }) : usersLocationsDoc.delete();
      const sendUserNotification = this.notificationsService.addNotification(notification, locationOwner);

      return Promise.all([setLocationUser, setUserLocation, sendUserNotification]);
    });
  }

  notifyAdmin(transaction, boostedDates) {
    const requestDoc = this.afs.collection(`pending`);
    const adminRequest = {
      request: 'locationBoostedAdPurchased',
      locationData: {
        locationId: transaction.typeId,
        locationName: transaction.typeName,
        boostedStartDate: moment(boostedDates.boostedStartDate).format('DD MMMM YYYY'),
        boostedEndDate: moment(boostedDates.boostedEndDate).format('DD MMMM YYYY')
      },
      user: {
        fullname: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
        email: this.loggedInUser.email
      },
      entityId: this.entityId,
      source: config.source,
    }
    return requestDoc.add(adminRequest);
  }

  notifyLocation(location) {
    const requestDoc = this.afs.collection(`pending`);
    const adminRequest = {
      request: 'locationBoostedAdNotify',
      locationData: {
        locationId: location.uid,
        locationAddress: location.address.formatted_address,
        locationName: location.name,
        locationRegion: location.address.region,
      },
      user: {
        fullname: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
        email: this.loggedInUser.email
      },
      owner: location.owner,
      entityId: this.entityId,
      source: config.source,
    }
    return requestDoc.add(adminRequest);
  }

}
