import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.page.html',
  styleUrls: ['../auth.scss']
})
export class PasswordResetPage implements OnInit {
  passwordResetForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private AuthenticationService: AuthenticationService,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.passwordResetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  sendRecoveryLink(form) {
    this.loading = true;
    const emailForRecovery = form.value.email;
    this.AuthenticationService.resetPassword(emailForRecovery).then(async () => {
      this.loading = false;
      this.passwordResetForm.reset();
      this.alertService.displayAlert('Recovery email sent', 'Check your email inbox for the password reset email.', [{
        text: 'Ok',
        handler: () => {
          this.router.navigate(['landing']);
        }
      }]);
    });
  }

}
