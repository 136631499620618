import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { Sender } from 'src/app/_shared/models/sender';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'src/app/_shared/models/message';
import { MessagesService } from 'src/app/services/messages.service';
import { Conversation } from 'src/app/_shared/models/conversation';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

declare var $: any;

@Component({
  selector: "app-messages-list-page",
  templateUrl: "./messages-list.page.html",
  styleUrls: ['../messages.scss']
})
export class MessagesListComponent implements OnInit, OnDestroy {

  location: any;
  conversationIdToLoad: string;
  userIdOfConversations: string;
  conversationsSubscription: Subscription;
  isLoading: boolean = false;
  conversationsList: any;
  existingConversationsLookupList: any[] = [];
  currentUserId: string;
  selected = '';

  constructor(
    public router: Router,
    private messagesService: MessagesService,
    private auth: AuthenticationService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.conversationIdToLoad = (this.route.snapshot.queryParamMap.get('conversationIdToLoad')) ? this.route.snapshot.queryParamMap.get('conversationIdToLoad') : null; 
    this.auth.user.subscribe(user => {
      if (user) {
        this.currentUserId = user.uid;
    
        if (this.conversationIdToLoad) {
          if (this.conversationIdToLoad === 'location') {
            this.storage.get('location').then((location) => {
              this.location = location;
            });
            this.userIdOfConversations = this.route.snapshot.queryParamMap.get('userIdOfConversations');
          } else {
            this.userIdOfConversations = user.uid;
          }
        } else {
          this.userIdOfConversations = user.uid;
        }
        this.conversationsSubscription = this.messagesService
          .getConversationsForUser(this.userIdOfConversations)
          .subscribe((conversations) => {
            if (conversations) {
              this.conversationsList = conversations;
              this.conversationsList.forEach((convo) => {
                this.existingConversationsLookupList.push(convo.id);
              });
              this.isLoading = false;
            }
          });  
      }
    });
  }

  goToConversation(conversationId, conversationName) {
    this.router.navigate([`/messages/conversation/${conversationId}`], { queryParams: { conversationName, conversationIdToLoad: this.conversationIdToLoad } });
  }

  goBack() {
    this.storage.clear();
    this.navCtrl.back();
  }

  ionViewDidLeave() {
    if (this.conversationsSubscription) {
      this.conversationsSubscription.unsubscribe();
    }
    this.conversationIdToLoad = '';
    this.userIdOfConversations = '';
    this.conversationsList = [];
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

}
