export const defaultAmenities = [
  {
    name: 'Unlimited Wifi',
    value: false,
    type: 'default'
  },
  {
    name: 'Limited Wifi',
    value: false,
    type: 'default'
  },
  {
    name: 'Free Parking',
    value: false,
    type: 'default'
  },
  {
    name: 'Paid Parking',
    value: false,
    type: 'default'
  },
  {
    name: '10% Discount on Food and Beverages',
    value: false,
    type: 'default'
  },
  {
    name: '20% Discount on Food and Beverages',
    value: false,
    type: 'default'
  },
  {
    name: 'Toilets',
    value: false,
    type: 'default'
  },
  {
    name: 'Free Coffee, Tea or Water',
    value: false,
    type: 'default'
  },
  {
    name: 'Seats with Plugs',
    value: false,
    type: 'default'
  }
];

export const extraAmenities = [
  {
    name: 'Video Games',
    value: false,
    type: 'extra'
  },
  {
    name: 'Printers',
    value: false,
    type: 'extra'
  },
  {
    name: 'VR',
    value: false,
    type: 'extra'
  },
  {
    name: 'Air-conditioning',
    value: false,
    type: 'extra'
  },
  {
    name: 'Showers',
    value: false,
    type: 'extra'
  },
  {
    name: 'Gym',
    value: false,
    type: 'extra'
  }
];
