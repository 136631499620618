import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, Observable } from 'rxjs';
import { defaultAmenities, extraAmenities } from '../_models/amenities';
import { weekdays } from '../../location/models/weekdays';
import { defaultTypes } from '../_models/types';
import { SortablejsOptions } from 'ngx-sortablejs';
import { MeetingRoomsService } from '../_services/meeting-rooms.service';
import { NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { Storage } from '@ionic/storage';
import { Camera } from '@ionic-native/camera/ngx';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-meeting-rooms-add',
  templateUrl: './meeting-rooms-add.component.html'
})
export class MeetingRoomsAddComponent implements OnInit {

  isLoading: boolean = false;

  meetingRoomAddForm: FormGroup;
  meetingRoom: any = {};
  step;
  formValues: any;
  isSubmitting: boolean = false;

  // LOCATION
  locationId: string;
  location: any;

  // BUSINESS HOURS
  businessDays = weekdays;
  closedTime;

  // AMENITIES
  defaultAmenities = [];
  extraAmenities = [];
  customAmenities = [];
  showDefaultAmenities = true;
  showExtraAmenities = false;
  showCustomAmenities = false;

  // ROOM TYPES
  defaultTypes = defaultTypes;

  // GALLERY UPLOAD
  eventOptions: SortablejsOptions = {
    handle: '.handle'
  };
  saveImage = false;
  saveGallery = false;
  imgPreview = 'assets/images/locations/location_profile.jpg';
  galleryFeaturedImg;
  galleryImages: any[] = [];
  imageFile: any = {};
  loadingGallery: boolean = false;

  // USER
  userSubscription: Subscription;
  user: User;

  constructor(
    private meetingRoomsService: MeetingRoomsService,
    private auth: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private storage: Storage,
    private cameraPlugin: Camera,
    private file: File,
    private alertService: AlertService,
    public sanitizer: DomSanitizer,
    private base64: Base64

  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.defaultAmenities = defaultAmenities;
    this.extraAmenities = extraAmenities;
    this.step = 1;
    this.buildForm();
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.storage.get('location').then((location) => {
      this.location = location;
      this.isLoading = false;
    });

    const midnight = moment().startOf('day');
    const isoMidnight = midnight.toISOString();
    this.closedTime = isoMidnight;
  }

  buildForm() {
    this.meetingRoomAddForm = this.fb.group({
      basicInfo: this.fb.group({
        name: ['', [Validators.required]],
        description: ['', [Validators.required]],
        sqMetres: ['', [Validators.required]],
        maxPeople: ['', [Validators.required]],
        hourlyRate: ['', [Validators.required]]
      }),
      businessHours: this.fb.group({
        monday: this.fb.group(this.timeFields()),
        tuesday: this.fb.group(this.timeFields()),
        wednesday: this.fb.group(this.timeFields()),
        thursday: this.fb.group(this.timeFields()),
        friday: this.fb.group(this.timeFields()),
        saturday: this.fb.group(this.timeFields()),
        sunday: this.fb.group(this.timeFields())
      }),
      amenities: this.fb.group({
        amenity: ['', [Validators.maxLength(50)]]
      }),
      types: this.fb.group({
        type: ['', [Validators.maxLength(50)]]
      })
    });
  }

  private timeFields() {
    return {
      open: ['', [Validators.required]],
      close: ['', [Validators.required]]
    }
  }

  getChange(event, day, time) {
    const date = event.target.value;
    if (moment(date).hours() === moment(this.closedTime).hours() && moment(date).minutes() === moment(this.closedTime).minutes()) {
      this.meetingRoomAddForm.get('businessHours').get(day).get(time).patchValue(this.closedTime);
    }
  }

  // BUSINESS HOUR FIELDS
  applyToAllDays(type) {
    let hours = this.meetingRoomAddForm.get('businessHours');
    hours.markAsDirty();
    if (type === 'weekdays') {
      const open = hours.get('monday').get('open').value;
      const close = hours.get('monday').get('close').value;
      hours.get('tuesday').get('open').patchValue(open);
      hours.get('tuesday').get('close').patchValue(close);
      hours.get('wednesday').get('open').patchValue(open);
      hours.get('wednesday').get('close').patchValue(close);
      hours.get('thursday').get('open').patchValue(open);
      hours.get('thursday').get('close').patchValue(close);
      hours.get('friday').get('open').patchValue(open);
      hours.get('friday').get('close').patchValue(close);
    }
    if (type === 'weekend') {
      const open = hours.get('saturday').get('open').value;
      const close = hours.get('saturday').get('close').value;
      hours.get('sunday').get('open').patchValue(open);
      hours.get('sunday').get('close').patchValue(close);
    }
  }

  setDayClosed(event, day) {
    let hours = this.meetingRoomAddForm.get('businessHours');
    hours.markAsDirty();
    if (event.target.checked) {
      hours.get(day).get('open').patchValue(this.closedTime);
      hours.get(day).get('close').patchValue(this.closedTime);
    } else {
      hours.get(day).get('open').patchValue('');
      hours.get(day).get('close').patchValue('');
    }
  }

  checkIfClosed(day) {
    if (day) {
      let hours = this.meetingRoomAddForm.get('businessHours').get(day);
      console.log(hours);
      if (hours.get('open').value.hour === 0 && hours.get('open').value.minute === 0 && hours.get('close').value.hour === 0 && hours.get('close').value.minute === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  formatISODate(open, close) {
    return {
      open: {
        hour: moment(open).hour(),
        minute: moment(open).minute(),
        second: 0
      },
      close: {
        hour: moment(close).hour(),
        minute: moment(close).minute(),
        second: 0
      }
    };
  }

  formatBusinessHours(hours) {
    return {
      monday: this.formatISODate(hours.monday.open, hours.monday.close),
      tuesday: this.formatISODate(hours.tuesday.open, hours.tuesday.close),
      wednesday: this.formatISODate(hours.wednesday.open, hours.wednesday.close),
      thursday: this.formatISODate(hours.thursday.open, hours.thursday.close),
      friday: this.formatISODate(hours.friday.open, hours.friday.close),
      saturday: this.formatISODate(hours.saturday.open, hours.saturday.close),
      sunday: this.formatISODate(hours.sunday.open, hours.sunday.close)
    };
  }

  // AMENITIES
  amenityChanged(name, type) {
    console.log('amenity and type ', name, type);
    type.forEach(amenity => {
      if (amenity.name.toLowerCase() === name.toLowerCase()) {
        amenity.value = !amenity.value;
      }
    });
  }

  addAmenity(name) {
    console.log('amenity name ', name);
    if (!this.customAmenities.some(amenity => amenity.name.toLowerCase() === name.toLowerCase())) {
      console.log('pushing to custom amenities ', name, this.customAmenities);
      this.customAmenities.push({
        name: name,
        value: true,
        type: 'custom'
      });
      this.meetingRoomAddForm.get('amenities.amenity').reset();
      this.meetingRoomAddForm.get('amenities.amenity').markAsPristine();
    }
  }

  // TYPES
  typeChanged(name, roomType) {
    console.log(name, roomType)
    roomType.forEach(type => {
      if (type.name === name) {
        type.value = !type.value;
      }
    })
  }

  checkTypesForSelected() {
    const defaultArray = this.defaultTypes.filter(type => {
      return type.value === true;
    }).length > 0;
    if (defaultArray) {
      return true;
    } else {
      return false;
    }
  }

  // SORT GALLERY
  sortGallery() {
    this.eventOptions = {
      handle: '.handle',
      onUpdate: () => {
        this.galleryImages.forEach((image, index) => {
          image.order = index;
        });
      }
    };
  }

  // SET FEATURED IMAGE
  setFeaturedImg(tempId) {
    this.galleryFeaturedImg = tempId;
  }

  // DELETE IMAGE FROM ARRAY
  deleteGalleryImage(file: any) {
    if (file.tempId === this.galleryFeaturedImg) {
      this.galleryFeaturedImg = '';
    }
    const index = this.galleryImages.indexOf(file);
    this.galleryImages.splice(index, 1);
    this.galleryImages.forEach((image, index) => {
      image.order = index;
    });
  }

  // Take a picture using the phone camera
  takePicture() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      }).then((imageData) => {
        this.loadingGallery = true;
        this.base64.encodeFile(imageData).then((base64File: string) => {
          let imageFile: any = {
            order: this.galleryImages.length,
            url: base64File,
            tempId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
          }
          if (imageFile.order === 0) {
            this.galleryFeaturedImg = imageFile.tempId;
          }
          const promise: Promise<any> = new Promise<any>((resolve) => {
            this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
              entry.file(file => {
                resolve(imageFile.file = file);
              });
            });
          });
          return promise.then(() => {
            this.galleryImages.push(imageFile);
            this.loadingGallery = false;
          });
        }, (err) => {
          console.log(err);
        });
      }, (error) => {
        console.log(JSON.stringify(error));
      });
  }

  // Select a picture from the phone gallery
  selectPicture() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingGallery = true;
          this.base64.encodeFile(imageData).then((base64File: string) => {
            let imageFile: any = {
              order: this.galleryImages.length,
              url: base64File,
              tempId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
            if (imageFile.order === 0) {
              this.galleryFeaturedImg = imageFile.tempId;
            }
            const promise: Promise<any> = new Promise<any>((resolve) => {
              this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
                entry.file(file => {
                  resolve(imageFile.file = file);
                });
              });
            });
            return promise.then(() => {
              this.galleryImages.push(imageFile);
              this.loadingGallery = false;
            });
          }, (err) => {
            console.log(err);
          });
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  }

  onSubmit(formValues) {
    this.isSubmitting = true
    this.meetingRoom.basicInfo = formValues.basicInfo;
    this.meetingRoom.locationDetails = this.location;
    this.meetingRoom.businessHours = this.formatBusinessHours(formValues.businessHours);
    this.meetingRoom.amenities = this.defaultAmenities.concat(this.extraAmenities).concat(this.customAmenities);
    this.meetingRoom.roomTypes = this.defaultTypes;
    if (this.galleryImages.length > 0) {
      this.meetingRoom.gallery = this.galleryImages;
      this.meetingRoom.featuredImg = this.galleryFeaturedImg;
    } else {
      this.meetingRoom.gallery = null;
      this.meetingRoom.featuredImg = null;
    }
    this.meetingRoomsService.addMeetingRoom(this.meetingRoom).then(() => {
      this.isSubmitting = false;
      this.alertService.displayToast('Your meeting room has been submitted for review');
      this.meetingRoom = [];
      this.galleryImages = [];
      this.defaultAmenities = [];
      this.extraAmenities = [];
      this.customAmenities = [];
      this.defaultTypes = [];
      this.meetingRoomAddForm.reset();
      this.meetingRoomAddForm.markAsPristine();
      this.router.navigate(['/meeting-rooms/' + this.locationId]);
    }).catch(error => {
      console.log('error adding location ', error);
      this.alertService.displayToast('Error creating location');
    })
  }

  goBack() {
    this.navCtrl.back();
  }

  ionViewDidLeave() {
    this.meetingRoom = [];
    this.galleryImages = [];
    this.defaultAmenities = [];
    this.extraAmenities = [];
    this.customAmenities = [];
    this.defaultTypes = [];
    defaultAmenities.concat(extraAmenities).forEach(amenity => {
      amenity.value = false;
    });
    defaultTypes.forEach(type => {
      type.value = false;
    });
    this.meetingRoomAddForm.reset();
    this.meetingRoomAddForm.markAsPristine();
  }

}
