import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { UsersService } from '../../../services/users.service';
import { User } from 'src/app/_shared/models/users.model';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: '',
  templateUrl: './users-search.component.html'
})
export class UsersSearchComponent implements OnInit {

  isLoading = false;
  users: User[];
  allUsers$: Observable<Array<{}>>;

  constructor(
    private usersService: UsersService,
    private alert: AlertService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.allUsers$ = this.usersService.findUsers();
  }

  getUser(event) {
    if (event.type === 'viewUser') return this.viewUser(event.data);
    if (event.type === 'userChat') return this.openChat(event.data);
  }

  viewUser(user) {
    const modalData = {
      title: `${user.userRef}`,
      userId: user.userId,
      type: user.type,
      userIndex: user.userIndex
    }
    this.alert.openModal(UserViewComponent, modalData, 'modal-small');
  }

  openChat(data) {
    this.router.navigate([`/messages/conversation/create/${data.conversationId}`], { queryParams: { conversationName: data.conversationName } });
  }

  ionViewDidLeave() {
    this.allUsers$ = this.usersService.blankInfo;
  }
}
