import { Injectable } from '@angular/core';
import { FCM } from '@ionic-native/fcm/ngx';
import { Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';


@Injectable()
export class FcmService {

  constructor(
    private fcm: FCM,
    public profileService: ProfileService,
    private router: Router,
    private localNotification: LocalNotifications
  ) {
  }

  async getToken() {
    this.fcm.getToken().then(token => {
      this.saveTokenToFirestore(token);
    });
  }

  async handlePushNotification(data) {
    console.log('Notification received and tapped', data);
    if (data.notificationUrl) {
      await this.router.navigate(['/notifications-list']);
    } else {
      this.router.navigate(['/notifications-list']);
    }
  }


  createLocalPushNotification(data, userId) {
    console.log('got data for local notification ', userId, data);
    this.localNotification.schedule({
      id: 1,
      title: data.msgTitle,
      text: data.msgBody,
      data: data,
      // icon: 'res://icon.png',
      foreground: true,
      trigger: { after: new Date(new Date().getTime() + 3600), count: 1, single: true, in: 1 },
    });


  };


  clearAllnotifications() {
    this.localNotification.cancelAll();



  }

  // Save the token to firestore
  private saveTokenToFirestore(token) {
    if (!token) return;
    return this.profileService.saveUserToken(token);
  }

}
