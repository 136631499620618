import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { User } from 'src/app/_shared/models/users.model';

type PasswordFields = "old_password" | "password" | "confirm_password";
type FormErrors = { [u in PasswordFields]: string };

@Component({
  selector: 'app-password-reset-component',
  templateUrl: './password-reset.component.html'
})
export class PasswordResetComponent implements OnInit {
  updatePasswordForm: FormGroup;
  loginForm: FormGroup;
  isUpdating = false;
  user: User;
  message = '';
  passwordMismatch = false;
  showRelogin;
  errorMessage = '';
  loggingIn = false;
  wrongPassword = false;

  formErrors: FormErrors = {
    'old_password': '',
    'password': '',
    'confirm_password': '',
  };
  validationMessages = {
    'old_password': {
      'required': 'Old password is required',
    },
    'password': {
      'required': 'Password is required.',
      'pattern': 'Password must include atleast one letter and one number.',
      'minlength': 'Password must be at least 6 characters long.',
      'maxlength': 'Password cannot be more than 25 characters long.',
    },
    'confirm_password': {
      'required': 'Confirm password is required',
      'passwordMismatch': 'Passwords do not match',
    },

  };


  constructor(
    private modalCtrl: ModalController,
    private alert: AlertService,
    private auth: AuthenticationService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.buildPasswordForm();
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  buildPasswordForm() {
    this.updatePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      password: ['', [
        Validators.required,
        Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+).*$"),
        Validators.minLength(6),
        Validators.maxLength(25)]],
        confirm_password: ['', [Validators.required, this.isEqualTo("password")]]
    });

    this.updatePasswordForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  buildLoginForm() {
    this.loginForm = this.fb.group({
      email: [this.user.email, [Validators.required, Validators.required]],
      password: ['', Validators.required]
    });
  }

  isEqualTo(field): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root.value[field] === control.value) {
        return null;
      }
      else {
        return { 'passwordMismatch': { value: 'passwordMismatch' } };
      }
    };
  }

  onValueChanged(data?: any) {
    if (!this.updatePasswordForm) { return; }
    const form = this.updatePasswordForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'old_password' || field === 'password' || field === 'confirm_password')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
                this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
              }
            }
          }
        }
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onPasswordUpdate() {
    if (this.updatePasswordForm.valueChanges && this.updatePasswordForm.valid) {
      this.isUpdating = true;
      const oldPassword = this.updatePasswordForm.value.old_password;
      const password = this.updatePasswordForm.value.password;
      this.user.uid = this.user.uid;
      this.auth.changePassword(oldPassword, password).then((error: any) => {
        this.isUpdating = false;
        if (error) {
          if (error.code === "auth/requires-recent-login") {
            this.showRelogin = true;
          }
          if (error.code === "auth/wrong-password") {
            this.wrongPassword = true;
            this.message = 'Old password is incorrect, please try again.';
          }
          if (error.code === "auth/too-many-requests") {
            this.wrongPassword = true;
            this.message = 'Too many unsuccessful login attempts. Please try again later.';
          }
        } else {
          this.updatePasswordForm.reset();
          this.alert.displayToast('Password updated succesfully');
          this.wrongPassword = false;
          this.closeModal();
        };
      });
    }
  }

  forgotPassword() {
    this.auth.signOut('password');
    this.user = null;
    this.closeModal();
  }

  relogin(loginForm) {
    console.log('logging in again ', loginForm);
    this.errorMessage = undefined;
    this.loggingIn = true;
    let hasError = false;
    this.auth
      .loginUser(loginForm.email, loginForm.password)
      .catch(error => {
        if (error) {
          this.errorMessage = error.message;
          hasError = true;
          this.loggingIn = false;
        }
      })
      .then(() => {
        if (!hasError) {
          console.log('user is logged in again without error');
          this.auth.user.subscribe(user => {
            console.log('user detailsfor relogin');
            if (user) {
              this.loggingIn = false;
              this.modalCtrl.dismiss();
              this.updatePasswordForm.reset();
            }
          });
        }
      });
  }

}
