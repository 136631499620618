import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/_shared/models/users.model';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { AlertService } from 'src/app/services/alert.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import * as queryString from 'query-string';

@Component({
  selector: 'app-meeting-room-payment',
  templateUrl: './meeting-room-payment.component.html'
})
export class MeetingRoomPaymentComponent implements OnInit {
  extraData: any;
  updateForm: FormGroup;
  user: User;
  team: any;
  teamUsers: any;
  payerInfo: any;
  isUpdating = false;
  showLegal = false;

  usersForBooking: any;

  productStepsForm: FormGroup;
  productsSubscription: Subscription;
  isLoading = false;
  isSubmitting = false;
  provinces = Provinces;

  step = 1;

  booking: any;

  finishUpdate: any;

  // CONFIRM
  confirm = false;

  isRedirecting = false;

  // CARDS
  cardsForm: FormGroup;
  payer: any;
  userCardsSubscription: Subscription;
  userCards: any[];
  hideCardSelection: boolean = false;
  defaultCard: any;
  changeDefault: boolean = false;
  changedCardId: string;
  deletingCard: boolean = false;
  deletedCardSubscription: Subscription;
  // ADD CARDS
  addCardBrowser: any;
  pendingCardSubscription: Subscription;
  cardErrorMsg: any;
  pendingCardId: string;

  pendingSubscription: Subscription;
  oldSubscription: any;
  paymentBrowser: any;

  //currentPlatform
  currentPlatform: string;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private usersService: UsersService,
    private alerService: AlertService,
    private transactionsService: TransactionsService,
    private bookingsService: BookingsService,
    private iab: InAppBrowser,
    private alertService: AlertService,
    private router: Router,
    private storageService: StorageService,
    private storage: Storage,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.buildForms();

    if (!this.route.snapshot.queryParamMap.get('requestId')) {
      this.setPaymentData(this.extraData);
      this.storage.set('extraData', this.extraData);
    }

    if (this.route.snapshot.queryParamMap.get('requestId')) {
      const requestParam = this.route.snapshot.queryParamMap.get('requestId');
      this.step = 2;
      this.watchPendingCard(requestParam);
      this.storage.get('extraData').then((extraData) => {
        if (extraData) {
          this.extraData = extraData;
          this.setPaymentData(this.extraData);
        }
      });
    }
    
    this.storageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    });
  }

  setPaymentData(extraData) {
    this.user = extraData.data.user;
    this.team = extraData.data.team;
    this.booking = extraData.data.booking;
    if (extraData.data.usersForBooking) {
      this.usersForBooking = extraData.data.usersForBooking;
    }
    if (this.team) {
      this.payerInfo = extraData.data.payerInfo;
      this.teamUsers = extraData.data.teamUsers;
    }
    
    // GET USER CARDS
    if (this.user) {
      this.payer = this.user;
    }
    if (this.payerInfo) {
      this.payer = this.payerInfo;
    }
    // GET USER CARDS
    this.userCardsSubscription = this.transactionsService.getUserCards(this.payer.uid).subscribe(cardsData => {
      this.userCards = cardsData.filter((card: any) => { if (card.cardToken) return card; });
      if (this.userCards.length > 0) {
        if (this.userCards.length === 1) {
          this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
            this.setDefaultCard();
          });
        } else {
          this.setDefaultCard();
        }
      }
      this.isLoading = false;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  buildForms() {
    if (this.user) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: ['']
        })
      });
    }

    if (this.team) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: [''],
          legalStatus: ['', [Validators.required]]
        })
      });
    }

    this.cardsForm = this.fb.group({
      card: [''],
      defaultCard: [''],
    });

  }

  // CARDS
  setDefaultCard() {
    const setDefaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (setDefaultCard[0]) {
      this.cardsForm.get('card').patchValue(setDefaultCard[0].id);
      this.defaultCard = setDefaultCard[0];
    }
  }

  setCardAsDefault() {
    const setDefault = this.cardsForm.get('defaultCard').value;
    if (setDefault) {
      this.transactionsService.setDefaultCard(this.changedCardId, this.user.uid).then(() => {
        this.alertService.displayToast(`Default card changed`);
        this.changeDefault = false;
      });
    }
  }

  onCardChange() {
    this.changedCardId = this.cardsForm.get('card').value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(card => card.id === this.changedCardId && card.default === true);
      if (!noChange) {
        this.changeDefault = true;
        this.cardsForm.get('defaultCard').reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  deleteCard(cardId) {
    this.deletingCard = true;
    this.transactionsService.deleteCard(cardId).then(data => {
      this.deletedCardSubscription = this.transactionsService.getDeletedCard(data).subscribe(card => {
        if (!card) {
          this.deletingCard = false;
        }
      });
    });
  }

  addCard() {
    let cardUrlParams;
    const requestId = (Date.now()/Math.random()).toFixed(0);
    if (this.currentPlatform !== 'desktop') {
      if (this.user) {
        cardUrlParams = `${environment.paymentUrl}?userId=${this.user.uid}&requestId=${requestId}`
      }
      if (this.team) {
        cardUrlParams = `${environment.paymentUrl}?userId=${this.payerInfo.uid}&requestId=${requestId}`
      }
      this.addCardBrowser = this.iab.create(cardUrlParams, '_blank', 'usewkwebview=yes,toolbar=no,location=no');
      this.watchPendingCard(requestId);
      this.cardErrorMsg = '';
    } else {
      const parseReturn = {
        returnUrl: ''
      }
      if (this.user) {
        parseReturn.returnUrl = `${environment.websiteUrl}/meeting-room/${this.booking.typeId}?requestId=${requestId}`;
        cardUrlParams = `${environment.paymentUrl}?${queryString.stringify(parseReturn)}&userId=${this.user.uid}&requestId=${requestId}`;
      }
      if (this.team) {
        parseReturn.returnUrl = `${environment.websiteUrl}/team/edit/${this.team.uid}?requestId=${requestId}&team=yes`;
        cardUrlParams = `${environment.paymentUrl}?${queryString.stringify(parseReturn)}&userId=${this.payerInfo.uid}&requestId=${requestId}`;
      }
      window.location.href = cardUrlParams;
    }
  }

  watchPendingCard(requestId) {
    this.pendingCardSubscription = this.transactionsService.checkPendingCards(requestId).subscribe((card: any) => {
      if (card.length > 0) {
        if (this.currentPlatform !== 'desktop') {
          this.addCardBrowser.close();
        }
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.displayCardAdded(card[0].cardStorageOutcome) : this.cardErrorMsg = card[0].description;
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.pendingCardId = card[0].id : this.pendingCardId = '';
      }
    });
  }

  displayCardAdded(outcome) {
    this.alerService.displayToast(outcome).then(() => {
      this.transactionsService.deletePendingCard(this.pendingCardId);
    });
  }

  onConfirm() {
    this.confirm = true;
  }

  onBillingUpdateComplete() {
    this.step = 2;
  }

  makePayment() {
    let onceOff = true;
    this.booking.cardId = this.cardsForm.get('card').value;
    this.bookingsService.createUserMeetingBooking(this.booking).then(bookingId => {
      this.transactionsService.addTransaction(this.booking, this.booking.price, onceOff, bookingId).then(transactionId => {
        if (transactionId) {
          this.isRedirecting = true;
          this.cardErrorMsg = '';
          if (this.usersForBooking) {
            this.storageService.teamUsersForBooking.next(this.usersForBooking);
          }
          this.router.navigate([`/payments-confirmation/${transactionId}`]);
          this.closeModal();
        }
      });
    });
  }

  updateHistory(editForm) {
    // UPDATE USER HISTORY
    const handleFormData = (form: FormGroup | FormArray) => new Promise((resolve, reject) => {
      let changedArray = [];
      if (form.dirty) {
        for (let i in form.controls) {
          if (form.controls[i] instanceof FormControl && form.controls[i].dirty) {
            changedArray.push({ field: i, value: form.controls[i].value });
          }
        }
        resolve(changedArray);
      } else {
        reject('No changes detected');
      }
    });
    return handleFormData(editForm).then((changedFieldsArray) => {

      return this.usersService.addHistoryLogToUser(changedFieldsArray, this.user.uid);
    }).catch((err) => {
      // this.toastr.error(err);
      return err;
    });
  }

  ngOnDestroy() {
    this.userCardsSubscription.unsubscribe();
    this.team = {};
    this.modalCtrl.dismiss();
  }


}
