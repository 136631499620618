import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Provinces } from '../../../_shared/models/provinces.list';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagesService } from 'src/app/services/images.service';
import { config } from 'src/app/_shared/configs/config';
import { TeamsService } from '../_services/teams.service';
import { AuthenticationService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'public-teams-add',
  templateUrl: './teams-add.component.html',
  styleUrls: ['../teams.scss']
})
export class TeamAddComponent implements OnInit {
  teamAddForm: FormGroup;
  team: any = {
    billingInfo: {
      billingAddress: {
      }
    },
  };
  step = 1;
  provinces = Provinces;
  teamImageData: any;
  teamImage: any[] = [];
  entityId = config.entityId;
  addedUser = [];

  isLoading = false;
  isSubmitting = false;

  saveImage = false;
  imgPreview = '/assets/images/teams/team_profile.jpg';

  //currentPlatform
  currentPlatform:string;

  constructor(
    private fb: FormBuilder,
    private cameraPlugin: Camera,
    private imagesService: ImagesService,
    private teamsService: TeamsService,
    private auth: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private file: File,
    public sanitizer: DomSanitizer,
    private base64: Base64,
    private sorageService:StorageService
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
    this.sorageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })
  }

  ionViewDidEnter() {
    this.buildAddTeamsForm();

    this.auth.user.subscribe(user => {
      if (user) {
        user.isAdmin = true;
        user.isOwner = true;
        user.isStaff = true;
        user.invited = false;
        this.addedUser.push(user);
        this.team.users = this.addedUser;
        this.team.owner = {
          uid: user.uid,
          firstname: user.firstname,
          surname: user.surname,
          email: user.email,
        };
      }
    });
  }

  buildAddTeamsForm() {
    this.teamAddForm = this.fb.group({
      basicInfo: this.fb.group({
        name: ['', [Validators.required]],
        description: ['']
      }),
      billingInfo: this.fb.group({
        billingName: ['', [Validators.required]],
        billingEmail: ['', [Validators.required, Validators.email]],
        billingAddress: this.fb.group({
          streetAddress: ['', [Validators.required]],
          city: ['', [Validators.required]],
          postalCode: ['', [Validators.required]],
          country: ['South Africa', [Validators.required]],
          region: ['', [Validators.required]],
        }),
        taxNumber: [''],
        legalStatus: ['Company', [Validators.required]]
      })
    });
  }

  
  async takePictureForProfile() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
            this.imgPreview = '';
            this.isLoading = true;
            const logo: any = [];
            try {
              this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
                entry.file(file => {
                  console.log("FILE");
                  logo.push(file);
                  this.teamImage = logo;
                  var reader = new FileReader();
                  const onLoadPromise: Promise<any> = new Promise(resolve => {
                    reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                    reader.readAsDataURL(file);
                  })
                  onLoadPromise.then(val => {
                    console.log("BASE64 PROFILE IMG");
                    this.imgPreview = val;
                    this.isLoading = false;
                  });
                });
              });
            } catch (errO) {
              console.log("errO");
              console.log(errO);
            }
          
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  // Select a picture from the phone gallery
  async selectPictureForProfile() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
            this.imgPreview = '';
            this.isLoading = true;
            const logo: any = [];
            try {
              this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
                entry.file(file => {
                  console.log("FILE");
                  logo.push(file);
                  this.teamImage = logo;
                  var reader = new FileReader();
                  const onLoadPromise: Promise<any> = new Promise(resolve => {
                    reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                    reader.readAsDataURL(file);
                  })
                  onLoadPromise.then(val => {
                    console.log("BASE64 PROFILE IMG");
                    this.imgPreview = val;
                    this.isLoading = false;
                  });
                });
              });
            } catch (errO) {
              console.log("errO");
              console.log(errO);
            }
          
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  onFileSelectedFromWeb(event){
    console.log("Incoming file", event[0]);
    const logo: any = [];
    logo.push(event[0]);
    this.teamImage = logo;
    this.onFileSelected(event[0]);
  }

  onFileSelected(event) {
    var selectedFile = event;
    var reader = new FileReader();
    var imgtag = document.getElementById("locImage");
    reader.onload = (onLoadEvent) =>  this.fileIsready(onLoadEvent);
    reader.readAsDataURL(selectedFile);
  }

  fileIsready(onLoadEvent){
    this.imgPreview = onLoadEvent.target.result;
  }

  onSubmit() {
    this.team.billingInfo = this.teamAddForm.get('billingInfo').value;
    this.team.basicInfo = this.teamAddForm.get('basicInfo').value;
    if (this.teamImage.length > 0) {
      this.team.teamLogo = this.teamImage;
    } else {
      this.team.teamLogo = null;
    }
    console.log('team for teams add ', this.team);
    this.isSubmitting = true;
    this.teamsService.addTeam(this.team).then(() => {
      this.isSubmitting = false;
      this.alertService.displayToast('Succesfully submitted team for approval by WealthSpaces Admin');
      this.imgPreview = '/assets/images/teams/team_profile.jpg';
      this.saveImage = false;
      this.team = [];
      this.addedUser = [];
      this.teamAddForm.reset();
      this.teamAddForm.get('billingInfo.legalStatus').patchValue('Company');
      this.teamAddForm.get('billingInfo.billingAddress.country').patchValue('South Africa');
      this.router.navigate(['/teams']);
    }).catch(error => {
      this.isSubmitting = false;
      this.alertService.displayToast(`Error creating team: ${error}`);
    });
  }

  ionViewDidLeave() {
    this.team = [];
    this.addedUser = [];
    this.teamAddForm.reset();
    this.teamAddForm.get('billingInfo.legalStatus').patchValue('Company');
    this.teamAddForm.get('billingInfo.billingAddress.country').patchValue('South Africa');
    this.imgPreview = '/assets/images/teams/team_profile.jpg';
  }


  getBase64(event, resolve) {
    resolve(this.imgPreview = event.target.result);
    return;
  }

}
