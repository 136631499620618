import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UpdateBillingInfoComponent } from './update-billing-info.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        UpdateBillingInfoComponent
    ],
    exports: [
        UpdateBillingInfoComponent
    ]
})
export class BillingInfoModule { }