import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PackageConfirmPurchaseComponent } from '../package-confirm-purchase/package-confirm-purchase.component';

@Component({
  selector: 'app-package-change',
  templateUrl: './package-change.component.html'
})
export class PackageChangeComponent implements OnInit {
  package;

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async openPurchaseModal(packageInfo) {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: PackageConfirmPurchaseComponent,
      componentProps: {
        package: packageInfo
      }
    });
    modal.present();
  }

}
