export const config = {
  entityId: 'wealthspaces-sa',
  entityName: 'WealthSpaces',
  source: 'app',
  websiteUrl: 'https://app.ws.noltech.co.za/',
  adminUrl: 'https://admin.ws.noltech.co.za/',
  clientUrl: 'https://app.ws.noltech.co.za/',
  wealthSpacesIcon: "/assets/images/icon/WS-ICON.png",
  paymentUrl: 'https://wealthspaces-admin-dev.appspot.com',
  buildId: '__BUILD_ID__'
};