import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TeamsService } from 'src/app/pages/teams/_services/teams.service';
import { UsersService } from 'src/app/services/users.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html'
})
export class UserInviteComponent implements OnInit {
  teamInviteUserForm: FormGroup;
  team: any;
  addedEmail: any;
  teamId;
  teamUsers: any;
  users: any;

  teamUsersSubscription: Subscription;
  usersSubscription: Subscription;
  feedbackMessage = '';

  addingInvitedUser = false;


  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private teamsService: TeamsService,
    private usersService: UsersService,
    private alert: AlertService
  ) {
  }

  ngOnInit() {
    this.teamId = this.team.uid;

    this.buildInviteUserForm();

    // GET TEAM USERS
    this.teamUsersSubscription = this.teamsService.getTeamUsers(this.teamId).subscribe(usersData => {
      this.teamUsers = usersData;
      console.log('team member ', this.team, this.teamId);
    });

    // GET ALL USERS
    this.usersSubscription = this.usersService.fetchEntityUsers().subscribe(entityUsersData => {
      this.users = entityUsersData;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  buildInviteUserForm() {
    this.teamInviteUserForm = this.fb.group({
      firstname: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', [Validators.required]],
    });

    if (this.addedEmail) {
      this.teamInviteUserForm.get('email').patchValue(this.addedEmail);
    }
  }

  addInvitedUser(formValues) {
    if (this.users.some(member => member.email === formValues.email)) {
      this.feedbackMessage = 'User already exists';
    } else {
      if (this.teamUsers.some(member => member.email === formValues.email)) {
        this.feedbackMessage = 'User already added';
      } else {
        this.addingInvitedUser = true;
        formValues.invited = true;
        formValues.isStaff = true;
        formValues.isAdmin = false;
        formValues.isOwner = false;
        formValues.permissions = '';
        this.feedbackMessage = '';
        console.log('team id ', this.teamId);
        this.teamsService.linkUsersAndTeams(formValues, this.team, this.teamId).then(result => {
          console.log('result ', result)
          this.modalCtrl.dismiss();
          this.alert.displayToast('User succesfully invited');
          this.addingInvitedUser = false;
          this.teamInviteUserForm.reset();
        });
      }
    }
  }

}
