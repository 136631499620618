import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['loader-component.component.scss'],
})
export class LoaderComponent implements OnInit {

  @Input() loadingPayment: boolean;
  @Output() stopLoading = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    let water = document.getElementById("water");
    let percent = 0;
    let interval;
    interval = setInterval(() => { 
      percent++; 
      water.style.transform='translate(0'+','+(100-percent)+'%)';
      if (percent === 100) {
        clearInterval(interval);
        this.stopLoading.emit(false);
      }
    },60);
  }

}
