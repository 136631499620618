import { Component, OnInit } from '@angular/core';

import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-user-bookings-list',
  templateUrl: './user-bookings.component.html'
})
export class UserBookingsComponent implements OnInit {

  isLoading = false;
  user: User;

  constructor(
    private auth: AuthenticationService,
    private nav: NavController
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.isLoading = false;
      }
    });
  }

  goBack() {
    this.nav.back();
  }
}
