import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private auth: AngularFireAuth) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.auth.authState.pipe(
			take(1),
			map((user) => !!user),
			tap((loggedIn) => {
				if (!loggedIn) {
					this.router.navigate(['/login']);
					// console.log(`not logged in`)
				} else {
					this.auth.authState.subscribe((user: any) => {
						if (user) {
							if (!user.verified) {
								// this.router.navigate([`/profile-edit/${user.uid}`]);
							}
						}
					});
				}
			})
		);
	}
}
