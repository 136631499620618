import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { MeetingRoomsService } from '../../_services/meeting-rooms.service';

@Component({
  selector: 'app-meeting-room-edit-types',
  templateUrl: './meeting-room-types.component.html'
})
export class MeetingRoomEditTypesComponent implements OnInit {
  
  isLoading = false;
  roomId;
  meetingRoom: any;
  meetingRoomEditForm: FormGroup;

  defaultTypes: any;
  amenitiesSubscription: Subscription;

  // AMENITIES
  defaultAmenities = [];
  extraAmenities = [];
  customAmenities = [];
  showDefaultAmenities = true;
  showExtraAmenities = false;
  showCustomAmenities = false;

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private meetingRoomsService: MeetingRoomsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.roomId = this.route.snapshot.paramMap.get('roomId');
    this.buildForm();
    this.amenitiesSubscription = this.meetingRoomsService.getMeetingRoomTypes(this.roomId).subscribe((types: any) => {
      this.defaultTypes = types.list;
      console.log(this.defaultTypes)
      this.storage.get('meetingRoom').then((meetingRoom) => {
        this.meetingRoom = meetingRoom;
      });
      this.isLoading = false;
    });
  }

  buildForm() {
    this.meetingRoomEditForm = this.fb.group({
      type: ['', [Validators.maxLength(50)]]
    });
  }

  // TYPES
  typeChanged(name, roomType) {
    roomType.forEach(type => {
      if (type.name === name) {
        type.value = !type.value;
      }
    });
    this.meetingRoomEditForm.markAsDirty();
  }

  checkTypesForSelected() {
    const defaultArray = this.defaultTypes.filter(type => {
      return type.value === true;
    }).length > 0;
    if (defaultArray) {
      return true;
    } else {
      return false;
    }
  }

  onUpdate(meetingRoomEditForm) {

    this.isSubmitting = true;
    this.meetingRoomsService.updateRoomTypes(this.roomId, this.defaultTypes).then(() => {
      this.isSubmitting = false;
      this.meetingRoomEditForm.markAsPristine();
      this.alert.displayToast('Room updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.showDefaultAmenities = true;
    this.showExtraAmenities = false;
    this.showCustomAmenities = false;
    this.amenitiesSubscription.unsubscribe();
  }

}
