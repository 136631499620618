import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html'
})
export class NotificationsListComponent implements OnInit, OnDestroy {

    @Input() notifications$: Observable<Array<{}>>;
    @Output() sendNotification = new EventEmitter();
    @Output() sendNotificationCount = new EventEmitter();

    isLoading: boolean = false;

    notificationsSubscription: Subscription;
    notifications: any[];

    constructor(
        private route: ActivatedRoute,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit() {
      this.isLoading = true;
      this.notificationsSubscription = this.notifications$.subscribe(notificationsData => {
          this.notifications = notificationsData;
          const unread = this.notifications.filter(notification => {
              if (notification.unread) {
                  return notification;
              }
          });
          this.sendNotificationCount.emit(unread.length);
          this.isLoading = false;
      });
    }

    markAsRead(notificationId) {
        const data = {
            unread: false
        }
        this.notificationsService.updateNotifiction(notificationId, data);
    }

    ngOnDestroy(): void {
        this.notificationsSubscription.unsubscribe();
    }

}
