import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MeetingRoomsService } from '../_services/meeting-rooms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { MessagesService } from 'src/app/services/messages.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-meeting-rooms-edit',
  templateUrl: './meeting-rooms-edit.component.html'
})
export class MeetingRoomsEditComponent implements OnInit {

  locationId;
  roomId;
  isLoading = false;
  imgPreview = '/assets/images/locations/location_profile.jpg';

  showDropdown = false;

  roomsSubscription: Subscription;
  meetingRoom: any;


  constructor(
    private meetingRoomsService: MeetingRoomsService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: Storage,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;

    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.roomId = this.route.snapshot.paramMap.get('roomId');

    this.roomsSubscription = this.meetingRoomsService.getMeetingRoomInfo(this.roomId).subscribe((roomData: any) => {
      this.meetingRoom = roomData;
      this.storage.set('meetingRoom', this.meetingRoom);
      this.isLoading = false;
    });

  }

  navigateToRoomPage(route) {
    this.router.navigate([route]);
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.showDropdown = false;
    this.roomsSubscription.unsubscribe();
  }


}
