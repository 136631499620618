import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PackagePaymentComponent } from '../package-payment/package-payment.component';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { TermsAndConditionsComponent } from '../terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-package-confirm-purchase',
  templateUrl: './package-confirm-purchase.component.html'
})
export class PackageConfirmPurchaseComponent implements OnInit {
  package;
  extraData;
  user: User;

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private router: Router,
    private auth: AuthenticationService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        if (this.route.snapshot.queryParamMap.get('requestId')) {
          this.openPaymentModal();
        }
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  navigateToTerms() {
    this.router.navigate(['/terms-conditions']);
    this.modalCtrl.dismiss();
  }

  openTermsModal() {
    this.alertService.openModal(TermsAndConditionsComponent, {}, 'modal-terms');
  }

  openPaymentModal() {
    this.alertService.openModal(PackagePaymentComponent, { product: this.package, extraData: this.extraData }, '');
    this.modalCtrl.dismiss();
  }

  getImage(product) {
    switch (product) {
      case 'Essential':
        return `/assets/images/packages/essential.png`;
      case 'Classic':
        return `/assets/images/packages/classic.png`;
      case 'Premium':
        return `/assets/images/packages/premium.png`;
    }
  }

}
