import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './faq/faq.component';
import { ComponentsModule } from 'src/app/_shared/components/components.module';
import { TutorialPageComponent } from './tutorial/tutorial.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    CommonModule,
    ComponentsModule
  ],
  declarations: [
    FaqComponent,
    TutorialPageComponent
  ],
  exports: [
    FaqComponent,
    TutorialPageComponent
  ]
})
export class SupportModule { }
