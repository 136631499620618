import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/_shared/models/users.model';

@Component({
  selector: 'apple-signin-component',
  templateUrl: './apple-signin.component.html'
})
export class AppleSigninComponent implements OnInit {
  user: any;
  signinForm: FormGroup;
  isUpdating = false;
  isLoading = false;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder
  ) { }

  ngOnInit() {this.isLoading = true;
    this.buildPasswordForm();
    console.log('user information for test ', this.user);
    this.isLoading = false;
  }

  buildPasswordForm() {
    this.signinForm = this.fb.group({
      firstname: ['', Validators.required],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
    if (this.user.user.email) {
      console.log('user already has email');
      const emailInput = this.signinForm.get('email')
      emailInput.disable();
      emailInput.patchValue(this.user.user.email);
    }
    if (this.user.firstname) {
        this.signinForm.get('firstname').patchValue(this.user.firstname);
    }
    if (this.user.surname) {
        this.signinForm.get('surname').patchValue(this.user.surname);
    }
  }

  onSubmit(userInfo) {
      console.log('userInfo data ', userInfo);
    this.isUpdating = true;
    this.user.firstname = userInfo.firstname;
    this.user.surname = userInfo.surname;
    this.user.email = this.signinForm.get('email').value;
    console.log('submitting the user details ', this.user);
    this.modalCtrl.dismiss(this.user);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
