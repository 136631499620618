import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LocationsService } from '../../_services/locations.service';

@Component({
  selector: 'app-location-edit-address',
  templateUrl: './location-address.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditAddressPage implements OnInit {
  
  isLoading = false;
  locationId;
  location: any;
  locationEditForm: FormGroup;
  resetFormAddress: Subject<boolean> = new Subject<boolean>();

  // ADDRESS
  address: any;
  formatted_address: string;
  addressDetails: any;
  showAddress = true;

  // MAP
  mapHeight = '200px';

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.buildForm();
    this.storage.get('location').then((location) => {
      if (location) {
        this.location = location;
        this.address = location.address;
        this.locationEditForm.patchValue(this.address);
        this.formatted_address = location.address.formatted_address;
        this.addressDetails = {
          latitude: this.address.latitude,
          longitude: this.address.longitude,
          formatted_address: this.formatted_address
        };
        this.isLoading = false;
      }
    });
  }

  buildForm() {
    this.locationEditForm = this.fb.group({
      address: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      region: ['', [Validators.required]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      formatted_address: [''],
      floorNo: [''],
    });
  }

  
  // ADDRESS FIELDS
  getMapAddress(address) {
    if (address) {
      if (address.physicalAddress) {
        this.locationEditForm.get('address').patchValue(address.physicalAddress);
      } else {
        this.locationEditForm.get('address').patchValue(' ');
      }
      if (address.sublocality_level_1) {
        this.locationEditForm.get('suburb').patchValue(address.sublocality_level_1);
      } else {
        this.locationEditForm.get('suburb').patchValue(' ');
      }
      if (address.postal_code) {
        this.locationEditForm.get('postalCode').patchValue(address.postal_code);
      } else {
        this.locationEditForm.get('postalCode').patchValue(' ');
      }
      this.locationEditForm.get('city').patchValue(address.locality);
      this.locationEditForm.get('region').patchValue(address.administrative_area_level_1);
      this.locationEditForm.get('country').patchValue(address.country);
      this.locationEditForm.get('latitude').patchValue(address.latitude);
      this.locationEditForm.get('longitude').patchValue(address.longitude);
      this.locationEditForm.get('formatted_address').patchValue(address.formatted_address);
      this.locationEditForm.markAsDirty();
      this.formatted_address = address.formatted_address;
    }
  }

  onUpdate(locationEditForm) {
    
    const address = {
      address: locationEditForm
    }

    this.isSubmitting = true;
    this.locationsService.updateLocation(this.locationId, address).then(() => {
      this.isSubmitting = false;
      this.locationEditForm.markAsPristine();
      this.alert.displayToast('Location updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
  }

}
