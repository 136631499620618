import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgPipesModule } from 'ngx-pipes';

import { LoaderModule } from '../../_shared/loader-component/loader.module';
import { UsersSearchComponent } from './users-search/users-search.component';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { ProfilePage } from './profile/profile.page';
import { UserBookingsComponent } from './user-bookings/user-bookings.component';
import { ComponentsModule } from 'src/app/_shared/components/components.module';
import { ProfileEditPage } from './profile-edit/profile-edit.page';
import { UserNotificationsPage } from './user-notifications/user-notifications.page';
import { UserHistoryPage } from './user-history/user-history.page';
import { ProfileBillingPage } from './profile-billing/profile-billing.page';
import { userRoutes } from './user.routes';
import { UsersComponent } from './users.component';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        NgPipesModule,
        FormsModule,
        LoaderModule,
        ReactiveFormsModule,
        ComponentsModule,
        userRoutes,
        ImageCropperModule,
        
    ],
    declarations: [
        UsersSearchComponent,
        ProfilePage,
        UserBookingsComponent,
        ProfileEditPage,
        UserNotificationsPage,
        UserHistoryPage,
        ProfileBillingPage,
        UsersComponent,
    
    ],
    exports: [
        UsersSearchComponent,
        ProfilePage,
        UserBookingsComponent,
        UserNotificationsPage,
        UserHistoryPage,
    ]
})
export class UsersModule { }