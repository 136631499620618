import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'blocked-user-component',
  templateUrl: './blocked-user.component.html'
})
export class BlockedUserComponent implements OnInit {
  userRef: any;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      if (user) {
        if (user.status !== 2) {
          this.modalCtrl.dismiss();
        }
      }
    });
    this.auth.signOutUser();
    this.router.navigate(['/landing']);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}


