import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/_shared/models/users.model';
import { NavController, Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html'
})
export class TransactionsListComponent implements OnInit, OnDestroy {

  @Input() transactions$: Observable<Array<{}>>;
  @Output() sendTransaction = new EventEmitter();

  isLoading: boolean = false;

  transactionsSubscription: Subscription;
  transactions: any[];

  allTransactions: any[];
  transactionsRef;
  user: User;

  currentPlatform:string;

  constructor(
    private nav: NavController,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private transfer: FileTransfer,
    private file: File,
    private fileOpener: FileOpener,
    private storageServices:StorageService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.transactionsSubscription = this.transactions$.subscribe(transactionsData => {
      this.transactions = transactionsData;
      this.isLoading = false;
    });

    this.storageServices.currentPlatform.subscribe(platform =>{
      this.currentPlatform = platform;
    })
  }

  getType(type, transaction) {
    switch (type) {
      case 'userSubscription':
        return `Personal Subscription `;
        break;
      case 'teamSubscription':
        return `${transaction.paidByName} - Team Subscription `;
        break;
      case 'meetingRoom':
        return `Meeting Room Booking `;
        break;
      default:
    }
  }

  getStatus(type) {
    switch (type) {
      case 1:
        return `<span class="badge badge-warning">Pending</span>`;
        break;
      case 2:
        return `<span class="badge badge-success">Paid</span>`;
        break;
      case 3:
        return `<span class="badge badge-danger">Unsuccessful</span>`;
        break;
      case 4:
        return `<span class="badge badge-light">Cancelled</span>`;
        break;
      default:
    }
  }

  getPermission(url, name) {
     if(this.currentPlatform == "desktop"){
       this.downloadInvoice(url, name);
    
     }else{

    if (this.platform.is('ios')) {
      this.downloadInvoice(url, name);
    } else {
      // get permission from device to save 
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(result => {

      }).catch(err => {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
          () => {},
          error => {
            console.log('error requesting location permissions ', error);
          }
        );
      });

      // get permission from device to save 
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
          .then(result => {
              if (result) {
                  this.downloadInvoice(url, name);
              }
              else {
                  this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
                    () => {
                      this.downloadInvoice(url, name);
                    },
                    error => {
                      console.log('error requesting location permissions ', error);
                    }
                  );
              }
          });
    }
  }
  }

  downloadInvoice(url, name) {
    const downloadLink = encodeURI(url);
    let directory;
    if(this.currentPlatform == "desktop"){
      // console.log("DOWNLOAD PATH",downloadLink);
      window.open(downloadLink, '_blank');
       return;
    }
    if (this.platform.is('ios')) {
      directory = this.file.documentsDirectory;
    }
    if (this.platform.is('android')) {
      directory = this.file.externalDataDirectory;
    }
    const downloadPath = directory + name + ".pdf";
    
    const fileTransfer: FileTransferObject = this.transfer.create();
    fileTransfer.download(url, downloadPath).then((entry) => {
        this.fileOpener.open(downloadPath, 'application/pdf');
    }, (error) => {
      alert('Download error: ' + error.message);
    });
  }

  ngOnDestroy(): void {
    this.transactionsSubscription.unsubscribe();
  }
}
