import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { CalendarComponentOptions } from 'ion2-calendar'

import * as moment from 'moment';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'public-meeting-rooms-view',
  templateUrl: './location-booking.component.html'
})
export class LocationBookingComponent implements OnInit {
  locationBookingForm: FormGroup;
  user: User;
  userForBooking: any;
  userTierSubscription: Subscription;
  userTierSubscriptions = [];
  location: any;
  businessHours: any;
  userBookings: any;
  allowUserBooking = false;
  isLoading = false;
  authSubscription: Subscription;
  submitting = false;
  minDate;
  errorMessage = 'Please select a booking date';

  // CALENDAR
  dates: string[];
  type: 'moment';
  optionsMulti: CalendarComponentOptions = {};
  disabledDays: any[] = [];

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private usersService: UsersService,
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private subscriptionsService: SubscriptionsService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.setCalendarOptions();
    this.buildForm();
    this.authSubscription = this.auth.user.subscribe(user => {
      this.user = user;

      this.userTierSubscription = this.subscriptionsService.getUserSubscriptions(this.user.uid).subscribe((subs: any) => {
        this.userTierSubscriptions = subs.filter((e) => { return e.type === 'userSubscription' && !e.suspended || e.type === 'teamSubscription' && e.teamId && !e.suspended; });

        this.userTierSubscriptions.filter(subscription => {
          if (subscription.product === this.location.product.title) return this.allowUserBooking = true;
          else if (subscription.product === 'Classic' && this.location.product.title !== 'Premium') return this.allowUserBooking = true;
          else if (subscription.product === 'Premium') return this.allowUserBooking = true;
        });

        this.isLoading = false;
      });
    });
    if (this.userForBooking) {
      this.usersService.fetchUserDetailsForView(this.userForBooking.uid).subscribe(user => {
        if (user) {
          this.userForBooking = user;
        }
      })
    }
    this.minDate = new Date();
  }

  setCalendarOptions() {
    this.businessHours.sunday.day = 0;
    this.businessHours.monday.day = 1;
    this.businessHours.tuesday.day = 2;
    this.businessHours.wednesday.day = 3;
    this.businessHours.thursday.day = 4;
    this.businessHours.friday.day = 5;
    this.businessHours.saturday.day = 6;
    let filtered = Object.values(this.businessHours);
    filtered.forEach((day: any) => {
      if (JSON.stringify(day.open) === JSON.stringify(day.close)) {
        this.disabledDays.push(day.day);
      }
    });
    this.optionsMulti = {
      pickMode: 'multi',
      disableWeeks: this.disabledDays,
      weekStart: 1,
      daysConfig: this.userBookings
    };
  }

  buildForm() {
    this.locationBookingForm = this.fb.group({
      bookingDate: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitting = true;
    const bookedDates = this.locationBookingForm.value;
    const valueArray: any = [];
    bookedDates.bookingDate.forEach((value: any, index, array) => {

      const day = value.day();

      let daySelect;
      switch (day) {
        case 0: daySelect = this.businessHours.sunday;
          break;
        case 1: daySelect = this.businessHours.monday;
          break;
        case 2: daySelect = this.businessHours.tuesday;
          break;
        case 3: daySelect = this.businessHours.wednesday;
          break;
        case 4: daySelect = this.businessHours.thursday;
          break;
        case 5: daySelect = this.businessHours.friday;
          break;
        case 6: daySelect = this.businessHours.saturday;
          break;
      }

      const startDate: any = new Date(
        value.year(),
        value.month(),
        value.date(),
        daySelect.open.hour,
        daySelect.open.minute,
        daySelect.open.second);

      const endDate: any = new Date(
        value.year(),
        value.month(),
        value.date(),
        daySelect.close.hour,
        daySelect.close.minute,
        daySelect.close.second);

        const startTimeDate = new Date(bookedDates.start);
        const endTimeDate = new Date(bookedDates.end);

        const startTime: any = new Date(
          value.year(),
          value.month(),
          value.date(),
          startTimeDate.getHours(),
          startTimeDate.getMinutes(),
          startTimeDate.getSeconds());
  
        const endTime: any = new Date(
          value.year(),
          value.month(),
          value.date(),
          endTimeDate.getHours(),
          endTimeDate.getMinutes(),
          endTimeDate.getSeconds());

      this.bookingsService.createUserLocationBooking(startDate, endDate, Date.parse(startTime), Date.parse(endTime), this.location, this.userForBooking).then(() => {
        valueArray.push(value);
        if (valueArray.length === array.length) {
          this.alertService.displayToast('Booking has been requested for location');
          this.submitting = false;
          this.modalCtrl.dismiss();
        }
      }).catch(err => {
        valueArray.push(value);
        if (valueArray.length === array.length) {
          console.log("ModalBookingRoomComponent -> onSubmit -> err", err)
          this.submitting = false;
        }
      });
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
