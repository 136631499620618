import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { EventRSVPComponent } from '../modals/event-rsvp/event-rsvp.component';

@Component({
  selector: 'app-events-list-component',
  templateUrl: './events-list.component.html'
})
export class EventsListComponent implements OnInit {
  isLoading = false;
  wordSearch = '';
  region = '';
  showFilters = false;
  eventsSubscription: Subscription;
  userBookingsSubscription: Subscription;
  todaysDate;
  allEvents = [];
  pastEvents: any[] = [];
  upcomingEvents: any[] = [];
  user: User;
  userBookings: any;

  @Input() events: any;
  @Input() includeFilters: boolean;
  @Input() includeSearch: boolean;

  teamMenu = 'upcoming';

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.allEvents = this.events;
    this.todaysDate = new Date();
    this.events.forEach(event => {
      const end = new Date(event.endDate.toDate());
      if (this.todaysDate > end) {
        this.pastEvents.push(event);
        event.isPast = true;
      } else {
        this.upcomingEvents.push(event);
        event.isPast = false;
      }
    });
    console.log('events ', this.events);

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.userBookingsSubscription = this.bookingsService.getUserRsvpBookings(this.user.uid).subscribe(userBookings => {
          if (userBookings) {
            this.userBookings = userBookings;
          }
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });

  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  checkIfUserAttending(eventId) {
    if (this.userBookings.length > 0) {
      if (this.userBookings.some(booking => booking.uid === eventId)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async openRSVPModal(eventInfo) {
    this.alertService.openModal(EventRSVPComponent, { event: eventInfo }, '');
  }

  navigateToEvent(eventId) {
    console.log('event id ', eventId);
    this.router.navigate([`event/${eventId}`]);
  }

  filterEvents(value) {
    if (value === 'region') {
      if (value !== '') {
        this.events = this.allEvents.filter((event) => {
          return event.address.region === value;
        });
      } else {
        this.events = this.allEvents;
      }
    }
  }

  searchEvents(value) {
    this.showFilters = false;
    if (value !== '') {
      this.events = this.allEvents.filter(event => {
        return event.title.toLowerCase().includes(value.toLowerCase())
          || event.address.formatted_address.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      this.events = this.allEvents;
    }
  }

  changeTab(tab) {
    this.teamMenu = tab;
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  resetFilters() {

  }

}
