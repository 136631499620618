import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Audit } from '../_shared/models/audit.model';
import { User } from '../_shared/models/users.model';
import { AuthenticationService } from '../pages/auth/auth.service';
import { config } from '../_shared/configs/config';

@Injectable({
  providedIn: 'root'  // <--provides this service in the root ModuleInjector
})
export class ClientService {

  currentAudits: Audit[];
  currentUser: User;

  constructor(
    public auth: AuthenticationService,
    public afs: AngularFirestore,
    public router: Router
  ) {
    // FETCH LOGGED IN USER
    this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.currentUser = userDetails;
      }
    });
  }

  sendContactEmail(contactFormSubmission) {
    const entityID = config.entityId;
    const contactCollection = this.afs.collection('pending');
    contactFormSubmission.request = 'contactFormSubmissions';
    contactFormSubmission.subject = 'Contact Form';
    contactFormSubmission.created = Date.now();
    contactFormSubmission.entityID = entityID;
    return contactCollection.add(contactFormSubmission);
  }

}
