import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.page.html'
})
export class UserHistoryPage implements OnInit {

  isLoading: boolean = false;
  
  checkIns$: Observable<Array<{}>>;
  collectionRef: string;

  transactions$: Observable<Array<{}>>;
  transaction: any;

  // USER
  userId: string;
  userName: string;

  historyMenu = '';

  constructor(
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private transactionsService: TransactionsService,
    private nav: NavController,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.historyMenu = (this.route.snapshot.queryParamMap.get('tab')) ? this.route.snapshot.queryParamMap.get('tab') : 'checkins';
    this.auth.user.subscribe((userDetails: User) => {
      if (userDetails) {
        this.userId = userDetails.uid;
        this.collectionRef = `users/${this.userId}/entities/${config.entityId}`;
        this.fetchTransactions();
        this.fetchCheckIns();
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  fetchTransactions() {
    this.transactions$ = this.transactionsService.getTransactions(this.collectionRef);
  }

  getTransaction(event) {
    console.log(event)
  }

  fetchCheckIns() {
    this.checkIns$ = this.bookingsService.getCheckIns(this.collectionRef);
  }

  getCheckIn(event) {
    console.log(event)
  }

  changeTab(tab) {
    this.historyMenu = tab;
  }

  goBack() {
    this.nav.back();
  }

}