import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { UsersService } from 'src/app/services/users.service';
import { PasswordResetComponent } from '../../components/modals/password-reset/password-reset.component';
import { MessagesService } from 'src/app/services/messages.service';
import { User } from '../../models/users.model';

@Component({
  selector: 'app-nav-header-dropdown',
  templateUrl: './nav-header-dropdown.component.html',
  styleUrls: ['./nav-header-dropdown.component.scss'],
}) 
export class NavHeaderDropdownComponent implements OnInit {
  userDetails;
  user: User;
  userSubscription: Subscription;
  conversationsSubscription: Subscription;
  conversationsCount: number;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private usersService: UsersService,
    private messagesService: MessagesService

  ) { }

  ngOnInit() {
    this.authenticationService.user.subscribe(user => {
      if (user) {
        this.userDetails = user;
        this.userSubscription = this.usersService.fetchUserDetails(`/users/${this.userDetails.uid}`).subscribe((userData: User) => {
          this.user = userData;
          console.log("USER");
          console.log(this.user);
        });

        this.conversationsSubscription = this.messagesService.getUnreadConversationsForUser(this.userDetails.uid).subscribe(conversations => {
          this.conversationsCount = conversations.length;
          console.log(conversations)
        });


      }
    }
    )
  }
  openPasswordResetModal(value) {
    this.alertService.openModal(PasswordResetComponent, { user: this.userDetails, showRelogin: value }, 'modal-small');
  }


  async logOut() {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Yes',
        handler: () => {
          this.authenticationService.signOutUser();
          this.router.navigate(['/landing']);
          this.userDetails = null;
        }
      }
    ];
    this.alertService.displayAlert('Log Out', 'Are you sure you want to log out?', buttons);
  }

}
