import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { User } from 'src/app/_shared/models/users.model';

declare var $: any;

@Component({
  selector: 'ws-bookings-edit-meeting-rooms-form',
  templateUrl: './bookings-edit-meeting-room.form.html'
})
export class CalendarEditMeetingRoomFormComponent implements OnInit, OnDestroy {

  @Input() event: any;
  @Input() bookingType: any;

  // FORM
  editEventForm: FormGroup;
  isSubmitting: boolean = false;
  removeUpdate: boolean = false;
  showDeleteButtons: boolean = false;

  // DATES
  todaysDate;
  bookingDate: any;
  bookingEndDate: any;
  bookingLocation: any;
  bookingTime: any;
  oldBookingDate: any;

  showBookedMessage = false;
  isUpdating = false;
  processing = false;
  eventFinished = false;

  // USER
  bookingUserInfo: User;

  // STATUS
  bookingStatus: any;

  // BOOKING MESSAGE
  showMessage: boolean = false;
  messagePlaceholder: string;

  // ACTIVATE BOOKING
  showActiveMessage: boolean = false;

  // NOTIFICATION
  sendNotification: boolean = false;
  notificationType: string;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private alertService: AlertService,
    private bookingsService: BookingsService
  ) { }

  ngOnInit() {
    this.buildForm();

    this.bookingUserInfo = this.event.booking.user;
    this.bookingStatus = this.event.booking.status;
    this.bookingTime = this.event.bookingTime;
    this.bookingLocation = this.event.booking.locationName;

    if (Object.prototype.toString.call(this.event.booking.startDate) === "[object Date]") {
      console.log('IS A DATE')
      if (isNaN(this.event.booking.startDate.getTime())) {
        console.log('INVALID DATE')
      } else {
        this.bookingDate = new Date(this.event.booking.startDate.getTime());
        this.bookingEndDate = new Date(this.event.booking.endDate.getTime());
        this.event.booking.startDate = new Date(this.event.booking.startDate.getTime());
        this.event.booking.endDate = new Date(this.event.booking.endDate.getTime());
      }
    } else {
      this.bookingDate = this.event.booking.startDate.toDate();
      this.bookingEndDate = this.event.booking.endDate.toDate();
      this.event.booking.startDate = this.event.booking.startDate.toDate();
      this.event.booking.endDate = this.event.booking.endDate.toDate();
    }

    this.todaysDate = moment(new Date());

    // USER BOOKINGS
    if (this.bookingType !== 'userBookings') {
      this.setForm(this.todaysDate);
      this.notificationType = 'meetingRoom';
    } else {
      this.editEventForm.removeControl('date');
      this.notificationType = 'user';
    }
  }

  buildForm() {
    this.editEventForm = this.fb.group({
      date: [''],
      meetingTime: this.fb.group({
        start: [''],
        end: ['']
      })
    });
    this.editEventForm.disable();
  }

  dateChange() {
    if (this.event.booking.status === 3) {
      this.showActiveMessage = true;
    }
    this.showMessage = true;
    this.messagePlaceholder = 'Reason for change...';
    this.editEventForm.addControl('message', new FormControl('', Validators.required));
  }

  setForm(current) {

    // SET DATE
    this.editEventForm.controls.date.setValue(moment(this.event.booking.startDate).toISOString());
    console.log(moment(this.event.booking.startDate.toISOString()).format('HH:mm'))
    // SET TIMES
    this.editEventForm.controls.meetingTime.get('start').setValue(moment(this.event.booking.startDate).toISOString());
    this.editEventForm.controls.meetingTime.get('end').setValue(moment(this.event.booking.endDate).toISOString());

    if (new Date(this.event.booking.endDate) < new Date(current)) {
      this.eventFinished = true;
      this.editEventForm.disable();
    }

    this.editEventForm.markAsUntouched();
    this.editEventForm.markAsPristine();
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `<span class="badge badge-warning">Pending</span>`;
        break;
      case 2:
        return `<span class="badge badge-success">Confirmed</span>`;
        break;
      case 3:
        return `<span class="badge badge-danger">Cancelled</span>`;
        break;
      default:
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onUpdate() {
    this.isSubmitting = true;
    const times = {
      open: {
        hour: +moment(this.editEventForm.get('meetingTime.start').value).format('HH'),
        minute: +moment(this.editEventForm.get('meetingTime.start').value).format('mm'),
        second: +moment(this.editEventForm.get('meetingTime.start').value).format('ss')
      },
      close: {
        hour: +moment(this.editEventForm.get('meetingTime.end').value).format('HH'),
        minute: +moment(this.editEventForm.get('meetingTime.end').value).format('mm'),
        second: +moment(this.editEventForm.get('meetingTime.end').value).format('ss')
      }
    }

    const selectedDate = {
      year: +moment(this.editEventForm.get('date').value).format('YYYY'),
      month: +moment(this.editEventForm.get('date').value).format('MM'),
      day: +moment(this.editEventForm.get('date').value).format('DD')
    }

    this.event.booking.startDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, times.open.hour, times.open.minute, times.open.second);
    this.event.booking.endDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, times.close.hour, times.close.minute, times.close.second);

    if (this.showActiveMessage) this.event.booking.status = 2;
    let pendingData = {
      startDate: this.event.booking.startDate,
      newTime: moment(this.event.booking.startDate).format('HH:mm') + '-' + moment(this.event.booking.endDate).format('HH:mm'),
      locationRef: this.event.booking.locationRef,
      oldLocation: this.bookingLocation,
      name: this.event.booking.name,
      oldDate: this.bookingDate,
      oldTime: moment(this.bookingDate).format('HH:mm') + '-' + moment(this.bookingEndDate).format('HH:mm'),
      oldStatus: this.bookingStatus,
      status: this.event.booking.status,
      createdBy: this.event.booking.createdBy,
      user: this.event.booking.user
    };
    const bookingData = this.event.booking;
    delete bookingData.user;
    this.bookingsService.updateBooking(bookingData, this.event.booking.uid).then(() => {
      this.bookingsService.sendPendingNotify(pendingData, this.editEventForm.get('message').value, this.notificationType).then(() => {
        this.isSubmitting = false;
        this.editEventForm.markAsPristine();
        this.alertService.displayToast('Booking updated');
        this.modalCtrl.dismiss();
      }).catch(error => {
        this.alertService.displayToast(`Error updating booking: ${error}`);
      });
    }).catch(error => {
      this.alertService.displayToast(`Error updating booking: ${error}`);
    });
  }

  onDelete(type, remove?) {
    if (type === 'warning') {
      this.showDeleteButtons = true;
      if (this.bookingTime === 'future' && !remove) {
        console.log(this.bookingTime)
        this.showMessage = true;
        this.messagePlaceholder = 'Reason for cancellation...';
        this.editEventForm.addControl('message', new FormControl('', Validators.required));
      }
    }
    if (type === 'delete') {
      let toastrMsg = '';
      if (this.bookingTime === 'future' && !remove) {
        this.event.booking.status = 3;
        toastrMsg = 'Booking cancelled';
        this.sendNotification = true;
      }
      else {
        this.event.booking.active = false;
        toastrMsg = 'Booking removed';
        this.sendNotification = false;
      }

      const userId = (this.event.booking.bookingFor) ? this.event.booking.bookingFor : this.event.booking.createdBy;
      const bookingData = this.event.booking;
      delete bookingData.user;

      this.bookingsService.updateBooking(bookingData, this.event.booking.uid).then(() => {
        if (this.sendNotification) {
          this.event.booking.newTime = moment(this.event.booking.startDate).format('HH:mm') + '-' + moment(this.event.booking.endDate).format('HH:mm');
          this.event.booking.oldTime = moment(this.bookingDate).format('HH:mm') + '-' + moment(this.bookingEndDate).format('HH:mm');
          this.bookingsService.sendPendingNotify(this.event.booking, this.editEventForm.get('message').value, this.notificationType).then(() => {
            this.updateUserBooking(userId, bookingData);
            this.alertService.displayToast(toastrMsg);
            this.showDeleteButtons = false;
            this.showMessage = false;
            this.messagePlaceholder = '';
            this.modalCtrl.dismiss();
          });
        } else {
          this.updateUserBooking(userId, bookingData);
          this.alertService.displayToast(toastrMsg);
          this.showDeleteButtons = false;
          this.showMessage = false;
          this.messagePlaceholder = '';
          this.modalCtrl.dismiss();
        }
      }).catch(error => {
        this.alertService.displayToast(`Error deleting booking: ${error}`);
      })
    }
    if (type === 'cancel') {
      this.showDeleteButtons = false;
      this.showMessage = false;
      this.messagePlaceholder = '';
      if (this.bookingType !== 'userBookings') {
        const current = new Date();
        this.setForm(current);
      }
      if (this.editEventForm.get('message')) {
        this.editEventForm.removeControl('message');
      }
    }
  }

  updateUserBooking(userId, booking) {
    this.bookingsService.updateUserBooking(userId, booking).then(() => {
      console.log('USER BOOKING CANCELLED');
    }).catch((err) => {
      console.log('ERROR CANCELLING: ', err);
    })
  }

  ngOnDestroy() {

  }

}
