import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationsService } from '../_services/locations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { IonSlides, ModalController, AlertController, NavController } from '@ionic/angular';
import { weekdays } from '../models/weekdays';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { LocationBookingComponent } from 'src/app/_shared/components/modals/location-booking/location-booking.component';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from '../../auth/auth.service';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs';
import { Location } from "@angular/common";
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { CheckinService } from 'src/app/services/checkin.service';
import { ReportingModalComponent } from 'src/app/_shared/components/modals/reporting-modal/reporting-modal.component';
import { LocationNetworkComponent } from 'src/app/_shared/components/modals/location-network/location-network.component';
import { TestBed } from '@angular/core/testing';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.page.html',
  styleUrls: ['../location.scss'],
})
export class LocationViewPage implements OnInit {
  isLoading = false;
  userDetails: User;

  location: any;
  locationId;
  locationIsBooked = false;
  checkedIn = false;
  locationBookings = [];

  checkingIn = false;

  bookingId;

  meetingRooms: any[] = [];

  // BUSINESS HOURS
  businessHours: any;
  businessDays: any[] = [];
  businessDay: any;

  // AMENITIES
  amenities: any;
  amenitiesIcons = {
    'Unlimited Wifi': 'wifi',
    'Limited Wifi': 'cellular',
    'Free Parking': 'car',
    'Paid Parking': 'car',
    '10% Discount on Food and Beverages': 'restaurant',
    '20% Discount on Food and Beverages': 'restaurant',
    'Toilets': 'person',
    'Free Coffee, Tea or Water': 'cafe',
    'Seats with Plugs': 'flash',
    'Video Games': 'desktop',
    'Printers': 'print',
    'VR': 'glasses',
    'Air-conditioning': 'snow',
    'Showers': 'water',
    'Gym': 'barbell'
  };

  // GALLERY
  gallery: any[];
  activeSliderId = "ngb-slide-" + 0;

  slideControl: any[] = [];

  backgroundImage: string;
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  mapSettings: any;
  showMap = false;

  userTierSubscription: Subscription;
  allowUserBooking = false;
  userTierSubscriptions: any[];
  mobileView = false;
  currentPlatform;

  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  sliderOne: any;

  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };

  userBookedDays: any[] = [];

  constructor(
    private locationsService: LocationsService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private launchNavigator: LaunchNavigator,
    private router: Router,
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private alert: AlertService,
    private subscriptionsService: SubscriptionsService,
    private alertCtrl: AlertController,
    private locationCtrl: Location,
    private checkinService: CheckinService,
    public navCtrl: NavController
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
    this.storageService.pubMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    this.storageService.currentPlatform.subscribe((data) => {
      this.currentPlatform = data;
    });


  }

  ionViewDidEnter() {
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.isLoading = true;

    this.locationsService.getPublicLocationsDetails(this.locationId).subscribe((locationData: any) => {

      // LOCATION INFO
      this.location = locationData.location;
      this.storageService.singleLocation.next(this.location);

      // BUSINESS HOURS
      this.businessHours = locationData.businessHours;
      this.businessDays = [];
      for (let businessHour in this.businessHours) {
        let openTime = {
          hour: this.businessHours[businessHour]['open'].hour,
          minute: this.businessHours[businessHour]['open'].minute
        }
        let closeTime = {
          hour: this.businessHours[businessHour]['close'].hour,
          minute: this.businessHours[businessHour]['close'].minute
        }
        const open = this.toTime(openTime);
        const close = this.toTime(closeTime);
        let closedDay = false;
        if (open === close || (closeTime.hour === openTime.hour && openTime.minute === closeTime.minute)) {
          closedDay = true;
        }
        let days = {
          day: businessHour,
          open: open,
          close: close,
          closed: closedDay
        }
        this.businessDays.push(days);
        if (this.businessDays.length === 7) {
          this.orderHours(weekdays, this.businessDays);
          const today = new Date().getUTCDay();
          this.businessDay = this.businessDays[today];
        }
      }

      // AMENITIES
      this.amenities = locationData.amenities.list.filter((amenity) => {
        return amenity.value === true;
      });

      // GALLERY
      this.gallery = locationData.gallery;
      if (this.location.featureImage) {
        this.backgroundImage = this.location.featureImage;
      }

      this.sliderOne = {
        isBeginningSlide: true,
        isEndSlide: false,
        slideItems: this.gallery
      };

      // MEETING ROOMS
      if (locationData.meetingRooms) {
        this.meetingRooms = locationData.meetingRooms;
      }

      const date = new Date();
      const currentDay = date.getUTCDay();
      const currentHour = date.getHours();
      const currentMinute = date.getMinutes();

      let daySelect;
      switch (currentDay) {
        case 0: daySelect = this.businessHours.sunday;
          break;
        case 1: daySelect = this.businessHours.monday;
          break;
        case 2: daySelect = this.businessHours.tuesday;
          break;
        case 3: daySelect = this.businessHours.wednesday;
          break;
        case 4: daySelect = this.businessHours.thursday;
          break;
        case 5: daySelect = this.businessHours.friday;
          break;
        case 6: daySelect = this.businessHours.saturday;
          break;
      }

      // tslint:disable-next-line: max-line-length
      this.location.currentHours = `${this.toTime({ hour: daySelect.open.hour, minute: daySelect.open.minute })} - ${this.toTime({ hour: daySelect.close.hour, minute: daySelect.close.minute })}`;
      if (currentHour >= daySelect.open.hour && currentHour < daySelect.close.hour) {
        this.location.isOpen = true;
      } else {
        if (currentHour === daySelect.open.hour && currentMinute >= daySelect.open.minute) {
          this.location.isOpen = true;
        } else {
          if (currentHour === daySelect.close.hour && currentMinute <= daySelect.close.minute) {
            this.location.isOpen = true;
          } else {
            this.location.isOpen = false;
          }
        }
      }

      if (daySelect.open.hour === daySelect.close.hour && daySelect.open.minute === daySelect.close.minute) {
        this.location.isOpen = false;
        this.location.currentHours = 'Closed all day';
      }

      this.auth.user.subscribe(user => {
        if (user) {
          this.userDetails = user;

          this.userTierSubscription = this.subscriptionsService.getUserSubscriptions(this.userDetails.uid).subscribe((subs: any) => {
            this.userTierSubscriptions = subs.filter((e) => { return e.type === 'userSubscription' && !e.suspended || e.type === 'teamSubscription' && e.teamId && !e.suspended; });

            this.userTierSubscriptions.filter(subscription => {
              if (subscription.product === this.location.product.title) return this.allowUserBooking = true;
              else if (subscription.product === 'Classic' && this.location.product.title !== 'Premium') return this.allowUserBooking = true;
              else if (subscription.product === 'Premium') return this.allowUserBooking = true;
            });
          });

          this.bookingsService.getUserTypeBookings(this.locationId, 'location').subscribe(locationBookings => {
            if (locationBookings.length > 0) {
              this.locationBookings = locationBookings;

              // GET USERS BOOKED DAYS FOR BOOKING POP UP
              this.userBookedDays = [];
              this.locationBookings.forEach(booking => {
                const bookingDates = {
                  date: booking.startTime,
                  disable: true
                }
                this.userBookedDays.push(bookingDates);
              });

              if (this.userDetails) {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const today = currentDate.getDate();
                const locationBookingCheck = this.locationBookings.some(locationInfo => {
                  this.checkedIn = locationInfo.booking.checkedIn;
                  if (locationInfo.booking.status === 2 && locationInfo.booking.typeId === this.location.uid && locationInfo.startTime.getFullYear() === currentYear
                    && locationInfo.startTime.getMonth() === currentMonth && locationInfo.startTime.getDate() === today) {
                    this.bookingId = locationInfo.booking.uid;
                    this.location.bookingId = locationInfo.booking.uid;
                    return true;
                  } else {
                    return false;
                  }
                });
                if (locationBookingCheck && this.location.isOpen) {
                  this.location.isBooked = true;
                } else {
                  this.location.isBooked = false;
                }
              }
            }
          });
        }
      });
      this.isLoading = false;
    });

    this.storageService.checkingIn.subscribe(value => {
      this.checkingIn = value;
    });
  }

  // BUSINESS HOURS
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
    //Hide the meridian information
    //return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  orderHours(array_with_order, array_to_order) {
    let ordered_array = [],
      len = array_to_order.length,
      len_copy = len,
      index, current;

    for (; len--;) {
      current = array_to_order[len];
      index = array_with_order.indexOf(current.day);
      ordered_array[index] = current;
    }

    // change the array
    Array.prototype.splice.apply(array_to_order, [0, len_copy].concat(ordered_array));
  }

  goBack() {
    this.navCtrl.back();
  }

  // INFO
  getParking(parking) {
    switch (parking) {
      case 'FO':
        return `Free Outdoor Parking`;
      case 'FI':
        return `Free Indoor Parking`;
      case 'PP':
        return `Paid Parking`;
      case 'NP':
        return `No Parking`;
      default:
    }
  }

  launchNavigatorApp() {
    if (this.currentPlatform == "desktop" || this.currentPlatform == "mobile") {
      window.open(`https://www.google.co.za/maps/place/${this.location.address.formatted_address}`,
        '_blank');
    } else {
      this.launchNavigator.navigate(this.location.address.formatted_address);
    }

  }

  openBookingModal() {
    this.alert.openModal(LocationBookingComponent, { location: this.location, businessHours: this.businessHours, userBookings: this.userBookedDays }, '');
  }

  openCheckIn() {
    this.checkinService.displayCheckinActionSheet(this.userDetails, this.location).then(() => {
      this.checkingIn = true;
    });
  }

  navigateToMeetingRoom(room) {
    this.router.navigate([`meeting-room/${room.uid}`]);
  }

  // Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  // Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  // Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  // Call methods to check if slide is first or last to enable disbale navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  reportLocation(location) {
    this.alert.openModal(ReportingModalComponent, { location: location, user: this.userDetails, reportType: 'location' }, 'modal-small');
  }

  // CHAT
  openChat(conversationId, conversationName, locationId) {
    this.router.navigate([`/messages/conversation/create/${conversationId}`], { queryParams: { conversationName, locationId } });
  }

  openNetworkModal(location) {
    this.alert.openModal(LocationNetworkComponent, { location, user: this.userDetails }, '');
  }

}
