import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../_services/locations.service';
import { IonContent } from '@ionic/angular';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';
import { UserInviteComponent } from 'src/app/_shared/components/modals/user-invite/user-invite.component';

@Component({
  selector: 'app-location-edit-members',
  templateUrl: './location-members.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditMembersPage implements OnInit {
  
  isLoading = false;
  locationId;
  locationUsersSubscription: Subscription;
  location: any;
  gallery: any;

  // LOCATION USERS
  user: User;
  locationUsers: any;
  locationUserInputEnabled = true;
  locationUsersPermissions: any;
  currentLocationUser: User;
  users: User[];
  allUsers: any;
  usersSubscription: Subscription;
  locationUserForm: FormGroup;
  addedUser: User;
  addNewUser = false;
  addUserMessage: any = {
    color: '',
    text: ''
  };
  addingNewUser = false;
  isUserAllowed = false;

  // INVITE USERS
  locationInviteUserForm: FormGroup;
  showMessage = false;
  feedbackMessage: string;
  addingInvitedUser = false;
  showInvite = false;

  // OWNER USER
  userOwnerForm: FormGroup;
  locationOwner: any;
  addNewOwner = false;
  updatingOwner = false;
  addMember = false;

  // SUBMIT
  isSubmitting = false;

  @ViewChild(IonContent, { static: true }) content: IonContent;
  
  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private locationsService: LocationsService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    // this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.buildForm();


    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    // GET TEAM USERS
    this.locationUsersSubscription = this.locationsService.getLocationUsers(this.locationId).subscribe((teamData) => {
      this.locationUsers = teamData;
      this.storage.get('location').then((location) => {
        this.location = location;
        if (this.location.owner) {
          const owner = this.locationUsers.filter((e) => { return e.uid === this.location.owner.uid; });
          const userLocation = this.locationUsers.filter(e => e.uid === this.user.uid);
          this.currentLocationUser = userLocation[0];
          console.log('user location ', this.currentLocationUser);
          this.locationOwner = owner[0];
          this.userOwnerForm.get('owner').patchValue(this.locationOwner);
        } else {
          this.userOwnerForm.get('owner').patchValue('');
        }
      });
      this.isLoading = false;
    });

    // GET ALL USERS
    this.usersSubscription = this.usersService.fetchEntityUsers().subscribe(entityUsersData => {
      this.users = entityUsersData;
    });
    this.allUsers = this.usersService.findUsers();
  }

  buildForm() {
    this.locationUserForm = this.fb.group({
      user: ['']
    });
    this.userOwnerForm = this.fb.group({
      owner: ['']
    });
  }

  buildInviteUserForm() {
    this.locationInviteUserForm = this.fb.group({
      firstname: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: [''],
    });
  }

  makeAdmin(user, value) {
    if (value) {
      user.isAdmin = true;
    } else {
      user.isAdmin = false;
    }
  }

  setOwner(owner) {
    this.updatingOwner = true;
    if (this.locationOwner) {
      // REMOVE OLD OWNER
      const updateValues = {
        isOwner: false
      }
      this.locationsService.updateLocationUser(this.locationOwner.uid, this.locationId, updateValues).then(() => {
        this.addOwner(owner);
      });
    } else {
      this.addOwner(owner);
    }
  }

  addOwner(owner) {
    // SET NEW OWNER IN TEAM
    const location = {
      owner: {
        firstname: owner.firstname,
        surname: owner.surname,
        email: owner.email,
        uid: owner.uid
      }
    };
    this.locationsService.updateLocation(this.locationId, location).then(() => {
      // SET USER TO OWNER
      const updateValues = {
        isOwner: true
      }
      this.locationsService.updateLocationUser(owner.uid, this.locationId, updateValues).then(() => {
        this.updatingOwner = false;
        this.addNewOwner = false;
        this.alert.displayToast('Owner Set');
      });
    });

  }

  resetOwnerForm() {
    if (this.locationOwner) {
      this.userOwnerForm.reset();
      this.userOwnerForm.get('owner').patchValue(this.locationOwner);
    } else {
      this.userOwnerForm.reset();
      this.userOwnerForm.get('owner').patchValue('');
    }
  }

  inviteUser(email) {
    // $("#inviteUser").modal("show");
    this.locationInviteUserForm.get('email').patchValue(email);
    this.locationInviteUserForm.get('email').markAsTouched();
  }

  addInvitedUser(formValues) {
    if (this.users.some(member => member.email === formValues.email)) {
      this.feedbackMessage = 'User already exists';
    } else {
      if (this.locationUsers.some(member => member.email === formValues.email)) {
        this.feedbackMessage = 'User already added';
      } else {
        this.addingInvitedUser = true;
        formValues.invited = true;
        formValues.isAdmin = true;
        formValues.isOwner = false;
        formValues.permissions = '';
        this.feedbackMessage = '';
        this.locationsService.linkUsersAndLocations(formValues, this.location, this.locationId).then(() => {
          this.addingInvitedUser = false;
          this.addNewUser = false;
          this.locationInviteUserForm.reset();
          this.addUserMessage = '';
          this.locationUserForm.reset();
          // $("#inviteUser").modal("hide");
          this.alert.displayToast('Invited user succesfully');
        }).catch(error => {
          this.alert.displayToast(`Error: ${error}`);
        });
      }
    }
  }

  updateLocationUser(userId, type, value?) {
    let updateValues;
    if (type === 'remove') {
      this.locationsService.deleteLocationUser(userId, this.locationId, this.location.name).then(() => {
        this.alert.displayToast('User removed');
      }).catch(error => {
        this.alert.displayToast(`Error: ${error}`);
      });
    }
    if (type === 'update') {
      updateValues = {
        isAdmin: value
      };
      this.locationsService.updateLocationUser(userId, this.locationId, updateValues).then(() => {
        this.alert.displayToast('User updated');
      }).catch(error => {
        this.alert.displayToast(`Error: ${error}`);
      });
    }
  }

  viewUserProfile(userId, userIndex) {
    this.alert.openModal(UserViewComponent, { userId: userId, userIndex: userIndex }, 'modal-small');
  }

  // SEARCH USERS
  emailCheck(email) {
    this.usersService.emailCheck(email.toLowerCase()).then((snapShot) => {
      if (snapShot.empty) {
        this.addUserMessage = { color: 'danger', text: 'User does not exist' };
        this.showInvite = true;
        this.isUserAllowed = false;
        this.content.scrollToBottom();
      } else {
        this.addUserMessage = '';
        if (this.locationUsers.some(member => member.email === email)) {
          this.addUserMessage = { color: 'danger', text: 'User already added' };
          this.showInvite = false;
          this.isUserAllowed = false;
        } else {
          this.isUserAllowed = true;
          snapShot.forEach(snap => {
            this.addedUser = snap.data();
          });
          this.addUserMessage = { color: 'success', text: 'User exists and can be added' };
          this.showInvite = false;
        }
      }
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.users.filter(v =>
          (v.full_name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
          (v.email.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
          (v.refNo.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    )

  formatter = (x: { full_name: string }) => x.full_name;

  addUser(user) {
    if (user) {
      if (this.locationUsers.some(member => member.email === user.email)) {
        this.addUserMessage = 'User already added';
      } else {
        this.addingNewUser = true;
        user.invited = false;
        user.isAdmin = true;
        user.isOwner = false;
        this.addUserMessage = '';
        this.locationsService.linkUsersAndLocations(user, this.location, this.locationId).then(() => {
          this.locationUserForm.get('user').reset();
          this.addingNewUser = false;
          this.addNewUser = false;
          this.alert.displayToast('User added');
        }).catch(error => {
          this.alert.displayToast(`Error: ${error}`);
        });
      }
    } else {
      this.addUserMessage = 'Please add a valid user';
    }
  }

  openInviteModal() {
    const email = this.locationUserForm.get('user').value;
    this.alert.openModal(UserInviteComponent, { location: this.location, addedEmail: email }, '');
    this.showInvite = false;
    this.addMember = false;
    this.addUserMessage = '';
    this.locationUserForm.reset();
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.locationUsersSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
  }

}
