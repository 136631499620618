import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

  faqList = [
    {
      title: 'How do we get registered?',
      content: 'Simply go to www.wealthspaces.co.za, click on the sign-up tab and fill in your details. Make sure to have you credit or debit card handy when you sign up.',
      open: true
    },
    {
      title: 'Where are your locations?',
      content: 'We have locations throughout South Africa, locations are added on the site on a weekly basis.',
      open: false
    },
    {
      title: 'Do you have meeting rooms?',
      content: 'Meeting rooms are one of the exciting features and services on WealthSpaces. You can view the meeting rooms on the website, but keep in mind that the meeting rooms are at an additional cost. WealthSpaces has negotiated the best prices for meeting rooms for your benefit.',
      open: false
    },
    {
      title: 'How many hours can I use the location?',
      content: 'The hours can be seen when opening up the locations on the website. Hours differ location to location.',
      open: false
    },
    {
      title: 'Do I get a discount on food and beverages?',
      content: 'Once you have checked in at the location, you will automatically get a discount on your bill. Make sure you notify your waiter that you are a WealthSpaces member, you have been working so hard you deserve the discount.',
      open: false
    },
    {
      title: 'How much coffee can I have?',
      content: 'Each WealthSpaces member can have 1 cup on filter coffee or tea',
      open: false
    },
    {
      title: 'Are the meeting rooms free?',
      content: 'No, however the prices are hugely discounted for your benefit',
      open: false
    },
    {
      title: 'Does one membership allow me my whole team?',
      content: 'Only if it is for a meeting when you have booked a meeting room.',
      open: false
    },
    {
      title: "How do locations know I'm a member?",
      content: 'When you book to go work at a location, they receive an email notification stating the you are Coming. When you get to a location it is advised to check in, this notifies the location that you have arrived. They will always give you a warm welcome.',
      open: false
    },
    {
      title: 'Which locations am I allowed to use?',
      content: 'You are allowed to use any location under your package. Your memberships allow you to use all locations in South Africa under your specific package.',
      open: false
    },
    {
      title: 'Can I use more than one location a day?',
      content: 'Yes you can use as many locations as you want in a day',
      open: false
    },
    {
      title: 'Can I rate a location? * Coming Soon',
      content: 'Rating locations is highly advised as it gives you tokens towards our rewards program',
      open: false
    },
    {
      title: 'Can a location rate me? * Coming Soon',
      content: 'Yes, the locations can rate you.',
      open: false
    },
    {
      title: 'Does the discount for food and beverages include my guest?',
      content: 'Yes the discount includes your guests',
      open: false
    },
    {
      title: 'Is the free coffee and tea included for my guest?',
      content: 'No, the free coffee and tea only applies to WealthSpaces members',
      open: false
    },
    {
      title: 'When can I start using my WealthSpaces membership?',
      content: 'You can use WealthSpaces immediately',
      open: false
    },
    {
      title: 'Can I book on the go?',
      content: 'Absolutely! WealthSpaces is built for flexibility and convenience',
      open: false
    },

  ];

  // loaders
  public loading: boolean;

  constructor() { }

  ngOnInit() {

  }

  toggleItem(index) {
    console.log('toggling item for index ', index);
    this.faqList[index].open = !this.faqList[index].open;
  }
}
