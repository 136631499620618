import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, NavController } from '@ionic/angular';
import { AuthenticationService } from '../../auth/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { ClientService } from 'src/app/services/client.service';
import { Router } from '@angular/router';

const url = '//code.tidio.co/yni5ardvseaw1x0wapvaqsdhkesws7yo.js';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html'
})
export class ContactUsPage implements OnInit, OnDestroy {
  contactForm: FormGroup;

  // states
  loading: boolean;
  submitting: boolean;

  loadAPI: Promise<any>;

  constructor(
    private fb: FormBuilder,
    private authSrvc: AuthenticationService,
    public loadingController: LoadingController,
    private alert: AlertService,
    private clientService: ClientService,
    private router: Router,
    private navCtrl: NavController
  ) {
  }

  ngOnInit() {
    this.initContactForm();

    this.loading = true;

    this.ionViewDidEnter();

    this.authSrvc.user.subscribe(user => {
      if (user) {
        this.contactForm.get('name').patchValue(`${user.firstname} ${user.surname}`);
        this.contactForm.get('email').patchValue(user.email);
      }
      this.loading = false;
    });
  }

  ionViewDidEnter() {
    this.loadAPI = new Promise((resolve) => {
      console.log('resolving promise...');
      this.loadScript();
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  public loadScript() {
    console.log('preparing to load...')
    let div = document.createElement('div');
    div.setAttribute("id", "tidioScript");
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(div).appendChild(node);
  }

  initContactForm = () => this.contactForm = this.fb.group({
    name: ['', [
      Validators.required,
      Validators.minLength(2)
    ]],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    message: ['', [
      Validators.required,
      Validators.minLength(5)
    ]]
  })

  submitForm() {
    this.submitting = true;
    if (this.contactForm.valid) {
      this.clientService.sendContactEmail(this.contactForm.value).then(() => {
        this.submitting = false;
        this.alert.displayToast('Message sent successfully');
        this.router.navigate(['/home']);
        this.contactForm.reset();
      }).catch(err => {
        this.alert.displayToast(`Error sending message: ${err}`);
        this.submitting = false;
      });
    }
  }

  ionViewDidLeave() {
    document.getElementById("tidioScript").remove();
    document.getElementById("tidio-chat-code").remove();
    document.getElementById("tidio-chat").remove();
  }

  ngOnDestroy() {
    // document.getElementById("tidioScript").remove();
    // document.getElementById("tidio-chat-code").remove();
    // document.getElementById("tidio-chat").remove();
  }

}
