export class Upload {
    $key?: string;
    file?: File;
    name?: string;
    downloadFile?: string;
    path?: string;
    size?: string;
    created?: any;
    createdByUID?: string;
    createdByName?: string;
    filetype?: string;
    ref?: any;
    saveRef?: any;
    active?: boolean;
    order?: number;
    downloadFileThumbnail?: string;
    uploadedPhoto?: string;
    uploadedPhotoThumbnail?: string;

    constructor(file: File) {
        this.file = file;
    }
}