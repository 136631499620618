import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { config } from 'src/app/_shared/configs/config';
import { UsersService } from 'src/app/services/users.service';
import { StorageService } from 'src/app/services/storage.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'share-ref-component',
  templateUrl: './share-ref.component.html'
})
export class ShareRefComponent implements OnInit {
  emailShareForm: FormGroup;
  isSubmitting = false;
  user: any;
  showEmail = false;
  refCode = '';
  showWAShare = false;
  entityId = config.entityId;

  currentPlatform: string;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private socialShare: SocialSharing,
    private alert: AlertService,
    private fb: FormBuilder,
    private platform: Platform,
    private userService: UsersService,
    private storageServices: StorageService,
    public afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.emailShareForm = new FormGroup({
      'email': new FormControl(null),
    });
    this.storageServices.currentPlatform.subscribe(plat => {
      this.currentPlatform = plat;
    })

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.refCode = this.user.refNo;
        if (this.currentPlatform != 'desktop') {
          this.socialShare.canShareVia('whatsapp').then(() => {
            this.showWAShare = true;
          }).catch(error => {
            this.showWAShare = false;
            console.log('cannot share via whatsapp ', error);
          });
          this.userService.addUserReferenceShare('Email', this.user.uid);
        } else {
          this.showWAShare = false;
          this.showEmail = true;
        }
      }
    });


  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  shareRefCode(type) {
    if (type === 'WA') {
      this.socialShare.shareViaWhatsApp(`${this.user.firstname} ${this.user.surname} has started using WealthSpaces.

They have shared their reference code: ${this.user.refNo}

Enter code on signup when you create a new account, and receive 10% for the first month.

We look forward to collaborating with you, WealthSpaces`, null, null)
        .then(() => {
          this.userService.addUserReferenceShare('WhatsApp', this.user.uid);
        })
        .catch(error => {
          console.log('error sharing with whatsapp ', error);
          this.alert.displayAlert('Error sharing via whatsapp ', JSON.stringify(error), [{ text: 'Ok', role: 'cancel' }]);
        });
    }

    // if (type === 'FB') {
    //   this.socialShare.shareViaFacebook(this.refCode).then(() => {
    //     this.modalCtrl.dismiss();
    //     this.alert.displayToast('Successfully shared code');
    //   });
    // }

    if (type === 'email') {
      this.buildEmailForm();
      this.showEmail = true;
    }

    // if (type === 'twitter') {
    //   this.socialShare.shareViaTwitter(this.refCode).then(() => {
    //     this.modalCtrl.dismiss();
    //     this.alert.displayToast('Successfully shared code');
    //   });
    // }
  }

  buildEmailForm() {
    this.emailShareForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  async shareViaEmail() {
    this.isSubmitting = true;
    const email = this.emailShareForm.get('email').value;
    // this.socialShare.canShareViaEmail().then(() => {
    //   // tslint:disable-next-line: max-line-length
    //   this.socialShare.shareViaEmail(`${this.user.firstname} ${this.user.surname} has started using WealthSpaces <br> <br> Work, meet and connect at one of our many inspiring co-working spaces and 
    //   meeting rooms. <br> <br> They have shared their reference code: ${this.user.refNo} <br> <br> Enter code on signup when you create a new account, and receive 10% for the first month.
    //    <br> <br> We look forward to collaborating with you. <br> WealthSpaces`, `WealthSpaces Reference Share from ${this.user.firstname}`, [email]).then(() => {
    //     this.modalCtrl.dismiss();
    //     this.emailShareForm.reset();
    //     this.isSubmitting = false;
    //     this.alert.displayToast('Successfully shared code via email');
    //     this.userService.addUserReferenceShare('Email', this.user.uid);
    //   });
    // }).catch(error => {
    //   this.isSubmitting = false;
    //   this.emailShareForm.reset();
    //   this.alert.displayToast(`Error sharing code: ${error}`);
    // });
    try {
      await this.addEmail(email);
      this.modalCtrl.dismiss();
      this.emailShareForm.reset();
      this.isSubmitting = false;
      this.alert.displayToast('Successfully shared code via email');
      this.userService.addUserReferenceShare('Email', this.user.uid);
    } catch (error) {
      this.isSubmitting = false;
      this.emailShareForm.reset();
      this.alert.displayToast(`Error sharing code: ${error}`);
    }




  }


  async addEmail(email) {
    console.log("EMAIL", email);
    const updateUser = this.afs.collection(`pending`);
    return await updateUser.add({
      request: 'emailShare',
      emailbody: `${this.user.firstname} ${this.user.surname} has started using WealthSpaces <br> <br> Work, meet and connect at one of our many inspiring co-working spaces and 
      meeting rooms. <br> <br> They have shared their reference code: ${this.user.refNo} <br> <br> Enter code on signup when you create a new account, and receive 10% for the first month.
       <br> <br> We look forward to collaborating with you. <br> WealthSpaces, WealthSpaces Reference Share from ${this.user.firstname}`,
      emailto: email,
      entityId: this.entityId,
      source: 'app',
    })
  }


}
