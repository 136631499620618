import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ContactUsPage } from './contact-us.page';
import { LoaderModule } from 'src/app/_shared/loader-component/loader.module';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    LoaderModule
  ],
  declarations: [ContactUsPage],
  exports: [ContactUsPage]
})
export class ContactUsPageModule { }
