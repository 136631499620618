import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { UserViewComponent } from '../user-view/user-view.component';
import { AlertService } from 'src/app/services/alert.service';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import * as moment from 'moment';

@Component({
    selector: 'app-location-network',
    templateUrl: './location-network.component.html'
  })
export class LocationNetworkComponent implements OnInit {

    isLoading: boolean = false;
    location: any;
    user: User;
    checkInsSubscription: Subscription;
    checkIns: any[] = [];
    checkedInUsers: any[] = [];

    constructor(
        private modalCtrl: ModalController,
        private usersService: UsersService,
        private bookingsService: BookingsService,
        private alert: AlertService,
        public router: Router
    ) {}

    ngOnInit() {
        this.isLoading = true;
        const currentDate = moment(Date.now()).format('DD/MM/YYYY');
        this.checkInsSubscription = this.bookingsService.getCheckIns(`entities/${config.entityId}/locations/${this.location.uid}`).subscribe((checkins: any) => {
            this.checkIns = checkins.filter(checkIn => { 
                checkIn.created = moment(new Date(checkIn.created.seconds * 1000)).format('DD/MM/YYYY');
                if (currentDate === checkIn.created && checkIn.createdBy !== this.user.uid) {
                    return checkIn
                }
            });
            if (this.checkIns.length > 0) {
                this.checkIns.forEach((checkedInUser, index, array) => {
                    this.usersService.getUser(checkedInUser.createdBy).subscribe(user => { 
                        const userDetails = {
                            refNo: user.data().refNo,
                            photoURL: user.data().photoURL,
                            uploadedPhoto: user.data().uploadedPhoto,
                            uploadedPhotoThumbnail: user.data().uploadedPhotoThumbnail,
                            industrySector: (user.data().industrySector) ? user.data().industrySector : '',
                            id: user.data().uid,
                            firstname: user.data().firstname,
                            surname: user.data().surname
                        }
                        this.checkedInUsers.push(userDetails);
                        if (this.checkedInUsers.length === array.length) {
                            this.isLoading = false;
                        }
                    });
                })
            } else {
                this.isLoading = false;
            }
        })
    }

    viewUser(userRef, userId, index) {
        const modalData = {
          title: `${userRef}`,
          userId: userId,
          type: '',
          userIndex: index
        }
        this.alert.openModal(UserViewComponent, modalData, 'modal-small');
    }

    openChat(conversationId, conversationName) {
        this.modalCtrl.dismiss();
        this.router.navigate([`/messages/conversation/create/${conversationId}`], { queryParams: { conversationName } });
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

}