import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { User } from "src/app/_shared/models/users.model";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionsService } from "src/app/services/transactions.service";
import { AlertService } from "src/app/services/alert.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/pages/auth/auth.service";
import { StorageService } from 'src/app/services/storage.service';
import * as queryString from 'query-string';

@Component({
  selector: "app-location-boost-payment",
  templateUrl: "./location-boost-payment.component.html",
})
export class LocationBoostPaymentComponent implements OnInit {

  isLoading = false;
  step = 1;
  location: any;
  extraData: any;
  isUpdating = false;
  isRedirecting = false;
  price: number = 1000;

  // USER
  user: User;

  // CARDS
  cardsForm: FormGroup;
  payer: any;
  userCardsSubscription: Subscription;
  userCards: any[];
  hideCardSelection: boolean = false;
  defaultCard: any;
  changeDefault: boolean = false;
  changedCardId: string;
  deletingCard: boolean = false;
  deletedCardSubscription: Subscription;

  // ADD CARDS
  addCardBrowser: any;
  pendingCardSubscription: Subscription;
  cardErrorMsg: any;
  pendingCardId: string;

  //currentPlatform
  currentPlatform: string;

  constructor(
    private auth: AuthenticationService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private alerService: AlertService,
    private transactionsService: TransactionsService,
    private iab: InAppBrowser,
    private alertService: AlertService,
    private router: Router,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.buildForm();
    this.extraData = {
      submit: true,
      type: "modal",
      data: {
        location: this.location,
      },
    };
    
    this.storageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })

    if (this.route.snapshot.queryParamMap.get('requestId')) {
      const requestParam = this.route.snapshot.queryParamMap.get('requestId');
      this.step = 2;
      this.watchPendingCard(requestParam);
    }

    this.auth.user.subscribe((userDetails) => {
      this.user = userDetails;
      // GET USER CARDS
      this.userCardsSubscription = this.transactionsService.getUserCards(this.user.uid).subscribe(cardsData => {
        this.userCards = cardsData.filter((card: any) => { if (card.cardToken) return card; });
        if (this.userCards.length > 0) {
          if (this.userCards.length === 1) {
            this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
              this.setDefaultCard();
            });
          } else {
            this.setDefaultCard();
          }
        }
        this.isLoading = false;
      });
    });
  }

  buildForm() {
    this.cardsForm = this.fb.group({
      card: [""],
      defaultCard: [""],
    });
  }

  onBillingUpdateComplete() {
    this.step = 2;
  }

  // CARDS
  setDefaultCard() {
    const setDefaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (setDefaultCard[0]) {
      this.cardsForm.get("card").patchValue(setDefaultCard[0].id);
      this.defaultCard = setDefaultCard[0];
    }
  }

  setCardAsDefault() {
    const setDefault = this.cardsForm.get("defaultCard").value;
    if (setDefault) {
      this.transactionsService
        .setDefaultCard(this.changedCardId, this.user.uid)
        .then(() => {
          this.alertService.displayToast(`Default card changed`);
          this.changeDefault = false;
        });
    }
  }

  onCardChange() {
    this.changedCardId = this.cardsForm.get("card").value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(
        (card) => card.id === this.changedCardId && card.default === true
      );
      if (!noChange) {
        this.changeDefault = true;
        this.cardsForm.get("defaultCard").reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  deleteCard(cardId) {
    this.deletingCard = true;
    this.transactionsService.deleteCard(cardId).then((data) => {
      this.deletedCardSubscription = this.transactionsService
        .getDeletedCard(data)
        .subscribe((card) => {
          if (!card) {
            this.deletingCard = false;
          }
        });
    });
  }

  addCard() {
    const requestId = (Date.now() / Math.random()).toFixed(0);
    let cardUrlParams;
    if (this.currentPlatform !== 'desktop') {
      cardUrlParams = `${environment.paymentUrl}?userId=${this.user.uid}&requestId=${requestId}`;
      this.addCardBrowser = this.iab.create(
        cardUrlParams,
        "_blank",
        "usewkwebview=yes,toolbar=no,location=no"
      );
      this.watchPendingCard(requestId);
      this.cardErrorMsg = "";
    } else {
      const parseReturn = {
        returnUrl: `${environment.websiteUrl}/location-boost/${this.location.uid}?requestId=${requestId}`
      }
      cardUrlParams = `${environment.paymentUrl}?${queryString.stringify(parseReturn)}&userId=${this.user.uid}&requestId=${requestId}`;
      window.location.href = cardUrlParams;
    }
  }

  watchPendingCard(requestId) {
    this.pendingCardSubscription = this.transactionsService
      .checkPendingCards(requestId)
      .subscribe((card: any) => {
        if (card.length > 0) {
          if (this.currentPlatform !== 'desktop') {
            this.addCardBrowser.close();
          }
          !Number.isInteger(card[0].cardStorageOutcome)
            ? this.displayCardAdded(card[0].cardStorageOutcome)
            : (this.cardErrorMsg = card[0].description);
          !Number.isInteger(card[0].cardStorageOutcome)
            ? (this.pendingCardId = card[0].id)
            : (this.pendingCardId = "");
        }
      });
  }

  displayCardAdded(outcome) {
    this.alerService.displayToast(outcome).then(() => {
      this.transactionsService.deletePendingCard(this.pendingCardId);
    });
  }

  makePayment() {
    const boostData = {
      createdBy: this.user.uid,
      createdByName: `${this.user.firstname} ${this.user.surname}`,
      paidBy: this.location.uid,
      paidByName: this.location.name,
      type: 'boostedAd',
      name: this.location.name,
      price: this.price,
      cardId: this.cardsForm.get('card').value
    }
    this.transactionsService.addTransaction(boostData, this.price, true, this.location.uid).then(transactionId => {
      if (transactionId) {
        this.isRedirecting = true;
        this.cardErrorMsg = '';
        this.router.navigate([`/payments-confirmation/${transactionId}`]);
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.userCardsSubscription.unsubscribe();
  }
}
