import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { NgPipesModule } from 'ngx-pipes';
import { LoaderModule } from '../loader-component/loader.module';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { PackagesListComponent } from './packages-list/packages-list.component';
import { BookingsCalendarComponent } from './bookings-calendar/bookings-calendar.component';
import { NgCalendarModule } from 'ionic2-calendar';
import { UpdateBillingInfoComponent } from './billing-info/update-billing-info.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { EventsListComponent } from './events-list/events-list.component';
import { BillingInfoModule } from './billing-info/billing-info.component.module';
import { CheckinsListComponent } from './checkins-list/checkins-list.component';
import { RatingsReviewComponent } from './ratings/ratings.component';
import { RatingsModule } from './ratings/ratings.module';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { UsersListComponent } from './users-list/users-list';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        NgPipesModule,
        FormsModule,
        LoaderModule,
        NgCalendarModule,
        NgPipesModule,
        ReactiveFormsModule,
        BillingInfoModule,
        RatingsModule
    ],
    declarations: [
        TransactionsListComponent,
        PackagesListComponent,
        BookingsCalendarComponent,
        TutorialComponent,
        EventsListComponent,
        CheckinsListComponent,
        NotificationsListComponent,
        UsersListComponent,
    ],
    exports: [
        TransactionsListComponent,
        PackagesListComponent,
        BookingsCalendarComponent,
        TutorialComponent,
        EventsListComponent,
        CheckinsListComponent,
        RatingsModule,
        NotificationsListComponent,
        UsersListComponent,
    ]
})
export class ComponentsModule { }