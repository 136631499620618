import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MeetingRoomsService } from '../_services/meeting-rooms.service';
import { ModalController, NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-meeting-rooms-locations-list',
  templateUrl: './meeting-rooms-locations-list.component.html'
})
export class MeetingRoomsLocationsListComponent implements OnInit {
  
  isLoading: boolean = false;
  locationId;
  location: any;
  meetingRooms: any[];
  meetingRoomsSubscription: Subscription;

  constructor(
    private meetingRoomsService: MeetingRoomsService,
    private auth: AuthenticationService,
    private modalCtrl: ModalController,
    private router: Router,
    private storage: Storage,
    private route: ActivatedRoute,
    private navCtrl: NavController
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.meetingRoomsSubscription = this.meetingRoomsService.getLocationMeetingRooms(this.locationId).subscribe((rooms: any) => {
      this.meetingRooms = rooms;
      this.storage.get('location').then((location) => {
        this.location = location;
      });
      this.isLoading = false;
    });
  }

  navigateToRoom(roomId, type) {
    if (type === 'edit') {
      this.router.navigate([`/meeting-rooms/${this.locationId}/edit/${roomId}`]);
    }
    if (type === 'view') {
      this.router.navigate([`/meeting-room/${roomId}`]);
    }
  }

  goBack() {
    this.navCtrl.back();
  }

}
