import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { config } from 'src/app/_shared/configs/config';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userId;
  firebaseId;
  entityId;

  constructor(
    private fireStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private auth: AuthenticationService
  ) {
    if (!this.userId) {
      this.auth.user.subscribe(user => {
        if (user) {
          this.userId = user.uid;
          this.firebaseId = user.firebaseId;
        }
      });
    }

    this.entityId = config.entityId;
  }

  // updateUserProfileThumbData(profileImageThumbData) {
  //   return this.fireStore
  //     .collection('users')
  //     .doc(this.userId)
  //     .set({ uploadPhotoThumbnail: profileImageThumbData }, { merge: true });
  // }

  // Save profile image URL
  async saveProfileImage(imageData) {
    return firebase
      .storage()
      .ref('/users/')
      .child(this.userId)
      .child('logo.png')
      .putString(imageData, 'base64', { contentType: 'image/png' })
      .then(() => {
        return firebase.storage().ref('/users/').child(this.userId).child('logo.png').getDownloadURL().then((url) => {
          const updateUserPhoto = this.fireStore
            .collection('users')
            .doc(this.userId)
            .set({ uploadedPhoto: url }, { merge: true });
          const updateUserRefPhoto = this.fireStore
            .collection('userRefs')
            .doc(this.firebaseId)
            .set({ photoURL: url }, { merge: true });
          return Promise.all([updateUserPhoto, updateUserRefPhoto]);
        });
      })
      .catch((error) => {
        console.log('Error uploading user profile image', error);
      });
  }

  saveUserToken(token) {
    const userRef = this.fireStore.doc(`/users/${this.userId}/tokens/${token}`).set({
      token: token
    }, { merge: true });

    const tokensRef = this.fireStore.doc(`entities/${this.entityId}/tokens/${token}`).set({
      token: token
    });

    return Promise.all([userRef, tokensRef]);
  }
}
