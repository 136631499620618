import { Component, OnInit, Input } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TeamsService } from '../_services/teams.service';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { StorageService } from 'src/app/services/storage.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'public-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['../teams.scss']
})
export class TeamsListComponent implements OnInit {
  previous;
  isLoading = false;
  user: User;
  profileRef = '/home';

  teams: any[] = [];
  userSubscription: Subscription;
  teamsSubscription: Subscription;

  constructor(
    private teamsService: TeamsService,
    private router: Router,
    private auth: AuthenticationService,
    private nav: NavController
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter()
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.userSubscription = this.auth.user.subscribe(user => {
      if (Object.keys(user).length > 0) {
        this.user = user;
        this.profileRef = `/profile`;
        this.teamsSubscription = this.teamsService.getTeamsForUser(user.uid).subscribe(teamsData => {
          this.teams = teamsData;
          console.log(this.teams)
          this.isLoading = false;
        });
      }
    });
  }

  goBack() {
    this.nav.back();
  }

  navigateToTeam(teamId, accepted, page) {
    if (accepted) {
      this.router.navigate([`/team/${page}/${teamId}`]);
    }
  }

  acceptInvite(team, value) {
    let acceptedValues;
    if (value === 'yes') acceptedValues = true;
    else if (value === 'no') acceptedValues = false;
    this.teamsService.acceptTeamInvite(team, this.user, acceptedValues);
  }

  ionViewDidLeave() {
    this.teamsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

}
