import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, AlertController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Camera } from '@ionic-native/camera/ngx';
import { TeamsService } from '../_services/teams.service';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { packages } from '../_models/packages.model';
import { productRenewal } from 'src/app/_shared/models/product-renewal';
import { AlertService } from 'src/app/services/alert.service';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from '../../../services/users.service';
import { UserInviteComponent } from 'src/app/_shared/components/modals/user-invite/user-invite.component';
import { PackageListComponent } from 'src/app/_shared/components/modals/package-list/package-list.component';
import { PackagePaymentComponent } from 'src/app/_shared/components/modals/package-payment/package-payment.component';
import { LocationSelectComponent } from 'src/app/_shared/components/modals/location-select/location-select.component';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { FilesService } from 'src/app/_shared/components/files/files.service';
import { config } from 'src/app/_shared/configs/config';
import { DomSanitizer } from '@angular/platform-browser';
import { MeetingRoomSelectComponent } from 'src/app/_shared/components/modals/meeting-room-select/meeting-room-select.component';
import { Chooser } from '@ionic-native/chooser/ngx';
import { StorageService } from 'src/app/services/storage.service';
import { MeetingRoomPaymentComponent } from 'src/app/_shared/components/modals/meeting-room-payment/meeting-room-payment.component';

@Component({
  selector: 'public-teams-edit',
  templateUrl: './teams-edit.component.html',
  styleUrls: ['../teams.scss']
})
export class TeamEditComponent implements OnInit {
  basicInfoForm: FormGroup;
  isLoading = false;
  user: User;
  team: any;
  teamId;
  userSubscription: Subscription;
  teamSubscription: Subscription;
  teamUsersSubscription: Subscription;
  teamUsers;
  teamOwner;
  currentUserTeam;

  extraData;

  // OWNER FORM
  userOwnerForm: FormGroup;
  addNewOwner = false;
  updatingOwner = false;
  isUserAllowed = false;

  // TEAM MEMBERS FORM
  teamUserForm: FormGroup;
  addMember = false;
  showInvite = false;
  addUserMessage: any = {
    color: '',
    text: ''
  };
  addedUser: User;
  addingNewUser = false;

  // TEAM IMAGES
  showImageSave = false;
  updatingImage: boolean = false;

  updateBillingInfo = false;
  updatingBasicInfo = false;

  // PRODUCTS
  productsSubscription: Subscription;
  userProducts: any[] = [];
  totalPrice = 0;
  discountPrice = 0;
  product: any;

  // SUBSCRIPTION
  teamSubscriptionPlans: Subscription;
  subscriptionPlan: any;
  subscription: any;

  // PACKAGES
  teamDetails: any = {};
  teamPackage: any;
  showPendingPackage: boolean = false;
  showPendingPackageInfo: boolean = false;
  showCurrentPackageInfo: boolean = false;
  packages = packages;
  renewDays = productRenewal;
  frequency: any;

  teamMenu = 'basicInfo';

  info: any;

  loadingSub: boolean = false;

  @ViewChild(IonContent, { static: true }) content: IonContent;

  // USER CSV UPLOAD
  csvUploaded: boolean;
  presentAlert: boolean = false;

  //currentPlatform
  currentPlatform:string;

  constructor(
    private route: ActivatedRoute,
    private teamsService: TeamsService,
    private auth: AuthenticationService,
    private subscriptionsService: SubscriptionsService,
    private alert: AlertService,
    private fb: FormBuilder,
    private usersService: UsersService,
    private cameraPlugin: Camera,
    private router: Router,
    public alertCtrl: AlertController,
    private filesService: FilesService,
    private file: File,
    public sanitizer: DomSanitizer,
    private base64: Base64,
    public platform: Platform,
    private chooser: Chooser,
    public storageServices: StorageService
  ) {
  }

  ngOnInit() {
    this.storageServices.currentPlatform.subscribe(platform =>{
      this.currentPlatform = platform;
    });

    
    if (this.route.snapshot.queryParamMap.get('team')) {
      this.changeTab('members');
      this.alert.openModal(MeetingRoomPaymentComponent, {}, '');
    }

    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.isLoading = true;
    this.buildForms();
    this.userSubscription = this.auth.user.subscribe(user => {
      this.user = user;
    });
    this.teamSubscription = this.teamsService.getTeam(this.teamId).subscribe(teamData => {
      this.team = teamData;
      
      this.csvUploaded = !!this.team.usersImported;
      if (this.team.usersImported) {
        if (this.team.usersImported.errorCount || this.team.usersImported.successCount) {
          if (!this.presentAlert) {
            this.presentCount(this.team.usersImported.errorCount, this.team.usersImported.successCount)
          }
        }
      }

      this.basicInfoForm.patchValue(this.team);
      this.updatingImage = false;

      this.extraData = {
        data: {
          team: this.team
        },
        type: 'page'
      };

      // TEAM PACKAGE
      this.teamSubscriptionPlans = this.subscriptionsService.getTeamSubscriptions(this.teamId).subscribe(subs => {
        this.subscriptionPlan = subs[0];
        if (this.subscriptionPlan) {
          this.loadingSub = this.subscriptionPlan.loadingSub;
          this.teamPackage = this.subscriptionPlan.product;
          // SET FREQUENCY
          const frequency = this.renewDays.filter((e) => { return e.value === this.subscriptionPlan.frequency; });
          this.frequency = frequency[0];
          if (this.subscriptionPlan.amount === 0) {
            this.subscriptionsService.updateSubscription(this.teamId, { status: 1 });
          }
        }
      });

      // GET TEAM USERS
      this.teamUsersSubscription = this.teamsService.getTeamUsers(this.teamId).subscribe(usersData => {
        this.teamUsers = usersData;
        if (this.csvUploaded) {
          this.addMember = false;
        }
        this.setTotalCost(this.teamUsers);
        if (this.team.owner) {
          const owner = this.teamUsers.filter((e) => e.uid === this.team.owner.uid);
          const userTeam = this.teamUsers.filter(e => e.uid === this.user.uid);
          this.currentUserTeam = userTeam[0];
          this.teamOwner = owner[0];
          this.userOwnerForm.get('owner').patchValue(this.teamOwner);
        } else {
          this.teamOwner = '';
        }
      });

      this.isLoading = false;
    });

    // GET SELECTED PRODUCT
    this.productsSubscription = this.alert.modalData.subscribe(product => {
      if (product) {
        if (Object.keys(product).length > 0) {
          console.log(product)
          this.setUserProduct(product);
        }
      }
    });
  }

  buildForms() {
    this.teamUserForm = this.fb.group({
      user: ['']
    });
    this.userOwnerForm = this.fb.group({
      owner: ['', [Validators.required]]
    });
    this.basicInfoForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  changeTab(tab) {
    this.teamMenu = tab;
  }

  viewUserProfile(userId, userIndex) {
    this.alert.openModal(UserViewComponent, { userId: userId, userIndex: userIndex }, 'modal-small');
  }

  // SEARCH USERS
  emailCheck(email) {
    this.usersService.emailCheck(email.toLowerCase()).then((snapShot) => {
      if (snapShot.empty) {
        this.addUserMessage = { color: 'danger', text: 'User does not exist' };
        this.showInvite = true;
        this.isUserAllowed = false;
        this.content.scrollToBottom();
      } else {
        this.addUserMessage = '';
        if (this.teamUsers.some(member => member.email === email)) {
          this.addUserMessage = { color: 'danger', text: 'User already added' };
          this.showInvite = false;
          this.isUserAllowed = false;
        } else {
          this.isUserAllowed = true;
          snapShot.forEach(snap => {
            this.addedUser = snap.data();
          });
          this.addUserMessage = { color: 'success', text: 'User exists and can be added' };
          this.showInvite = false;
        }
      }
    });
  }

  addUser(user) {
    if (user) {
      if (this.teamUsers.some(member => member.email === user.email)) {
        this.addUserMessage = 'User already added';
      } else {
        this.addingNewUser = true;
        user.invited = false;
        user.isStaff = true;
        user.isAdmin = false;
        user.isOwner = false;
        this.addUserMessage = '';
        this.teamsService.linkUsersAndTeams(user, this.team, this.teamId).then(() => {
          this.teamUserForm.get('user').reset();
          this.addingNewUser = false;
          this.isUserAllowed = false;
          this.addMember = false;
        });
      }
    } else {
      this.addUserMessage = 'Please add a valid user';
    }
  }

  onBillingUpdateComplete() {
    this.updateBillingInfo = false;
  }

  selectRoom() {
    this.alert.openModal(MeetingRoomSelectComponent, { user: this.user, teamUsers: this.teamUsers, team: this.team }, '');
  }

  // Take a picture using the phone camera
  takePicture() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.updatingImage = true;
          this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
            entry.file(file => {
              const imageFile = [file]
              const pathInfo = {
                filePath: `entities/${config.entityId}/teams/${this.teamId}`,
                dbPath: `entities/${config.entityId}/teams/${this.teamId}`
              };
              this.setImage(imageFile, pathInfo);
            });
          });
        },
        (error) => {
          console.log('ERROR TAKING PHOTO -> ' + JSON.stringify(error));
        }
      );
  }

  // Select a picture from the phone gallery
  selectPicture() {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.updatingImage = true;
          this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
            entry.file(file => {
              const imageFile = [file]
              const pathInfo = {
                filePath: `entities/${config.entityId}/teams/${this.teamId}`,
                dbPath: `entities/${config.entityId}/teams/${this.teamId}`
              };
              this.setImage(imageFile, pathInfo);
            });
          });
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  onFileSelectedFromWeb(event) {
    console.log("Incoming file", event[0]);
    const pathInfo = {
      filePath: `entities/${config.entityId}/teams/${this.teamId}`,
      dbPath: `entities/${config.entityId}/teams/${this.teamId}`
    };
    this.updatingImage = true;
    this.filesService.handleDrop(event, pathInfo);

  }

  importCsv(event?: any) {
    const saveRef = {
      filePath: `entities/${config.entityId}/teams/${this.teamId}`,
      uid: this.teamId,
      type: 'team'
    };
    if (this.platform.is('cordova')) {
      this.chooser.getFile('text/comma-separated-values')
      .then(data => {
        this.file.resolveLocalFilesystemUrl(data.uri).then((entry: FileEntry) => {
          entry.file(file => {
            this.uploadFile(saveRef, data.dataURI, 'app');
          });
        });
      })
      .catch((error: any) => console.error(error));
    } else {
      this.uploadFile(saveRef, event.target.files[0], 'web');
    }
  }

  uploadFile(saveRef, file, type) {
    this.csvUploaded = false;
    this.teamsService.updateTeam({usersImported: false}, this.teamId).then(() => {
      this.filesService.uploadDocument(saveRef, file, type);
    });
  }

  async presentCount(errorCount, successCount) {
    this.presentAlert = true;
    const totalImports = errorCount + successCount;
    const alert = await this.alertCtrl.create({
      header: 'CSV Import Status',
      message: `<b>${successCount}/${totalImports}</b> imports were successful`,
      buttons: [{
        text: 'Ok',
        handler: () => {
          this.presentAlert = false;
          this.teamsService.updateTeam({usersImported: true}, this.teamId);
        }
      }],
      backdropDismiss: false
    });
    await alert.present();
  }

  setImage(imagesArray, pathInfo) {
    this.filesService.handleDrop(imagesArray, pathInfo);
  }

  updateBasicInfo(formValues) {
    this.updatingBasicInfo = true;
    this.teamsService.updateTeam(formValues, this.teamId).then(() => {
      this.updatingBasicInfo = false;
      this.basicInfoForm.markAsPristine();
      this.alert.displayToast('Updated basic team information');
    });
  }

  openInviteModal() {
    const email = this.teamUserForm.get('user').value;
    this.alert.openModal(UserInviteComponent, { team: this.team, addedEmail: email }, '');
    this.showInvite = false;
    this.addMember = false;
    this.addUserMessage = '';
    this.teamUserForm.reset();
  }

  makeAdmin(user, value) {
    if (value) {
      user.isAdmin = true;
    } else {
      user.isAdmin = false;
    }
  }

  setOwner(owner) {
    this.updatingOwner = true;
    if (this.teamOwner) {
      // REMOVE OLD OWNER
      const updateValues = {
        isOwner: false
      }
      this.teamsService.updateTeamUser(this.teamOwner.uid, this.teamId, updateValues).then(() => {
        this.addOwner(owner);
      });
    } else {
      this.addOwner(owner);
    }
  }

  addOwner(owner) {
    // SET NEW OWNER IN TEAM
    const team = {
      owner: {
        firstname: owner.firstname,
        surname: owner.surname,
        email: owner.email,
        uid: owner.uid
      }
    };
    this.teamsService.updateTeam(team, this.teamId).then(() => {
      // SET USER TO OWNER
      const updateValues = {
        isOwner: true,
        isAdmin: true
      };
      this.teamsService.updateTeamUser(owner.uid, this.teamId, updateValues).then(() => {
        this.updatingOwner = false;
        this.addNewOwner = false;
      });
    });
  }

  resetOwnerForm() {
    this.userOwnerForm.reset();
    if (this.teamOwner) {
      this.userOwnerForm.get('owner').patchValue(this.teamOwner);
    }
  }

  // PRODUCTS
  addProductToUser(member, type) {
    let product = (member.product) ? member.product : '';
    if (member.pendingProduct) {
      product = (member.pendingProduct) ? member.pendingProduct : '';
    }
    let status;
    let updateValues;
    if (type === 'add') {

      this.alert.openModal(PackageListComponent, { team: this.team, user: member, subscription: product }, '');
    }
    if (type === 'delete') {
      status = member.product.status;
      updateValues = {
        product: ''
      };
      this.teamsService.updateTeamUser(member.uid, this.teamId, updateValues).then(() => {
        this.subscriptionsService.deleteUserTeamSubscription(member.uid, this.teamId);
        if (status === 2) {
          this.subscriptionsService.updateSubscription(this.subscriptionPlan.id, { amount: this.totalPrice });
        }
      });
    }
    if (type === 'deletePending') {
      updateValues = {
        pendingProduct: ''
      };
      this.teamsService.updateTeamUser(member.uid, this.teamId, updateValues);
    }
  }

  onAddToCart() {
    let oldSubscription = (this.subscriptionPlan) ? this.subscriptionPlan : undefined;
    this.alert.openModal(PackagePaymentComponent, {
      product: this.product,
      extraData: {
        data: {
          team: this.team,
          teamUsers: this.userProducts,
          payerInfo: this.user,
          oldSubscription: oldSubscription
        }
      },
    }, '');
  }

  onUpdate() {

  }

  setTotalCost(users) {
    let total = 0;
    this.discountPrice = 0;
    let totalDiscount = 0;

    this.userProducts = users.filter((e) => {
      if (!e.pendingProduct) {
        if (e.product) { return e.product; }
      } else {
        if (e.pendingProduct) { return e.pendingProduct; }
      }
    });
    this.showPendingPackage = users.some(user => {
      if (!user.pendingProduct) {
        if (user.product) { return user.product.status === 1; }
      } else {
        if (user.pendingProduct) { return user.pendingProduct.status === 1; }
      }
    });
    this.userProducts.forEach(user => {
      if (!user.pendingProduct) {
        total += user.product.amount;
      } else {
        total += user.pendingProduct.amount;
      }
    });

    let productInfo = {
      product: '1 Member',
      refNo: 'TEAMPKG',
    }
    if (this.userProducts.length > 1 && this.userProducts.length < 6) {
      this.discountPrice = 10;
      productInfo = {
        product: '2-5 Members',
        refNo: 'TEAMPKG1',
      }
    } else if (this.userProducts.length > 5 && this.userProducts.length < 11) {
      this.discountPrice = 12;
      productInfo = {
        product: '6-10 Members',
        refNo: 'TEAMPKG2',
      }
    } else if (this.userProducts.length > 10 && this.userProducts.length < 16) {
      this.discountPrice = 13;
      productInfo = {
        product: '11-15 Members',
        refNo: 'TEAMPKG3',
      }
    } else if (this.userProducts.length > 15 && this.userProducts.length < 26) {
      this.discountPrice = 15;
      productInfo = {
        product: '16-25 Members',
        refNo: 'TEAMPKG4',
      }
    } else if (this.userProducts.length > 25) {
      this.discountPrice = 20;
      productInfo = {
        product: '25+ Members',
        refNo: 'TEAMPKG5',
      }
    }

    totalDiscount = Math.round(total * this.discountPrice - 0.5) / 100;
    this.totalPrice = total - totalDiscount;
    this.product = {
      title: productInfo.product,
      refNo: productInfo.refNo,
      amount: this.totalPrice,
      subMembers: this.userProducts.length
    };

    if (this.route.snapshot.queryParamMap.get('product')) {
      this.changeTab('members')
      this.showPendingPackageInfo = true;
      this.onAddToCart();
    }
  }

  setUserProduct(userProduct) {
    let updateValues;
    let userProductExists = this.teamUsers.some(user => {
      if (userProduct.user.uid === user.uid) {
        if (user.product) { return user.product.status === 2; }
      }
    });
    if (userProductExists) {
      updateValues = {
        pendingProduct: {
          active: true,
          productRef: userProduct.product.refNo,
          amount: userProduct.product.regularPrice,
          product: userProduct.product.title,
          productId: userProduct.product.uid,
          status: 1
        }
      }
    }
    if (!userProductExists) {
      updateValues = {
        product: {
          active: true,
          productRef: userProduct.product.refNo,
          amount: userProduct.product.regularPrice,
          product: userProduct.product.title,
          productId: userProduct.product.uid,
          status: 1
        }
      }
    }
    this.teamsService.updateTeamUser(userProduct.user.uid, this.teamId, updateValues).then(() => {
      this.alert.modalData.next('');
    });
  }

  updateTeamUser(member, type, value?) {
    let updateValues;
    if (type === 'remove') {
      const deletedMember = member;
      this.teamsService.deleteTeamUser(member.uid, this.teamId, this.team.name).then(() => {
        this.subscriptionsService.deleteUserTeamSubscription(deletedMember.uid, this.teamId);
        if (deletedMember.product.status === 2) {
          this.subscriptionsService.updateSubscription(this.subscriptionPlan.id, { amount: this.totalPrice });
        }
      });
    }
    if (type === 'update') {
      updateValues = {
        isAdmin: value
      };
      this.teamsService.updateTeamUser(member.uid, this.teamId, updateValues);
    }
  }

  async presentConfirmRemove(member, type) {
    const alert = await this.alertCtrl.create({
      // header: 'Confirm!',
      message: 'Are you sure you?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: 'Yes',
        handler: () => {
          this.updateTeamUser(member, type);
        }
      }]
    });

    this.alertCtrl.dismiss();
    await alert.present();
  }

  createUserBooking(user) {
    this.alert.openModal(LocationSelectComponent, { user: user, bookingUser: this.user }, '');
  }

  navigateToTeamPage(route) {
    this.router.navigate([route]);
  }

  ionViewDidLeave() {
    this.teamSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.teamSubscriptionPlans.unsubscribe();
    this.teamUsersSubscription.unsubscribe();
    this.productsSubscription.unsubscribe();
  }
}
