import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'unverified-user-component',
  templateUrl: './unverified-user.component.html'
})
export class UnverifiedUserComponent implements OnInit {
  user: any;
  isLoadingButton = false;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      console.log('user for testing ', user);
      if (user) {
        this.user = user;
        if (user.verified) {
          console.log('user verified');
          return this.modalCtrl.dismiss();
        }
      }
    });
  }

  sendVerificationEmail() {
    this.isLoadingButton = true;
    this.auth.sendEmailVerificationRequest(this.user).then(() => {
      this.alertService.displayToast('Verification request email sent');
      this.isLoadingButton = false;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
