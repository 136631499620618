import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/_shared/configs/config';
import { Subject, Subscription } from 'rxjs';
import { RatingService } from 'src/app/services/ratings.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsReviewComponent implements OnInit {

  @Input() ratedUser: any;
  @Input() ratedUserIndex: number = 0;
  @Input() ratedLocation: any;
  @Input() locationId: any;
  @Output() ratingOutput = new EventEmitter;

  user: any;
  userSubscription: Subscription;

  ratingsRef: any;

  ratingsSubscription: Subscription;
  ratings: any;
  avgRating: number;
  ratingValue: number = 0;

  ratingId: string;
  ratingType: string;

  currentUser: any;

  test: any;

  constructor(
    private ratingsService: RatingService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.userDetails;
    if (this.ratedUser) {
      this.ratingsRef = `/users/${this.ratedUser.uid}/entities/${config.entityId}/ratings`;
      this.ratingId = this.ratedUser.uid;
      this.ratingType = 'user';
    }
    if (this.ratedLocation) {
      this.ratingsRef = `entities/${config.entityId}/locations/${this.ratedLocation.uid}/ratings`;
      this.ratingId = this.ratedLocation.uid;
      this.ratingType = 'location';
    }

    this.ratingsSubscription = this.ratingsService.getRatingStars(this.ratingsRef).subscribe((stars: any) => {
      this.ratings = stars;
      const rater = this.ratings.filter(rating => {
        if (rating.ratedByType === 'user') {
          if (rating.ratedBy === this.currentUser.uid) return rating;
        }
        if (rating.ratedByType === 'location') {
          if (rating.ratedBy === this.locationId) return rating;
        }
      });
      if (rater.length > 0) {
        this.ratingValue = rater[0].rating;
        this.ratingOutput.emit(this.ratingValue);
      }
      this.avgRating = this.ratings.reduce((sum, { rating }) => sum + rating, 0) / this.ratings.length;
      if (this.ratedLocation) {
        this.ratingsService.setLocationAvgRating(this.ratedLocation.uid, +this.avgRating.toFixed(1));
      }
    });
  }

  logRatingChange(rating) {
    this.ratingsService.addRating(rating, this.ratingsRef, this.ratingType, this.locationId);
    this.ratingOutput.emit(rating);
    // do your stuff
  }

  starHandler(rating) {
    this.ratingsService.addRating(rating, this.ratingsRef, this.ratingType, this.locationId);
  }


}
