import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AuthenticationService } from '../auth.service';
import { AlertController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
// import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['../auth.scss']
})
export class SignupPage implements OnInit {
  signupForm: FormGroup;
  passwordMissmatch = false;
  loading = false;
  scannedData: any;
  // barcodeScannerOptions: BarcodeScannerOptions;

  passwordInput = {
    show: false,
    type: 'password',
    icon: 'eye'
  };
  confirmPasswordInput = {
    show: false,
    type: 'password',
    icon: 'eye'
  };

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private alertService: AlertService
    // private barcodeScanner: BarcodeScanner

  ) { }

  ngOnInit() {
    this.buildForm();

    // this.barcodeScannerOptions = {
    //   showTorchButton: true,
    //   showFlipCameraButton: true
    // };
  }

  buildForm() {
    this.signupForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required, this.isEqualTo('password')]],
      refCode: ['']
    });
  }

  isEqualTo(field): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root.value[field] === control.value) {
        this.passwordMissmatch = false;
        return null;
      } else {
        this.passwordMissmatch = true;
        return { passwordMismatch: { value: 'passwordMismatch' } };
      }
    };
  }

  scanCode() {
    console.log('scanning qr code');
    // this.barcodeScanner
    //   .scan()
    //   .then(barcodeData => {
    //     alert('Barcode data ' + JSON.stringify(barcodeData));
    //     this.scannedData = JSON.stringify(barcodeData);
    //     this.signupForm.get('refCode').patchValue(this.scannedData.text);
    //   })
    //   .catch(err => {
    //     console.log('Error', err);
    //   });
  }

  signupUser(signupForm) {
    if (signupForm.valid) {
      this.loading = true;
      this.auth.signUpNewUser(signupForm.value).then(() => {
        this.loading = false;
        this.signupForm.reset();
        this.router.navigate(['/home']);
      }).catch(async error => {
        this.loading = false;
        // this.signupForm.reset();
        this.alertService.displayAlert('Error Signing Up', error.message, [
          {
            text: 'Ok',
            role: 'cancel'
          }
        ])
      });
    }
  }

  showPassword(value) {
    console.log('show password click ', value);
    if (value === 1) {
      this.passwordInput.show = !this.passwordInput.show;
      if (!this.passwordInput.show) {
        this.passwordInput.icon = 'eye';
        this.passwordInput.type = 'password';
      } else {
        this.passwordInput.icon = 'eye-off';
        this.passwordInput.type = 'text';
      }
    }

    if (value === 2) {
      this.confirmPasswordInput.show = !this.confirmPasswordInput.show;
      if (!this.confirmPasswordInput.show) {
        this.confirmPasswordInput.icon = 'eye';
        this.confirmPasswordInput.type = 'password';
      } else {
        this.confirmPasswordInput.icon = 'eye-off';
        this.confirmPasswordInput.type = 'text';
      }
    }
  }
}
