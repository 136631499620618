import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { StorageService } from 'src/app/services/storage.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-location-bookings-list',
  templateUrl: './location-bookings.component.html'
})
export class LocationBookingsComponent implements OnInit {

  locationId: string;
  isLoading = false;
  user: User;

  constructor(
    private auth: AuthenticationService,
    private nav: NavController,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.locationId = this.route.snapshot.paramMap.get('locationId');
  }

  goBack() {
    this.nav.pop();
  }

}