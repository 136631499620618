import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/_shared/models/users.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RatingService } from 'src/app/services/ratings.service';
import { AlertService } from 'src/app/services/alert.service';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { ReportingModalComponent } from '../reporting-modal/reporting-modal.component';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  source: any;
  loading = false;
  user: any;
  userId;
  userSubscription: Subscription;
  submittingComment = false;
  commentForm: FormGroup;
  starRating: number;
  checkIn: any;
  hideComment = false;

  locationId: string;
  ratedUserIndex: number;

  // LOGGED IN USER
  userDetails: User;

  constructor(
    private modalCtrl: ModalController,
    private usersService: UsersService,
    private fb: FormBuilder,
    private ratingService: RatingService,
    private alert: AlertService,
    private bookingsService: BookingsService,
    private auth: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.loading = true;

    this.usersService.fetchUserDetailsForView(this.userId).subscribe((user: any) => {
      if (user) {
        this.user = user;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });

    if (this.source === 'locationCheckins') {
      this.commentForm = this.fb.group({
        comment: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(300)]]
      });
    }

    if (this.checkIn) {
      if (this.checkIn.id) {
        this.bookingsService.getCheckinData(this.locationId, this.checkIn.id).subscribe((checkin: any) => {
          this.hideComment = checkin.status === 1 ? false : true;
        });
      }
    }
    
    // GET LOGGED IN USER
    this.auth.user.subscribe(authUser => {
      if (authUser) {
        this.userDetails = authUser;
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  reportMember(member) {
    this.modalCtrl.dismiss();
    this.alert.openModal(ReportingModalComponent, { member, user: this.userDetails, reportType: 'user' }, 'modal-small');
  }

  openChat() {
    console.log('opening chat with user ', this.user.uid);
  }

  getRatingValue(event) {
    this.starRating = event;
  }

  submitComment(comment) {
    this.submittingComment = true;
    this.ratingService.addComment(this.locationId, this.userId, comment, this.starRating, 'location').then(() => {
      this.bookingsService.markCheckinAsReviewed(this.locationId, this.checkIn.id);
      this.submittingComment = false;
      this.alert.displayToast('User review submitted');
      this.modalCtrl.dismiss();
    });
  }

}
