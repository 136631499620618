import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'boostItems'})
export class BoostedItemsPipe implements PipeTransform {
  transform(
    items: any[],
    regionSearch?: string,
    ): any[] {
    if (!items) {
      return [];
    }
    
    const boostedItems = items.sort((location) => {
      if (!location.boosted && regionSearch !== location.address.region) {
        return 1;
      } else if (location.boosted && regionSearch === location.address.region) {
        return -1 - Math.random();
      } else {
        return 0;
      }
    })
    
    return boostedItems
    
  }
}