import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from '../pages/auth/auth.service';

@Injectable()
export class MessagesService {
  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;
  defaultWSUser: string = 'Qvjl4K2ffO4qW1SUZUAF';

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private afAuth: AngularFireAuth,
    private auth: AuthenticationService
  ) {}

  getConversationsForUser(userId) {
    const userConversations = this.afs.collection(
      `users/${userId}/entities/wealthspaces-sa/conversations`,
      (ref) => ref.orderBy("lastMessageDate", "desc")
    );
    return userConversations.valueChanges({ idField: "id" });
  }

  getMessagesForConversation(userId, convoId) {
    return this.afs
      .collection(
        `users/${userId}/entities/wealthspaces-sa/conversations/${convoId}/messages`,
        (ref) => ref.orderBy("created", "asc")
      )
      .valueChanges({ idField: "id" });
  }

  createDefaultConversation(userId, convoId, locationId?) {
    if (userId && convoId)
    {
      const conversationsCollection = this.afs.doc(`users/${userId}/entities/wealthspaces-sa/conversations/${convoId}`);
      return conversationsCollection.ref.get().then((conversationDetails) => {
        if (conversationDetails.exists) {
          return Promise.resolve();
         } else {
          return this.createConversation(userId, convoId, (locationId) ? locationId : null).then(() => {
            return Promise.resolve();
          });
        }
      });
    }
    else
    {
      return Promise.reject("userId or convoId is blank : "+userId+" - "+convoId);
    }
    
  }

  addMessageToConversation(userId, convoId, message) {
    return this.afs
      .collection(
        `users/${userId}/entities/wealthspaces-sa/conversations/${convoId}/messages`
      )
      .add(message);
  }

  createConversation(currentUserId, guestUserId, locationId?) {
    if (locationId) {
      // IF LOCATION
      const locationRef = this.afs.doc(`/entities/wealthspaces-sa/locations/${locationId}`).ref;

      return locationRef.get()
      .then((location) => {
        const locationData = location.data();

        let imageUrl = "/assets/images/icon/WS-ICON.png";
        if (locationData.uploadedPhotoThumbnail) {
          imageUrl = locationData.uploadedPhotoThumbnail;
        }

        // CREATE LOCATION USER
        const locationUserRef = this.afs.doc(`users/${guestUserId}`);

        return locationUserRef.set({
          firstname: locationData.name,
          surname: '',
          photoURL: imageUrl
        }, { merge: true })
        .then(() => {
          // CREATE CONVERSATION
          const conversationDetails = {
            created: new Date(),
            createdById: currentUserId,
            name: locationData.name,
            image: imageUrl,
            lastMessageDate : new Date()
          };
          const userConvoRef = `users/${currentUserId}/entities/wealthspaces-sa/conversations/${guestUserId}`;
          return this.afs
            .doc(userConvoRef)
            .set(conversationDetails, { merge: true })
            .then((ref) => {
              return this.afs.collection(userConvoRef + "/messages").add({
                created: new Date(),
                createdById: currentUserId,
                type: 'system',
                text: `${this.auth.userDetails.firstname} started a conversation`,
                active: true,
              });
            });
        });
      });
    } else {
      // IF USER
      const userRef = this.afs.doc(`users/${guestUserId}`).ref;

      return userRef.get()
      .then((guestUserDetailsData) => {
        const guestUserDetails = guestUserDetailsData.data();
        let imageUrl = "/assets/images/icon/WS-ICON.png";
        console.log('here')
        if (guestUserDetails.photoURL || guestUserDetails.uploadedPhotoThumbnail) {
          imageUrl = (guestUserDetails.uploadedPhotoThumbnail) ? guestUserDetails.uploadedPhotoThumbnail : guestUserDetails.photoURL;
        }
        const conversationDetails = {
          created: new Date(),
          createdById: currentUserId,
          name: guestUserDetails.firstname + " " + guestUserDetails.surname,
          image: imageUrl,
          lastMessageDate : new Date()
        };
        const userConvoRef = `users/${currentUserId}/entities/wealthspaces-sa/conversations/${guestUserId}`;
        return this.afs
          .doc(userConvoRef)
          .set(conversationDetails, { merge: true })
          .then((ref) => {
            return this.afs.collection(userConvoRef + "/messages").add({
              created: new Date(),
              createdById: currentUserId,
              type: 'system',
              text: `${this.auth.userDetails.firstname} started a conversation`,
              active: true,
            });
          });
      });      
    }
  }

  getUnreadConversationsForUser(userId) {
    const userConversations = this.afs.collection(
      `users/${userId}/entities/wealthspaces-sa/conversations`,
      (ref) => ref.where("lastMessageRead", "==", false)
    );
    return userConversations.valueChanges({ idField: "id" });
  }

  updateConversationToRead(userId, conversationId) {
    const userConversation = this.afs.doc(`users/${userId}/entities/wealthspaces-sa/conversations/${conversationId}`);
    return userConversation.set({
      lastMessageRead: true
    }, {merge: true})
  }
}
