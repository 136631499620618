import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { User } from 'src/app/_shared/models/users.model';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { UsersService } from 'src/app/services/users.service';
import { AlertService } from 'src/app/services/alert.service';
import { TeamsService } from 'src/app/pages/teams/_services/teams.service';
import { LocationsService } from 'src/app/pages/location/_services/locations.service';

@Component({
  selector: 'app-update-billing-info',
  templateUrl: './update-billing-info.component.html'
})
export class UpdateBillingInfoComponent implements OnInit {

  updateForm: FormGroup;
  user: User;
  team: any;
  location: any;
  type = 'modal';
  teamUsers: any;
  isUpdating = false;
  showLegal = false;

  isLoading = false;
  provinces = Provinces;

  @Output() updateComplete = new EventEmitter<boolean>();
  @Input() extraData: any;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private alerService: AlertService,
    private teamsService: TeamsService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    this.user = this.extraData.data.user;
    this.team = this.extraData.data.team;
    this.location = this.extraData.data.location;
    if (this.extraData.type) {
      this.type = this.extraData.type;
    }
    this.buildForms();
    if (this.user) {
      if (this.user.billingInfo) {
        this.updateForm.patchValue(this.user);
        this.updateForm.markAsDirty();
      }
    }
    if (this.team) {
      this.teamUsers = this.extraData.data.teamUsers;
      this.showLegal = true;
      if (this.team.billingInfo) {
        this.updateForm.patchValue(this.team);
      }
    }
    if (this.location) {
      this.showLegal = true;
      if (this.location.billingInfo) {
        this.updateForm.patchValue(this.location);
      }
    }
  }

  buildForms() {
    if (this.user) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: ['']
        })
      });
    }

    if (this.team || this.location) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: [''],
          legalStatus: ['', [Validators.required]]
        })
      });
    }
  }

  setLocationInfo() {
    const billingInfo = this.updateForm.get('billingInfo');
    const billingAddress = billingInfo.get('billingAddress');
    billingInfo.get('billingName').patchValue(`${this.location.owner.firstname} ${this.location.owner.surname}`);
    billingInfo.get('billingEmail').patchValue(this.location.owner.email);
    billingAddress.get('streetAddress').patchValue(this.location.address.address);
    billingAddress.get('city').patchValue(this.location.address.city);
    billingAddress.get('postalCode').patchValue(this.location.address.postalCode);
    billingAddress.get('country').patchValue(this.location.address.country);
    billingAddress.get('region').patchValue(this.location.address.region);
  }

  onUpdate(editForm) {
    this.isUpdating = true;
    let updateInfo;
    if (this.updateForm.valueChanges) {
      if (this.user) {
        updateInfo = this.user;
        updateInfo.billingInfo = this.updateForm.get('billingInfo').value;
        this.usersService
          .updateUser(updateInfo)
          .then(() => {
            this.isUpdating = false;
            this.alerService.displayToast('Billing info updated');
            this.updateHistory(editForm);
            this.updateForm.markAsPristine();
            this.updateForm.markAsUntouched();
            this.updateComplete.emit(true);
          })
          .catch(err => {
            this.isUpdating = false;
            this.alerService.displayToast(`Error updating billing info: ${err}`);
          });
      }
      if (this.team) {
        updateInfo = {
          billingInfo: this.updateForm.get('billingInfo').value
        };
        this.teamsService
          .updateTeam(updateInfo, this.team.uid)
          .then(() => {
            this.isUpdating = false;
            this.alerService.displayToast('Billing info updated');
            this.updateForm.markAsPristine();
            this.updateForm.markAsUntouched();
            this.updateComplete.emit(true);
          })
          .catch(err => {
            this.isUpdating = false;
            this.alerService.displayToast(`Error updating billing info: ${err}`);
          });
      }
      if (this.location) {
        updateInfo = {
          billingInfo: this.updateForm.get('billingInfo').value
        };
        this.locationsService
          .updateLocation(this.location.uid, updateInfo)
          .then(() => {
            this.isUpdating = false;
            this.alerService.displayToast('Billing info updated');
            this.updateForm.markAsPristine();
            this.updateForm.markAsUntouched();
            this.updateComplete.emit(true);
          })
          .catch(err => {
            this.isUpdating = false;
            this.alerService.displayToast(`Error updating billing info: ${err}`);
          });
      }
    } else {
      this.isUpdating = false;
    }
  }

  updateHistory(editForm) {
    // UPDATE USER HISTORY
    const handleFormData = (form: FormGroup | FormArray) => new Promise((resolve, reject) => {
      let changedArray = [];
      if (form.dirty) {
        for (let i in form.controls) {
          if (form.controls[i] instanceof FormControl && form.controls[i].dirty) {
            changedArray.push({ field: i, value: form.controls[i].value });
          }
        }
        resolve(changedArray);
      } else {
        reject('No changes detected');
      }
    });
    return handleFormData(editForm).then((changedFieldsArray) => {

      return this.usersService.addHistoryLogToUser(changedFieldsArray, this.user.uid);
    }).catch((err) => {
      // this.toastr.error(err);
      return err;
    });
  }

  ionViewDidLeave() {
    this.updateForm.reset();
  }



}
