import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { NavController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationsService } from '../../_services/locations.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocationBoostPaymentComponent } from 'src/app/_shared/components/modals/location-boost-payment/location-boost-payment.component';

@Component({
  selector: 'app-location-edit-boosted',
  templateUrl: './location-boost.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditBoostPage implements OnInit {

  isLoading = false;
  locationId;
  location: any;
  locationsSubscription: Subscription;
  locations: any[];
  allowBoosted: boolean;

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private modalCtrl: ModalController,
    private alert: AlertService,
    private locationsService: LocationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.storage.get('location').then((location) => {
      if (location) {
        this.location = location;
        if (this.location.boostedEndDate) {
          this.location.boostedEndDate = new Date(this.location.boostedEndDate.seconds * 1000);
        }

        this.locationsSubscription = this.locationsService.getBoostedLocations(this.location.address.region).subscribe(locationsData => {
          this.locations = locationsData;
          this.allowBoosted = (this.locations.length >= 5) ? false : true;
          if (this.route.snapshot.queryParamMap.get('requestId') && this.allowBoosted) {
            this.openPaymentModal();
          }
          this.isLoading = false;
        });
      }
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  async openPaymentModal() {
    this.alert.openModal(LocationBoostPaymentComponent, { location: this.location }, '');
  }

  notifyLocation() {
    this.locationsService.notifyLocation(this.location).then(() => {
      this.router.navigate([`/location-edit/${this.locationId}`])
      this.alert.displayToast('Notfication sent.');
    });
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.locationsSubscription.unsubscribe();
  }

}
