import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-teams-transactions-list',
  templateUrl: './teams-transactions.component.html'
})
export class TeamsTransactionsComponent implements OnInit {
  
  teamId: string;

  isLoading = false;
  transactions$: Observable<Array<{}>>;

  transactionsRef: string;
  transaction: any;

  constructor(
    private transactionsService: TransactionsService,
    private nav: NavController,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.transactionsRef = `entities/${config.entityId}/teams/${this.teamId}`;
    this.fetchTransactions();
    this.isLoading = false;
  }

  fetchTransactions() {
    this.transactions$ = this.transactionsService.getTransactions(this.transactionsRef);
  }

  getTransaction(event) {
    console.log(event)
  }

  goBack() {
    this.nav.back();
  }

}