import { Injectable } from '@angular/core';
import { AlertController, ToastController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  // SINGLE LOCATION
  public modalData = new BehaviorSubject<any>({});

  constructor(
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController
  ) {
  }

  async displayAlert(headerText: string, messageText: string, buttonsObject) {
    const alert = await this.alertCtrl.create({
      header: headerText,
      message: messageText,
      buttons: buttonsObject
    });
    return alert.present();
  }

  async displayToast(messageText: string) {
    const toast = await this.toastCtrl.create({
      message: messageText,
      duration: 2000,
      color: 'light',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    return toast.present();
  }

  async openModal(comp, compProps, modalClass) {
    const modal = await this.modalCtrl.create({
      component: comp,
      componentProps: compProps,
      cssClass: modalClass,
    });
    modal.onDidDismiss()
      .then((data) => {
        this.modalData.next(data['data']);
    });

    return await modal.present();
  }

}
