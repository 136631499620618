import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../auth.service';
import { Router } from '@angular/router';
import { MenuController, AlertController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['../auth.scss']
})
export class LoginPage implements OnInit {
  
  loginForm: FormGroup;
  loading = false;
  passwordInput = {
    show: false,
    icon: 'eye',
    type: 'password'
  };

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    public menuCtrl: MenuController,
    private alertService: AlertService,
    private usersService: UsersService
  ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  loginUser(user) {
    this.loading = true;
    this.auth.loginUser(user.value.email, user.value.password).then(userData => {
      this.loading = false;
      this.loginForm.reset();
      this.usersService.checkUserSuspended();
      this.router.navigate(['/home'], { queryParams: { type: 'login' } });
    }).catch(async error => {
      this.loading = false;
      console.log('error logging in ', error);
      this.loginForm.get('password').reset();
      this.alertService.displayAlert('Login Failed', 'Your login details are incorrect, please try again.', [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]);
    });

  }

  showPassword() {
    console.log('show password input click ', this.showPassword);
    this.passwordInput.show = !this.passwordInput.show;
    if (!this.passwordInput.show) {
      this.passwordInput.icon = 'eye';
      this.passwordInput.type = 'password';
    } else {
      this.passwordInput.icon = 'eye-off';
      this.passwordInput.type = 'text';
    }
  }
}
