import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/_shared/models/users.model';

@Component({
  selector: 'app-calendar-event-edit-component',
  templateUrl: './calendar-event-edit.component.html'
})
export class CalendarEventEditComponent implements OnInit {
  
  event: any;
  bookingType: any;
  type: any;

  // USER
  bookingUserInfo: User;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.bookingUserInfo = this.event.booking.user;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `<span class="badge badge-warning">Pending</span>`;
        break;
      case 2:
        return `<span class="badge badge-success">Confirmed</span>`;
        break;
      case 3:
        return `<span class="badge badge-danger">Cancelled</span>`;
        break;
      default:
    }
  }

}
