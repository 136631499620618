import { Component, OnInit, Input, ViewChild, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader, ZoomControlOptions, ControlPosition, ZoomControlStyle } from '@agm/core';
import { Subscription } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { mapStyles } from '../_models/styles.model';
import { StorageService } from 'src/app/services/storage.service';
import { IonSlides, NavController, Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AlertService } from 'src/app/services/alert.service';
import { LocationBookingComponent } from '../../modals/location-booking/location-booking.component';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { CheckinService } from 'src/app/services/checkin.service';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { LocationNetworkComponent } from '../../modals/location-network/location-network.component';

declare var google;

@Component({
  selector: 'app-maps-locations',
  templateUrl: './maps-locations.component.html',
  styleUrls: ['../maps.scss']
})
export class MapsLocationsComponent implements OnInit, OnChanges, OnDestroy {
  loading = false;
  user: User;

  @Input() mapSettings: any;
  locationsSubscription: Subscription;
  positionSubscription: Subscription;
  locations: any[];

  // google maps zoom level
  zoom = 5;
  currentPosition: any;

  // initial center position for the map
  defaultLat = -26.195246;
  defaultLng = 25.034088;
  lat;
  lng;
  zoomToUserPosition = true;

  zoomControlOptions: ZoomControlOptions = {
    position: ControlPosition.RIGHT_BOTTOM,
    style: ZoomControlStyle.SMALL
  };

  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  sliderOne: any;
  slideOptsOne = {
    slidesPerView: 1,
    autoplay: false,
    pagination: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

  };
  activeLocation: any;

  imagesLoaded = false;

  mapHeight = 'calc(100vh - 51px)';
  markers = [];
  myLocationMarker: any;
  mapStyles = mapStyles;
  previous;
  showUserLocation = false;

  openedWindow: number;

  // USER REGION
  @Output() userRegion = new EventEmitter<any>();
  userLocationRegion: any;

  //CurrentPlatform
  currentPlatform: string;

  mobileView: boolean;

  userLat;
  userLng;

  constructor(
    private storageService: StorageService,
    private androidPerm: AndroidPermissions,
    private locationAccuracy: LocationAccuracy,
    private geolocation: Geolocation,
    private platform: Platform,
    private alertService: AlertService,
    private auth: AuthenticationService,
    private checkinService: CheckinService,
    private mapsAPILoader: MapsAPILoader,
    private bookingsService: BookingsService,
    public navCtrl: NavController
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.zoomToUserPosition = true;
    await this.getCurrentPosition();
    this.lat = this.defaultLat;
    this.lng = this.defaultLng;
    this.myLocationMarker = {
      icon: '/assets/images/locations/ws-user-location.png',
      name: 'My Location',
      address: '',
      logo: '',
      url: ``,
      slug: '',
      id: null,
      isOpen: false
    };

    this.storageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })

    this.storageService.pubMobileView.subscribe(mobilev => {
      this.mobileView = mobilev;
    })

    this.storageService.userLocation.subscribe(position => {
      if (position.coords && this.zoomToUserPosition) {
        this.myLocationMarker.lat = position.coords.latitude;
        this.myLocationMarker.lng = position.coords.longitude;
        this.userLat = position.coords.latitude;
        this.userLng = position.coords.longitude;
        this.zoom = 13;
        this.zoomToUserPosition = false;
        this.getUserRegion()
      }
    });

    this.locationsSubscription = this.storageService.mapLocations.subscribe(locationsData => {
      this.locations = locationsData;
      this.markers = [];

      if (locationsData.length === 0) {
        this.loading = false;
      }

      for (const location of Object.keys(this.locations)) {
        const featureImage = (this.locations[location].featureImage) ? this.locations[location].featureImage : '/assets/images/locations/feature-img.jpg';
        const logo = (this.locations[location].uploadedPhoto) ? this.locations[location].uploadedPhoto : '/assets/images/locations/location_profile.jpg';
        const custom = (this.locations[location].uploadedPhoto) ? true : false;
        const icon = (this.locations[location].isOpen) ? '/assets/images/locations/ws-location-open.png' : '/assets/images/locations/ws-location-closed.png';
        const locationType = (this.locations[location].type !== 'other') ? this.locations[location].type : this.locations[location].typeOther;
        const bookingId = this.locations[location].bookingId != null ? this.locations[location].bookingId : "None";
        const boosted = (this.locations[location].boosted) ? this.locations[location].boosted : false;
        const network = (this.locations[location].checkIns) ? this.locations[location].checkIns.length : '';
        this.markers.push({
          bookingId: bookingId,
          lat: this.locations[location].address.latitude,
          lng: this.locations[location].address.longitude,
          icon: icon,
          name: this.locations[location].name,
          address: this.locations[location].address,
          logo: logo,
          featureImage: featureImage,
          url: `/location/${this.locations[location].id}`,
          slug: this.locations[location].refNo,
          isOpen: this.locations[location].isOpen,
          isBooked: this.locations[location].isBooked,
          hours: this.locations[location].currentHours,
          custom: custom,
          id: +location,
          checkedIn: this.locations[location].checkedIn,
          uid: this.locations[location].uid,
          avgRating: this.locations[location].avgRating,
          locationType: locationType,
          refNo: this.locations[location].refNo,
          boosted,
          network,
          userLocationFromMarker: this.userLat != null ? this.getDistanceBetweenUserAndMarker(this.locations[location].address.latitude, this.locations[location].address.longitude) : "none"
        });

        this.markers.sort((mar, mar2) => mar.userLocationFromMarker - mar2.userLocationFromMarker);
      }

      if (this.markers.length > 0 && (this.markers.length === locationsData.length)) {
        this.loading = false;
        this.markers.map((marker, i) => {
          marker.id = i;
        })
        if (this.userLat) {
          this.activeLocation = this.markers[0];
          this.openedWindow = this.markers[0]['id'];
          this.myLocationMarker.isOpen = false;
          this.lat = this.activeLocation.lat;
          this.lng = this.activeLocation.lng;
          this.zoom = 13;
        }

      }

    });



    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }



  getDistanceBetweenUserAndMarker(pinLat, pinLon) {
    return this.calcCrow(this.userLat, this.userLng, pinLat, pinLon);
  }

  calcCrow(lat1, lon1, lat2, lon2) {
    const radius = 6371;
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    const latitude1: any = this.toRad(lat1);
    const latitude2: any = this.toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latitude1) * Math.cos(latitude2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = radius * c;

    return d.toFixed(2);
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  ngOnChanges() {
    if (this.mapSettings) {
      if (this.myLocationMarker) {
        this.zoom = 13;
        this.lat = this.myLocationMarker.lat;
        this.lng = this.myLocationMarker.lng;
      }
    } else {
      this.zoom = 5;
      this.lat = this.defaultLat;
      this.lng = this.defaultLng;
    }
  }

  geocode(latlng: any): Promise<any> {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          location: latlng
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0]);
          } else {
            reject(new Error(status));
          }
        }
      );
    });
  }

  navigateToLocation(markerUrl, index) {
    this.navCtrl.navigateRoot(markerUrl.url);

  }

  setActiveMarker() {
    this.slideWithNav.getActiveIndex().then(slideNo => {
      if (!this.zoomToUserPosition) {
        this.activeLocation = this.markers[slideNo];
        this.openedWindow = this.markers[slideNo]['id'];
        this.lat = this.activeLocation.lat;
        this.lng = this.activeLocation.lng;
        this.zoom = 13;
      }
    });
  }

  setNewActiveMarker(id) {
    if (!this.zoomToUserPosition) {
      this.slideWithNav.slideTo(id);
    }
  }

  // LEAVE IN - in case removing this causes errors on native devices
  // async getUserLocationPromise() {
  //   var promise = new Promise((resolve, reject) => {
  //     this.getCurrentPosition(resolve, reject);
  //   })
  //  return promise;

  // }

  async getCurrentPosition() {
    if (!this.platform.is('cordova')) {
      await this.geolocation.getCurrentPosition().then((resp) => {
        this.userLat = resp.coords.latitude;
        this.userLng = resp.coords.longitude;
      }).catch((error) => {
        this.alertService.displayToast(`Error getting user location.`);
      });
    } else if (this.platform.is('ios')) {
      this.getUserLocation();
    } else {
      await this.androidPerm.checkPermission(this.androidPerm.PERMISSION.ACCESS_COARSE_LOCATION).then(result => {
        if (result.hasPermission) {
          this.askToTurnOnGPS();
        } else {
          this.requestGPSPermission();
        }
      });
    }
  }

  clickedMarker(infowindow, id, type) {
    this.openedWindow = id;
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
    if (id !== null) {
      this.setNewActiveMarker(id);
    }
    if (type === 'location-marker') {
      this.myLocationMarker.isOpen = false;
    }

    if (type === 'my-marker') {
      this.myLocationMarker.isOpen = true;
      this.lat = this.myLocationMarker.lat;
      this.lng = this.myLocationMarker.lng;
    }
  }

  async requestGPSPermission() {
    await this.locationAccuracy.canRequest().then(async (canRequest: boolean) => {
      if (canRequest) {
        this.askToTurnOnGPS();
      } else {
        await this.androidPerm.requestPermission(this.androidPerm.PERMISSION.ACCESS_COARSE_LOCATION)
          .then(
            () => {
              this.askToTurnOnGPS();
            },
            error => {
              this.alertService.displayToast('Error requesting location permission.')
            }
          );
      }
    });
  }

  async askToTurnOnGPS() {
    await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      () => {
        this.getUserLocation();
      }
    );
  }

  async getUserLocation() {
    if (this.myLocationMarker && !this.myLocationMarker.lat && !this.myLocationMarker.lng && this.zoomToUserPosition) {
      await this.geolocation.getCurrentPosition().then(position => {
        this.myLocationMarker.lat = position.coords.latitude;
        this.myLocationMarker.lng = position.coords.longitude;
        this.zoom = 13;
        this.zoomToUserPosition = false;
        this.getUserRegion()
      });
    }
  }


  openBookingModal(marker) {
    const bookingLocation = this.locations.filter(location => {
      return location.uid === marker.uid;
    });
    this.alertService.openModal(LocationBookingComponent, {
      location: bookingLocation[0],
      businessHours: bookingLocation[0].businessHours
    }, '');
  }

  openCheckinModal(marker) {
    if (marker.bookingId != "None") {
      this.checkinService.displayCheckinActionSheet(this.user, marker);
    }

  }

  isInfoWindowOpen(id) {
    if (!this.zoomToUserPosition && !this.loading) {
      return this.openedWindow === id;
    }
    // alternative: check if id is in array
  }

  // Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    if (!this.zoomToUserPosition) {
      this.setActiveMarker();
      this.myLocationMarker.isOpen = false;
    }
  }

  // Call methods to check if slide is first or last to enable disbale navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  getUserRegion() {
    this.mapsAPILoader.load().then(() => {
      let latlng = { lat: this.lat, lng: this.lng };
      this.geocode(latlng).then(place => {
        const region = place.address_components.filter(address => {
          if (address.types[0] === 'administrative_area_level_1') {
            return address;
          }
        })
        this.userLocationRegion = region[0].long_name;
        this.userRegion.emit(region[0].long_name);
      });
    });
  }

  //Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext();
    if (!this.zoomToUserPosition) {
      this.setActiveMarker();
    }
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev();
    if (!this.zoomToUserPosition) {
      this.setActiveMarker();
    }
  }

  ngOnDestroy() {
    if (this.positionSubscription) {
      this.positionSubscription.unsubscribe();
    }
  }

  openNetworkModal(location) {
    this.alertService.openModal(LocationNetworkComponent, { location, user: this.user }, '');
  }

}
