import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AuthenticationService } from '../pages/auth/auth.service';
import { User } from '../_shared/models/users.model';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  user: User;

  constructor(
    private fb: AngularFirestore,
    private auth: AuthenticationService
  ) {
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  // Generate thumbnail from image
  generateThumbnail(img, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1, callback) {
    const canvas: any = document.createElement('canvas');
    const image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      try {
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, width, height);

        const dataURL = canvas.toDataURL('image/png', quality);
        console.log('this is the user thumb image ', dataURL);
        callback(dataURL);
      } catch (error) {
        console.log('Error generating thumbnail from image ', error);
      }
    };
    image.src = img;
  }

  // updateImageThumbData(profileImageThumbData, saveRef) {
  //   const updatePhoto = firebase
  //     .collection('users')
  //     .doc(this.userId)
  //     .set({ profileImageThumbData: profileImageThumbData }, { merge: true });
  //   const updateUserRefPhoto = this.fireStore
  //     .collection('userRefs')
  //     .doc(this.firebaseId)
  //     .set({ profileImageThumbData: profileImageThumbData }, { merge: true });
  //   return Promise.all([updatePhoto, updateUserRefPhoto]);
  // }


  // Save profile image URL
  async saveImage(imageData, saveRef, dbRef, saveId, name) {
    console.log('saving image ', imageData, saveRef, dbRef, saveId, name);
    const imageName = `${name}.jpg`;
    const imageThumbName = `${name}_thumb.jpg`;
    const thumbData = 'data:image/png;base64,' + imageData;
    return this.generateThumbnail(thumbData, 200, 200, 0.9, (thumbnailData => {
      console.log('generated profile image thumb ', thumbnailData);
      const saveImage = firebase
        .storage()
        .ref(saveRef)
        .child(imageName)
        .putString(imageData, 'base64', { contentType: 'image/jpg' })
        .then(() => {
          return firebase.storage().ref(saveRef).child(imageName).getDownloadURL().then((url) => {
            return this.fb.collection(dbRef).doc(saveId).set({ uploadedPhoto: url }, { merge: true });
          });
        })
        .catch((error) => {
          console.log('Error uploading image', error);
        });

      const saveThumbImage = firebase
        .storage()
        .ref(saveRef)
        .child(imageThumbName)
        .putString(thumbnailData, 'data_url', { contentType: 'image/jpg' })
        .then(() => {
          return firebase.storage().ref(saveRef).child(imageThumbName).getDownloadURL().then((url) => {
            return this.fb.collection(dbRef).doc(saveId).set({ uploadedPhotoThumbnail: url }, { merge: true });
          });
        })
        .catch((error) => {
          console.log('Error uploading thumbnail image', error);
        });

      return Promise.all([saveImage, saveThumbImage]);
    }));
  }

  // Save profile image URL
  async saveGalleryImage(imageData, saveRef, dbRef, saveId, name, order) {
    let imageUrl, imageThumbUrl;
    console.log('saving image ', imageData, saveRef, dbRef, saveId, name);
    const imageName = `${name}.jpg`;
    const imageThumbName = `${name}_thumb.jpg`;
    const thumbData = 'data:image/png;base64,' + imageData.data;
    const imageId = this.fb.createId();
    return this.generateThumbnail(thumbData, 200, 200, 1.0, (thumbnailData => {
      console.log('generated profile image thumb ', thumbnailData);
      const saveImage = firebase
        .storage()
        .ref(saveRef)
        .child(imageName)
        .putString(imageData.data, 'base64', { contentType: 'image/jpg' })
        .then(() => {
          return firebase.storage().ref(saveRef).child(imageName).getDownloadURL().then((url) => {
            imageUrl = url;
            const image = {
              active: true,
              created: Date.now(),
              createdByName: `${this.user.firstname} ${this.user.surname}`,
              downloadFile: url,
              filetype: 'image/jpeg',
              name: imageName,
              order: order,
              path: `${saveRef}/${imageName}`,
              path_thumb: `${saveRef}/${imageThumbName}`,
              ref: dbRef,
            };
            console.log('uploading image to firebase ', image);
            return this.fb.collection(dbRef).doc(imageId).set(image, { merge: true });
          });
        })
        .catch((error) => {
          console.log('Error uploading image', error);
        });

      const saveThumbImage = firebase
        .storage()
        .ref(saveRef)
        .child(imageThumbName)
        .putString(thumbnailData, 'data_url', { contentType: 'image/jpg' })
        .then(() => {
          return firebase.storage().ref(saveRef).child(imageThumbName).getDownloadURL().then((url) => {
            imageThumbUrl = url;
            console.log('adding thumbnail url to firebase ', url);
            return this.fb.collection(dbRef).doc(imageId).set({ downloadFileThumbnail: url }, { merge: true });
          });
        })
        .catch((error) => {
          console.log('Error uploading thumbnail image', error);
        });

      return Promise.all([saveImage, saveThumbImage]).then(() => {
        if (order === 0) {
          return this.fb.doc(saveRef).set({
            featureImage: imageUrl,
            featurImageThumbnail: imageThumbUrl,
            featureImageId: imageId
          }, { merge: true });
        } else {
          return Promise.resolve();
        }
      });
    }));
  }
}
