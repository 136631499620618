import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { MeetingRoomsService } from '../../_services/meeting-rooms.service';

@Component({
  selector: 'app-meeting-room-edit-amenities',
  templateUrl: './meeting-room-amenities.component.html'
})
export class MeetingRoomEditAmenitiesComponent implements OnInit {
  
  isLoading = false;
  roomId;
  meetingRoom: any;
  meetingRoomEditForm: FormGroup;

  amenities: any;
  amenitiesSubscription: Subscription;

  // AMENITIES
  defaultAmenities = [];
  extraAmenities = [];
  customAmenities = [];
  showDefaultAmenities = true;
  showExtraAmenities = false;
  showCustomAmenities = false;

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private meetingRoomsService: MeetingRoomsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.roomId = this.route.snapshot.paramMap.get('roomId');
    this.buildForm();
    this.amenitiesSubscription = this.meetingRoomsService.getMeetingRoomAmenities(this.roomId).subscribe((amenities: any) => {
      this.amenities = amenities.list;
      this.defaultAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'default';
      });

      this.extraAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'extra';
      });

      this.customAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'custom';
      });
        
      this.storage.get('meetingRoom').then((meetingRoom) => {
        this.meetingRoom = meetingRoom;
      });
      this.isLoading = false;
    });
  }

  buildForm() {
    this.meetingRoomEditForm = this.fb.group({
      amenity: ['', [Validators.maxLength(50)]]
    });
  }

  // AMENITIES
  amenityChanged(name, type) {
    type.forEach(amenity => {
      if (amenity.name === name) {
        amenity.value = !amenity.value;
      }
    });
    this.meetingRoomEditForm.markAsDirty();
  }

  checkAmenitiesForSelected() {
    const defaultArray = this.defaultAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    const extraArray = this.extraAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    const customArray = this.customAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    if (defaultArray || extraArray || customArray) {
      return true;
    } else {
      return false;
    }
  }

  addAmenity(name) {
    if (!this.customAmenities.some(amenity => amenity.name.toLowerCase() === name.toLowerCase())) {
      this.customAmenities.push({
        name: name,
        value: true,
        type: 'custom'
      });
      this.meetingRoomEditForm.get('amenity').reset();
      this.meetingRoomEditForm.get('amenity').markAsDirty();
    }
  }

  onUpdate(meetingRoomEditForm) {

    let amenitiesList = this.defaultAmenities.concat(this.extraAmenities).concat(this.customAmenities);
    this.amenities = amenitiesList;

    this.isSubmitting = true;
    this.meetingRoomsService.updateAmenities(this.roomId, amenitiesList).then(() => {
      this.isSubmitting = false;
      this.meetingRoomEditForm.markAsPristine();
      this.alert.displayToast('Room updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.showDefaultAmenities = true;
    this.showExtraAmenities = false;
    this.showCustomAmenities = false;
    this.amenitiesSubscription.unsubscribe();
  }

}
