import { LoaderModule } from '../../_shared/loader-component/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { NgPipesModule } from 'ngx-pipes';
import { LocationViewPage } from './location-view/location-view.page';
import { LocationEditPage } from './location-edit/location-edit.page';
import { LocationAddPage } from './location-add/location-add.page';
import { LocationsListComponent } from './locations-list/locations-list.component';
import { MapsModule } from 'src/app/_shared/components/maps/maps.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { LocationBookingsComponent } from './location-bookings/location-bookings.component';
import { ComponentsModule } from 'src/app/_shared/components/components.module';
import { LocationCheckInsPage } from './location-checkins/location-checkins.page';
import { LocationEditDetailsPage } from './location-edit/location-details/location-details.page';
import { LocationEditAddressPage } from './location-edit/location-address/location-address.page';
import { LocationEditHoursPage } from './location-edit/location-hours/location-hours.page';
import { LocationEditAmenitiesPage } from './location-edit/location-amenities/location-amenities.page';
import { LocationEditGalleryPage } from './location-edit/location-gallery/location-gallery.page';
import { LocationEditMembersPage } from './location-edit/location-members/location-members.page';
import { LocationEditReviewsPage } from './location-edit/location-reviews/location-reviews.page';
import { LocationEditBoostPage } from './location-edit/location-boost/location-boost.page';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        NgPipesModule,
        ReactiveFormsModule,
        FormsModule,
        LoaderModule,
        MapsModule,
        SortablejsModule,
        ComponentsModule
    ],
    declarations: [
        LocationViewPage,
        LocationEditPage,
        LocationAddPage,
        LocationsListComponent,
        LocationBookingsComponent,
        LocationCheckInsPage,
        LocationEditDetailsPage,
        LocationEditAddressPage,
        LocationEditHoursPage,
        LocationEditAmenitiesPage,
        LocationEditGalleryPage,
        LocationEditMembersPage,
        LocationEditReviewsPage,
        LocationEditBoostPage
    ],
    exports: [
        LocationViewPage,
        LocationEditPage,
        LocationAddPage,
        LocationsListComponent,
        LocationBookingsComponent,
        LocationCheckInsPage,
        LocationEditDetailsPage,
        LocationEditAddressPage,
        LocationEditHoursPage,
        LocationEditAmenitiesPage,
        LocationEditGalleryPage,
        LocationEditMembersPage,
        LocationEditReviewsPage,
        LocationEditBoostPage
    ],
    providers: [
        DatePipe
    ]
})
export class LocationsModule { }