import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from '../_services/events.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.page.html'
})
export class EventsListPage implements OnInit {
  isLoading = false;
  eventsSubscription: Subscription;
  todaysDate;
  events: any;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.eventsSubscription = this.eventsService.getPublicEvents().subscribe((eventsData: any) => {
      console.log('events data ', eventsData);
      this.events = eventsData;
      this.todaysDate = new Date();
      this.isLoading = false;
    });
  }

}
