import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs';
import { MeetingRoomsService } from 'src/app/pages/meeting-rooms/_services/meeting-rooms.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { MeetingRoomBookingComponent } from '../meeting-room-booking/meeting-room-booking.component';
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-location-select',
  templateUrl: './meeting-room-select.component.html'
})
export class MeetingRoomSelectComponent implements OnInit {
  user: any;
  teamUsers: any;
  team: any;
  meetingRoomSubscription: Subscription;
  isLoading = false;
  selectUsers = false;
  selectedRoom: any;
  selectedMembers = [];
  meetingRooms = [];
  inviteErrorMessage: any;

  constructor(
    private modalCtrl: ModalController,
    private alert: AlertService,
    private meetingRoomsService: MeetingRoomsService,
    private auth: AuthenticationService,
    private storageService: StorageService
  ) {
  }

  ionViewDidLoad() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.isLoading = true;
    console.log('modal data ', this.teamUsers, this.team);

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.teamUsers.forEach(user => {
      user.selected = false;
    });

    this.meetingRoomSubscription = this.meetingRoomsService.getPublicMeetingRooms().subscribe((roomsData: any) => {
      this.meetingRooms = roomsData;
      const today = moment().format('dddd').toLowerCase();
      this.meetingRooms.forEach((room, index) => {
        this.meetingRoomsService.getMeetingRoomHours(room.uid).subscribe((meetingRoomHours: any) => {
          this.meetingRooms[index].businessHours = meetingRoomHours;
          this.meetingRooms[index].currentHours = `${this.toTime(meetingRoomHours[today].open)} - ${this.toTime(meetingRoomHours[today].open)}`;
        });
      });
      this.isLoading = false;
    });
  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  selectMembers(room) {
    this.selectedRoom = room;
    this.selectUsers = true;
  }

  addMember(member, index) {
    const user = this.selectedMembers.filter(user => {
      return user.uid === member.uid;
    });
    if (user[0]) {
      this.selectedMembers = this.selectedMembers.filter(user => {
        return user.uid !== member.uid;
      });
      this.teamUsers[index].selected = false;
    } else {
      if (this.selectedRoom.maxPeople - this.selectedMembers.length > 0) {
        this.inviteErrorMessage = undefined;
        this.selectedMembers.push(member);
        this.teamUsers[index].selected = true;
      } else {
        this.inviteErrorMessage = `You cannot invite more members as this room has a capacity of ${this.selectedRoom.maxPeople} people.`;
      }
    }
  }

  bookRoom() {
    this.storageService.meetingRoomCapacity.next(this.selectedRoom.maxPeople);
    this.modalCtrl.dismiss();
    this.alert.openModal(MeetingRoomBookingComponent, {
      meetingRoom: this.selectedRoom,
      businessHours: this.selectedRoom.businessHours,
      usersForBooking: this.selectedMembers,
      team: this.team
    }, '');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ionViewDidLeave() {
    this.meetingRoomSubscription.unsubscribe();
  }
}
