import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { PackagesListPage } from './packages-list.page';
import { ComponentsModule } from 'src/app/_shared/components/components.module';
import { LoaderModule } from 'src/app/_shared/loader-component/loader.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    LoaderModule
  ],
  declarations: [
    PackagesListPage
  ]
})
export class PackagesListPageModule { }
