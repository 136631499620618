import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html'
})
export class NotificationViewComponent implements OnInit {
  notification: any;
  title = '';

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    console.log('this is the notification ', this.notification);

    // switch (this.notification.type) {
    //   case 'locationBooking': return this.title = 'Location Booking';
    //   case 'meetingRoomBooking': return this.title = 'Meeting Room Booking';
    //   case 'eventBooking': return this.title = 'Event RSVP';
    // }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
