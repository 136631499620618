import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PackagePaymentComponent } from '../package-payment/package-payment.component';
import { User } from 'src/app/_shared/models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-package-info',
  templateUrl: './package-info.component.html'
})
export class PackageInfoComponent implements OnInit {
  package;
  user: User;
  extraData: any;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private alert: AlertService,
    private productsService: ProductsService
  ) {
  }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        const origin = this.package.origin;

        if (origin === 'profile') {
          this.productsService.getProductDetails(this.package.productId).subscribe(productInfo => {
            if (productInfo) {
              this.package = productInfo;
              this.package.origin = origin;
            }
          });
        }
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async openPaymentModal() {
    this.modalCtrl.dismiss();
    this.alert.openModal(PackagePaymentComponent, { product: this.package, extraData: this.extraData }, '');
  }

}
