import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationsService } from '../_services/locations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.page.html',
  styleUrls: ['../location.scss']
})
export class LocationEditPage implements OnInit {

  locationId;
  locationMenu = 'basicInfo';
  isLoading = false;
  imgPreview = '/assets/images/locations/location_profile.jpg';
  locationOwner: any;
  
  locationSubscription: Subscription;
  location: any;

  showDropdown = false;

  conversationsSubscription: Subscription;
  conversationsCount: number;


  constructor(
    private locationsService: LocationsService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: Storage,
    private messagesService: MessagesService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;

    this.locationId = this.route.snapshot.paramMap.get('locationId');

    this.locationSubscription = this.locationsService.getLocationInfo(this.locationId).subscribe((locationData: any) => {
      this.location = locationData;
      this.storage.set('location', this.location);

      this.conversationsSubscription = this.messagesService.getUnreadConversationsForUser(this.location.refNo).subscribe(conversations => {
        this.conversationsCount = conversations.length;
      });
      this.isLoading = false;
    });

  }

  addMeetingRoom() {
    this.router.navigate(['/meeting-rooms/add'], {
      queryParams: {
        locationId: this.locationId
      },
      queryParamsHandling: 'merge',
    });
  }

  navigateToLocationPage(route) {
    this.router.navigate([route]);
  }

  navigateToChat(route) {
    this.router.navigate([route], { queryParams: { conversationIdToLoad: 'location', userIdOfConversations: this.location.refNo } });
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.locationSubscription.unsubscribe();
    this.conversationsSubscription.unsubscribe();
    this.showDropdown = false;
  }


}
