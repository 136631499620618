import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MeetingRoomBookingComponent } from './meeting-room-booking/meeting-room-booking.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LocationBookingComponent } from './location-booking/location-booking.component';
import { PackageInfoComponent } from './package-info/package-info.component';
import { EventRSVPComponent } from './event-rsvp/event-rsvp.component';
import { ShareRefComponent } from './share-ref/share-ref.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { CalendarEventEditComponent } from './calendar-event-edit/calendar-event-edit.component';
import { PackageCancelComponent } from './package-cancel/package-cancel.component';
import { PackageChangeComponent } from './package-change/package-change.component';
import { LocationCheckinComponent } from './location-checkin/location-checkin.component';
import { ProfilePopoverComponent } from './profile-popover/profile-popover.component';
import { PackagePaymentComponent } from './package-payment/package-payment.component';
import { PackageConfirmPurchaseComponent } from './package-confirm-purchase/package-confirm-purchase.component';
import { UserViewComponent } from './user-view/user-view.component';
import { NotificationViewComponent } from './notification-view/notification-view.component';
import { MeetingRoomPaymentComponent } from './meeting-room-payment/meeting-room-payment.component';
import { LoaderModule } from '../../loader-component/loader.module';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { UserInviteComponent } from './user-invite/user-invite.component';
import { QRCodeComponent } from './qr-code/qr-code.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { PackageListComponent } from './package-list/package-list.component';
import { CalendarEditLocationFormComponent } from './calendar-event-edit/locations/bookings-edit-location.form';
import { CalendarEditEventFormComponent } from './calendar-event-edit/events/bookings-edit-event.form';
import { BillingInfoModule } from '../billing-info/billing-info.component.module';
import { RatingsModule } from '../ratings/ratings.module';
import { CalendarModule } from 'ion2-calendar';
import { AppleSigninComponent } from './apple-signin/apple-signin.component';
import { UnverifiedUserComponent } from './unverified-user/unverified-user.component';
import { TermsAndConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LocationSelectComponent } from './location-select/location-select.component';
import { LocationReviewComponent } from './location-review/location-review.component';
import { BlockedUserComponent } from './blocked-user/blocked-user.component';
import { CalendarEditMeetingRoomFormComponent } from './calendar-event-edit/meeting-rooms/bookings-edit-meeting-room.form';
import { MeetingRoomSelectComponent } from './meeting-room-select/meeting-room-select.component';
import { ReportingModalComponent } from './reporting-modal/reporting-modal.component';
import { LocationBoostPaymentComponent } from './location-boost-payment/location-boost-payment.component';
import { LocationNetworkComponent } from './location-network/location-network.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderModule,
        NgxQRCodeModule,
        BillingInfoModule,
        RatingsModule,
        CalendarModule
    ],
    declarations: [
        MeetingRoomBookingComponent,
        LocationBookingComponent,
        PackageInfoComponent,
        PackageConfirmPurchaseComponent,
        PackageCancelComponent,
        PackageChangeComponent,
        EventRSVPComponent,
        ShareRefComponent,
        PasswordResetComponent,
        CalendarEventEditComponent,
        LocationCheckinComponent,
        ProfilePopoverComponent,
        PackagePaymentComponent,
        UserViewComponent,
        NotificationViewComponent,
        MeetingRoomPaymentComponent,
        PaymentConfirmationComponent,
        UserInviteComponent,
        QRCodeComponent,
        PackageListComponent,
        CalendarEditLocationFormComponent,
        CalendarEditEventFormComponent,
        CalendarEditMeetingRoomFormComponent,
        AppleSigninComponent,
        UnverifiedUserComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        LocationSelectComponent,
        LocationReviewComponent,
        BlockedUserComponent,
        MeetingRoomSelectComponent,
        ReportingModalComponent,
        LocationBoostPaymentComponent,
        LocationNetworkComponent
    ],
    entryComponents: [
        MeetingRoomBookingComponent,
        LocationBookingComponent,
        PackageInfoComponent,
        PackageConfirmPurchaseComponent,
        PackageCancelComponent,
        PackageChangeComponent,
        EventRSVPComponent,
        ShareRefComponent,
        PasswordResetComponent,
        CalendarEventEditComponent,
        LocationCheckinComponent,
        ProfilePopoverComponent,
        PackagePaymentComponent,
        UserViewComponent,
        NotificationViewComponent,
        MeetingRoomPaymentComponent,
        PaymentConfirmationComponent,
        UserInviteComponent,
        QRCodeComponent,
        PackageListComponent,
        CalendarEditLocationFormComponent,
        CalendarEditEventFormComponent,
        CalendarEditMeetingRoomFormComponent,
        AppleSigninComponent,
        UnverifiedUserComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        LocationSelectComponent,
        LocationReviewComponent,
        BlockedUserComponent,
        MeetingRoomSelectComponent,
        ReportingModalComponent,
        LocationBoostPaymentComponent,
        LocationNetworkComponent
    ],
    providers: [
        DatePipe
    ]
})
export class ModalsModule { }