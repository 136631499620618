import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../_services/locations.service';

@Component({
  selector: 'app-location-edit-reviews',
  templateUrl: './location-reviews.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditReviewsPage implements OnInit {
  isLoading = false;
  locationId;
  location: any;
  reviews: any;
  locationReviewsSubscription: Subscription;

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');

    this.locationReviewsSubscription = this.locationsService.getLocationReviews(this.locationId).subscribe(reviews => {
      console.log('reviews ', reviews)
      this.reviews = reviews;
      this.isLoading = false;
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.locationReviewsSubscription.unsubscribe();
  }

}
