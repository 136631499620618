import { defaultAmenities } from './../../location/models/amenities';
import { Component, OnInit, Input } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { MeetingRoomsService } from '../_services/meeting-rooms.service';
import { ModalController, NavController } from '@ionic/angular';
import { MeetingRoomBookingComponent } from 'src/app/_shared/components/modals/meeting-room-booking/meeting-room-booking.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';

@Component({
  selector: 'public-meeting-rooms-list',
  templateUrl: './meeting-rooms-list.component.html'
})
export class MeetingRoomsPublicListComponent implements OnInit {
  isLoading = false;
  user: User;

  meetingRooms: any[];
  allMeetingRooms: any[];
  meetingRoomSubscription: Subscription;

  defaultAmenities = defaultAmenities;

  // filters
  filterArray = [];
  wordSearch = '';
  region = '';
  city = '';
  parking = '';
  noiseLevel = '';
  amenity = '';

  showFilters = false;

  constructor(
    private meetingRoomsService: MeetingRoomsService,
    private auth: AuthenticationService,
    private modalCtrl: ModalController,
    private router: Router,
    private navCtrl: NavController
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.meetingRoomSubscription = this.meetingRoomsService.getPublicMeetingRooms().subscribe((roomsData: any) => {
      this.meetingRooms = roomsData;
      this.allMeetingRooms = roomsData;
      console.log('meeting rooms data ', this.meetingRooms);
      this.isLoading = false;
    });
  }

  searchMeetingRooms(value) {
    this.showFilters = false;
    if (value !== '') {
      this.meetingRooms = this.allMeetingRooms.filter(room => {
        return room.name.toLowerCase().includes(value.toLowerCase())
          || room.address.formatted_address.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      this.meetingRooms = this.allMeetingRooms;
    }
  }

  filterMeetingRooms(value, type) {
    if (type === 'region') {
      this.amenity = '';
      if (value !== '') {
        this.meetingRooms = this.allMeetingRooms.filter((location) => {
          return location.address.region == value;
        });
      } else {
        this.meetingRooms = this.allMeetingRooms;
      }
    }
  }

  navigateToRoom(roomId) {
    this.router.navigate([`/meeting-room/${roomId}`]);
  }

  goBack() {
    this.navCtrl.back();
  }


  resetFilters() {
    this.wordSearch = '';
    this.region = '';
    this.meetingRooms = this.allMeetingRooms;
  }

  ionViewDidLeave() {
    this.meetingRoomSubscription.unsubscribe();
  }

}
