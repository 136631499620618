export const packages = [
  {
    name: '2-5 Members',
    discount: 10,
    max: 5
  },
  {
    name: '6-10 Members',
    discount: 12,
    max: 10
  },
  {
    name: '11-15 Members',
    discount: 13,
    max: 15
  },
  {
    name: '16-20 Members',
    discount: 15,
    max: 20
  }
];
