import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/_shared/models/users.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RatingService } from 'src/app/services/ratings.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocationsService } from 'src/app/pages/location/_services/locations.service';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './location-review.component.html',
  styleUrls: ['./location-review.component.scss']
})
export class LocationReviewComponent implements OnInit {
  checkin: any;
  location: any;
  locationId;
  loading = false;
  submittingComment = false;
  commentForm: FormGroup;
  locationRating: any;
  user: User;

  constructor(
    private modalCtrl: ModalController,
    private locationsService: LocationsService,
    private fb: FormBuilder,
    private bookingsService: BookingsService,
    private ratingService: RatingService,
    private alert: AlertService,
    private auth: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.locationId = this.checkin.locationId;
    this.location = {
      name: this.checkin.name,
      uid: this.locationId
    };
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.minLength(5)]]
    });

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  submitComment(comment) {
    this.submittingComment = true;
    this.ratingService.addComment(this.locationId, this.user.uid, comment, this.locationRating, 'user').then(() => {
      this.bookingsService.markCheckinAsReviewed(this.locationId, this.checkin.id);
      this.modalCtrl.dismiss();
      this.submittingComment = false;
      this.alert.displayToast('Location review submitted');
    });
  }

  getRatingValue(event) {
    this.locationRating = event;
  }

  skipReview() {
    this.bookingsService.markCheckinAsReviewed(this.locationId, this.checkin.id);
    this.modalCtrl.dismiss();
  }

}
