import { LoaderModule } from './../../_shared/loader-component/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { NgPipesModule } from 'ngx-pipes';
import { TeamsListComponent } from './teams-list/teams-list.component';
import { TeamViewComponent } from './teams-view/teams-view.component';
import { TeamAddComponent } from './teams-add/teams-add.component';
import { ComponentsModule } from 'src/app/_shared/components/components.module';
import { BillingInfoModule } from 'src/app/_shared/components/billing-info/billing-info.component.module';
import { TeamsTransactionsComponent } from './teams-transactions/teams-transactions.component';
import { TeamsCheckInsPage } from './teams-checkins/teams-checkins.page';
import { TeamEditComponent } from './teams-edit/teams-edit.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        NgPipesModule,
        ReactiveFormsModule,
        FormsModule,
        LoaderModule,
        ComponentsModule,
        BillingInfoModule
    ],
    declarations: [
        TeamsListComponent,
        TeamViewComponent,
        TeamEditComponent,
        TeamAddComponent,
        TeamsTransactionsComponent,
        TeamsCheckInsPage
    ],
    exports: [
        TeamsListComponent,
        TeamViewComponent,
        TeamEditComponent,
        TeamAddComponent,
        TeamsTransactionsComponent,
        TeamsCheckInsPage
    ]
})
export class TeamsModule { }