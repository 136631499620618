import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/pages/events/_services/events.service';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-events-list-component',
  templateUrl: './event-rsvp.component.html'
})
export class EventRSVPComponent implements OnInit {
  event: any;
  processing = false;
  eventBooked = false;
  user: any;

  constructor(
    private modalCtrl: ModalController,
    private alert: AlertService,
    private auth: AuthenticationService,
    private eventsService: EventsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;

        this.eventsService.checkUserEvent(this.event.uid, this.user.uid).subscribe(event => {
          if (event) {
            this.eventBooked = true;
          }
        });
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onConfirm() {
    this.processing = true;
    this.eventsService.rsvpToEvent(this.event, this.user).then(() => {
      this.alert.displayToast('RSVP successfull');
      this.processing = false;
      this.modalCtrl.dismiss();
    }).catch(error => {
      this.processing = false;
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  cancelEventAttendance() {
    console.log('event attendance ', this.event.uid);
    this.processing = true;
    this.eventsService.deleteEventBooking(this.event.uid).then(() => {
      this.processing = false;
      this.alertService.displayToast('Successfully cancelled event attendance');
      this.modalCtrl.dismiss();
    }).catch(error => {
      this.processing = false;
      this.alertService.displayToast('Error cancelling event attendance');
    });
  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM';
    } else {
      meridian = 'AM';
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

}
