import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Upload } from './upload';
import * as _ from "lodash";
import { GenerateThumbnailService } from 'src/app/services/generate-thumbnail.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { config } from '../../configs/config';
import * as moment from 'moment';
// import { AuthenticationService } from 'src/app/auth/_services';
// import { GenerateThumbnailService } from '../../_services/generate-thumbnail.service';
// import { config } from '../../../_shared/_configs/config';

@Injectable()
export class FilesService {

    loggedInUser = {
        uid: '',
        userFirstname: '',
        userSurname: ''
    };
    userId: string;

    currentUpload: Upload;
    dropzoneActive: boolean = false;
    // Main task
    task: AngularFireUploadTask;

    // Progress monitoring
    percentage: Observable<number>;
    snapshot: Observable<any>;

    // Download URL
    downloadURL: Observable<string>;

    // State for dropzone CSS toggling
    isHovering: boolean;

    entityID: string;
    private uploadingFile: BehaviorSubject<any>;
    fileUploaded: boolean = false;

    constructor(
        private storage: AngularFireStorage,
        public generateThumbnailsService: GenerateThumbnailService,
        public afStore: AngularFirestore,
        private auth: AuthenticationService
    ) {
        this.entityID = config.entityId;
        this.uploadingFile = new BehaviorSubject<boolean>(false);
        this.auth.user.subscribe(userDetails => {
            if (userDetails) {
                this.userId = userDetails.uid;
                // Get Logged in User
                this.loggedInUser.uid = userDetails.uid;
                this.loggedInUser.userFirstname = userDetails.firstname;
                this.loggedInUser.userSurname = userDetails.surname;
            }
        });
    }

    public getUploadStatus(): Observable<any> {
        return this.uploadingFile.asObservable();
    }

    public getFiles(filesRef) {
        return filesRef.valueChanges({ idField: 'id' });
    }

    public removeFile(fileRef, fileId: string) {
        return fileRef.doc(fileId).update({ active: false });
    }

    handleDrop(fileList: FileList, saveRef) {
        let filesIndex = _.range(fileList.length);

        _.each(filesIndex, (idx) => {
            this.currentUpload = new Upload(fileList[idx]);
            this.startUpload(this.currentUpload, saveRef)
        });
    }

    startUpload(upload: Upload, saveRef) {
        this.setUploadStatus(true, upload.file.name);

        let reader = new FileReader();
        reader.readAsDataURL(upload.file);
        reader.onerror = (error) => {
            error;
        };
        return reader.onload = () => {
            return new Promise((resolve, reject) => {
                this.generateThumbnailsService.generateFromImage(reader.result, 700, 700, 1, imageData => {
                    const fileName = `${new Date().getTime()}_logo.jpg`;
                    const path = `${saveRef.filePath}/${fileName}`;
                    const ref = this.storage.ref(path);
                    const task = ref.putString(imageData, 'data_url');
                    return new Promise((resolve, reject) => {
                        task.snapshotChanges().pipe(
                            finalize(() => {
                                const downloadURL = ref.getDownloadURL()
                                downloadURL.subscribe(url => {
                                    this.generateThumbnailsService.generateThumbFromImage(reader.result, 370, 370, 1, thumbnailData => {
                                        const thumbFileName = `${new Date().getTime()}_thumb_logo.jpg`;
                                        const thumbPath = `${saveRef.filePath}/${thumbFileName}`;
                                        const thumbRef = this.storage.ref(thumbPath);
                                        const thumbTask = thumbRef.putString(thumbnailData, 'data_url');
                                        return new Promise((resolve, reject) => {
                                            thumbTask.snapshotChanges().pipe(
                                                finalize(() => {
                                                    const downloadThumbURL = thumbRef.getDownloadURL()
                                                    downloadThumbURL.subscribe(thumbUrl => {
                                                        
                                                        const file = {
                                                            uploadedPhoto: url,
                                                            uploadedPhotoThumbnail: thumbUrl
                                                        }

                                                        this.saveSingleDataFirestore(saveRef, file)
                                                            .then(() => {
                                                                resolve();
                                                            });
                                                    });
                                                })
                                            ).subscribe();
                                        });
                                    });
                                });
                            })
                        ).subscribe();
                    });
                });
            });
        };
    }

    handleGalleryDrop(fileList: FileList, saveRef, featuredId?) {
        let filesIndex = _.range(fileList.length);

        _.each(filesIndex, (idx) => {
            this.currentUpload = new Upload(fileList[idx]);
            this.startGalleryUpload(this.currentUpload, saveRef[idx], featuredId);
        });
    }

    startGalleryUpload(upload: Upload, saveRef, featuredId?) {
        this.setUploadStatus(true, upload.file.name);

        let reader = new FileReader();
        reader.readAsDataURL(upload.file);
        reader.onerror = (error) => {
            error;
        };
        return reader.onload = () => {
            return new Promise((resolve, reject) => {
                this.generateThumbnailsService.generateFromImage(reader.result, 700, 700, 1, imageData => {
                    const fileName = `${new Date().getTime()}_${saveRef.order}.jpg`;
                    const path = `${saveRef.filePath}/${fileName}`;
                    const ref = this.storage.ref(path);
                    const task = ref.putString(imageData, 'data_url');
                    this.snapshot = task.snapshotChanges().pipe(
                        tap(snap => {
                            if (snap.bytesTransferred === snap.totalBytes) {
                            }
                        }) 
                    );
                    return new Promise((resolve, reject) => {
                        task.snapshotChanges().pipe(
                            finalize(() => {
                                const downloadURL = ref.getDownloadURL()
                                downloadURL.subscribe(url => {
                                    this.generateThumbnailsService.generateThumbFromImage(reader.result, 370, 370, 1, thumbnailData => {
                                        const thumbFileName = `${new Date().getTime()}_thumb_${saveRef.order}.jpg`;
                                        const thumbPath = `${saveRef.filePath}/${thumbFileName}`;
                                        const thumbRef = this.storage.ref(thumbPath);
                                        const thumbTask = thumbRef.putString(thumbnailData, 'data_url');
                                        return new Promise((resolve, reject) => {
                                            thumbTask.snapshotChanges().pipe(
                                                finalize(() => {
                                                    const downloadThumbURL = thumbRef.getDownloadURL()
                                                    downloadThumbURL.subscribe(thumbUrl => {
                                                        const file = {
                                                            downloadFile: url,
                                                            name: upload.file.name,
                                                            downloadFileThumbnail: thumbUrl,
                                                            path: path,
                                                            path_thumb: thumbPath,
                                                            size: upload.file.size.toString(),
                                                            created: new Date().getTime(),
                                                            createdByUID: this.loggedInUser.uid,
                                                            createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
                                                            filetype: upload.file.type,
                                                            active: true,
                                                            order: saveRef.order
                                                        }

                                                        this.saveGalleryFileDataFirestore(saveRef, file, featuredId)
                                                            .then(() => {
                                                                this.setUploadStatus(false, upload.file.name);
                                                                this.fileUploaded = true;
                                                                resolve();
                                                            });
                                                    });
                                                })
                                            ).subscribe();
                                        });
                                    });
                                });
                            })
                        ).subscribe();
                    });
                });
            });
        };
    }

    saveGalleryFileDataFirestore(saveRef, upload: Upload, featuredId?) {
        return this.saveFileDataFirestore(saveRef, upload).then(ref => {
            if (featuredId) {
                if (featuredId === saveRef.tempId) {
                    const saveDoc = this.afStore.doc(saveRef.feautureImgDoc);
                    return saveDoc.set({
                        featureImage: upload.downloadFile,
                        featureImageThumbnail: upload.downloadFileThumbnail,
                        featureImageId: ref.id
                    }, { merge: true });
                }
            }
        });
    }

    public setUploadStatus(newValue: boolean, fileName: string): void {
        const values = {
            uploading: newValue,
            fileName
        }
        this.uploadingFile.next(values);
    }

    saveFileDataFirestore(saveRef, upload: Upload) {

        const collectionRef = this.afStore.collection(saveRef.dbPath); //ref

        return collectionRef.add(upload)
            .then(ref => {
                ref.update({
                    ref: ref
                });

                return ref;
            });
    }

    saveSingleDataFirestore(saveRef, upload: Upload) {
        console.log('saving logo')

        const collectionRef = this.afStore.doc(saveRef.dbPath); //ref

        return collectionRef.set(upload, { merge: true });
    }

    // CONVERT BASE64 TO FILE
    public dataUrlToFileList(dataUrls: string[], fileNames: string[], fileTypes: string[]): FileList {
        const fileArray: File[] = [];

        for (let index = 0; index < dataUrls.length; index++) {
            const dataUrl = dataUrls[index];
            const fileName = fileNames[index];
            const fileType = fileTypes[index];
            // Converting content to Blob
            const blobObject = this.dataUrlToBlob(dataUrl);
            // Converting Blob to file
            const file = new File([blobObject], fileName, { type: fileType });
            fileArray.push(file);
        }

        // Converting array with file to filelist and passing to uploader
        return fileArray as unknown as FileList; // < -------------- MAGIC HAPPENS HERE
    }

    private dataUrlToBlob(dataUrl: string): Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataUrl.split(",")[1]);

        // separate out the mime component
        const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([ia], { type: mimeString });
        return blob;
    }

    // UPLOAD FILE
    uploadDocument(saveRef, upload, source) {
        const dateTime = moment().format('YYYYMMDDhmmss')
        const fileName = `${dateTime}_${saveRef.type}.csv`;
        const path = `${saveRef.filePath}/${fileName}`;
        const ref = this.storage.ref(path);
        
        // ADD FILE
        const task = (source === 'web') ? this.storage.upload(path, upload) :  ref.putString(upload, 'data_url');
        return task;
    }

}
