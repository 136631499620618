import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Subscription } from 'rxjs';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { User } from 'src/app/_shared/models/users.model';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { Router } from '@angular/router';
import { PackageInfoComponent } from 'src/app/_shared/components/modals/package-info/package-info.component';
import { PasswordResetComponent } from 'src/app/_shared/components/modals/password-reset/password-reset.component';
import { UsersService } from '../../../services/users.service';
import { BookingsService } from '../../bookings/_services/bookings.service';
import { config } from 'src/app/_shared/configs/config';
import { NotificationsService } from 'src/app/services/notifications.service';
import { QRCodeComponent } from 'src/app/_shared/components/modals/qr-code/qr-code.component';
import { ShareRefComponent } from 'src/app/_shared/components/modals/share-ref/share-ref.component';
import { MessagesService } from 'src/app/services/messages.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['../users.scss']
})
export class ProfilePage implements OnInit {

  userDetails: any;
  user: User;
  isLoading = false;
  profileImage: any;

  // SUBSCRIPTION
  subscriptionDetails = {
    submit: false,
    type: '',
    data: {
      user: {},
      subscription: ''
    }
  };
  userSubscriptionPlans: Subscription;
  subscriptionPlans: any[];
  hideProductSelection = false;
  userSubscriptionPlan: any;
  teamSubscriptionPlans: any;
  subscriptionPlan: Subscription;
  subscription: any;
  userSubscription: Subscription;

  showDropdown = false;

  provinces = Provinces;

  notificationsSubscription: Subscription;
  notificationsCount: number;

  conversationsSubscription: Subscription;
  conversationsCount: number;

  currentPlatform:string;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public clipboard: Clipboard,
    private subscriptionsService: SubscriptionsService,
    private usersService: UsersService,
    private nav: NavController,
    private router: Router,
    private bookingsService: BookingsService,
    private notificationsService: NotificationsService,
    private messagesService: MessagesService,
    private storageServices:StorageService
  ) { } 

  ngOnInit() {
    this.isLoading = true;
 
    this.authenticationService.user.subscribe(user => {
      if (user) {
        this.userDetails = user;

        this.userSubscription = this.usersService.fetchUserDetails(`/users/${this.userDetails.uid}`).subscribe((userData: User) => {
          this.user = userData;
          this.subscriptionDetails.submit = true;
          this.subscriptionDetails.type = 'User';
          this.subscriptionDetails.data.user = this.user;

          this.subscriptionDetails = {
            submit: true,
            type: 'User',
            data: {
              user: this.user,
              subscription: ''
            }
          };

          this.isLoading = false;
        });

        this.notificationsSubscription = this.notificationsService.getUserNotifications(this.userDetails.uid).subscribe(notifications => {
          this.notificationsCount = notifications.length;
        });

        this.conversationsSubscription = this.messagesService.getUnreadConversationsForUser(this.userDetails.uid).subscribe(conversations => {
          this.conversationsCount = conversations.length;
          console.log(conversations)
        });

        this.userSubscriptionPlans = this.subscriptionsService.getUserSubscriptions(this.userDetails.uid).subscribe(subs => {
          this.subscriptionPlans = subs;
          if (this.subscriptionPlans.length > 0) {

            // SET USER PLAN
            const userPlan = this.subscriptionPlans.filter((e) => e.type === 'userSubscription');

            this.hideProductSelection = (!userPlan[0]) ? false : true;
            this.userSubscriptionPlan = userPlan[0];
            this.subscriptionDetails.data.subscription = this.userSubscriptionPlan;

            // SET TEAM PLANS
            this.teamSubscriptionPlans = this.subscriptionPlans.filter((e) => e.type === 'teamSubscription' && e.teamId);
          } else {
            this.hideProductSelection = false;
            this.userSubscriptionPlan = '';
            this.subscriptionDetails.data.subscription = '';
          }
        });
      } else {
        this.isLoading = false;
      }
    });

    this.storageServices.currentPlatform.subscribe( platform =>{
      this.currentPlatform = platform;
    })
  }

  goBack() {
    this.nav.back();
  }

  async logOut() {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Yes',
        handler: () => {
          this.authenticationService.signOutUser();
          this.router.navigate(['/landing']);
        }
      }
    ];
    this.alertService.displayAlert('Log Out', 'Are you sure you want to log out?', buttons);
  }

  viewPlanDetails(plan) {
    plan.origin = 'profile';
    this.alertService.openModal(PackageInfoComponent, { package: plan }, '');
  }

  openPasswordResetModal(value) {
    this.alertService.openModal(PasswordResetComponent, { user: this.userDetails, showRelogin: value }, 'modal-small');
  }

  // MAY BE NEEDED LATER FOR TESTING LIVE
  // sendNotificationTest() {
  //   console.log('sending notification');
  //   const locationNotification = {
  //     title: 'Testing Notification Title',
  //     body: `Testing Notification Body`,
  //     type: 'userCheckIn',
  //     createdBy: this.user.uid,
  //     addToHistory: true,
  //     userId: this.user.uid,
  //     customData: {
  //       createdBy: this.user.uid,
  //       notificationUrl: `/locations/edit/qGcHqP4hJuvjLlYJVVsD`,
  //     },
  //     historyInfo: {
  //       created: new Date(),
  //       title: 'Member Checked Into Your Location',
  //       message: `${this.user.firstname} ${this.user.surname} has sent a test notification2`,
  //       linkText: `View Location Test`,
  //       type: 'userCheckIn',
  //       createdBy: this.user.uid,
  //       userId: this.user.uid,
  //       url: `/locations/edit/qGcHqP4hJuvjLlYJVVsD`,
  //       listRef: '/my-notifications',
  //       source: config.source,
  //       unread: true
  //     }
  //   }
  //   console.log('sending test notification ', locationNotification);
  //   this.notificationsService.addNotification(locationNotification).then(() => {
  //     console.log('sent notification function fired succesfully ');
  //   })
  //     .catch(err => {
  //       console.log('error sending notification data to cloud functions ', err);
  //     });
  // }

  openQRModal() {
    const mobile = (this.user.contactNumber) ? this.user.contactNumber : '';
    const modalData = {
      modalId: 'qrCodeModal',
      title: `${this.user.firstname} ${this.user.surname} - VCARD`,
      type: 'view',
      uid: `BEGIN:VCARD
VERSION:3.0
N:${this.user.firstname} ${this.user.surname}
FN:${this.user.firstname} ${this.user.surname}
EMAIL:${this.user.email}
TEL;TYPE=voce,work,pref:${mobile}
END:VCARD
`
    }
    this.alertService.openModal(QRCodeComponent, { data: modalData }, 'modal-small');
  }

  openSharingModal() {
   
      this.alertService.openModal(ShareRefComponent, {}, 'sharing-modal');
    
    
  }
}
