import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { config } from 'src/app/_shared/configs/config';

@Injectable()
export class ReportService {

  entityId: string;

  constructor(
    private afs: AngularFirestore
  ) {
    this.entityId = config.entityId;
  }

  sendReport(data) {
    const pendingReport = this.afs.collection("pending");
    return pendingReport.add({
      request: 'sendReport',
      entityId: this.entityId,
      source: 'app',
      data
    });
  }

}
