import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';
import { User } from '../../models/users.model';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { LocationSelectComponent } from '../modals/location-select/location-select.component';
import { AlertService } from 'src/app/services/alert.service';

declare var $: any;

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.html'
})
export class UsersListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() users$: Observable<Array<{}>>;
  @Input() type: string = '';
  @Input() userType: string;
  @Input() locationId: string;
  @Output() sendUser = new EventEmitter();

  users: User[];
  allUsers: User[];
  foundUsers: User[];
  userSubscription: Subscription;
  currentUserTeam: any;
  user: any;

  searchCriteria: any;
  showNotFound: boolean = false;
  showResetFilter: boolean = false;

  constructor(
    public auth: AuthenticationService,
    private alert: AlertService
  ) {
  }

  ngOnInit() {
    this.userSubscription = this.users$.subscribe(usersData => {
      this.users = usersData;
      this.allUsers = usersData;

      this.auth.user.subscribe(user => {
        if (user && this.userType === 'teamUsers') {
          this.user = user;
          const userTeam = this.users.filter(e => e.uid === user.uid);
          this.currentUserTeam = userTeam[0];
        }
      });
    });


  }

  findUsers() {
    if (this.searchCriteria && this.searchCriteria !== ' ' && this.searchCriteria.length > 2) {
      const val = this.searchCriteria.toLowerCase();
      const temp = this.allUsers.filter((user) => {
        const full_name = `${user.firstname} ${user.surname}`
        const name = full_name.toLowerCase().indexOf(val) !== -1 || !val;
        const email = user.email.toLowerCase().indexOf(val) !== -1 || !val;
        const contactNumber = user.contactNumber.indexOf(val) !== -1 || !val;
        const refNo = (user.refNo) ? user.refNo.toLowerCase().indexOf(val) !== -1 || !val : '';
        return name || email || contactNumber || refNo;
      });
      this.users = temp;
      this.foundUsers = temp;
      this.showResetFilter = true;
      this.showNotFound = (this.users.length === 0) ? true : false;
      this.showNotFound = (this.foundUsers.length === 0) ? true : false;
    } else {
      this.users = this.allUsers;
      this.foundUsers = [];
      this.showNotFound = true;
    }
  }

  resetFilter() {
    this.searchCriteria = '';
    this.users = this.allUsers;
    this.foundUsers = [];
    this.showNotFound = false;
    this.showResetFilter = false;
  }

  viewUser(userRef, userId, index) {
    const dataToSend = {
      type: 'viewUser',
      data: {
        userId,
        userRef,
        type: this.type,
        userIndex: index,
        locationId: this.locationId
      }
    }
    this.sendUser.emit(dataToSend);
  }

  userChat(conversationId, conversationName) {
    const dataToSend = {
      type: 'userChat',
      data: {
        conversationId,
        conversationName
      }
    }
    this.sendUser.emit(dataToSend);
  }

  createUserBooking(user) {
    this.alert.openModal(LocationSelectComponent, { user: user, bookingUser: this.user }, '');
  }

  ngAfterViewInit(): void {
  }

  ionViewDidLeave() {
    this.userSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
