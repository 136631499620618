import { config } from './../../_shared/configs/config';
import { Injectable } from '@angular/core';
import { map, take, switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { auth } from 'firebase/app';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { Facebook } from '@ionic-native/facebook/ngx';
import { User } from '../../_shared/models/users.model';
import { SignInWithApple, ASAuthorizationAppleIDRequest, AppleSignInResponse } from '@ionic-native/sign-in-with-apple/ngx';
import { AlertService } from 'src/app/services/alert.service';
import { AppleSigninComponent } from 'src/app/_shared/components/modals/apple-signin/apple-signin.component';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  FB_APP_ID: number = 1440033762841334;
  user: Observable<any>;
  userDetails: any;
  userId;
  entityId = config.entityId;
  entityName = config.entityName;

  constructor(
    public afAuth: AngularFireAuth,
    public fireStore: AngularFirestore,
    private router: Router,
    private fb: Facebook,
    private apple: SignInWithApple,
    private modalCtrl: ModalController
  ) {
    this.user = this.afAuth.authState.pipe(switchMap(user => {
      if (user) {
        let updateUser = false;
        if (!this.userDetails) {
          updateUser = true;
        } else if (this.userDetails.email !== user.email) {
          updateUser = true;
        }
        if (updateUser) {
          const userDoc = this.fireStore.collection('userRefs').doc(user.uid).ref;
          userDoc.get().then(doc => {
            if (doc.exists) {
              this.userDetails = doc.data();
              this.userId = this.userDetails.uid;
            }
          });
        }
        return this.fireStore.doc(`userRefs/${user.uid}`).valueChanges();
      } else {
        return of(null);
      }
    }));
  }

  signUpNewUser(user) {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password).then(userData => {
      const userDetails = {
        firstname: user.firstname,
        surname: user.surname,
        email: user.email.toLowerCase(),
        uid: userData.user.uid,
        photoURL: userData.user.photoURL,
        contactNumber: user.contactNumber,
        permissions: ['user'],
        signInMethod: 'email',
        inviteRef: user.refCode
      };
      return this.createUserData(userDetails, false);

    });
  }

  socialLogin(provider, user) {
    let userDetails: User = {};
    if (provider === 'google') {
      userDetails.firstname = user.additionalUserInfo.profile.given_name;
      userDetails.surname = user.additionalUserInfo.profile.family_name;
      userDetails.photoURL = user.user.photoURL;
      userDetails.signInMethod = 'google';
    }
    if (provider === 'facebook') {
      userDetails.firstname = user.additionalUserInfo.profile.first_name;
      userDetails.surname = user.additionalUserInfo.profile.last_name;
      userDetails.photoURL = 'https://graph.facebook.com/' + user.additionalUserInfo.profile.id + '/picture?type=large';
      userDetails.signInMethod = 'facebook';
    }
    if (provider === 'apple') {
        userDetails.firstname = user.firstname;
        userDetails.surname = user.surname;
        userDetails.email = user.email;
        userDetails.photoURL = user.photoURL;
        userDetails.signInMethod = 'apple';
        userDetails.uid = user.uid;
        userDetails.contactNumber = user.contactNumber;
    }
    if (provider != 'apple') {
        userDetails.email = user.user.email;
        userDetails.uid = user.user.uid;
        userDetails.contactNumber = user.user.phoneNumber;
    }

    userDetails.permissions = ['user'];

    const userRef = this.fireStore.doc(`/public/registeredUsers/list/${user.user.uid}`);

    return userRef
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()
      .then(snap => {
        if (!snap.payload.data()) {
          // IF USER DOES NOT EXIST CREATE USER WITH SOCIAL DETAILS
          return this.createUserData(userDetails, true);
        } else {
          this.updateUserData(userDetails, snap.payload.data(), 'social');
        }
      })
      .catch(error => {
        // IF USER DOES NOT EXIST CREATE USER WITH SOCIAL DETAILS
        return this.createUserData(userDetails, true);
      });
  }

  private createUserData(user, socialLogin: boolean) {
    const pendingCreateUserDataRef = this.fireStore.doc(`pending/${user.uid}`);

    return pendingCreateUserDataRef.set({
      request: 'createUserData',
      user: user,
      socialLogin: socialLogin,
      entityId: this.entityId,
      entityName: this.entityName,
      source: config.source,
      websiteUrl: `${environment.websiteUrl}verification/`,
      generatedId: this.fireStore.createId()
    });
  }

  private updateUserData(user, userId, type) {
    const id = userId.uid;
    const userRef: AngularFirestoreDocument = this.fireStore.doc(`users/${id}`);
    const userIDsRef: AngularFirestoreDocument = this.fireStore.doc(`userRefs/${user.uid}`);

    if (user.contactNumber === null) {
      user.contactNumber = '';
    }

    let userDetailsResult;
    let userIDsResult;

    if (type === 'social') {

      userDetailsResult = userRef.set(
        {
          uid: id,
          firebaseId: user.uid,
          email: user.email.toLowerCase(),
          firstname: user.firstname,
          surname: user.surname,
          photoURL: user.photoURL,
          contactNumber: user.contactNumber,
          socialLogin: true
        },
        { merge: true }
      );

      userIDsResult = userIDsRef.set(
        {
          uid: id,
          firebaseId: user.uid,
          email: user.email.toLowerCase(),
          firstname: user.firstname,
          surname: user.surname,
          photoURL: user.photoURL,
          socialLogin: true,
          verified: true
        },
        { merge: true }
      );
    } else {
      userDetailsResult = userRef.set({
        socialLogin: user.socialLogin,
        signInMethod: user.signInMethod
      }, { merge: true });

      userIDsResult = userIDsRef.set({
        socialLogin: user.socialLogin,
        signInMethod: user.signInMethod
      }, { merge: true });
    }

    return Promise.all([userDetailsResult, userIDsResult]);
  }

  async loginUser(email: string, password: string) {
    return await this.afAuth.auth.signInWithEmailAndPassword(email, password).then(credential => {
      // this.updateUserData(userData, snap.payload.data(), 'email');
      const userRef = this.fireStore.doc(`/public/registeredUsers/list/${credential.user.uid}`);
      return userRef
        .snapshotChanges()
        .pipe(take(1))
        .toPromise()
        .then(snap => {
          console.log(snap.payload.data())
          const userData = {
            uid: credential.user.uid,
            socialLogin: false,
            signInMethod: 'email'
          };
          this.updateUserData(userData, snap.payload.data(), 'email');
          return userData;
        });
    });
  }

  async googleLogin(token) {
    const userData = await this.afAuth.auth.signInWithCredential(firebase.auth.GoogleAuthProvider.credential(token));
    return this.socialLogin('google', userData).then(data => {
      this.router.navigate(['/home']);
      return userData;
    });
  }

  facebookLogin() {
    const permissions = ['public_profile', 'email'];
    return this.fb.login(permissions).then(response => {
      const fbCredential = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
      return firebase.auth().signInWithCredential(fbCredential).then(userData => {
        return this.socialLogin('facebook', userData);
      });
    });
  }

  desktopGoogleLogin() {
      const provider = new auth.GoogleAuthProvider();
      return this.oAuthLogin(provider, 'google');
  }

  desktopFacebookLogin() {
      const provider = new auth.FacebookAuthProvider();
      return this.oAuthLogin(provider, 'facebook');
  }

  private oAuthLogin(provider, type) {
    return this.afAuth.auth.signInWithPopup(provider).then((userData: any) => {
      return this.socialLogin(type, userData).then(data => {
        this.router.navigate(['/home']);
        return userData;
      });
    }).catch(error => {
      return error;
    });
  }

  async appleLogin() {
    const appleCredential: AppleSignInResponse = await this.apple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      });
      const credential = new firebase.auth.OAuthProvider('apple.com').credential(
          appleCredential.identityToken
        );
        return this.afAuth.auth.signInWithCredential(credential)
          .then(async(user: any) => {
            user.firstname = appleCredential.fullName.givenName;
            user.surname = appleCredential.fullName.familyName;
            user.photoURL =  '';
            user.signInMethod = 'apple';
            user.contactNumber = '';
            user.uid = user.user.uid;
            user.email = user.user.email;
            if (user.user.email && user.firstname && user.surname) {
                return this.socialLogin('apple', user).then(() => Promise.resolve())
            } else {
                  const modal = await this.modalCtrl.create({
                    component: AppleSigninComponent,
                    componentProps: {
                        user: user
                    }
                  });
                  modal.onDidDismiss()
                  .then((data) => {
                    return this.socialLogin('apple', data['data']).then(() => Promise.resolve());
                });
                modal.present();
            }
          })
          .catch((error) => {
            return Promise.resolve();
          });
  }

  updateUserEmail(userEmail) {
    const currentUser = this.afAuth.auth.currentUser;
    return currentUser.updateEmail(userEmail);
  }

  reAuthUser(email, password) {
      const currentUser = this.afAuth.auth.currentUser;
      const credential = auth.EmailAuthProvider.credential(email, password);
      return currentUser.reauthenticateWithCredential(credential).then(() => {
          console.log("correct password");
      })
      .catch(error => {
          console.log(error);
          return error;
      });
  }

  updateUserPassword(password) {
    const currentUser = this.afAuth.auth.currentUser;
    return currentUser
      .updatePassword(password)
      .then(() => {
        console.log("password successful");
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }

  changePassword(currentPassword, newPassword) {
    const currentUser = this.afAuth.auth.currentUser;
    return this.reAuthUser(currentUser.email, currentPassword).then((error: any) => {
      if (error) {
        return error;
      } else {
        return this.updateUserPassword(newPassword);
      };
    });
  }

  updateUserDetails(user) {
    const updateUser = this.fireStore.doc(`/pending/${user.uid}`);

    return updateUser.set({
      request: 'updateUserDetails',
      user: user,
      entityId: this.entityId,
      source: 'app',
      firebaseId: user.firebaseId
    });
  }

  resetPassword(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email.toLowerCase());
  }

  sendEmailVerificationRequest(user) {
    return this.fireStore
      .collection(`pending`)
      .doc(user.firebaseId)
      .set({
        request: 'emailVerificationRequests',
        email: user.email.toLowerCase(),
        firstname: user.firstname,
        uid: user.firebaseId,
        entityId: this.entityId,
        entityName: this.entityName,
        source: config.source,
        websiteUrl: `${environment.websiteUrl}verification/`,
      });
  }

  signOutUser() {
    console.log('signing out')
    return this.afAuth.auth.signOut();
  }

  signOut(type?) {
    this.afAuth.auth.signOut().then(() => {
        // this.updateOnlineStatus(this.userId, false);
        // window.location.reload();
        if (type === "password") {
            this.router.navigate(["/password-reset"]);
        } else {
            this.router.navigate(["/landing"]);
        }
    });
  }

  verifyUserEmail(firebaseId: string) {
      // CHECK USER UID EXISTS
      const userRef = this.fireStore.collection('public/registeredUsers/list').doc(firebaseId).ref;

      return userRef.get()
          .then(snap => {
              if (snap.exists === true) {
                  return this.fireStore.collection(`pending`).doc(firebaseId).set({
                      request: 'verifyUser',
                      verified: true,
                      source: config.source,
                      entityId: config.entityId
                  }, { merge: true })
                      .then(() => {
                          this.sendEmailWelcomeMail(firebaseId);
                          return Promise.resolve('User Verified');
                      });
              } else {
                  return Promise.reject('User could not be verified');
              }
          }).catch(error => {
              return Promise.reject('User could not be verified');
          });
  }

  sendEmailWelcomeMail(firebaseId) {
      return this.fireStore.collection(`pending`).add({
          request: 'emailWelcomeMail',
          firebaseId: firebaseId
      });
  }

}
