import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { TeamsService } from '../_services/teams.service';
import { UserViewComponent } from 'src/app/_shared/components/modals/user-view/user-view.component';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from '../../auth/auth.service';
import { MeetingRoomSelectComponent } from 'src/app/_shared/components/modals/meeting-room-select/meeting-room-select.component';

@Component({
  selector: 'public-teams-view',
  templateUrl: './teams-view.component.html',
  styleUrls: ['../teams.scss']
})
export class TeamViewComponent implements OnInit {
  isLoading: boolean = false;
  teamId;
  teamSubscription: Subscription;
  team: any;
  user: any;
  currentUserTeam: any;

  imgPreview = '/assets/images/teams/team_profile.jpg';

  // TEAM USERS
  teamUsers$: Observable<Array<{}>>;
  teamUsersSubscription: Subscription;
  teamUsersList: any;

  teamMenu = 'basicInfo';

  constructor(
    public router: Router,
    private teamsService: TeamsService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private auth: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.teamSubscription = this.teamsService.getTeam(this.teamId).subscribe((teamData) => {
      this.team = teamData;

      // GET TEAM USERS
      this.teamUsers$ = this.teamsService.getTeamUsers(this.teamId);

      this.auth.user.subscribe(userData => {
        if (userData) {
          // TODO -> get team users list to check if current user is owner
          // this.teamUsers$.subscribe(users => {
          //   console.log('team data ', users);
          //   this.teamUsersList = users;
          //   const userTeam = this.teamUsersList.filter(e => e.uid === userData.uid);
          //   this.currentUserTeam = userTeam[0];
          //   console.log('current user team ', this.currentUserTeam);
          // });
          this.user = userData;
        }
      });

      this.isLoading = false;

    });



  }

  changeTab(tab) {
    this.teamMenu = tab;
  }

  getUser(event) {
    if (event.type === 'viewUser') return this.viewUser(event.data);
    if (event.type === 'userChat') return this.openChat(event.data);
  }

  viewUser(user) {
    const modalData = {
      title: `${user.userRef}`,
      userId: user.userId,
      type: user.type,
      userIndex: user.userIndex
    }
    this.alert.openModal(UserViewComponent, modalData, 'modal-small');
  }

  openChat(data) {
    this.router.navigate([`/messages/conversation/create/${data.conversationId}`], { queryParams: { conversationName: data.conversationName } });
  }

  selectRoom() {
    this.alert.openModal(MeetingRoomSelectComponent, { user: this.user, teamUsers: this.teamUsers$, team: this.team }, '');
  }


  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

}
