import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocationBookingComponent } from '../location-booking/location-booking.component';

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html'
})
export class LocationSelectComponent implements OnInit {
  user: any;
  adminUser: any;
  locations = [];

  constructor(
    private storage: StorageService,
    private modalCtrl: ModalController,
    private alert: AlertService
  ) {
  }

  ngOnInit() {
    this.storage.locations.subscribe(locations => {
      this.locations = locations;

      this.locations = this.locations.filter(location => {
        if (location.product) {
          return this.user.product.product === location.product.title || this.user.product.product === 'Classic' && location.product.title !== 'Premium' || this.user.product.product === 'Premium';
        }
      });
    });
  }

  bookLocation(location) {
    this.modalCtrl.dismiss();
    this.alert.openModal(LocationBookingComponent, { location: location, businessHours: location.businessHours, userForBooking: this.user }, '');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
