import { Component, OnInit, OnDestroy } from '@angular/core';
import { PackageInfoComponent } from 'src/app/_shared/components/modals/package-info/package-info.component';
import { Subscription } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { PackageConfirmPurchaseComponent } from 'src/app/_shared/components/modals/package-confirm-purchase/package-confirm-purchase.component';
import { AuthenticationService } from '../auth/auth.service';
import { UsersService } from '../../services/users.service';
import { AlertService } from 'src/app/services/alert.service';
import { NavController } from '@ionic/angular';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-packages',
  templateUrl: './packages-list.page.html'
})
export class PackagesListPage implements OnInit, OnDestroy {

  isLoading: boolean = false;

  productsSubscription: Subscription;
  packages: any;
  extraData: any;

  userSubscriptionPlans: Subscription;
  subscriptionPlans: any[];

  showPlans: boolean;


  constructor(
    private navCtrl: NavController,
    private subscriptionsService: SubscriptionsService,
    private productsService: ProductsService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private alert: AlertService,
    private storageService: StorageService,
    private locationCtrl: Location
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.auth.user.subscribe(user => {
      if (user) {
        this.usersService.fetchUserDetails(`/users/${user.uid}`).subscribe(userDetails => {
          console.log('this.userDetails ', userDetails);
          this.extraData = {
            submit: true,
            type: 'User',
            data: {
              user: userDetails,
              subscription: ''
            }
          };
          this.storageService.showHidePlan.subscribe(value => {
            this.userSubscriptionPlans = this.subscriptionsService.getUserSubscriptions(userDetails.uid).subscribe(subs => {
              this.subscriptionPlans = subs;
              if (this.subscriptionPlans.length > 0) {
                // SET USER PLAN
                const userPlan = this.subscriptionPlans.filter((e) => e.type === 'userSubscription');
                this.extraData.data.subscription = userPlan[0];
                this.showPlans = value;
                this.isLoading = false;
              } else {
                this.showPlans = value;
                this.isLoading = false;
              }
            });
          });
        });
      }
    });

    this.productsSubscription = this.productsService.getPublicProducts().subscribe(productsData => {
      this.packages = productsData;
    });
  }

  goBack() {
    this.locationCtrl.back();
  }

  hidePlans(hidePlans) {
    console.log(hidePlans)
    this.showPlans = hidePlans;
  }

  async openPackageInfoModal(packageInfo) {
    this.alert.openModal(PackageInfoComponent, { package: packageInfo, extraData: this.extraData }, '');
  }

  async openPackagePurchaseModal(packageInfo) {
    this.alert.openModal(PackageConfirmPurchaseComponent, { package: packageInfo, extraData: this.extraData }, '');
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.productsSubscription.unsubscribe();
  }

}
