export const defaultTypes = [
  {
    name: 'Board Room',
    value: false,
    type: 'default'
  },
  {
    name: 'Classroom',
    value: false,
    type: 'default'
  },
  {
    name: 'Banquet',
    value: false,
    type: 'default'
  },
  {
    name: 'Theatre',
    value: false,
    type: 'default'
  },
  {
    name: 'U-Shaped',
    value: false,
    type: 'default'
  }
];
