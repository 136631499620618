import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { User } from 'src/app/_shared/models/users.model';
@Injectable({
  providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class StorageService {
  //MOBILE VIEW OR NOT
  public pubMobileView = new BehaviorSubject<any>({});

  //Platform
  public currentPlatform = new BehaviorSubject<any>({});
  // USER DETAILS
  public userDetails = new BehaviorSubject<User>({});

  // LOCATIONS
  public locations = new BehaviorSubject<any[]>([]);
  public mapLocations = new BehaviorSubject<any[]>([]);
  public locationsGallery = new BehaviorSubject<any[]>([]);

  // SINGLE LOCATION
  public singleLocation = new BehaviorSubject<any>({});

  // LOCATIONS FILTER
  public locationFilter = new BehaviorSubject<any>({});

  // MODALS
  private modal = new BehaviorSubject<any>({});
  currentModal = this.modal.asObservable();
  public blockedModalOpen = new BehaviorSubject<boolean>(false);

  // PRODUCTS
  public product = new BehaviorSubject<any>({});

  public pages = new BehaviorSubject<any>({});

  // PLANS
  public showHidePlan = new BehaviorSubject<boolean>(true);

  // USER LOCATION
  public userLocation = new BehaviorSubject<any>({});

  // CHECKING IN
  public checkingIn = new BehaviorSubject<boolean>(false);

  // TEAM MEETING ROOM BOOKING USERS
  public teamUsersForBooking = new BehaviorSubject<any>([]);
  public meetingRoomCapacity = new BehaviorSubject<number>(null);

  constructor() { }

  // MODALS
  openModal(modal: any) {
    return this.modal.next(modal);
  }

  // PRODUCTS
  selectedProduct(product: any) {
    return this.product.next(product);
  }

}
