import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { MessagesListComponent } from './messages-list/messages-list.page';
import { MessagesConversationsComponent } from './messages-conversations/messages-conversations.page';
import { MessagesConversationsCreateComponent } from './messages-conversations-create/messages-conversations-create.page';
import { LoaderModule } from 'src/app/_shared/loader-component/loader.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    LoaderModule,
    RouterModule,
  ],
  declarations: [
    MessagesListComponent,
    MessagesConversationsComponent,
    MessagesConversationsCreateComponent
  ]
})
export class MessagesModule { }