import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../_services/locations.service';
import { config } from 'src/app/_shared/configs/config';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagesService } from 'src/app/services/images.service';
import { SortablejsOptions } from 'ngx-sortablejs';
import { Subscription } from 'rxjs';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { FilesService } from 'src/app/_shared/components/files/files.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-location-edit-gallery',
  templateUrl: './location-gallery.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditGalleryPage implements OnInit {

  isLoading = false;
  locationId;
  gallerySubscription: Subscription;
  location: any;
  gallery: any;

  // GALLERY
  galleryFeaturedImg: any;
  eventOptions: SortablejsOptions = {
    handle: '.handle'
  };
  saveGallery = false;
  loadingGallery: boolean = false;
  currentPlatform = "";
  // SUBMIT
  isSubmitting = false;

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private locationsService: LocationsService,
    private cameraPlugin: Camera,
    private imagesService: ImagesService,
    private filesService: FilesService,
    private file: File,
    private storageServices: StorageService
  ) { }

  ngOnInit() {
    // this.ionViewDidEnter();
    this.storageServices.currentPlatform.subscribe(data => {
      this.currentPlatform = data;
    })
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.gallerySubscription = this.locationsService.getLocationGallery(this.locationId).subscribe((galleryImages) => {
      this.gallery = galleryImages;
      this.loadingGallery = false;
      this.storage.get('location').then((location) => {
        this.location = location;
        this.galleryFeaturedImg = location.featureImageId;
        this.isLoading = false;
      });
    });
  }

  // GALLERY
  // Take a picture using the phone camera
  takePicture(type) {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingGallery = true;
          this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
            entry.file(file => {
              const imageFile = [file]
              const pathInfo = [{
                filePath: `entities/${config.entityId}/locations/${this.locationId}`,
                dbPath: `entities/${config.entityId}/locations/${this.locationId}/gallery`,
                order: this.gallery.length,
                file: file
              }];
              return this.setGalleryImage(imageFile, pathInfo);
            });
          });
        },
        (error) => {
          console.log('ERROR TAKING PHOTO -> ' + JSON.stringify(error));
        }
      );
  }
  onFileSelectedFromWeb(files) {
    var pathInfo = []; 
    for(var item in files){
      console.log("FILE",files[item])
      var object = {
        filePath: `entities/${config.entityId}/locations/${this.locationId}`,
        dbPath: `entities/${config.entityId}/locations/${this.locationId}/gallery`,
        order: this.gallery.length,
        file: files[item]
      }
      pathInfo.push(object);
    }
    
    this.filesService.handleGalleryDrop(files,pathInfo);
  }
  // Select a picture from the phone gallery
  selectPicture(type) {
      this.cameraPlugin
        .getPicture({
          quality: 90,
          destinationType: this.cameraPlugin.DestinationType.FILE_URI,
          sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
          encodingType: this.cameraPlugin.EncodingType.JPEG,
          targetWidth: 840,
          targetHeight: 840,
          correctOrientation: true
        })
        .then(
          (imageData) => {
            this.loadingGallery = true;
            this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
              entry.file(file => {
                const imageFile = [file]
                const pathInfo = [{
                  filePath: `entities/${config.entityId}/locations/${this.locationId}`,
                  dbPath: `entities/${config.entityId}/locations/${this.locationId}/gallery`,
                  order: this.gallery.length,
                  file: file
                }];
                return this.setGalleryImage(imageFile, pathInfo);
              });
            });
          },
          (error) => {
            console.log('ERROR -> ' + JSON.stringify(error));
          }
        );
  }

  setGalleryImage(galleryArray, pathInfo) {
    this.filesService.handleGalleryDrop(galleryArray, pathInfo);
  }

  // SORT GALLERY
  sortGallery() {
    this.eventOptions = {
      onUpdate: () => {
        this.gallery.forEach((image, index) => {
          image.order = index;
          this.locationsService.updateGalleryImageOrder(this.locationId, image);
        });
      }
    };
  }

  // SET FEATURED IMAGE
  setFeaturedImg(image) {
    console.log('updating image as featured ', image.uid);
    this.galleryFeaturedImg = image.uid;
    const galleryData = {
      featureImageId: image.uid,
      featureImage: image.downloadFile,
      featureImageThumbnail: image.downloadFileThumbnail
    }
    this.locationsService.updateLocation(this.locationId, galleryData);
  }

  // DELETE IMAGE FROM ARRAY
  deleteGalleryImage(galleryImage: any) {
    console.log(galleryImage.uid, this.galleryFeaturedImg);
    if (galleryImage.uid === this.galleryFeaturedImg) {
      this.galleryFeaturedImg = '';
      const galleryData = {
        featureImageId: '',
        featureImage: '',
        featureImageThumbnail: ''
      }
      this.locationsService.updateLocation(this.locationId, galleryData);
    }
    if (this.gallery.length > 1) {
      const index = this.gallery.indexOf(galleryImage);
      this.gallery.splice(index, 1);
      this.gallery.forEach((image, index) => {
        image.order = index;
        this.locationsService.updateGalleryImageOrder(this.locationId, image).then(() => {
          this.locationsService.deleteGalleryImage(this.locationId, galleryImage.uid);
        });
      });
    } else {
      this.locationsService.deleteGalleryImage(this.locationId, galleryImage.uid);
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.gallerySubscription.unsubscribe();
  }

}
