import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { MeetingRoomsService } from '../../_services/meeting-rooms.service';

@Component({
  selector: 'app-meeting-room-edit-details',
  templateUrl: './meeting-room-details.component.html'
})
export class MeetingRoomEditDetailsComponent implements OnInit {
  
  isLoading = false;
  roomId;
  meetingRoom: any;
  meetingRoomEditForm: FormGroup;

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private meetingRoomsService: MeetingRoomsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.roomId = this.route.snapshot.paramMap.get('roomId');
    this.buildForm();
    this.storage.get('meetingRoom').then((meetingRoom) => {
      this.meetingRoom = meetingRoom;
      this.meetingRoomEditForm.patchValue(this.meetingRoom);
      this.isLoading = false;
    });
  }

  buildForm() {
    this.meetingRoomEditForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      sqMetres: ['', [Validators.required]],
      maxPeople: ['', [Validators.required]],
      hourlyRate: ['', [Validators.required]]
    });
  }

  onUpdate(meetingRoomEditForm) {

    const roomData = {
      name: meetingRoomEditForm.name,
      description: meetingRoomEditForm.description,
      sqMetres: meetingRoomEditForm.sqMetres,
      maxPeople: meetingRoomEditForm.maxPeople,
      hourlyRate: meetingRoomEditForm.hourlyRate
    }
    
    this.isSubmitting = true;
    this.meetingRoomsService.updateMeetingRoom(this.roomId, roomData).then(() => {
      this.isSubmitting = false;
      this.meetingRoomEditForm.markAsPristine();
      this.alert.displayToast('Room updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
  }

}
