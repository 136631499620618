import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Platform, MenuController, IonRouterOutlet, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './pages/auth/auth.service';
import { Router } from '@angular/router';
import { AlertService } from './services/alert.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { FcmService } from './services/fcm.service';
import { ProfileService } from './services/profile.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { UnverifiedUserComponent } from './_shared/components/modals/unverified-user/unverified-user.component';
import { Location } from '@angular/common';
import { NotificationsService } from './services/notifications.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { StorageService } from './services/storage.service';
import { BlockedUserComponent } from './_shared/components/modals/blocked-user/blocked-user.component';
import { UsersService } from './services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  userDetails: any;
  isLoading = false;
  pagesStack = [];
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  verifyModalOpen = false;
  userSubscription: Subscription;
  mobileView = true;

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private menuCtrl: MenuController,
    private alertService: AlertService,
    private fcm: FCM,
    private profileService: ProfileService,
    private fcmService: FcmService,
    private localNotifications: LocalNotifications,
    public alertCtrl: AlertController,
    private location: Location,
    private geolocation: Geolocation,
    private notificationsService: NotificationsService,
    private storage: StorageService,
    private usersService: UsersService

  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  initializeApp() {
    this.platform.ready().then(() => {
     

      if (this.platform.is('ios')) {
        console.log("I AM HERE IOS");
        this.statusBar.backgroundColorByHexString('#000000');
        this.storage.currentPlatform.next('ios');
         
      }
      if (this.platform.is('android') ) {
        console.log("I AM HERE Android");
        this.statusBar.styleLightContent();
        this.storage.currentPlatform.next('android'); 
        
      } 
      if (this.platform.is('mobileweb') && !this.platform.is('ios') && !this.platform.is('android')) {
       console.log("I AM HERE mobileweb");
        this.storage.currentPlatform.next('desktop'); 
       
      } 
       if (this.platform.is('desktop')) {
        console.log("I AM HERE desktop");
        this.storage.currentPlatform.next('desktop');
        
      } 
      
      // else {
      //   // this.storage.currentPlatform.next('desktop');
      //   this.statusBar.styleLightContent();
      // }

      this.isLoading = true;

      this.geolocation.getCurrentPosition().then(position => {
        this.storage.userLocation.next(position);
      })
        .catch(error => {
          console.log('error getting user gps position ', error);
        });
        
      this.authenticationService.user.subscribe(user => {
        if (user) {
          this.userSubscription = this.usersService.fetchUserDetails('/users/' + user.uid).subscribe(loggedUser => {
            if (loggedUser.status) {
              this.usersService.checkUserSuspended();
            }
          });

          this.splashScreen.hide();

          if (this.router.url === '/landing') {
            this.router.navigate([`/home`]);
          }

          if (!user.verified && this.router.url !== '/login' && this.router.url !== '/signup' && this.router.url !== '/password-reset' && !this.verifyModalOpen) {
            this.verifyModalOpen = true;
            this.alertService.openModal(UnverifiedUserComponent, {}, 'modal-small');
          }

          this.userDetails = user;

          if (this.platform.is('cordova')) {
            if (this.platform.is('ios')) {
              const w: any = window;
              w.FCMPlugin.requestPushPermissionIOS(() => {
                console.log('getting ios check for permissions');
                this.fcm.getToken().then(token => {
                  this.profileService.saveUserToken(token);
                  console.log('user device token for ios', token);
                });
              }, (error) => {
                console.log('error getting ios permissions for push notifications ', error);
              });
            } else {
              this.fcm.getToken().then(token => {
                this.profileService.saveUserToken(token);
                console.log('user device token for android', token);
              });
            }


            this.localNotifications.on('click').subscribe(notification => {
              console.log('user clicked local notification', notification.data, notification.data.notificationUrl, notification);

              if (notification.data.notificationUrl !== undefined) {
                console.log('clicked on message notification ');
                // this.router.navigate([notification.data.notificationUrl]);
                this.router.navigate(['/notifications-list']);
              } else {
                this.router.navigate(['/notifications-list']);
              }
            });

            this.fcm.onNotification().subscribe(data => {
              if (data.wasTapped) {
                console.log('Received in background', data);
                this.fcmService.handlePushNotification(data);
              } else {
                console.log('Received in foreground', data);
                //SAVE TO THAT DOCUMENTS
                if (data.msgTitle != null) {
                  this.fcmService.createLocalPushNotification(data, this.userDetails.uid);
                }
              }
            });
          }
        } else {
          this.router.navigate(['/landing']);
          this.splashScreen.hide();
          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
        this.isLoading = false;
      });



    });
  }

  ngOnInit() {
    if (window.innerWidth < 744) {
      this.mobileView = true;
      this.storage.pubMobileView.next(true);
    } else {
      this.mobileView = false;
      this.storage.pubMobileView.next(false);

    }

  }

  resizeScreen(event) {
    if (event.target.innerWidth < 744) {
      this.mobileView = true;
      this.storage.pubMobileView.next(true);
    } else {
      this.mobileView = false;
      this.storage.pubMobileView.next(false);
    }
  }

  async logOut() {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Yes',
        handler: () => {
          this.authenticationService.signOutUser();
          console.log('user is logged out');
          this.router.navigate(['/landing']);
          this.menuCtrl.close();
        }
      }
    ];
    this.alertService.displayAlert('Log Out', 'Are you sure you want to log out?', buttons);
  }

  navigateToLanding() {
    this.router.navigate(['/landing']);
    this.menuCtrl.close();
  }

  navigateToProfileEdit() {
    this.router.navigate([`/profile`]);
    this.menuCtrl.close();
  }

  navigateToPage(url, tab?) {
    if (tab === 'bookings') {
      this.router.navigate([url], { queryParams: { tab: 'bookings' } });
    } else {
      this.router.navigate([url]);
    }
  }

  backButtonEvent() {
    this.platform.backButton.subscribe((back) => {
      console.log('back line 162', back)
    });
    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('HELLO TOP')
      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (this.router.url != '/home') {
          this.location.back();
        } else if (this.router.url === '/home') {
          if (new Date().getTime() - this.lastTimeBackPress >= this.timePeriodToExit) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator['app'].exitApp();
          }
        }
      });
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: 'Close App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });

    await alert.present();
  }
}
