import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, IonSlides } from '@ionic/angular';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { LocationsService } from 'src/app/pages/location/_services/locations.service';
import { CalendarEventEditComponent } from '../modals/calendar-event-edit/calendar-event-edit.component';
import * as moment from 'moment';
import { CalendarComponent } from 'ion2-calendar';

@Component({
  selector: 'app-bookings-calendar-component',
  templateUrl: './bookings-calendar.component.html'
})
export class BookingsCalendarComponent implements OnInit {

  isLoading = false;
  @Input() calendarType: any;
  @Input() userId: any;
  @Input() locationId: any;
  @Input() roomId: any;
  @Input() calendarData: any;

  locationsSubscription: Subscription;
  bookingSubscription: Subscription;
  events: any;
  allEvents;
  locations: any;
  activeDayIsOpen: boolean;

  viewTitle = '';

  minDate = new Date().toISOString();

  calendar = {
    mode: 'month',
    currentDate: new Date(),
    startHour: 6,
    endHour: 20
  };

  swiper: any;

  @ViewChild(CalendarComponent, { static: false }) bookingsCalendar: CalendarComponent;
  @ViewChild('.swiper-container', { static: false }) slides: IonSlides;

  constructor(
    private bookingsService: BookingsService,
    private modalCtrl: ModalController
  ) { }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.isLoading = true;
    this.fetchBookings(this.calendarType);
  }

  fetchBookings(type) {
    if (type === 'userBookings') {
      this.bookingSubscription = this.bookingsService.getUserBookings(this.userId).subscribe((events: any) => {
        if (events.length > 0) {
          events.forEach((eventDetails, index) => {
            if (eventDetails.booking.type === 'event') {
              eventDetails.desc = 'Event Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'meetingRoom') {
              eventDetails.desc = 'Meeting Room Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'location') {
              eventDetails.desc = 'Location Booking';
            }
            if (index === events.length - 1) {
              this.isLoading = false;
            }
          });
          this.events = events;
          this.allEvents = events;
        } else {
          this.isLoading = false;
        }
      });
    }
    if (type === 'locationBookings') {
      this.bookingSubscription = this.bookingsService.getLocationBookings(this.locationId).subscribe((events: any) => {
        if (events.length > 0) {
          events.forEach((eventDetails, index) => {
            if (eventDetails.booking.type === 'event') {
              eventDetails.desc = 'Event Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'meetingRoom') {
              eventDetails.desc = 'Meeting Room Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'location') {
              eventDetails.desc = 'Location Booking';
              eventDetails.allDay = true;
            }
            if (index === events.length - 1) {
              this.isLoading = false;
            }
          });
          this.events = events;
          this.allEvents = events;
        } else {
          this.isLoading = false;
        }
      });
    }
    if (type === 'meetingRoomBookings') {
      this.bookingSubscription = this.bookingsService.getMeetingRoomBookings(this.roomId).subscribe((events: any) => {
        if (events.length > 0) {
          events.forEach((eventDetails, index) => {
            if (eventDetails.booking.type === 'event') {
              eventDetails.desc = 'Event Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'meetingRoom') {
              eventDetails.desc = 'Meeting Room Booking';
              eventDetails.allDay = false;
            }
            if (eventDetails.booking.type === 'location') {
              eventDetails.desc = 'Location Booking';
              eventDetails.allDay = true;
            }
            if (index === events.length - 1) {
              this.isLoading = false;
            }
          });
          this.events = events;
          this.allEvents = events;
        } else {
          this.isLoading = false;
        }
      });
    }
    this.activeDayIsOpen = false;
  }

  changeCalendarView(type) {
    this.calendar.mode = type;
  }

  rangeChanged(event) {
    this.fetchBookings(this.calendarType);
  }


  next() {
    if (this.calendar.mode === 'month') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setMonth(this.calendar.currentDate.getMonth() + 1));
    }
    if (this.calendar.mode === 'week') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setDate(this.calendar.currentDate.getDate() + 7));
    }
    if (this.calendar.mode === 'day') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setDate(this.calendar.currentDate.getDate() + 1));
    }
  }

  back() {
    if (this.calendar.mode === 'month') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setMonth(this.calendar.currentDate.getMonth() - 1));
    }
    if (this.calendar.mode === 'week') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setDate(this.calendar.currentDate.getDate() - 7));
    }
    if (this.calendar.mode === 'day') {
      this.calendar.currentDate = new Date(this.calendar.currentDate.setDate(this.calendar.currentDate.getDate() - 1));
    }
  }

  async onEventSelected(eventInfo) {
    console.log('event is selected ', eventInfo);
    const modal = await this.modalCtrl.create({
      component: CalendarEventEditComponent,
      componentProps: {
        event: eventInfo,
        bookingType: this.calendarType,
        type: eventInfo.type
      }
    });
    modal.present();
  }

  onCurrentDateChanged($event) {

  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  onTimeSelected(event) {
    // const selected = new Date(event.selectedTime);
    // this.event.startTime = selected.toISOString();
    // selected.setHours(selected.getHours() + 1);
    // this.event.endTime = (selected.toISOString());
  }

  onDestroy() {
    if (this.bookingSubscription) {
      this.bookingSubscription.unsubscribe();
    }
    if (this.locationsSubscription) {
      this.locationsSubscription.unsubscribe();
    }
  }

}
