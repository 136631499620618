import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { BookingsService } from '../pages/bookings/_services/bookings.service';
import { SubscriptionsService } from './subscriptions.service';
import { AlertService } from './alert.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckinService {
  userId;

  constructor(
    private actionSheetController: ActionSheetController,
    private bookingsService: BookingsService,
    private subscriptionsService: SubscriptionsService,
    private alertCtrl: AlertController,
    private alert: AlertService,
    private storageService: StorageService
  ) { }

  async displayCheckinActionSheet(userId, locationInfo) {
    this.userId = userId;

    const actionSheet = await this.actionSheetController.create({
      header: 'Check-In',
      cssClass: 'my-custom-class',
      buttons: [
        //   {
        //   text: 'QR Code Check-In',
        //   icon: 'caret-forward-circle',
        //   handler: () => {
        //     console.log('QR Code checkin clicked');
        //     this.handleCheckIn(userId,locationInfo)
        //   }
        // }, 
        {
          text: 'Manual Check-In',
          icon: 'arrow-forward',
          handler: (): any => {
            console.log('Manual Checked-in clicked');
            this.handleCheckIn(userId, locationInfo);
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: (): any => {
            console.log('Cancel clicked');
            this.storageService.checkingIn.next(false);
          }
        }]
    });
    actionSheet.present();
  } // displayCheckinActionSheet

  private async handleCheckIn(userUid, locationInfo) {
    console.log("locationInfo",locationInfo);
    const locationobject = {
      name: locationInfo.name,
      refNo: locationInfo.refNo,
      uid: locationInfo.uid,
    };

    var userTierSubscription = await this.subscriptionsService.getUserSubscriptionsOnceOff(userUid.uid);
    if (userTierSubscription.docs.length > 1) {
      this.handleMultipleSubscriptions(userTierSubscription.docs, locationInfo.bookingId, locationobject);
    } else {
      this.checkIn(locationInfo.bookingId, userTierSubscription.docs[0].data(), locationobject);
    }
  } // handleCheckIn

  private async handleMultipleSubscriptions(userTierSubscriptions, bookingId, locationobject) {
    let inputs = [];
    userTierSubscriptions.forEach((sub, i) => {
      sub = sub.data();
      if (sub.type === 'userSubscription' && !sub.suspended || sub.type === 'teamSubscription' && sub.teamId && !sub.suspended) {
        const subName = (sub.type === 'userSubscription') ? 'Personal' : sub.teamName;
        const subData = {
          name: `sub-${sub.type}`,
          type: 'radio',
          id: `radio-${i}`,
          value: sub,
          label: `${sub.product} (${subName})`
        };
        inputs.push(subData);
      }
    });
    const alertPopup = await this.alertCtrl.create({
      header: 'Select Subscription For Booking',
      buttons: [{
        text: 'Proceed',
        handler: data => {
          this.checkIn(bookingId, data, locationobject)
        }
      }],
      inputs: inputs
    });
    alertPopup.present();
  }


  private async checkIn(bookingId, userTierSubscriptions, location) {
    const data = {
      checkedIn: true
    };
    
    this.bookingsService.updateBooking(data, bookingId).then(() => {
      this.bookingsService.createCheckIn(location, bookingId, userTierSubscriptions).then(() => {
        console.log('checking data ', bookingId)
        this.bookingsService.updateUserBooking(this.userId, { uid: bookingId, checkedIn: true });
        this.alert.displayToast('Check in successful');
        this.storageService.checkingIn.next(false);
        // console.log("success with checkin")
      }).catch(err => {
        console.log("top error");
      console.log(err);
        this.alert.displayToast('There was an error checking in.');
        this.storageService.checkingIn.next(false);
      });
    }).catch(err => {
      console.log("bottom error");
      console.log(err);
      this.alert.displayToast('There was an error checking in.');
      this.storageService.checkingIn.next(false);
    });
  }
}
