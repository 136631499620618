import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.scss']
})
export class UsersComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
