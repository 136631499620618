import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { LocationsService } from '../../_services/locations.service';
import { weekdays } from '../../models/weekdays';
import * as moment from 'moment';

@Component({
  selector: 'app-location-edit-amenities',
  templateUrl: './location-amenities.page.html',
  styleUrls: ['../../location.scss']
})
export class LocationEditAmenitiesPage implements OnInit {
  
  isLoading = false;
  locationId;
  location: any;
  amenities: any;
  locationEditForm: FormGroup;
  amenitiesSubscription: Subscription;

  // AMENITIES
  defaultAmenities = [];
  extraAmenities = [];
  customAmenities = [];
  showDefaultAmenities = true;
  showExtraAmenities = false;
  showCustomAmenities = false;

  // SUBMIT
  isSubmitting = false;
  
  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private storage: Storage,
    private alert: AlertService,
    private locationsService: LocationsService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.locationId = this.route.snapshot.paramMap.get('locationId');
    this.buildForm();
    this.amenitiesSubscription = this.locationsService.getLocationAmenities(this.locationId).subscribe((amenities: any) => {
      this.amenities = amenities.list;
      this.defaultAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'default';
      });

      this.extraAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'extra';
      });

      this.customAmenities = this.amenities.filter(amenity => {
        return amenity.type === 'custom';
      });
        
      this.storage.get('location').then((location) => {
        this.location = location;
      });
      this.isLoading = false;
    });
  }

  buildForm() {
    this.locationEditForm = this.fb.group({
      amenity: ['', [Validators.maxLength(50)]]
    });
  }

  // AMENITIES
  amenityChanged(name, type) {
    type.forEach(amenity => {
      if (amenity.name === name) {
        amenity.value = !amenity.value;
      }
    });
    this.locationEditForm.markAsDirty();
  }

  checkAmenitiesForSelected() {
    const defaultArray = this.defaultAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    const extraArray = this.extraAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    const customArray = this.customAmenities.filter(amenity => {
      return amenity.value === true;
    }).length > 0;
    if (defaultArray || extraArray || customArray) {
      return true;
    } else {
      return false;
    }
  }

  addAmenity(name) {
    if (!this.customAmenities.some(amenity => amenity.name.toLowerCase() === name.toLowerCase())) {
      this.customAmenities.push({
        name: name,
        value: true,
        type: 'custom'
      });
      this.locationEditForm.get('amenity').reset();
      this.locationEditForm.get('amenity').markAsDirty();
    }
  }

  onUpdate(locationEditForm) {

    let amenitiesList = this.defaultAmenities.concat(this.extraAmenities).concat(this.customAmenities);
    this.amenities = amenitiesList;

    this.isSubmitting = true;
    this.locationsService.updateAmenitiesHours(this.locationId, amenitiesList).then(() => {
      this.isSubmitting = false;
      this.locationEditForm.markAsPristine();
      this.alert.displayToast('Location updated successfully');
    }).catch(error => {
      this.alert.displayToast(`Error: ${error}`);
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  ionViewDidLeave() {
    this.showDefaultAmenities = true;
    this.showExtraAmenities = false;
    this.showCustomAmenities = false;
    this.amenitiesSubscription.unsubscribe();
  }

}
