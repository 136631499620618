import { PopoverController, NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagesService } from 'src/app/services/images.service';
import { AlertService } from 'src/app/services/alert.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ShareRefComponent } from 'src/app/_shared/components/modals/share-ref/share-ref.component';
import { Subscription } from 'rxjs';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { User } from 'src/app/_shared/models/users.model';
import { ProfilePopoverComponent } from 'src/app/_shared/components/modals/profile-popover/profile-popover.component';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { ActivatedRoute } from '@angular/router';
import { PackageInfoComponent } from 'src/app/_shared/components/modals/package-info/package-info.component';
import { QRCodeComponent } from 'src/app/_shared/components/modals/qr-code/qr-code.component';
import { UsersService } from '../../../services/users.service';
import { StorageService } from 'src/app/services/storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { config } from 'src/app/_shared/configs/config';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FilesService } from 'src/app/_shared/components/files/files.service';


@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.page.html',
  styleUrls: ['../users.scss']
})
export class ProfileEditPage implements OnInit {
  previous;
  profileEditForm: FormGroup;
  accountForm: FormGroup;
  userDetails: any;
  user: User;
  isLoading = false;
  saving = false;
  isLoadingButton = false;
  showProfileImageSave = false;
  showProfileImageCropper = false;
  profileImageChanged = false;
  profileImage: any;
  encodeData = '';

  // SUBSCRIPTION
  subscriptionDetails = {
    submit: false,
    type: '',
    data: {
      user: {},
      subscription: ''
    }
  };
  userSubscriptionPlans: Subscription;
  subscriptionPlans: any[];
  hideProductSelection = false;
  userSubscriptionPlan: any;
  teamSubscriptionPlans: any;
  subscriptionPlan: Subscription;
  subscription: any;
  userSubscription: Subscription;

  profileMenu = 'basicInfo';
  currentTab = 1;
  showDropdown = false;

  croppedImage: any = '';
  loadedImage: any = '';

  provinces = Provinces;

  currentPlatform: string;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private cameraPlugin: Camera,
    private imagesService: ImagesService,
    private profileService: ProfileService,
    private alertService: AlertService,
    public clipboard: Clipboard,
    private subscriptionsService: SubscriptionsService,
    private usersService: UsersService,
    private popoverCtrl: PopoverController,
    private route: ActivatedRoute,
    private storage: StorageService,
    private nav: NavController,
    private fileService: FilesService
  ) { }

  ngOnInit() {
    this.ionViewDidEnter();

    this.storage.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })
  }

  ionViewDidEnter() {
    this.isLoading = true;
    this.buildForm();

    this.route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.profileMenu = params['tab'];
      }
    });

    this.authenticationService.user.subscribe(user => {
      if (user) {
        this.userDetails = user;

        this.userSubscription = this.usersService.fetchUserDetails(`/users/${this.userDetails.uid}`).subscribe((userData: User) => {
          this.user = userData;
          this.subscriptionDetails.submit = true;
          this.subscriptionDetails.type = 'User';
          this.subscriptionDetails.data.user = this.user;

          this.subscriptionDetails = {
            submit: true,
            type: 'User',
            data: {
              user: this.user,
              subscription: ''
            }
          };

          this.profileEditForm.patchValue(this.user);
          this.profileEditForm.markAsPristine();

          this.isLoading = false;
        });


        this.userSubscriptionPlans = this.subscriptionsService.getUserSubscriptions(this.userDetails.uid).subscribe(subs => {
          this.subscriptionPlans = subs;
          if (this.subscriptionPlans.length > 0) {

            // SET USER PLAN
            const userPlan = this.subscriptionPlans.filter((e) => e.type === 'userSubscription');
            this.hideProductSelection = (!userPlan[0]) ? false : true;
            this.userSubscriptionPlan = userPlan[0];
            this.subscriptionDetails.data.subscription = this.userSubscriptionPlan;

            // SET TEAM PLANS
            this.teamSubscriptionPlans = this.subscriptionPlans.filter((e) => e.type === 'teamSubscription' && e.teamId);
          } else {
            this.hideProductSelection = false;
            this.userSubscriptionPlan = '';
            this.subscriptionDetails.data.subscription = '';
          }
        });
      } else {
        this.isLoading = false;
      }
    }); 
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log('image cropped event what we want to upload ', event);
    this.croppedImage = event.base64;
    this.user.uploadedPhotoThumbnail = event.base64;
    const imageData = event.base64.toString().replace('data:image/png;base64,', '');
    console.log('image data ', imageData);
    this.user.uploadedPhoto = imageData;
    this.showProfileImageSave = true;
    console.log('this is the image data ', this.croppedImage);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  goBack() {
    this.nav.back();
  }

  buildForm() {
    this.profileEditForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: [''],
      gender: [''],
      ageGroup: [''],
      industrySector: ['']
    });
  }

  async openProfilePopover() {
    const popover = await this.popoverCtrl.create({
      component: ProfilePopoverComponent,
      cssClass: 'profile-popover',
      componentProps: {
        user: this.user
      }
    });
    popover.present();
  }

  saveUserProfile(user) {
    this.saving = true;
    user.uid = this.userDetails.uid;
    user.firebaseId = this.userDetails.firebaseId;
    user.status = this.userDetails.status;
    user.refNo = this.user.refNo;
    user.photoURL = this.user.photoURL;
    user.uploadedPhoto = this.user.uploadedPhoto;
    user.uploadedPhotoThumbnail = this.user.uploadedPhotoThumbnail;
    if (this.user.billingInfo) {
      user.billingInfo = this.user.billingInfo;
    } else {
      user.billingInfo = {};
    }
    user.email = this.profileEditForm.get('email').value.toLowerCase();

    if (this.profileEditForm.controls.email.dirty && user.email) {

      this.authenticationService
        .updateUserEmail(user.email)
        .then(() => {
          this.usersService
            .updateUser(user)
            .then(() => {
              this.saving = false;
              this.profileEditForm.markAsPristine();
              this.profileEditForm.markAsUntouched();
            })
            .catch(err => {
              this.saving = false;
            });
        })
        .catch(err => {
          // YOU HAVE TO HAVE LOGGED IN RECENTLY TO CHANGE AN EMAIL ADDRESS
          if (err.code === 'auth/requires-recent-login') {
            this.saving = false;
            this.profileEditForm.markAsPristine();
            this.profileEditForm.markAsUntouched();
          } else {
            this.saving = false;
          }
        });
    } else {
      this.authenticationService.updateUserDetails(user).then(() => {
        this.saving = false;
        this.profileEditForm.markAsPristine();
        this.alertService.displayToast('User details updated successfully');
      });
    }
  }

  viewPlanDetails(plan) {
    plan.origin = 'profile';
    this.alertService.openModal(PackageInfoComponent, { package: plan }, '');
  }

  sendVerificationEmail() {
    this.isLoadingButton = true;
    if (this.profileEditForm.get('email')) {
      this.authenticationService.sendEmailVerificationRequest(this.userDetails).then(() => {
        this.alertService.displayToast('Verification request email sent');
        this.isLoadingButton = false;
      });
    } else {
      this.isLoadingButton = false;
      this.alertService.displayAlert('Invalid email', 'Please enter a valid email', [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]);
    }
  }

  // Take a picture using the phone camera
  takePicture() {
    console.log("CURRENT VIeW", this.currentPlatform);
    if (this.currentPlatform != "desktop") {
      this.profileImageChanged = true;
      this.cameraPlugin
        .getPicture({
          quality: 100,
          destinationType: this.cameraPlugin.DestinationType.DATA_URL,
          sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
          encodingType: this.cameraPlugin.EncodingType.JPEG,
          targetWidth: 840,
          targetHeight: 840,
          allowEdit: false,
          correctOrientation: true,
          saveToPhotoAlbum: true
        })
        .then(
          (imageData) => {
            console.log('this is what we must upload ', imageData);
            this.user.uploadedPhoto = imageData;
            this.user.uploadedPhotoThumbnail = 'data:image/png;base64,' + imageData;
            this.loadedImage = 'data:image/png;base64,' + imageData;
            this.showProfileImageCropper = true;
          },
          (error) => {
            console.log('ERROR TAKING PHOTO -> ' + JSON.stringify(error));
          }
        );
    }
  }

  // Select a picture from the phone gallery
  selectPicture() {
    if (this.currentPlatform != "desktop") {
      this.cameraPlugin
        .getPicture({
          quality: 100,
          destinationType: this.cameraPlugin.DestinationType.DATA_URL,
          sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
          encodingType: this.cameraPlugin.EncodingType.JPEG,
          targetWidth: 840,
          targetHeight: 840,
          allowEdit: false,
          correctOrientation: true,
          saveToPhotoAlbum: true
        })
        .then(
          (imageData) => {
            this.user.uploadedPhoto = imageData;
            this.user.uploadedPhotoThumbnail = 'data:image/png;base64,' + imageData;
            this.loadedImage = 'data:image/png;base64,' + imageData;
            this.showProfileImageCropper = true;
          },
          (error) => {
            console.log('ERROR -> ' + JSON.stringify(error));
          }
        );
    }
  }
  onFileSelectedFromWeb(event) {
    console.log("Incoming file", event[0]);
    const pathInfo = {
      filePath: `users/entities/${config.entityId}/users/${this.user.uid}`,
      dbPath: `users/${this.user.uid}`
    };
    this.fileService.handleDrop(event, pathInfo);


  }

  async saveProfileImage() {
    this.showProfileImageSave = false;
    this.showProfileImageCropper = false;
    try {
      const saveRef = `/entities/${config.entityId}/users/${this.user.uid}`;
      const dbRef = `users`;
      const imageName = `${this.user.uid}_profile`;
      this.imagesService.saveImage(this.user.uploadedPhoto, saveRef, dbRef, this.user.uid, imageName).then(() => {
        // this.usersService.updateUser(this.user);
      });
    } catch (error) {
      this.showProfileImageSave = false;
      console.log('IMAGE COULD NOT BE SAVED ', error);
    }
  }

  cancelProfileImage() {
    this.user.uploadedPhoto = '';
    this.user.uploadedPhotoThumbnail = '';
    this.showProfileImageSave = false;
    this.showProfileImageCropper = false;
  }

  changeTab(tabName) {
    this.profileMenu = tabName;
  }

  copyRefCode() {
    this.clipboard.copy(this.userDetails.refNo);
    this.alertService.displayToast('Copied to clipboard');
  }

  openSharingModal() {
    this.alertService.openModal(ShareRefComponent, {}, 'sharing-modal');
  }

  openQRModal() {
    const mobile = (this.user.contactNumber) ? this.user.contactNumber : '';
    const modalData = {
      modalId: 'qrCodeModal',
      title: `${this.user.firstname} ${this.user.surname} - VCARD`,
      type: 'view',
      uid: `BEGIN:VCARD
VERSION:3.0
N:${this.user.firstname} ${this.user.surname}
FN:${this.user.firstname} ${this.user.surname}
EMAIL:${this.user.email}
TEL;TYPE=voce,work,pref:${mobile}
END:VCARD
`
    }
    this.alertService.openModal(QRCodeComponent, { data: modalData }, 'modal-small');
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `Pending`;
        break;
      case 2:
        return `Active`;
        break;
      default:
    }
  }

  ionViewDidLeave() {
    this.userSubscription.unsubscribe();
    this.userSubscriptionPlans.unsubscribe();
  }

}
