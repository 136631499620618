import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SignupPage } from './signup/signup.page';
import { LoginPage } from './login/login.page';
import { LandingPage } from './landing/landing.page';
import { PasswordResetPage } from './password-reset/password-reset.page';
import { LoaderModule } from '../../_shared/loader-component/loader.module';
import { RouterModule } from '@angular/router';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { VerfificationPage } from './verification/verification.page';
import { SocialLoginComponent } from './components/social-logins/social-logins';

@NgModule({
  imports: [ 
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    LoaderModule,
    RouterModule
  ],
  declarations: [
    SignupPage,
    LoginPage,
    LandingPage,
    PasswordResetPage,
    VerfificationPage,
    SocialLoginComponent
  ],
  exports: [
    SignupPage,
    LoginPage,
    LandingPage,
    PasswordResetPage,
    VerfificationPage,
    SocialLoginComponent
  ],
  providers: [
    SignInWithApple
  ]
})
export class AuthModule { }
