import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { Provinces } from 'src/app/_shared/models/provinces.list';
import { config } from 'src/app/_shared/configs/config';
import { Camera } from '@ionic-native/camera/ngx';
import { Subject, Subscription } from 'rxjs';
import { weekdays } from '../models/weekdays';

import * as moment from 'moment';
import { User } from 'src/app/_shared/models/users.model';
import { defaultAmenities, extraAmenities } from '../models/amenities';
import { SortablejsOptions } from 'ngx-sortablejs';
import { LocationsService } from '../_services/locations.service';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-location-add',
  templateUrl: './location-add.page.html',
})
export class LocationAddPage implements OnInit {
  locationAddForm: FormGroup;
  location: any = {};
  user: User;
  step = 1;
  provinces = Provinces;
  locationImage: any;
  entityId = config.entityId;

  showDefaultAmenities = true;
  showExtraAmenities = false;
  showCustomAmenities = false;

  defaultAmenities = [];
  extraAmenities = [];
  customAmenities = [];
  businessDays = weekdays;

  addedUser = [];

  isLoading = false;
  isSubmitting = false;

  saveImage = false;
  saveGallery = false;
  imgPreview = 'assets/images/locations/location_profile.jpg';
  galleryFeaturedImg;
  galleryImages: any[] = [];
  galleryUrlImages: any[] = [];
  loadingGallery: boolean = false;
  locationLogo: any[] = [];
  logoSubscription: Subscription;

  closedTime;

  eventOptions: SortablejsOptions = {
    handle: '.handle'
  };

  mapHeight = '200px';
  showAddress = false;
  setAddress: string;
  formatted_address: string;
  resetFormAddress: Subject<boolean> = new Subject<boolean>();

  //currentPlatform
  currentPlatform: string;

  constructor(
    private fb: FormBuilder,
    private cameraPlugin: Camera,
    private auth: AuthenticationService,
    private router: Router,
    private locationService: LocationsService,
    private alertService: AlertService,
    private file: File,
    public sanitizer: DomSanitizer,
    private navCtrl: NavController,
    private base64: Base64,
    private sorageService: StorageService,

  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
    this.sorageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })
  }

  ionViewDidEnter() {
    this.buildAddLocationsForm();
    this.step = 1;
    this.defaultAmenities = defaultAmenities;
    this.extraAmenities = extraAmenities;
    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;

        user.isAdmin = true;
        user.isOwner = true;
        user.invited = false;
        this.addedUser.push(user);
        this.location.users = this.addedUser;
        this.location.owner = {
          uid: user.uid,
          firstname: user.firstname,
          surname: user.surname,
          email: user.email,
        };
      }
    });
  }

  buildAddLocationsForm() {
    this.locationAddForm = this.fb.group({
      basicInfo: this.fb.group({
        name: ['', [Validators.required]],
        type: ['', [Validators.required]],
        typeOther: [''],
        description: ['', [Validators.required]],
        contactNumber: ['', [Validators.required]],
        noiseLevel: ['', Validators.required],
        parking: ['', Validators.required],
        availableSpaces: ['', Validators.required],
        meetingRooms: ['', Validators.required],
        eventSpace: ['', Validators.required]
      }),
      locationAddress: this.fb.group({
        address: ['', [Validators.required]],
        suburb: ['', [Validators.required]],
        city: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        country: ['', [Validators.required]],
        region: ['', [Validators.required]],
        latitude: [null, [Validators.required]],
        longitude: [null, [Validators.required]],
        floorNo: [''],
      }),
      businessHours: this.fb.group({
        monday: this.fb.group(this.timeFields()),
        tuesday: this.fb.group(this.timeFields()),
        wednesday: this.fb.group(this.timeFields()),
        thursday: this.fb.group(this.timeFields()),
        friday: this.fb.group(this.timeFields()),
        saturday: this.fb.group(this.timeFields()),
        sunday: this.fb.group(this.timeFields())
      }),
      amenities: this.fb.group({
        amenity: ['', [Validators.maxLength(50)]]
      })
    });
  }

  private timeFields() {
    return {
      open: ['', [Validators.required]],
      close: ['', [Validators.required]]
    };
  }

  onTypeChange(event) {
    console.log('event ', event.target.value);
    let val = event.target.value;
    if (val === 'other') {
      this.locationAddForm.get('basicInfo.typeOther').setValidators(Validators.required);
      this.locationAddForm.get('basicInfo.typeOther').updateValueAndValidity();
    } else {
      this.locationAddForm.get('basicInfo.typeOther').reset();
      this.locationAddForm.get('basicInfo.typeOther').clearValidators();
      this.locationAddForm.get('basicInfo.typeOther').updateValueAndValidity();
    }
  }

  // BUSINESS HOUR FIELDS
  applyToAllDays(type) {
    let hours = this.locationAddForm.get('businessHours');
    hours.markAsDirty();
    if (type === 'weekdays') {
      const open = hours.get('monday').get('open').value;
      const close = hours.get('monday').get('close').value;
      hours.get('tuesday').get('open').patchValue(open);
      hours.get('tuesday').get('close').patchValue(close);
      hours.get('wednesday').get('open').patchValue(open);
      hours.get('wednesday').get('close').patchValue(close);
      hours.get('thursday').get('open').patchValue(open);
      hours.get('thursday').get('close').patchValue(close);
      hours.get('friday').get('open').patchValue(open);
      hours.get('friday').get('close').patchValue(close);
    }
    if (type === 'weekend') {
      const open = hours.get('saturday').get('open').value;
      const close = hours.get('saturday').get('close').value;
      hours.get('sunday').get('open').patchValue(open);
      hours.get('sunday').get('close').patchValue(close);
    }
  }

  setDayClosed(event, day) {
    let hours = this.locationAddForm.get('businessHours');
    hours.markAsDirty();
    if (event.target.checked) {
      const midnight = moment().startOf('day');
      const isoMidnight = midnight.toISOString();
      this.closedTime = isoMidnight;
      hours.get(day).get('open').patchValue(isoMidnight);
      hours.get(day).get('close').patchValue(isoMidnight);
    } else {
      hours.get(day).get('open').patchValue('');
      hours.get(day).get('close').patchValue('');
    }
  }

  checkIfClosed(day) {
    if (day) {
      let hours = this.locationAddForm.get('businessHours').get(day);
      console.log(hours);
      if (hours.get('open').value.hour === 0 && hours.get('open').value.minute === 0 && hours.get('close').value.hour === 0 && hours.get('close').value.minute === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  // AMENITIES
  amenityChanged(name, type) {
    console.log('amenity and type ', name, type);
    type.forEach(amenity => {
      if (amenity.name.toLowerCase() === name.toLowerCase()) {
        amenity.value = !amenity.value;
      }
    });
  }

  addAmenity(name) {
    console.log('amenity name ', name);
    if (!this.customAmenities.some(amenity => amenity.name.toLowerCase() === name.toLowerCase())) {
      console.log('pushing to custom amenities ', name, this.customAmenities);
      this.customAmenities.push({
        name: name,
        value: true,
        type: 'custom'
      });
      this.locationAddForm.get('amenities.amenity').reset();
      this.locationAddForm.get('amenities.amenity').markAsPristine();
    }
  }

  // SORT GALLERY
  sortGallery() {
    this.eventOptions = {
      handle: '.handle',
      onUpdate: () => {
        this.galleryImages.forEach((image, index) => {
          image.order = index;
        });
      }
    };
  }

  // SET FEATURED IMAGE
  setFeaturedImg(tempId) {
    this.galleryFeaturedImg = tempId;
  }

  // DELETE IMAGE FROM ARRAY
  deleteGalleryImage(file: any) {
    if (file.tempId === this.galleryFeaturedImg) {
      this.galleryFeaturedImg = '';
    }
    const index = this.galleryImages.indexOf(file);
    this.galleryImages.splice(index, 1);
    this.galleryImages.forEach((image, index) => {
      image.order = index;
    });
  }

  // ADDRESS FIELDS
  getMapAddress(address) {
    if (address) {
      this.showAddress = true;
      if (address.physicalAddress) {
        this.locationAddForm.get('locationAddress.address').patchValue(address.physicalAddress);
      } else {
        this.locationAddForm.get('locationAddress.address').patchValue(' ');
      }
      if (address.sublocality_level_1) {
        this.locationAddForm.get('locationAddress.suburb').patchValue(address.sublocality_level_1);
      } else {
        this.locationAddForm.get('locationAddress.suburb').patchValue(' ');
      }
      if (address.postal_code) {
        this.locationAddForm.get('locationAddress.postalCode').patchValue(address.postal_code);
      } else {
        this.locationAddForm.get('locationAddress.postalCode').patchValue(' ');
      }
      this.locationAddForm.get('locationAddress.city').patchValue(address.locality);
      this.locationAddForm.get('locationAddress.region').patchValue(address.administrative_area_level_1);
      this.locationAddForm.get('locationAddress.country').patchValue(address.country);
      this.locationAddForm.get('locationAddress.latitude').patchValue(address.latitude);
      this.locationAddForm.get('locationAddress.longitude').patchValue(address.longitude);
      this.formatted_address = address.formatted_address;
    }
  }
 
  // TAKE PICTURE FOR PROFILE PICTURE
  async takePictureForProfile(type) {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          if (type === 'logo') {
            this.imgPreview = '';
            this.isLoading = true;
            const logo: any = [];
            try {
              this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
                entry.file(file => {
                  console.log("FILE");
                  logo.push(file);
                  this.locationLogo = logo;
                  var reader = new FileReader();
                  const onLoadPromise: Promise<any> = new Promise(resolve => {
                    reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                    reader.readAsDataURL(file);
                  })
                  onLoadPromise.then(val => {
                    console.log("BASE64 PROFILE IMG");
                    this.imgPreview = val;
                    this.isLoading = false;
                  });
                });
              });
            } catch (errO) {
              console.log("errO");
              console.log(errO);
            }
          }
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  // TAKE PICTURE
  async takePictureForGallery(type) {

    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.CAMERA,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingGallery = true;

          var imageFile;
          const promise: Promise<any> = new Promise<any>((resolve) => {
            this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
              entry.file(async file => {
                // CONVERTING FILEENTRY TO BASE64
                var reader = new FileReader();
                var base64;


                const onLoadPromise: Promise<any> = new Promise(resolve => {
                  reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                  base64 = reader.readAsDataURL(file);
                })

                onLoadPromise.then(val => {
                  console.log("BASE 64", val);
                  imageFile = {
                    order: this.galleryImages.length,
                    url: val,
                    tempId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                  }
                  if (imageFile.order === 0) {
                    this.galleryFeaturedImg = imageFile.tempId;
                  }
                  console.log("TEMP ID", imageFile.tempId);

                  console.log("IM HERE!! WOOOO YEAH BABY!!!");

                  resolve(imageFile.file = file);
                })



              });
            });
          });
          return promise.then(() => {
            console.log("IM HERE!! WOOOO");
            this.galleryImages.push(imageFile);
            this.loadingGallery = false;
          });


          // }, (err) => {
          //   console.log(err);
          // });
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  async onGalleryImagesSelected(files) {
    this.loadingGallery = true;
    var reader = new FileReader();
    reader.onload = (onLoadEvent) => this.galleryFileIsready(onLoadEvent, files[0]);
    reader.readAsDataURL(files[0]);
    this.loadingGallery = false;
  }

  galleryFileIsready(onLoadEvent, file) {
    let imageFile: any = {
      order: this.galleryImages.length,
      url: onLoadEvent.target.result,
      tempId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      file: file
    }
    this.galleryImages.push(imageFile);
  }

  onFileSelectedFromWeb(event) {
    console.log("Incoming file", event[0]);
    const logo: any = [];
    logo.push(event[0]);
    this.locationLogo = logo;
    this.onFileSelected(event[0]);
  }

  onFileSelected(event) {
    var selectedFile = event;
    var reader = new FileReader();
    reader.onload = (onLoadEvent) => this.fileIsready(onLoadEvent);
    reader.readAsDataURL(selectedFile);
  }

  fileIsready(onLoadEvent) {
    this.imgPreview = onLoadEvent.target.result;
  }

  // Select a picture from the phone gallery
  async selectPictureForProfile(type) {
    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          if (type === 'logo') {
            this.imgPreview = '';
            this.isLoading = true;
            const logo: any = [];
            try {
              this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
                entry.file(file => {
                  console.log("FILE");
                  logo.push(file);
                  this.locationLogo = logo;
                  var reader = new FileReader();
                  const onLoadPromise: Promise<any> = new Promise(resolve => {
                    reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                    reader.readAsDataURL(file);
                  })
                  onLoadPromise.then(val => {
                    console.log("BASE64 PROFILE IMG");
                    this.imgPreview = val;
                    this.isLoading = false;
                  });
                });
              });
            } catch (errO) {
              console.log("errO");
              console.log(errO);
            }
          }
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }



  async selectPictureForGallery(type) {

    this.cameraPlugin
      .getPicture({
        quality: 90,
        destinationType: this.cameraPlugin.DestinationType.FILE_URI,
        sourceType: this.cameraPlugin.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.cameraPlugin.EncodingType.JPEG,
        targetWidth: 840,
        targetHeight: 840,
        correctOrientation: true
      })
      .then(
        (imageData) => {
          this.loadingGallery = true;

          var imageFile;
          const promise: Promise<any> = new Promise<any>((resolve) => {
            this.file.resolveLocalFilesystemUrl(imageData).then((entry: FileEntry) => {
              entry.file(async file => {
                // CONVERTING FILEENTRY TO BASE64
                var reader = new FileReader();
                var base64;


                const onLoadPromise: Promise<any> = new Promise(resolve => {
                  reader.onload = (onLoadEvent) => this.getBase64(onLoadEvent, resolve);
                  base64 = reader.readAsDataURL(file);
                })

                onLoadPromise.then(val => {
                  console.log("BASE 64", val);
                  imageFile = {
                    order: this.galleryImages.length,
                    url: val,
                    tempId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                  }
                  if (imageFile.order === 0) {
                    this.galleryFeaturedImg = imageFile.tempId;
                  }
                  console.log("TEMP ID", imageFile.tempId);

                  console.log("IM HERE!! WOOOO YEAH BABY!!!");

                  resolve(imageFile.file = file);
                })



              });
            });
          });
          return promise.then(() => {
            console.log("IM HERE!! WOOOO");
            this.galleryImages.push(imageFile);
            this.loadingGallery = false;
          });


          // }, (err) => {
          //   console.log(err);
          // });
        },
        (error) => {
          console.log('ERROR -> ' + JSON.stringify(error));
        }
      );
  }

  getBase64(event, resolve) {
    resolve(this.imgPreview = event.target.result);
    return;
  }

  formatISODate(open, close) {
    return {
      open: {
        hour: moment(open).hour(),
        minute: moment(open).minute(),
        second: 0
      },
      close: {
        hour: moment(close).hour(),
        minute: moment(close).minute(),
        second: 0
      }
    };
  }

  formatBusinessHours(hours) {
    return {
      monday: this.formatISODate(hours.monday.open, hours.monday.close),
      tuesday: this.formatISODate(hours.tuesday.open, hours.tuesday.close),
      wednesday: this.formatISODate(hours.wednesday.open, hours.wednesday.close),
      thursday: this.formatISODate(hours.thursday.open, hours.thursday.close),
      friday: this.formatISODate(hours.friday.open, hours.friday.close),
      saturday: this.formatISODate(hours.saturday.open, hours.saturday.close),
      sunday: this.formatISODate(hours.sunday.open, hours.sunday.close)
    };
  }

  onSubmit(formValues) {
    this.location.basicInfo = formValues.basicInfo;
    this.location.locationAddress = formValues.locationAddress;
    this.location.locationAddress.formatted_address = this.formatted_address;
    this.location.businessHours = this.formatBusinessHours(formValues.businessHours);
    this.location.amenities = this.defaultAmenities.concat(this.extraAmenities).concat(this.customAmenities);

    this.isSubmitting = true;
    if (this.galleryImages.length > 0) {
      this.location.gallery = this.galleryImages;
      this.location.featuredImg = this.galleryFeaturedImg;
    } else {
      this.location.gallery = null;
      this.location.featuredImg = null;
    }
    if (this.locationLogo.length > 0) {
      this.location.locationLogo = this.locationLogo;
    } else {
      this.location.locationLogo = null;
    }

    this.locationService.addLocation(this.location).then(() => {
      this.alertService.displayToast('Your location has been submitted for review');
      this.isSubmitting = false;
      this.imgPreview = '/assets/images/locations/location_profile.jpg';
      this.addedUser = [];
      this.saveImage = false;
      formValues = [];
      this.galleryImages = [];
      this.defaultAmenities = [];
      this.extraAmenities = [];
      this.customAmenities = [];
      this.locationAddForm.reset();
      this.locationAddForm.get('basicInfo.parking').patchValue('');
      this.locationAddForm.get('basicInfo.noiseLevel').patchValue('');
      this.locationAddForm.markAsPristine();
      this.resetFormAddress.next(true);
      this.showAddress = false;
      this.router.navigate(['/locations-list']);
    }).catch(error => {
      console.log('error adding location ', error);
      this.alertService.displayToast('Error creating location');
    })
  }

  goBack() {
    this.navCtrl.back();
  }

  ionViewDidLeave() {
    this.location = [];
    this.galleryImages = [];
    this.defaultAmenities = [];
    this.extraAmenities = [];
    this.customAmenities = [];
    defaultAmenities.concat(extraAmenities).forEach(amenity => {
      amenity.value = false;
    });
    this.locationAddForm.reset();
    this.locationAddForm.get('basicInfo.parking').patchValue('');
    this.locationAddForm.get('basicInfo.noiseLevel').patchValue('');
    this.locationAddForm.markAsPristine();
    this.resetFormAddress.next(true);
    this.showAddress = false;
  }

}
