
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'profile-popover-component',
  templateUrl: './profile-popover.component.html'
})
export class ProfilePopoverComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private popover: PopoverController,
    private router: Router,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
  }

  navigateTo(route) {
    this.router.navigate([route]);
    this.popover.dismiss();
  }

  async logOut() {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel'
      },
      {
        text: 'Yes',
        handler: () => {
          this.auth.signOutUser();
          console.log('user is logged out');
          this.router.navigate(['/landing']);
        }
      }
    ];
    this.alertService.displayAlert('Log Out', 'Are you sure you want to log out?', buttons);
  }

}
