import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { Subscription } from 'rxjs';
import { TransactionsService } from 'src/app/services/transactions.service';
import { productRenewal } from 'src/app/_shared/models/product-renewal';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

// tslint:disable

declare var cordova: any;

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html'
})
export class PaymentConfirmationComponent implements OnInit {

  pendingTransaction: any;
  transaction: any;
  transactionOutcome: any;
  transactionId;
  user: User;
  transactionSubscription: Subscription;
  authSubscription: Subscription;
  pendingSubscription: Subscription;
  paymentBrowser: any;

  purchaseDetails: any = {
    type: '',
    booking: '',
    subscription: ''
  };
  renewDays = productRenewal;
  frequency: any;
  errorMessage: any;

  isLoading = false;

  // CARDS
  cardsForm: FormGroup;
  payer: any;
  userCardsSubscription: Subscription;
  userCards: any[];
  changeDefault: boolean = false;
  changedCardId: string;
  amount: number;
  proRataAmount: number;
  deletingCard: boolean = false;
  hideCardSelection: boolean = false;
  defaultCard: any;
  deletedCardSubscription: Subscription;
  // ADD CARDS
  addCardBrowser: any;
  pendingCardSubscription: Subscription;
  cardErrorMsg: any;
  pendingCardId: string;

  // PENDING TRANSACTION
  deletePendingTransaction: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private transactionsService: TransactionsService,
    private subscriptionsService: SubscriptionsService,
    private bookingsService: BookingsService,
    private alert: AlertService,
    private router: Router,
    private fb: FormBuilder,
    private iab: InAppBrowser,
    private storageService: StorageService,
    private alerService: AlertService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.transactionOutcome = (this.pendingTransaction.transactionOutcome === 'Success') ? 2 : 3;
    console.log('transaction data for loaded page ', this.pendingTransaction);
    this.transactionId = this.pendingTransaction.transactionId;
    this.authSubscription = this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
        console.log('getting transaction from sub removed ', this.transactionId, this.transactionOutcome)
        if (this.transactionOutcome) {
          this.deletePendingTransaction = false;
          console.log('transaction outcome ', this.transactionOutcome);
          this.transactionsService.updateTransaction(this.transactionId, this.transactionOutcome).then((transaction: any) => {
            console.log('updated transaction from service with type ', this.transaction, transaction);
            this.transaction = transaction;
            if (transaction.status === 2) {
              if (transaction.type === 'userSubscription') {
                console.log('add user sub and status = 2 ');
                this.subscriptionsService.addConfirmedSubscription(transaction.typeId, transaction.status).then((data: any) => {
                  console.log('data from updated user sub ', data);
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    // SET FREQUENCY
                    const frequency = this.renewDays.filter((e) => { return e.value === data.frequency; });
                    data.frequency = frequency[0];
                    this.purchaseDetails.subscription = data;
                    this.isLoading = false;
                  }
                });
              }
              if (transaction.type === 'teamSubscription') {
                this.subscriptionsService.addConfirmedTeamSubscription(transaction).then((data: any) => {
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    // SET FREQUENCY
                    const frequency = this.renewDays.filter((e) => { return e.value === data.frequency; });
                    data.frequency = frequency[0];
                    this.purchaseDetails.subscription = data;
                    this.isLoading = false;
                  }
                });
              }
              if (transaction.type === 'meetingRoom') {
                let payment = true;
                this.bookingsService.updateBooking({ status: transaction.status }, transaction.typeId, payment).then((data: any) => {
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    this.purchaseDetails.booking = data;
                    this.isLoading = false;
                    this.alert.displayToast('Meeting Room Payment Succesfull');
                  }
                });
              }
            }
            if (transaction.status === 3) {
              // GET USER CARDS
              this.buildCardForm();
              this.userCardsSubscription = this.transactionsService.getUserCards(this.user.uid).subscribe(cardsData => {
                this.userCards = cardsData.filter((card: any) => { if (card.vaultId) return card; });
                if (this.userCards.length > 0) {
                  if (this.userCards.length === 1) {
                    this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
                      this.setDefaultCard();
                    });
                  } else {
                    this.setDefaultCard();
                  }
                }
                if (this.pendingTransaction.errorMessage) {
                  this.errorMessage = this.pendingTransaction.errorMessage;
                }
                this.isLoading = false;
              });
            }
          })
            .then(() => {
              this.transactionsService.deletePendingTransaction(this.pendingTransaction.id);
              this.isLoading = false;
            })
        } else {
          if (this.deletePendingTransaction) {
            this.transactionsService.updateTransaction(this.transactionId, 4).then((transaction: any) => {
              if (transaction.type === 'userSubscription' || transaction.type === 'teamSubscription') {
                this.subscriptionsService.deletePendingSubscription(transaction.typeId).then((data: any) => {
                  this.isLoading = false;
                });
              }
              if (transaction.type === 'meetingRoom') {
                this.bookingsService.updateBooking({ status: transaction.status }, transaction.typeId).then((data: any) => {
                  this.isLoading = false;
                });
              }
            });
          }
        }
      }
    })
  }

  buildCardForm() {
    this.cardsForm = this.fb.group({
      card: [''],
      defaultCard: [''],
    });
  }

  // CARDS
  setDefaultCard() {
    const setDefaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (setDefaultCard[0]) {
      this.cardsForm.get('card').patchValue(setDefaultCard[0].id);
      this.hideCardSelection = true;
      this.defaultCard = setDefaultCard[0];
    }
  }

  onCardChange() {
    this.changedCardId = this.cardsForm.get('card').value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(card => card.id === this.changedCardId && card.default === true);
      if (!noChange) {
        this.changeDefault = true;
        this.cardsForm.get('defaultCard').reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  setCardAsDefault() {
    const setDefault = this.cardsForm.get('defaultCard').value;
    if (setDefault) {
      this.transactionsService.setDefaultCard(this.changedCardId, this.user.uid).then(() => {
        this.alert.displayToast(`Default card changed`);
      });
    }
  }

  deleteCard(cardId) {
    this.deletingCard = true;
    this.transactionsService.deleteCard(cardId).then(data => {
      this.deletedCardSubscription = this.transactionsService.getDeletedCard(data).subscribe(card => {
        if (!card) {
          this.deletingCard = false;
        }
      });
    });
  }

  addCard() {
    const requestId = (Date.now()/Math.random()).toFixed(0);
    const cardUrlParams = `${environment.paymentUrl}?userId=${this.user.uid}&requestId=${requestId}`;
    this.addCardBrowser = this.iab.create(cardUrlParams, '_blank', 'usewkwebview=yes,toolbar=no,location=no');
    this.watchPendingCard(requestId);
    this.cardErrorMsg = '';
  }

  watchPendingCard(requestId) {
    this.pendingCardSubscription = this.transactionsService.checkPendingCards(requestId).subscribe((card: any) => {
      if (card.length > 0) {
        this.addCardBrowser.close();
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.displayCardAdded(card[0].cardStorageOutcome) : this.cardErrorMsg = card[0].description;
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.pendingCardId = card[0].id : this.pendingCardId = '';
      }
    });
  }

  displayCardAdded(outcome) {
    this.alerService.displayToast(outcome).then(() => {
      this.transactionsService.deletePendingCard(this.pendingCardId);
    });
  }

  changeCard() {
    this.hideCardSelection = false;
  }

  closeModal() {
    this.storageService.showHidePlan.next(true);
    this.modalCtrl.dismiss();
  }

  navigateToBookings() {
    this.router.navigate(['/profile'], { queryParams: { tab: 'bookings' } });
  }

  watchPendingTransactions(transactionId) {
    this.pendingSubscription = this.transactionsService.checkPendingTransactions(transactionId).subscribe((transaction: any) => {
      if (transaction.length > 0) {
        this.paymentBrowser.close();
        this.alert.openModal(PaymentConfirmationComponent, { pendingTransaction: transaction[0] }, '');
        this.closeModal();
      }
    });
  }

  payAgain() {
    const paymentUrl = 'https://secret-island-96823.herokuapp.com/';
    let cardId;
    let card;
    if (this.cardsForm.get('card').value && this.userCards.length > 0) {
      cardId = `&cardId=${this.cardsForm.get('card').value}`;
      card = `&card=No`;
    } else {
      cardId = '';
      card = `&card=Yes`;
    }
    this.transactionsService.deletePendingTransaction(this.pendingTransaction.id);
    const paymentUrlParams = `${paymentUrl}?amount=${this.transaction.amount}&userId=${this.user.uid}&transactionId=${this.transactionId}${card}${cardId}`;
    this.paymentBrowser = this.iab.create(paymentUrlParams, '_blank', 'usewkwebview=yes,toolbar=no,location=no');
    this.watchPendingTransactions(this.transactionId);
  }

  cancelTransaction() {
    this.storageService.showHidePlan.next(true);
    if (this.pendingTransaction) {
      if (this.transaction.type === 'userSubscription' || this.transaction.type === 'teamSubscription') {
        this.subscriptionsService.deletePendingSubscription(this.transaction.typeId);
      }
      if (this.transaction.type === 'meetingRoom') {
        this.bookingsService.updateBooking({ status: this.transaction.status }, this.transaction.typeId);
      }
      this.transactionsService.deletePendingTransaction(this.pendingTransaction.id).then(() => {
        if (this.transaction.type === 'userSubscription' || this.transaction.type === 'meetingRoom') {
          this.router.navigate(['/profile']);
          this.closeModal();
        };
        if (this.transaction.type === 'teamSubscription') {
          this.router.navigate([`/team/edit/${this.transaction.typeId}`]);
          this.closeModal();
        };
      });
    } else {
      this.router.navigate(['/profile']);
      this.closeModal();
    }
  }

  getType(type) {
    switch (type) {
      case 'userSubscription':
        return `User Subscription`;
      case 'teamSubscription':
        return `Team Subscription`;
      case 'meetingRoom':
        return `Meeting Room Booking`;
      default:
    }
  }

  ionViewDidLeave() {
    this.storageService.showHidePlan.next(true);
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.transactionSubscription) {
      this.transactionSubscription.unsubscribe();
    }
    if (this.deletedCardSubscription) {
      this.deletedCardSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

}
