import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeetingRoomsService } from '../_services/meeting-rooms.service';
import { StorageService } from 'src/app/services/storage.service';
import { weekdays } from '../../location/models/weekdays';
import { IonSlides, ModalController } from '@ionic/angular';
import { MeetingRoomBookingComponent } from 'src/app/_shared/components/modals/meeting-room-booking/meeting-room-booking.component';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { AuthenticationService } from '../../auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { Subscription } from 'rxjs';
import { Location } from "@angular/common";
import { MeetingRoomPaymentComponent } from 'src/app/_shared/components/modals/meeting-room-payment/meeting-room-payment.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'public-meeting-rooms-view',
  templateUrl: './meeting-rooms-view.component.html'
})
export class MeetingRoomsPublicViewComponent implements OnInit {
  isLoading = false;
  user: User;
  meetingRoom: any;
  meetingRoomId: any;
  meetingRoomSubscription: Subscription;

  roomIsBooked = false;

  // BUSINESS HOURS
  businessHours: any;
  businessDays: any[] = [];
  businessDay: any;

  // AMENITIES
  amenities: any;
  amenitiesIcons = {
    'Unlimited Wifi': 'wifi',
    'Limited Wifi': 'cellular',
    'Free Parking': 'car',
    'Paid Parking': 'car',
    '10% Discount on Food and Beverages': 'restaurant',
    '20% Discount on Food and Beverages': 'restaurant',
    'Toilets': 'person',
    'Free Coffee, Tea or Water': 'cafe',
    'Seats with Plugs': 'flash',
    'Video Games': 'desktop',
    'Printers': 'print',
    'VR': 'glasses',
    'Air-conditioning': 'snow',
    'Showers': 'water',
    'Gym': 'barbell'
  };

  // GALLERY
  gallery: any[];
  activeSliderId = "ngb-slide-" + 0;

  slideControl: any[] = [];

  backgroundImage: string;
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  sliderOne: any;

  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };


  @Input() locationId: string;

  constructor(
    private route: ActivatedRoute,
    private meetingRoomService: MeetingRoomsService,
    private storageService: StorageService,
    private modalCtrl: ModalController,
    private launchNavigator: LaunchNavigator,
    private auth: AuthenticationService,
    private locationCtrl: Location,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.ionViewDidEnter();
    
    if (this.route.snapshot.queryParamMap.get('requestId')) {
      this.alertService.openModal(MeetingRoomPaymentComponent, {}, '');
    }
  }

  ionViewDidEnter() {
    this.meetingRoomId = this.route.snapshot.paramMap.get('roomId');
    this.isLoading = true;

    this.auth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.meetingRoomSubscription = this.meetingRoomService.getMeetingRoomDetails(this.meetingRoomId).subscribe((meetingRoomData: any) => {

      // LOCATION INFO
      this.meetingRoom = meetingRoomData.room;
      this.storageService.singleLocation.next(this.meetingRoom);

      // BUSINESS HOURS
      this.businessHours = meetingRoomData.businessHours;
      this.businessDays = [];
      for (let businessHour in this.businessHours) {
        let openTime = {
          hour: this.businessHours[businessHour]['open'].hour,
          minute: this.businessHours[businessHour]['open'].minute
        }
        let closeTime = {
          hour: this.businessHours[businessHour]['close'].hour,
          minute: this.businessHours[businessHour]['close'].minute
        }
        const open = this.toTime(openTime);
        const close = this.toTime(closeTime);
        let closedDay = false;
        if (open === close) {
          closedDay = true;
        }
        let days = {
          day: businessHour,
          open: open,
          close: close,
          closed: closedDay
        }
        this.businessDays.push(days);
        if (this.businessDays.length === 7) {
          this.orderHours(weekdays, this.businessDays);
          const today = new Date().getUTCDay();
          this.businessDay = this.businessDays[today];
        }
      }

      // AMENITIES
      this.amenities = meetingRoomData.amenities.list.filter((amenity) => {
        return amenity.value == true;
      });

      // GALLERY
      this.gallery = meetingRoomData.gallery;
      if (this.meetingRoom.featureImage) {
        this.backgroundImage = this.meetingRoom.featureImage;
      }

      this.sliderOne = {
        isBeginningSlide: true,
        isEndSlide: false,
        slideItems: this.gallery
      };

      this.isLoading = false;
    });
  }

  // BUSINESS HOURS
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM'
    } else {
      meridian = 'AM'
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  orderHours(array_with_order, array_to_order) {
    let ordered_array = [],
      len = array_to_order.length,
      len_copy = len,
      index, current;

    for (; len--;) {
      current = array_to_order[len];
      index = array_with_order.indexOf(current.day);
      ordered_array[index] = current;
    }

    //change the array
    Array.prototype.splice.apply(array_to_order, [0, len_copy].concat(ordered_array));
  }

  // GALLERY
  cycleToSlide(id) {
    this.activeSliderId = "ngb-slide-" + id;
  }

  showNavigationArrows() {
    if (this.gallery.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  // INFO
  getParking(parking) {
    switch (parking) {
      case 'FO':
        return `Free Outdoor Parking`;
        break;
      case 'FI':
        return `Free Indoor Parking`;
        break;
      case 'PP':
        return `Paid Parking`;
        break;
      case 'NP':
        return `No Parking`;
        break;
      default:
    }
  }

  async openMeetingRoomBooking() {
    const modal = await this.modalCtrl.create({
      component: MeetingRoomBookingComponent,
      componentProps: {
        meetingRoom: this.meetingRoom,
        businessHours: this.businessHours,
        gallery: this.gallery
      }
    });

    modal.present();
  }

  launchNavigatorApp() {
    this.launchNavigator.navigate(this.meetingRoom.address.address);
  }

  checkinMeetingRoom() {

  }

  //Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disbale navigation  
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  goBack() {
    this.locationCtrl.back();
  }

  ionViewDidLeave() {
    this.meetingRoomSubscription.unsubscribe();
  }

}
