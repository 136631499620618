import { LoaderModule } from './../../_shared/loader-component/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MeetingRoomsPublicListComponent } from './meeting-rooms-list/meeting-rooms-list.component';
import { MeetingRoomsPublicViewComponent } from './meeting-rooms-view/meeting-rooms-view.component';
import { IonicModule } from '@ionic/angular';
import { ModalsModule } from 'src/app/_shared/components/modals/modals.module';
import { NgPipesModule } from 'ngx-pipes';
import { MeetingRoomsAddComponent } from './meeting-rooms-add/meeting-rooms-add.component';
import { MeetingRoomsLocationsListComponent } from './meeting-rooms-locations-list/meeting-rooms-locations-list.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { MeetingRoomsEditComponent } from './meeting-rooms-edit/meeting-rooms-edit.component';
import { MeetingRoomEditDetailsComponent } from './meeting-rooms-edit/meeting-room-details/meeting-room-details.component';
import { MeetingRoomEditHoursComponent } from './meeting-rooms-edit/meeting-room-hours/meeting-room-hours.component';
import { MeetingRoomEditAmenitiesComponent } from './meeting-rooms-edit/meeting-room-amenities/meeting-room-amenities.component';
import { MeetingRoomEditTypesComponent } from './meeting-rooms-edit/meeting-room-types/meeting-room-types.component';
import { MeetingRoomEditGalleryComponent } from './meeting-rooms-edit/meeting-room-gallery/meeting-room-gallery.component';
import { MeetingRoomsBookingsComponent } from './meeting-rooms-bookings/meeting-rooms-bookings.component';
import { ComponentsModule } from 'src/app/_shared/components/components.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ModalsModule,
        NgPipesModule,
        ReactiveFormsModule,
        FormsModule,
        LoaderModule,
        SortablejsModule,
        ComponentsModule
    ],
    declarations: [
        MeetingRoomsPublicListComponent,
        MeetingRoomsPublicViewComponent,
        MeetingRoomsLocationsListComponent,
        MeetingRoomsAddComponent,
        MeetingRoomsEditComponent,
        MeetingRoomEditDetailsComponent,
        MeetingRoomEditHoursComponent,
        MeetingRoomEditAmenitiesComponent,
        MeetingRoomEditTypesComponent,
        MeetingRoomEditGalleryComponent,
        MeetingRoomsBookingsComponent
    ],
    exports: [
        MeetingRoomsPublicListComponent,
        MeetingRoomsPublicViewComponent,
        MeetingRoomsLocationsListComponent,
        MeetingRoomsAddComponent,
        MeetingRoomsEditComponent,
        MeetingRoomEditDetailsComponent,
        MeetingRoomEditHoursComponent,
        MeetingRoomEditAmenitiesComponent,
        MeetingRoomEditTypesComponent,
        MeetingRoomEditGalleryComponent,
        MeetingRoomsBookingsComponent
    ]
})
export class MeetingRoomsModule { }