import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { config } from '../_shared/configs/config';

declare var toastr: any;

@Injectable()
export class CouponsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
  ) {
    this.entityId = config.entityId;
  }

  getCouponRef(couponRef) {
    const couponsCollection = this.afs.collection(`entities/${this.entityId}/coupons`, ref => ref.where('active', '==', true).where('couponRef', '==', couponRef));
    return couponsCollection.valueChanges({ idField: 'id' });
  }

  updateCoupon(id: string, coupon: any) {
    const couponDoc = this.afs.doc(`entities/${this.entityId}/coupons/${id}`)
    return couponDoc.set(coupon, { merge:true });
  }

}
