import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-user-notifications-list',
  templateUrl: './user-notifications.page.html'
})
export class UserNotificationsPage implements OnInit {
    
  isLoading = false;
  notifications$: Observable<Array<{}>>;
  notificationsRef: string;
  notificationsCount: 0;

  transactionsRef: string;
  transaction: any;

  // USER
  userId: string;
  userName: string;

  constructor(
    private auth: AuthenticationService,
    private notificationsService: NotificationsService,
    private nav: NavController
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.auth.user.subscribe((userDetails: User) => {
      if (userDetails) {
        this.userId = userDetails.uid;
        this.notificationsRef = `users/${this.userId}/entities/${config.entityId}`;
        this.fetchNotifications();
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
  }

  fetchNotifications() {
    this.notifications$ = this.notificationsService.getAllNotifications(this.notificationsRef);
  }

  getNotificationsLength(event) {
    this.notificationsCount = event;
  }

  getNotification(event) {
    console.log(event)
  }

  markAllAsRead() {
    this.notificationsService.markAllAsRead(this.notificationsRef);
  }

  goBack() {
    this.nav.back();
  }

}