import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { User } from 'src/app/_shared/models/users.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { Subscription } from 'rxjs';
import { TransactionsService } from 'src/app/services/transactions.service';
import { productRenewal } from 'src/app/_shared/models/product-renewal';
import { BookingsService } from 'src/app/pages/bookings/_services/bookings.service';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { LocationsService } from '../../location/_services/locations.service';
import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import * as queryString from 'query-string';

@Component({
  selector: 'app-payments-confirmation',
  templateUrl: './payments-confirmation.page.html'
})
export class PaymentsConfirmationPage implements OnInit, OnDestroy {

  isLoading = false;

  transactionId;
  transactionSubscription: Subscription;
  transaction: any;

  showInvitedTeamUsers = false;
  invitedTeamUsers: any;
  roomCapacity: number;
  invitedUserCount = 0;

  // USER
  authSubscription: Subscription;
  user: User;

  // PRODUCT DETAILS
  purchaseDetails: any = {
    type: '',
    booking: '',
    subscription: ''
  };
  renewDays = productRenewal;

  // CARDS
  cardsForm: FormGroup;
  payer: any;
  userCardsSubscription: Subscription;
  userCards: any[];
  hideCardSelection: boolean = false;
  defaultCard: any;
  changeDefault: boolean = false;
  changedCardId: string;
  deletingCard: boolean = false;
  deletedCardSubscription: Subscription;
  // ADD CARDS
  addCardBrowser: any;
  pendingCardSubscription: Subscription;
  cardErrorMsg: any;
  pendingCardId: string;

  amount: number;
  proRataAmount: number;

  // REDIRECT URL
  redirectURL: any;

  // INVITE USERS
  @ViewChild('inviteForm', { static: false }) public inviteForm: NgForm;
  inviteUsers: boolean = false;
  invitedUser: any = {};
  invitingUser: boolean = false;

  //currentPlatform
  currentPlatform: string;

  constructor(
    private auth: AuthenticationService,
    private transactionsService: TransactionsService,
    private subscriptionsService: SubscriptionsService,
    private bookingsService: BookingsService,
    private locationService: LocationsService,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private iab: InAppBrowser,
    private storageService: StorageService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private storage: Storage
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {

    this.isLoading = true;
    this.storageService.showHidePlan.next(false);
    
    this.storageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    });

    this.storage.get('extraData').then((extraData) => {
      if (extraData) {
        this.storage.remove('extraData');
      }
    });

    if (this.route.snapshot.queryParamMap.get('requestId')) {
      const requestParam = this.route.snapshot.queryParamMap.get('requestId');
      this.watchPendingCard(requestParam);
    }

    this.route.params.subscribe((params: Params) => {

      this.transactionId = params['transactionId'];

      this.authSubscription = this.auth.user.subscribe(user => {

        if (user) {

          this.user = user;

          this.transactionSubscription = this.transactionsService.getTransaction(this.transactionId).subscribe((transaction: any) => {
            this.transaction = transaction;

            if (this.transaction.type === 'userSubscription') {
              this.redirectURL = `/profile`;
            }
            if (this.transaction.type === 'teamSubscription') {
              this.redirectURL = `/team/edit/${this.transaction.typeId}`;
            }
            if (this.transaction.type === 'meetingRoom') {
              this.redirectURL = `/my-bookings`;
            }
            if (this.transaction.type === 'boostedAd') {
              this.redirectURL = `/location-edit/${this.transaction.typeId}`;
            }

            // SUCCESSFULL TRANSACTION
            if (this.transaction.status === 2) {

              if (this.transaction.type === 'userSubscription') {
                this.subscriptionsService.addConfirmedSubscription(transaction.typeId, transaction.status).then((data: any) => {
                  console.log('data from updated user sub ', data);
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    // SET FREQUENCY
                    const frequency = this.renewDays.filter((e) => { return e.value === data.frequency; });
                    data.frequency = frequency[0];
                    this.purchaseDetails.subscription = data;
                    this.isLoading = false;
                  }
                });
              }

              if (this.transaction.type === 'teamSubscription') {
                this.subscriptionsService.addConfirmedTeamSubscription(transaction).then((data: any) => {
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    // SET FREQUENCY
                    const frequency = this.renewDays.filter((e) => { return e.value === data.frequency; });
                    data.frequency = frequency[0];
                    this.purchaseDetails.subscription = data;
                    this.isLoading = false;
                  }
                });
              }

              if (this.transaction.type === 'meetingRoom' && this.transaction.invoiceDownload) {
                const payment = true;
                this.bookingsService.updateBooking({ status: transaction.status }, transaction.typeId, payment, this.transactionId).then((data: any) => {
                  if (data) {
                    this.purchaseDetails.type = transaction.type;
                    this.purchaseDetails.booking = data;
                    this.isLoading = false;
                    this.alert.displayToast('Meeting Room Payment Successful');

                    if (this.showInvitedTeamUsers) {
                      this.invitedTeamUsers.forEach(teamUser => {
                        this.invitedUserCount++;
                        teamUser.fullname = `${teamUser.firstname} ${teamUser.surname}`;
                        this.bookingsService.inviteUser(teamUser, this.purchaseDetails.booking).then(() => {
                        });
                      });
                    }
                  }
                });

                this.storageService.teamUsersForBooking.subscribe(users => {
                  if (users.length > 0) {
                    this.invitedTeamUsers = users;
                    this.showInvitedTeamUsers = true;
                  }
                });

                this.storageService.meetingRoomCapacity.subscribe(capacity => {
                  if (capacity > 0) {
                    this.roomCapacity = capacity;
                  }
                });
              }

              if (this.transaction.type === 'boostedAd' && this.transaction.invoiceDownload) {
                const boostedStartDate = moment(transaction.created.toDate()).toDate();
                const boostedEndDate = moment(boostedStartDate).add(1, 'months').toDate();
                const locationBoosted = {
                  boosted: true,
                  boostedStartDate,
                  boostedEndDate
                }
                this.locationService.updateLocation(transaction.typeId, locationBoosted).then(() => {
                  this.purchaseDetails.type = transaction.type;
                  this.purchaseDetails.info = transaction;
                  this.purchaseDetails.info.startDate = boostedStartDate;
                  this.purchaseDetails.info.endDate = boostedEndDate;
                  this.isLoading = false;
                  this.locationService.notifyAdmin(transaction, locationBoosted);
                  this.alert.displayToast('Boosted Ad Payment Successful');
                });
              }

            }

            // UNSUCCESSFUL TRANSACTION
            if (this.transaction.status === 3) {
              // GET USER CARDS
              this.buildCardForm();
              this.userCardsSubscription = this.transactionsService.getUserCards(this.user.uid).subscribe(cardsData => {
                this.userCards = cardsData.filter((card: any) => { if (card.cardToken) return card; });
                if (this.userCards.length > 0) {
                  if (this.userCards.length === 1) {
                    this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
                      this.setDefaultCard();
                    });
                  } else {
                    this.setDefaultCard();
                  }
                }
                this.isLoading = false;
              });
            }

          });

        }

      });
    });

  }

  buildCardForm() {
    this.cardsForm = this.fb.group({
      card: [''],
      defaultCard: [''],
    });
  }

  getType(type) {
    switch (type) {
      case 'userSubscription':
        return `User Subscription`;
      case 'teamSubscription':
        return `Team Subscription`;
      case 'meetingRoom':
        return `Meeting Room Booking`;
      case 'boostedAd':
        return `Location Boosted Ad`;
      default:
    }
  }

  // CARDS
  setDefaultCard() {
    const setDefaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (setDefaultCard[0]) {
      this.cardsForm.get('card').patchValue(setDefaultCard[0].id);
      this.hideCardSelection = true;
      this.defaultCard = setDefaultCard[0];
    }
  }

  onCardChange() {
    this.changedCardId = this.cardsForm.get('card').value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(card => card.id === this.changedCardId && card.default === true);
      if (!noChange) {
        this.changeDefault = true;
        this.cardsForm.get('defaultCard').reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  goToBookings() {
    console.log('click');
    this.router.navigate(['/my-bookings']);
  }

  setCardAsDefault() {
    const setDefault = this.cardsForm.get('defaultCard').value;
    if (setDefault) {
      this.transactionsService.setDefaultCard(this.changedCardId, this.user.uid).then(() => {
        this.alertService.displayToast(`Default card changed`);
        this.changeDefault = false;
      });
    }
  }

  deleteCard(cardId) {
    this.deletingCard = true;
    this.transactionsService.deleteCard(cardId).then(data => {
      this.deletedCardSubscription = this.transactionsService.getDeletedCard(data).subscribe(card => {
        if (!card) {
          this.deletingCard = false;
        }
      });
    });
  }

  changeCard() {
    this.hideCardSelection = false;
  }

  addCard() {
    let cardUrlParams;
    const requestId = (Date.now()/Math.random()).toFixed(0);
    if (this.currentPlatform !== 'desktop') {
      cardUrlParams = `${environment.paymentUrl}?userId=${this.user.uid}&requestId=${requestId}`;
      this.addCardBrowser = this.iab.create(cardUrlParams, '_blank', 'usewkwebview=yes,toolbar=no,location=no');
      this.watchPendingCard(requestId);
      this.cardErrorMsg = '';
    } else {
      const parseReturn = {
        returnUrl: `${environment.websiteUrl}/payments-confirmation/${this.transactionId}?requestId=${requestId}`
      }
      cardUrlParams = `${environment.paymentUrl}?${queryString.stringify(parseReturn)}&userId=${this.user.uid}&requestId=${requestId}`;
      window.location.href = cardUrlParams;
    }
  }

  watchPendingCard(requestId) {
    this.pendingCardSubscription = this.transactionsService.checkPendingCards(requestId).subscribe((card: any) => {
      if (card.length > 0) {
        if (this.currentPlatform !== 'desktop') {
          this.addCardBrowser.close();
        }
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.displayCardAdded(card[0].cardStorageOutcome) : this.cardErrorMsg = card[0].description;
        (!Number.isInteger(card[0].cardStorageOutcome)) ? this.pendingCardId = card[0].id : this.pendingCardId = '';
      }
    });
  }

  displayCardAdded(outcome) {
    this.alertService.displayToast(outcome).then(() => {
      this.transactionsService.deletePendingCard(this.pendingCardId);
    });
  }

  payAgain() {
    this.transaction.renewTransaction = true;
    this.transactionsService.addTransaction(this.transaction, this.transaction.amount).then(transactionId => {
      if (transactionId) {
        this.storageService.showHidePlan.next(false);
        this.cardErrorMsg = '';
        this.router.navigate([`/payments-confirmation/${transactionId}`]);
      }
    });
  }

  cancelTransaction() {
    this.storageService.showHidePlan.next(true);
    if (this.transaction) {
      if (this.transaction.type === 'userSubscription' || this.transaction.type === 'teamSubscription') {
        this.subscriptionsService.deletePendingSubscription(this.transaction.typeId);
      }
      if (this.transaction.type === 'meetingRoom') {
        this.bookingsService.updateBooking({ status: this.transaction.status }, this.transaction.typeId);
      }
      this.transactionsService.updateTransaction(this.transaction.uid, 4).then(() => {
        this.router.navigate([this.redirectURL]);
      });
    } else {
      this.router.navigate([this.redirectURL]);
    }
  }

  goBack() {
    this.router.navigate([this.redirectURL]);
    this.storageService.showHidePlan.next(true);
  }

  inviteUser(user) {
    this.invitingUser = true;
    this.bookingsService.inviteUser(user, this.purchaseDetails.booking).then(() => {
      this.invitedUserCount++;
      this.invitingUser = false;
      this.inviteForm.reset();
    });
  }

  ionViewDidLeave() {
    this.storageService.showHidePlan.next(true);
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.transactionSubscription) {
      this.transactionSubscription.unsubscribe();
    }
    this.storageService.meetingRoomCapacity.next(0);
    this.storageService.teamUsersForBooking.next({});
    this.invitedUserCount = 0;
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

}
