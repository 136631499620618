import { BookingsService } from './../../../../pages/bookings/_services/bookings.service';
import { AuthenticationService } from './../../../../pages/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { User } from 'src/app/_shared/models/users.model';
import { config } from 'src/app/_shared/configs/config';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MeetingRoomPaymentComponent } from '../meeting-room-payment/meeting-room-payment.component';
import { UsersService } from 'src/app/services/users.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'public-meeting-rooms-view',
  templateUrl: './meeting-room-booking.component.html'
})
export class MeetingRoomBookingComponent implements OnInit {
  meetingRoomBookingForm: FormGroup;
  formDetails: any;

  usersForBooking: any;
  team: any;

  paymentDetails: any;

  bookingData: any;

  meetingRoomBookings: Subscription;
  dateSelectMessage = '';

  meetingRoom: any;
  businessHours: any;
  businessDay: any;
  gallery: any;

  allBookingTimes: any[] = [];
  isLoading = false;
  startTime: '';
  currentDate;
  minDate;
  minTime;
  maxTime;

  showBookedMessage = false;

  loggedInUser: User;
  user: User;

  submitting = false;

  //currentPlatform
  currentPlatform: string;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private usersService: UsersService,
    private storageService: StorageService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    
    this.storageService.currentPlatform.subscribe(platform => {
      this.currentPlatform = platform;
    })

    this.buildForm();
    this.minDate = new Date().toISOString();
    this.storageService.meetingRoomCapacity.next(this.meetingRoom.maxPeople);
    this.auth.user.subscribe(userDetails => {

      if (userDetails) {
        this.loggedInUser = userDetails;

        this.currentDate = new Date();

        this.filterHours(this.currentDate.getUTCDay());

        this.usersService.fetchUserDetails(`/users/${this.loggedInUser.uid}`).subscribe(userInfo => {
          if (userInfo) {
            this.user = userInfo;
          }
        });

        this.meetingRoomBookings = this.bookingsService.getMeetingRoomBookings(this.meetingRoom.uid).subscribe((bookingsData: any) => {

          bookingsData.forEach((booking) => {
            if (booking.endTime.getTime() >= this.currentDate.getTime()) {
              const times = {
                date: moment(booking.startTime).format('DD/MM/YYYY'),
                start: booking.startTime.getTime(),
                end: booking.endTime.getTime(),
                times: moment(booking.startTime).format('HH:mm') + ' ' + moment(booking.endTime).format('HH:mm')
              }
              this.allBookingTimes.push(times);
            }
          });
          this.isLoading = false;
        });
      }
    });
  }

  filterHours(day) {
    if (day) {
      const selectedDay = moment(day).day();

      let daySelect;
      switch (selectedDay) {
        case 0: daySelect = this.businessHours.sunday;
          break;
        case 1: daySelect = this.businessHours.monday;
          break;
        case 2: daySelect = this.businessHours.tuesday;
          break;
        case 3: daySelect = this.businessHours.wednesday;
          break;
        case 4: daySelect = this.businessHours.thursday;
          break;
        case 5: daySelect = this.businessHours.friday;
          break;
        case 6: daySelect = this.businessHours.saturday;
          break;
      }

      this.minDate = this.currentDate.toISOString();

      this.minTime = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDay(),
        daySelect.open.hour, daySelect.open.minute, this.currentDate.getSeconds(), this.currentDate.getMilliseconds());

      this.maxTime = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDay(),
        daySelect.close.hour, daySelect.close.minute, this.currentDate.getSeconds(), this.currentDate.getMilliseconds());

      this.minTime = moment(this.minTime).format();
      this.maxTime = moment(this.maxTime).format();

      if (this.maxTime === this.minTime) {
        this.meetingRoomBookingForm.get('meetingTime.start').disable();
        this.meetingRoomBookingForm.get('meetingTime.start').disable();
        this.meetingRoomBookingForm.get('meetingDate').reset();
        this.dateSelectMessage = 'The meeting room is closed on the selected day. Please select another day';
      }
    }
  }

  buildForm() {
    this.meetingRoomBookingForm = this.fb.group({
      noPeople: ['', [Validators.required, Validators.max(this.meetingRoom.maxPeople)]],
      meetingDate: ['', Validators.required],
      meetingTime: this.fb.group({
        start: ['', [Validators.required]],
        end: ['', [Validators.required]]
      }),
      additionalInfo: ['']
    });

    this.meetingRoomBookingForm.get('meetingTime').disable();
    this.onValueChanges();
  }

  onValueChanges(): void {
    this.meetingRoomBookingForm.get('meetingDate').valueChanges.subscribe((meetingDate: any) => {
      if (meetingDate) {
        this.meetingRoomBookingForm.get('meetingTime').enable();
        this.meetingRoomBookingForm.get('meetingTime').valid;
      } else {
        this.meetingRoomBookingForm.get('meetingTime').reset();
        this.meetingRoomBookingForm.get('meetingTime').disable();
      }
    });
    this.meetingRoomBookingForm.get('meetingTime.start').valueChanges.subscribe((startTime: any) => {
      if (startTime) {
        // GET START TIME
        const startMeetingDate: any = new Date(
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('YYYY'),
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('MM') - 1,
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('DD'),
          parseInt(moment(Date.parse(startTime)).format('HH')),
          parseInt(moment(Date.parse(startTime)).format('mm')),
          parseInt(moment(Date.parse(startTime)).format('ss')));
        const startingTime = startMeetingDate.getTime();
        const date = moment(startMeetingDate).format('DD/MM/YYYY');
        // GET ALL BOOKING TIMES
        const checkStart = this.allBookingTimes.some(time => date === time.date && time.start <= startingTime && time.end > startingTime);
        const endingTime = this.parseISODate(startTime, 'endTime');

        if (!checkStart) {
          this.meetingRoomBookingForm.get('meetingTime.end').patchValue(endingTime);
          this.showBookedMessage = false;
        } else {
          this.meetingRoomBookingForm.get('meetingTime.end').patchValue('');
          this.showBookedMessage = true;
        }
      }
    });
    this.meetingRoomBookingForm.get('meetingTime.end').valueChanges.subscribe((endTime: any) => {
      if (endTime) {
        const endMeetingDate: any = new Date(
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('YYYY'),
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('MM') - 1,
          +moment(this.meetingRoomBookingForm.get('meetingDate').value).format('DD'),
          parseInt(moment(Date.parse(endTime)).format('HH')),
          parseInt(moment(Date.parse(endTime)).format('mm')),
          parseInt(moment(Date.parse(endTime)).format('ss')));
        // GET END TIME
        const endingTime = endMeetingDate.getTime();
        const date = moment(endMeetingDate).format('DD/MM/YYYY');
        // GET ALL BOOKING TIMES
        const checkEnd = this.allBookingTimes.some(time => date === time.date && time.start < endingTime && time.end > endingTime);
        if (checkEnd) {
          this.meetingRoomBookingForm.get('meetingTime.end').patchValue('');
          this.showBookedMessage = true;
        } else {
          this.showBookedMessage = false;
        }
      }
    });
  }

  private parseISODate(isoDate, type) {
    const date = isoDate.split(/[^0-9]/);
    let returnDate;
    if (type === 'date') {
      returnDate = new Date(
        date[0],
        date[1],
        date[2],
        date[3],
        date[4],
        date[5]);
    }
    if (type === 'endTime') {
      const nextHour: number = parseInt(date[3]) + 1;
      returnDate = new Date(
        date[0],
        date[1] - 1,
        date[2],
        nextHour,
        date[4],
        date[5]);
      returnDate = moment(returnDate).format();
    }

    if (type === 'saveEndTime') {
      const returnHour: number = parseInt(date[3]) + 1;
      returnDate = new Date(
        date[0],
        date[1] - 1,
        date[2],
        returnHour,
        date[4],
        date[5]);
    }
    return returnDate;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  formatIsoDate(date): any {
    const timestamp = Date.parse(date);
    const convert = new Date(timestamp);
    const returnDate = {
      hour: convert.getHours(),
      minute: convert.getMinutes(),
      second: convert.getSeconds(),
      day: convert.getDate(),
      month: convert.getMonth(),
      year: convert.getFullYear(),
    };
    return returnDate;
  }

  onSubmit() {
    // this.submitting = true;
    this.formDetails = this.meetingRoomBookingForm.value;
    const meetingStart = this.formatIsoDate(this.formDetails.meetingTime.start);
    const meetingEnd = this.formatIsoDate(this.formDetails.meetingTime.end);
    const dateMeet = this.formDetails.meetingDate.split(/[^0-9]/);
    const meetStart = this.formDetails.meetingTime.start.split(/[^0-9]/);
    const meetEnd = this.formDetails.meetingTime.end.split(/[^0-9]/);

    const startMeetingDate: any = new Date(
      dateMeet[0],
      dateMeet[1] - 1,
      dateMeet[2],
      meetStart[3],
      meetStart[4],
      meetStart[5]);

    const endMeetingDate: any = new Date(
      dateMeet[0],
      dateMeet[1] - 1,
      dateMeet[2],
      meetEnd[3],
      meetEnd[4],
      meetEnd[5]);

    const milliseconds = Math.abs(startMeetingDate - endMeetingDate);
    const hours = milliseconds / 36e5;
    const calculatedPrice = (Math.ceil(hours) * this.meetingRoom.hourlyRate);
    const wsPercentage = (calculatedPrice / 100) * 14;
    const locationPercentage = calculatedPrice - wsPercentage;
    const calculatedActualPrice = (this.meetingRoom.actualRate) ? (Math.ceil(hours) * this.meetingRoom.actualRate) : 0;

    // this.modalData
    const booking = {
      active: true,
      price: +calculatedPrice.toFixed(2),
      created: new Date(),
      createdBy: this.auth.userId,
      createdByName: `${this.auth.userDetails.firstname} ${this.auth.userDetails.surname}`,
      name: this.meetingRoom.name,
      startDate: startMeetingDate,
      endDate: endMeetingDate,
      locationId: this.meetingRoom.locationId,
      locationRef: `entities/${config.entityId}/locations/${this.meetingRoom.locationId}`,
      locationName: this.meetingRoom.locationName,
      type: 'meetingRoom',
      typeId: this.meetingRoom.uid,
      typeRef: `entities/${config.entityId}/meetingRooms/${this.meetingRoom.uid}`,
      referencePrefix: `MEETING-B-`,
      entityId: config.entityId,
      source: config.source,
      description: this.formDetails.additionalInfo,
      noPeople: this.formDetails.noPeople,
      address: this.meetingRoom.address,
      status: 1,
      locationPercentage,
      wsPercentage,
      actualRate: +calculatedActualPrice.toFixed(2)
    };

    this.bookingData = booking;

    let modalData: any = {};
    modalData.booking = this.bookingData;

    if (this.usersForBooking) {
      modalData.usersForBooking = this.usersForBooking;
    }
    if (this.team) {
      modalData.team = this.team;
      modalData.payerInfo = this.user;
    } else {
      modalData.user = this.user;
    }

    this.alertService.openModal(MeetingRoomPaymentComponent, {
      booking: this.bookingData, extraData: {
        submit: true,
        type: 'modal',
        data: modalData
      }
    }, '');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
