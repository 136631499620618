import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { Sender } from 'src/app/_shared/models/sender';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'src/app/_shared/models/message';
import { MessagesService } from 'src/app/services/messages.service';
import { Conversation } from 'src/app/_shared/models/conversation';
import { AuthenticationService } from '../../auth/auth.service';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { config } from "../../../_shared/configs/config";

declare var $: any;

@Component({
  selector: "app-messages-conversations-page",
  templateUrl: "./messages-conversations.page.html",
  styleUrls: ['../messages.scss']
})
export class MessagesConversationsComponent implements OnInit, OnDestroy {

  messagesSubscription: Subscription;
  isLoading: boolean = false;
  messagesList: any;
  conversationDetails: Conversation;
  userIdOfConversations: string;
  senderDetails: Sender;
  currentUserId: string;
  activeConversationId: string;
  messageObj: object;  
  selected = '';
  conversationId: string;
  conversationName: string;
  conversationIdToLoad: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private auth: AuthenticationService,
    private navCtrl: NavController,
    private storage: Storage
  ) {
    this.conversationDetails = new Conversation();
    this.senderDetails = new Sender();
  }

  ngOnInit() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.conversationIdToLoad = this.route.snapshot.queryParamMap.get('conversationIdToLoad');
    this.storage.get('location').then((location) => {
      this.auth.user.subscribe(user => {
        if (user) {
          this.currentUserId = user.uid;
          if (this.conversationIdToLoad) {
            if (this.conversationIdToLoad === 'location') {
              this.senderDetails.id = location.refNo;
              this.senderDetails.name = location.name;
              this.senderDetails.image = (location.uploadedPhotoThumbnail && location.uploadedPhotoThumbnail !== '') ? location.uploadedPhotoThumbnail : config.wealthSpacesIcon;
              this.userIdOfConversations = location.refNo;  
            } else {
              this.senderDetails.id = user.uid;
              this.senderDetails.name = user.firstname + " " + user.surname;
              this.senderDetails.image = (user.uploadedPhotoThumbnail) ? user.uploadedPhotoThumbnail : user.photoURL;
              this.userIdOfConversations = user.uid;
            }
          } else {
            console.log(user)
            this.senderDetails.id = user.uid;
            this.senderDetails.name = user.firstname + " " + user.surname;
            this.senderDetails.image = (user.uploadedPhotoThumbnail) ? user.uploadedPhotoThumbnail : user.photoURL;
            this.userIdOfConversations = user.uid;
          }
          this.conversationId = this.route.snapshot.paramMap.get('conversationId'); 
          this.conversationName = this.route.snapshot.queryParamMap.get('conversationName');
          this.scrollToConversation(this.conversationId);
          this.messagesService.updateConversationToRead(this.userIdOfConversations, this.conversationId) 
        }
      }); 
    });
  }

  scrollToConversation(conversationId) {
    this.selected = conversationId;
    this.activeConversationId = conversationId;
    this.messagesSubscription = this.messagesService
      .getMessagesForConversation(
        this.userIdOfConversations,
        this.activeConversationId
      )
      .subscribe((messages) => {
        if (messages) {
          this.messagesList = messages;
          setTimeout(function () {
            if (messages.length > 0) {
              const lastMessage = document.getElementById(
                messages[messages.length - 1]["id"]
              );
              if (lastMessage) {
                // NEED TO FIX THE SCROLL
                lastMessage.scrollIntoView();
              }
            }
          }, 500);
        }
      });
  }

  addMessage(newMessageForm) {
    const messageObj = new Message();
    if (newMessageForm.message) {
      messageObj.text = newMessageForm.message;
      messageObj.created = new Date();
      messageObj.createdById = this.senderDetails.id;
      messageObj.createdByName = this.senderDetails.name;
      messageObj.createdByImage = (this.senderDetails.image) ? this.senderDetails.image : '/assets/images/icon/WS-ICON.png';
      messageObj.type = "text";
      this.messagesService
        .addMessageToConversation(
          this.userIdOfConversations,
          this.activeConversationId,
          Object.assign({}, messageObj)
        )
        .then(() => {
          newMessageForm.message = "";
        });
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  ionViewDidLeave() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

}
