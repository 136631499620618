import { Component, OnInit, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { MessagesService } from 'src/app/services/messages.service';
import { AuthenticationService } from 'src/app/pages/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: "app-messages-conversations-create-page",
  templateUrl: "./messages-conversations-create.page.html",
  styleUrls: ["../messages.scss"],
})
export class MessagesConversationsCreateComponent implements OnInit {

  messagesSubscription: Subscription;
  messagesList: any;
  message: string;
  messageObj:object;
  currentUserId: string;
  conversationId: string;
  conversationName: string;
  locationId: string;

  constructor(
    private messagesService: MessagesService,
    private auth: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
  }

  ionViewDidEnter() {

    this.auth.user.subscribe(user => {
      if (user) {
        this.currentUserId = user.uid;
        this.conversationId = this.route.snapshot.paramMap.get('conversationId'); 
        this.conversationName = this.route.snapshot.queryParamMap.get('conversationName');
        this.locationId = (this.route.snapshot.queryParamMap.get('locationId')) ? this.route.snapshot.queryParamMap.get('locationId') : null;
        this.createDefaultMessageObj();
        this.messagesSubscription = this.messagesService
          .getMessagesForConversation(
            this.auth.userDetails.uid,
            this.conversationId
          )
          .subscribe((messages) => {
            this.messagesList = messages;
            console.log(this.messagesList)
            if (messages.length === 0) {
              this.messagesService.createDefaultConversation(
                this.auth.userDetails.uid,
                this.conversationId,
                this.locationId
              );
            }
            
            setTimeout(function () {
              if (messages.length > 0) {
                const lastMessage = document.getElementById(
                  messages[messages.length - 1]["id"]
                );
                if (lastMessage) {
                  lastMessage.scrollIntoView();
                }
              }
            }, 500);
          });
        }
      
    }); 
  }

  addMessage(newMessageForm) {
    if (newMessageForm.message) {
      const image = (this.auth.userDetails.uploadedPhotoThumbnail) ? this.auth.userDetails.uploadedPhotoThumbnail : this.auth.userDetails.photoURL;
      this.messageObj['text'] = newMessageForm.message;
      this.messageObj['created'] = new Date();
      this.messageObj['createdById'] = this.auth.userId;
      this.messageObj['createdByName'] = this.auth.userDetails.firstname + " " + this.auth.userDetails.surname;
      this.messageObj['createdByImage'] = (image) ? image : '/assets/images/icon/WS-ICON.png';
      this.messageObj['type'] = 'text';
      this.messagesService
        .addMessageToConversation(
          this.auth.userDetails.uid,
          this.conversationId,
          this.messageObj
        )
        .then(() => {
          newMessageForm.message = "";
          this.message = "";
          this.createDefaultMessageObj();
        });
    }
  }

  createDefaultMessageObj() {
    this.messageObj = 
    {
      text : 'Default message',
      created  :  new Date(),
      createdById  :  'wealthspaces',
      createdByName  :  "WealthSpaces",
      type  :  'text',
      createdByImage  :  "/assets/images/icon/WS-ICON.png",
    }
  }

  ionViewDidLeave() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.ionViewDidLeave();
  }

  goBack() {
    this.navCtrl.back();
  }
}

