import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { config } from 'src/app/_shared/configs/config';
import { AuthenticationService } from '../pages/auth/auth.service';

@Injectable()
export class RatingService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthenticationService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  // Rating reviews that belong to a user
  getUserRatings(userId) {
    const ratingsRef = this.afs.collection('stars', ref => ref.where('userId', '==', userId));
    return ratingsRef.valueChanges();
  }

  // Get all ratings that belong to a location
  getRatingStars(ref) {
    const starsRef = this.afs.collection(ref);
    return starsRef.valueChanges();
  }

  addRating(rating, ref, type, locationId) {
    let ratingData = {
      active: true,
      createdBy: this.loggedInUser.uid,
      ratedBy: this.loggedInUser.uid,
      ratedByType: 'user',
      ratingType: type,
      created: new Date(),
      rating: rating,
      uid: this.loggedInUser.uid
    }

    if (locationId) {
      ratingData.ratedBy = locationId;
      ratingData.ratedByType = 'location';
    }

    const ratingId = (!locationId) ? this.loggedInUser.uid : locationId;

    const starDoc = this.afs.collection(ref).doc(ratingId);
    return starDoc.set(ratingData, { merge: true });
  }

  setLocationAvgRating(locationId, avgRating) {
    return this.afs.doc(`entities/${config.entityId}/locations/${locationId}`).set({ avgRating }, { merge: true });
  }

  addComment(locationId, reviewerId, comment, rating, type) {
    const locationReviewsCollection = this.afs.collection(`entities/${config.entityId}/locations/${locationId}/reviews`);
    let ratingData = {
      active: true,
      comment: comment,
      createdBy: this.loggedInUser.uid,
      ratedBy: reviewerId,
      ratedByName: this.loggedInUser.firstname + ' ' + this.loggedInUser.surname,
      ratedByEmail: this.loggedInUser.email,
      ratedByType: type,
      rating: rating,
      created: new Date()
    };
    return locationReviewsCollection.add(ratingData);
  }

}
